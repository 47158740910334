// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.my-datatable {
    border-radius: 20px;
    background: white;
}`, "",{"version":3,"sources":["webpack://./src/pages/Sell/Index.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,iBAAiB;AACrB","sourcesContent":[".my-datatable {\n    border-radius: 20px;\n    background: white;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
