import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import SmallLogo from '../../../assets/Images/LogoBlue.png'
import Modal from 'react-bootstrap/Modal';
import { P, Small, Card, Row, Label, Input, Button } from './Styled'
import OTPInput from "otp-input-react";
import Select from 'react-select'
import axios from 'axios';
import { API_URLs } from '../../../Utils/ApiUrls';
import './Signup.css'
import { Para } from './Otp';
import { useAPIContextHook } from '../../../Context/ApiContext';
import { useThemeContext } from '../../../Context/ThemeContext';
import { useFormik } from 'formik';
import * as Yup from 'yup'
import CustomModal from '../../../hooks/CustomModal';
import ButtonLoader from '../../../Component/Loader/ButtonLoader';
import { RxCross2 } from "react-icons/rx";
// import Icon from '../../../images/Home/qvaniIcon.png'


const details = (number) => {
    const data = [
        {
            name: 'Let’s get started',
            head: 'Verify your Phone Number to unlock trading. No ID is required.',
            label: 'Phone Number',
            placeholder: 'Enter Phone Number',
            button: 'Verify Phone Number'
        },
        {
            name: 'Choose a Username',
            head: 'This Username is public and will be displayed on you public profile. No ID is required.',
            label: 'Username',
            placeholder: 'Enter Username',
            button: 'Start Trading'
        }
    ]
    return data[number];
}

function CardContent({ type, setType }) {
    const navigate = useNavigate();

    const { phoneCode } = useAPIContextHook();
    const { isDarkTheme } = useThemeContext();

    const [phone, setPhone] = useState('');

    const handlePhoneChange = (value) => {
        setPhone(value);
    };


    const [loading, setLoading] = useState('');
    const formik = useFormik({
        initialValues: {
            input: '',
            phoneCode: '',
        },
        validationSchema: Yup.object().shape({
            input: Yup.string().required(`${type === 0 ? 'Phone Number' : 'Username'} is required`),
            // phoneCode: Yup.object().shape({
            //     value: Yup.string().required('Phone code is required')
            // })
        }),
        onSubmit: (values) => {
            console.log('phoneCodeValue', values);
            setLoading(true);
            if (type === 0) {
                handleVerifyPhone(values)
            } else {
                handleAddUsername(values)
            }
        }
    })
    const [otpValue, setOtpValue] = useState('');
    const [show, setShow] = useState(false);
    const [token, setToken] = useState('');

    const [showModal, setShowModal] = useState(false);
    const [modalData, setModalData] = useState(null);
    const [navigateLink, setNavigateLink] = useState('');
    const handleShowModal = (data) => {
        setModalData(data);
        setShowModal(true);
    };
    const handleCloseModal = () => {
        setShowModal(false)
    };


    const handleClose = () => {
        setShow(false)
    }

    const sortedPhoneCode = phoneCode.sort((a, b) => a.label.localeCompare(b.label));

    function handleVerifyPhone(values) {

        axios.post(API_URLs.signUpMobile, {
            phone: `${phone.label}` + values.input,
            phoneCode: `${phone.label}`

        }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('jwt')}`,
                Accept: 'application/json'
            }
        })
            .then((res) => {
                setToken(res.data?.tokens);
                formik.setValues({
                    input: '',
                    phoneCode: ''
                })
                setLoading(false);
                setShow(true);
            })
            .catch((err) => {
                console.log(err)
                setLoading(false)
                handleShowModal(err?.response?.data?.message ? err?.response?.data?.message : 'Some Error Occured')
            })
    }
    function handleVerifyOtp() {
        setLoading(false);
        axios.post(API_URLs.signUpMobileVerify, {
            token: token,
            otp: otpValue
        }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('jwt')}`,
                Accept: 'application/json'
            }
        })
            .then((res) => {
                setType(type + 1);
                setShow(false);
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                console.log(error)
            })
    }
    function handleAddUsername(values) {
        axios.post(API_URLs.signUpNickName, {
            nickname: values.input
        }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('jwt')}`,
                Accept: 'application/json'
            }
        })
            .then((res) => {
                setLoading(false);
                handleShowModal(res?.data?.msg ? res?.data?.msg : 'You can now start trading, after login')
                setNavigateLink('/login');
            })
            .catch((err) => {
                handleShowModal(err?.response?.data?.message ? err?.response?.data?.message : 'Some Error Occured');
                setLoading(false);
                console.log(err);
            })
    }

    // const styles = {
    //     control: (styles) => ({
    //         ...styles,
    //         borderRadius: '20px',
    //         outline: 'none',
    //         border: `${({ theme }) => theme.border}`, "&:hover": {
    //             border: `${({ theme }) => theme.border}`
    //         },
    //         boxShadow: `${({ theme }) => theme.boxshadow}`,
    //         color: 'white'
    //     }),
    //     option: (provided, state) => ({
    //         ...provided,
    //         backgroundColor: isDarkTheme && state.isHovered ? '#302F30' : `${({ theme }) => theme.body}`,
    //         ':hover': {
    //             backgroundColor: `${isDarkTheme ? '#302F30' : '#D9EDE7'}`,
    //         },
    //         color: isDarkTheme && state.isSelected ? '#1476FF' : `${({ theme }) => theme.text}`,
    //     }),
    //     indicatorsContainer: (styles) => ({
    //         ...styles, color: isDarkTheme ? 'white' : 'black', 'div:nth-child(2)': {
    //             color: isDarkTheme ? 'white' : 'black',
    //             padding: '0px !important'
    //         }
    //     }),
    //     menu: (styles) => ({
    //         ...styles, background: isDarkTheme ? 'rgb(27, 27, 28)' : 'white',
    //         color: isDarkTheme ? 'white' : 'black',
    //         width: '20vw'
    //     })
    // }

    const styles = {
        control: (styles) => ({
            ...styles,
            width: '9vw',
            borderRadius: '20px',
            outline: 'none',
            border: 'none',
            "&:hover": {
                border: 'none'
            },
            boxShadow: 'none',
            color: 'black',
        }),
        placeholder: (styles) => ({
            ...styles,
            color: 'grey',
            cursor: 'pointer',
        }),

        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isHovered ? '#f0f0f0' : '#fff',
            color: 'black',
        }),
        indicatorsContainer: (styles) => ({
            ...styles,
            color: 'black',
            'div:nth-child(2)': {
                color: 'black',
                padding: '0px !important'
            }
        }),
        menu: (styles) => ({
            ...styles,
            background: 'white',
            color: 'black',
            width: '15vw',
            zIndex: 999,
        }),
        
    };

    const filterOptions = (candidate, input) => {
        if (!input) return true;
    
        const inputValue = input.toLowerCase();
        const label = candidate.label.toLowerCase();
        
        return label.startsWith(inputValue) || label.includes(inputValue);
    };


    const [time, setTime] = useState(600);

    useEffect(() => {
        const interval = setInterval(() => {
            if (time > 0) {
                setTime(time - 1);
            } else {
                clearInterval(interval);
            }
        }, 1000);

        return () => clearInterval(interval);
    }, [time]);

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    };

    // let modifiedDropdownValue = { label: formik?.values.phoneCode ? formik.values.phoneCode.value : '', value: '' }
    // let placeholderText = modifiedDropdownValue.label ? '' : 'Search';

    return (
        <>
            <P className='mb-0'>{details(type).name}</P>
            <Small>{details(type).head}</Small><br />
            <form onSubmit={formik.handleSubmit}>
                <Label className='mt-4'>{details(type).label}</Label>
                <div className='selct-cntrr d-flex gap-1 align-items-center position-relative'>

                    {type === 0 && (
                        <>
                            {/* {!isSearching && (
                                <div style={{ position: 'absolute', top: '8px', left: '10px', color: '#a0a0a0', zIndex: '999', fontSize: '16px', cursor: 'pointer' }} >{placeholderText}</div>
                            )} */}

                            <Select
                                options={sortedPhoneCode}
                                styles={styles}
                                id='phoneCode'
                                name='phoneCode'
                                placeholder='Search'
                                value={phone}
                                // value={phone}
                                onChange={(selectedValues) => {
                                    let arr = { label: selectedValues.value, value: '' }
                                    setPhone(arr)
                                }}
                                filterOption={filterOptions}
                                // onChange={(selectedValues) => {
                                //     let event = { target: { name: 'phoneCode', value: selectedValues } }
                                //     formik.handleChange(event)
                                // }}
                                // onBlur={(selectedValues) => {
                                //     let event = { target: { name: 'phoneCode', value: selectedValues } }
                                //     formik.handleBlur(event)
                                // }}
                                // onInputChange={(inputValue) => setIsSearching(!!inputValue.trim())}
                                
                            />

                        </>
                    )}
                    <Input
                        id='input'
                        name='input'
                        placeholder={details(type).placeholder}
                        value={formik.values.input}
                        onChange={formik.handleChange}
                        maxLength={15}
                    />
                </div>
                <Button className='mt-3' disabled={loading} type='submit'> {loading ? <ButtonLoader /> : details(type).button} </Button>
            </form>
            <Modal show={show}>
                <Modal.Header>
                    <div className='w-100 d-flex justify-content-between'>
                        <p className='fw-bold fs-5 mb-0'>Verify Phone Number</p>
                        {/* <img src={Icon} alt='' width='60px' className='align-self-center' /> */}
                        <RxCross2 size={20} onClick={handleClose} />
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className='mb-1'>
                        <Para className='text-center' >We have send successfully verification code to your phone number</Para>
                    </div>
                    <OTPInput
                        className='OtpInputBox'
                        value={otpValue}
                        onChange={setOtpValue}
                        autoFocus
                        OTPLength={4}
                        otpType="number"
                        disabled={false}
                        secure
                    />
                    {time === 0 ?
                        <Para className='text-center pt-3 mb-1'>Verification code has been expired</Para> :
                        <Para className='text-center pt-3 mb-1'>Verification code will expire in {formatTime(time)} minutes</Para>
                    }
                    <Button className='mt-3' disabled={loading} onClick={() => handleVerifyOtp()}>{loading ? 'Verifying...' : 'Verify'}</Button>
                </Modal.Body>
            </Modal>

            {showModal && (<CustomModal
                show={showModal}
                handleClose={handleCloseModal}
                navigateLink={navigateLink}
                data={modalData}
            />)}
        </>
    );
}

export default function PhoneVerification() {
    const [type, setType] = useState(0);
    return (
        <div className='container-fluid' style={{ height: '100vh' }}>
            <div className='d-flex pt-2'>
                <Link to='/home'><img src={SmallLogo} /></Link>
            </div>
            <Row className='row h-full m-0'>
                <Card className='card'>
                    <div className='steppers'>
                        <div className={`step ${(type === 0 || 1) && 'active'}`}></div>
                        <div className={`step ${(type === 1) && 'active'}`}></div>
                        <div className='count'>{type + 1}/2</div>
                    </div>
                    <CardContent type={type} setType={setType} />
                </Card>
            </Row>
        </div>
    );
}