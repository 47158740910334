import React from 'react'
import * as Yup from 'yup'
import Modal from 'react-bootstrap/Modal';
import { CardContainer, ChangeButton, InputContainer, TextSpan, ImgDiv, InputLabel, Label, P, ProfileHeading } from '../Profile/StyledComponents';
import { Input, PriceSide } from '../CreateOffer/StyledComponents';
import { ProfileImage, Small, Label1, Para, Para1 } from '../User/StyledComponent'
import { FiInfo } from 'react-icons/fi'
import { RxCross2 } from 'react-icons/rx'
import { DisputeLabel, DisputeInput, Sendbutton } from './StyledComponents';
import { useNavigate, useParams } from 'react-router-dom';
import { useCallback } from 'react';
import axios from 'axios';
import { API_URLs } from '../../Utils/ApiUrls';
import { useEffect } from 'react';
import { useState } from 'react';
import { useFormik } from 'formik';
import ButtonLoader from '../../Component/Loader/ButtonLoader';
import DataLoader from '../../Component/Loader/DataLoader';
import Select from 'react-select';
import { useAuthContextHook } from '../../Context/AuthContext';
import { useAPIContextHook } from '../../Context/ApiContext';
import DataTable, { createTheme } from 'react-data-table-component';
import { useThemeContext } from '../../Context/ThemeContext';
import CustomModal from '../../hooks/CustomModal';
import { FaArrowLeftLong } from "react-icons/fa6";
import { debounce } from 'lodash';

export const getRates = async (from, to, margin) => {
    try {
        let amount = 1
        let res = await axios.get(API_URLs.convertCryptoToFiat(from, amount, to))
        let convertedAmount = res.data?.convertedRate
        let calculatedSellerRate = Number(convertedAmount + (convertedAmount * margin / 100)).toFixed(3)
        return calculatedSellerRate
    } catch (error) {
    }
}
const DisputeCategory = [
    { value: 'Security Concerns', label: 'Security Concerns' },
    { value: 'Customer Support', label: 'Customer Support' },
    { value: 'User Interface and Experience', label: 'User Interface and Experience' },
    { value: 'Trading Fees and Costs', label: 'Trading Fees and Costs' },
    { value: 'Regulatory Compliance', label: 'Regulatory Compliance' },
    { value: 'Exchange Downtime and Performance', label: 'Exchange Downtime and Performance' },
];

export default function ViewOffer() {
    const [imgName, setImgName] = useState('Upload Image')
    const [imgElement, setImgElement] = useState();
    const [disputeModal, setDisputeModal] = useState(false)
    const disputeModalClose = () => { setDisputeModal(false) }
    const disputeModalShow = () => { setDisputeModal(true) }
    const navigate = useNavigate();
    const params = useParams();
    const { offerId } = params
    const [offerData, setOfferData] = useState({})
    const [thisOffer, setThisOffer] = useState([])
    const [sellerRate, setSellerRate] = useState("");
    const [myCurrencyWorth, setMyCurrencyWorth] = useState("");
    const [offerType, setOfferType] = useState("");
    const [source, setSource] = useState("");
    const { userDetails } = useAuthContextHook()
    const { sendNotification } = useAPIContextHook()
    const [loader, setLoader] = useState(false);
    const [fullScreenLoader, setFullScreenLoader] = useState(true);
    const { isDarkTheme } = useThemeContext();

    const [showModal, setShowModal] = useState(false);
    const [modalData, setModalData] = useState(null);
    const [navigateLink, setNavigateLink] = useState('');
    
    const [userTradeData, setUserTradeData] = useState();

    const handleShowModal = (data) => {
        setModalData(data);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false)
    };

    const formik = useFormik({
        initialValues: {
            payableAmount: '',
            receivableAmount: ''
        },
        validationSchema: Yup.object().shape({
            payableAmount: Yup.string().required()
        }),
        onSubmit: (values) => {
            handleBuy(values)
        }
    })

    const formik1 = useFormik({
        initialValues: {
            query: '',
            category: '',
            image: ''
        },
        validationSchema: Yup.object().shape({
            query: Yup.string().required('Required'),
        }),
        onSubmit: values => {
            axios.post(API_URLs.dispute, {
                query: formik1.values.query,
                category: formik1.values.category.value,
                image: imgElement
            },
                {
                    headers: {
                        'accept': 'multipart/form-data',
                        'Authorization': `Bearer ${localStorage.getItem('jwt')}`
                    }
                })
                .then((res) => {
                    disputeModalClose();
                    handleShowModal(res.data.msg ? res.data.msg : 'Dispute Send Successfully!!');
                    formik1.setValues({
                        ...formik1.values,
                        query: '',
                        category: ''
                    })
                })
                .catch((error) => {
                    handleShowModal(error?.response.data.message ? error?.response.data.message : 'Some Error Occured');
                })
        }
    })

    const handleGetUserTradeData = (id)=>{
        axios.get(API_URLs.getPublicUserTradeProfile + `/${id}`,{
            headers: {
                accept: 'application/json',
                Authorization: `Bearer ${localStorage.getItem('jwt')}`
            }
        })
        .then((res)=>{
            setUserTradeData(res?.data);
        })
        .catch((err)=>{
            
        })
    }


    const getOfferInfo = useCallback(async () => {
        let arr = []
            try {
                let res = await axios.get(API_URLs.getOfferDetailsById(offerId), {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('jwt')}`
                    }
                })
                
                setOfferData(res.data.res_obj)

                if(res?.data?.res_obj?.user?._id){
                    handleGetUserTradeData(res?.data?.res_obj?.user?._id)
                }
                arr.push(res.data.res_obj)
                setOfferType(res.data.res_obj.offer.offer_type === "sell" ? "Buy" : "Sell")

                let from = res.data?.res_obj?.offer?.cryptoCurrency?._id
                let to = res.data?.res_obj?.offer?.preffered_currency?._id
                let margin = res.data?.res_obj?.offer?.offer_margin
                let rate = await getRates(from, to, margin)
                setSellerRate(rate)
                setFullScreenLoader(false)
            } catch (error) {
                setFullScreenLoader(false)
            }
        setThisOffer(arr);
    }, [offerId])

    useEffect(() => {
        getOfferInfo()
    }, [getOfferInfo])

    const handleBuy = async (value) => {
        setLoader(true)
        try {
            let res = await axios.post(API_URLs.initiateTrade(offerId), {
                amount: Number(value.payableAmount).toFixed(10)
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('jwt')}`
                }
            })


            sendNotification(offerData?.user?._id, "New Trade Initiated", "New Trade has been initiated on your offer",
                'offer-active',
                { transactionType: 'trade/' },
                {
                    transactionIds: [
                        {
                            idName: 'offerId',
                            id: offerId
                        },
                        {
                            idName: 'txnHash',
                            id: res.data?.txn_hash
                        }
                    ]
                })

            setLoader(false)
            navigate(`/trade/${offerId}/${res.data?.txn_hash}`)
        } catch (error) {
            setLoader(false)
            handleShowModal(error?.response?.data?.message ? error?.response?.data?.message: 'Some Error Occured');
        }
    }

    const currencyRates = useCallback(debounce(async (amount) => {
        try {
            setLoader(true)
            let from = offerData?.offer?.preffered_currency?._id;
            let to = offerData?.offer?.cryptoCurrency?._id;


            if (source) {
                source.cancel()
            }

            setSource(axios.CancelToken.source)

            formik.setValues({
                ...formik.values, payableAmount: amount,
            })
            let myWorth;
            if (offerData?.offer?.trade_pricing_type === 'fixed') {
                myWorth = amount
            }
            else {
                myWorth = amount - (amount * offerData?.offer?.offer_margin / 100)
            }
            setMyCurrencyWorth(myWorth)

            let res = await axios.get(API_URLs.convertCryptoToFiat(from, myWorth, to), {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('jwt')}`
                },
                cancelToken: source.token
            })

            formik.setValues({
                ...formik.values, payableAmount: amount, receivableAmount: res.data?.convertedRate
            })
            setLoader(false)
        } catch (error) {
            formik.setValues({
                ...formik.values,
                payableAmount: "", receivableAmount: ""
            })
            setLoader(false)
        }
    }, 500), [offerData?.offer?.preffered_currency?.symbol, offerData?.offer?.cryptoCurrency?.symbol])

    const columns = [
        {
            name: 'Crypto Price',
            selector: (row) => <div>
                {row?.offer?.trade_pricing_type === 'market' ? sellerRate : row?.offer?.fixed_offer_price}
            </div>
        },
        {
            name: 'Payment Method',
            selector: (row) => <div>
                {row?.offer?.payment_method}
            </div>
        },
        {
            name: 'Minimum/Maximum Amount',
            selector: (row) => <div>
                <p className='mb-0'>{row?.offer?.trade_limit?.min}-{row?.offer?.trade_limit?.max}</p>
                <p className='mb-0'>{row?.offer?.preffered_currency?.symbol}</p>
            </div>
        },
        {
            name: 'Trade Time Limit',
            selector: (row) => <div>
                {row?.offer?.offer_time_limit} Minutes
            </div>
        },
        {
            name: 'Qvani Fee',
            selector: (row) => <div>
                {row?.fee}%
            </div>
        },
    ]

    createTheme('solarized', {
        background: {
            default: `${isDarkTheme ? "#0c0c0c" : 'white'}`
        },
        action: {
            hover: 'rgba(0,0,0,.08)',
        },
        text: {
            secondary: `${isDarkTheme ? "white" : 'black'}`
        },
    });
    const customStyles = {
        header: {
            style: {
                fontSize: '20px',
                color: '#0B0E2C',
                fontFamily: 'Inter',
                fontStyle: 'normal',
                fontWeight: '600',
            },
        },
        headRow: {
            style: {
                fontFamily: 'Inter',
                fontStyle: 'normal',
                fontSize: '12px',
                ...(isDarkTheme ? { borderTop: '1px solid #302f30' } :
                    { border: '1px solid lightgray' }),
                color: `${isDarkTheme ? "#e9e9e9" : '#6F7182'}`,
                backgroundColor: `${isDarkTheme ? "#0c0d0d" : "#F7F9FC"}`,
                textTransform: 'uppercase'
            },
        },
        rows: {
            style: {
                color: `${isDarkTheme && 'white'}`,
                backgroundColor: `${isDarkTheme && 'rgba(8, 9, 14, 0.96)'}`,
                borderTop: '1px solid lightgray',
                '&:not(:last-of-type)': {
                    borderBottom: 'none',
                },
            }
        },
        headCells: {
            style: {
                display: 'flex',
                alignItems: 'center'
            },
        },
        cells: {
            style: {
                display: 'flex',
                alignItems: 'center'
            },
        }
    }

    const handleInputChange = (event) => {
        const { value } = event.target;
        formik.setFieldValue('payableAmount', value);
        currencyRates(value);
      };

    return (
    <>
        
        {fullScreenLoader ?
            <div style={{ minHeight: "85vh" }} className='d-flex flex-column justify-content-center align-items-center'>
                <DataLoader />
            </div> :
            <div className='p-4 container'>
                <FaArrowLeftLong onClick={()=>navigate(-1)}/>
                <div className='row m-0 d-flex justify-content-center'>
                    <div className='col-md-7 col-sm-12'>
                        <CardContainer>
                            <div className='p-4'>
                                <ProfileHeading className=''> {offerType} {offerData?.offer?.cryptoCurrency?.name}</ProfileHeading>

                                <form onSubmit={formik.handleSubmit} className=''>
                                    <div className='d-flex flex-column flex-grow-1 gap-1'>
                                        <InputLabel>
                                            {offerType === "Buy" ? 'I will pay' : 'I will receive'}
                                        </InputLabel>
                                        <InputContainer className='p-1 d-flex'>
                                            <Input
                                                name='payableAmount'
                                                value={formik.values.payableAmount}
                                                // onBlur={(event) => {

                                                //     currencyRates(event.target.value)
                                                // }}
                                                onChange={handleInputChange}
                                                className='px-2 py-1 flex-grow-1' />
                                            <PriceSide className='px-4 text-uppercase pt-1'>{offerData?.offer?.preffered_currency?.symbol}</PriceSide>
                                        </InputContainer>
                                    </div>
                                    <div className='pt-2 d-flex gap-2 text-secondary align-items-center'>
                                        <FiInfo />
                                        {
                                            formik.values.payableAmount ?
                                                <P className='m-0'>You {offerType === "Buy" ? 'get' : 'pay'} {myCurrencyWorth}
                                                    <span className='text-uppercase'> {offerData?.offer?.preffered_currency?.symbol}</span> worth of {offerData?.offer?.cryptoCurrency?.name}</P> :
                                                <P className='m-0'>Enter amount to get started</P>
                                        }
                                    </div>
                                    {
                                        formik.values.payableAmount < offerData?.offer?.trade_limit?.min || formik.values.payableAmount > offerData?.offer?.trade_limit?.max ?
                                            <div className='d-flex gap-2 text-secondary align-items-center'>
                                                <FiInfo />
                                                <P className='m-0'>The Trade Limit is {offerData?.offer?.trade_limit?.min} to {offerData?.offer?.trade_limit?.max} {offerData?.offer?.preffered_currency?.symbol}</P>
                                            </div> : ""
                                    }
                                    <div className='d-flex flex-column flex-grow-1 gap-1'>
                                        <InputLabel>
                                            {offerType === "Buy" ? 'And receive' : `and pay in ${offerData?.offer?.cryptoCurrency?.name}`}
                                        </InputLabel>
                                        <InputContainer className='p-1 d-flex'>
                                            <Input
                                                disabled
                                                name='receivableAmount'
                                                value={formik.values.receivableAmount && Number(formik.values.receivableAmount).toFixed(10)}
                                                className='px-2 py-1 flex-grow-1' />
                                            <PriceSide className='px-4 text-uppercase pt-1'>{offerData?.offer?.cryptoCurrency?.symbol}</PriceSide>
                                        </InputContainer>
                                    </div>
                                    <ChangeButton
                                        disabled={loader || userDetails?.user?._id === offerData?.offer?.user} type='submit' className='py-2 mt-4'>
                                        {
                                            loader ? <><ButtonLoader /> <span>{offerType}</span></> :
                                                userDetails?.user?._id === offerData?.offer?.user ? 'You can not trade on your own offer' : offerType
                                        }
                                    </ChangeButton>
                                </form>
                            </div>
                        </CardContainer>
                    </div>
                </div>
                <div className='mt-3'>
                    <CardContainer>
                        <p className='p-3 mb-0 pb-0 fw-bold '>Offer Details</p>
                        <div className='p-3'>
                            <DataTable
                                columns={columns}
                                data={thisOffer}
                                customStyles={customStyles}
                            />
                        </div>
                    </CardContainer>
                </div>
                <div className='mt-3'>
                    <CardContainer className='p-2' >
                        <div className='pb-3'>
                            <div className='d-flex flex-column flex-md-row justify-content-md-between align-items-md-center p-3'>
                                <div className='d-flex align-items-start gap-3'>
                                    <div>
                                        {
                                            offerData?.user?.avatar && <ProfileImage src={offerData?.user?.avatar} alt="" />
                                        }
                                    </div>
                                    <div className='d-flex flex-column pt-3'>
                                        <ProfileHeading className='m-0 d-flex flex-column'>{offerData?.user?.nickNames}
                                            <Small className='text-secondary'>{offerData?.user?.bio}</Small>
                                        </ProfileHeading>
                                    </div>
                                </div>
                                <div className='d-flex flex-column text-center'>
                                    <small className='fs-6 text-secondary'>FEEDBACK</small>
                                    {offerData?.averageFeedback ? offerData?.averageFeedback : '0'}
                                </div>
                            </div>
                        </div>

                        <div style={{ backgroundColor: isDarkTheme && 'transparent' }} className="card col-12 my-3 p-3 d-flex flex-row justify-content-between">
                            <div className='d-flex gap-2 flex-column pt-3'>
                                <Label1>Total Trading</Label1>
                                <Para1 className='m-0'>{userTradeData?.totalTrade ? userTradeData?.totalTrade : "0"}</Para1>
                            </div>
                            <div className='d-flex gap-2 flex-column pt-3'>
                                <Label>30 Days Trade </Label>
                                <Para className='m-0'>{userTradeData?.get30daysTrade ? userTradeData?.get30daysTrade : "0"}</Para>
                            </div>
                            <div className='d-flex gap-2 flex-column pt-3'>
                                <Label>Avg Release Time</Label>
                                <Para className='m-0'>{userTradeData?.AverageofReleasetime ? userTradeData?.AverageofReleasetime : "0"} minutes</Para>
                            </div>
                            <div className='d-flex gap-2 flex-column pt-3'>
                                <Label>Avg Pay Time</Label>
                                <Para className='m-0'>{userTradeData?.AverageofPaidtime ? userTradeData?.AverageofPaidtime : "0"} minutes</Para>
                            </div>
                        </div>
                    </CardContainer>
                </div>
                <div className='my-4'>
                    <CardContainer className='p-3'>
                        <p className='p-3 fw-bold'>Trade Instruction</p>
                        <ul>
                            <li className='text-capitalize'>{offerData?.offer?.trade_instruction || 'N/A'}</li>
                        </ul>
                    </CardContainer>
                </div>
                <Modal show={disputeModal} onHide={disputeModalClose}>
                    <Modal.Header className='d-flex justify-content-between'>
                        <p className='fs-5 fw-bold mb-0'>Report a Problem </p>
                        <RxCross2 size={25} onClick={disputeModalClose} />
                    </Modal.Header>
                    <Modal.Body>
                        <form onSubmit={formik1.handleSubmit}>
                            <div>
                                <DisputeLabel htmlFor='query'>Query</DisputeLabel>
                                <div>
                                    <DisputeInput
                                        placeholder='Enter your Query'
                                        name='query'
                                        id='query'
                                        value={formik1.values.query}
                                        onChange={formik1.handleChange}
                                        onBlur={formik1.handleBlur}
                                    />
                                </div>
                            </div>
                            <div>
                                <Label htmlFor='category'>Category</Label>
                                <Select
                                    placeholder='Select Category'
                                    name='category'
                                    id='category'
                                    options={DisputeCategory}
                                    styles={{
                                        control: (styles) => ({
                                            ...styles,

                                            background: 'white',
                                            borderRadius: '52px',
                                            boxShadow: '0px 0px 10px #E9EAF3',
                                            border: '1px solid #E9EAF3', "&:hover": {
                                                border: '1px solid #E9EAF3'
                                            },
                                            color: 'white'
                                        }),
                                        indicatorSeparator: () => ({
                                            all: 'unset'
                                        }),
                                        indicatorsContainer: (styles) => ({
                                            ...styles, color: 'white', 'div:nth-child(2)': {
                                                color: 'black'
                                            }
                                        }),
                                        menu: (styles) => ({
                                            ...styles, background: 'white',
                                            color: 'black'
                                        }),
                                        input: (styles) => ({
                                            ...styles,
                                            color: 'black',
                                            fontFamily: 'Inter',
                                            fontStyle: 'normal',
                                            fontWeight: '400',
                                            fontSize: '14px'
                                        }),
                                        singleValue: (styles) => ({
                                            ...styles,
                                            color: 'black',
                                            fontFamily: 'Inter',
                                            fontStyle: 'normal',
                                            fontWeight: '400',
                                            fontSize: '14px'
                                        }),
                                        option: (styles, { isSelected }) => ({
                                            ...styles, color: () => {
                                                return isSelected ? 'blue' : 'black'
                                            }, background: 'white', ":hover": {
                                                background: '#1476FF',
                                                color: 'white'
                                            }
                                        })
                                    }}
                                    onChange={(selectedValues) => {
                                        let event = { target: { name: 'category', value: selectedValues } }
                                        formik1.handleChange(event)
                                    }}
                                    onBlur={(selectedValues) => {
                                        let event = { target: { name: 'category', value: selectedValues } }
                                        formik1.handleBlur(event)
                                    }}
                                    value={formik1.values.category}
                                />
                            </div>
                            <div>
                                <ImgDiv>
                                    <TextSpan className='d-flex justify-content-between align-items-center w-75 ps-3'>
                                        {String(imgName).length > 20 ? String(imgName).slice(0, 20) + '...' : String(imgName)}
                                    </TextSpan>
                                    <input
                                        onChange={(e) => {
                                            setImgName(e.target.files[0].name)
                                            setImgElement(e.target.files[0])
                                        }}
                                        name='image'
                                        type="file"
                                    />
                                </ImgDiv>
                            </div>
                            <div>
                                <Sendbutton type='submit'>Send Dispute</Sendbutton>
                            </div>
                        </form>
                    </Modal.Body>
                </Modal>
            </div>}
            {showModal && (<CustomModal
                show={showModal}
                handleClose={handleCloseModal}
                navigateLink={navigateLink}
                data={modalData}
            />)}
        </>
    );
}