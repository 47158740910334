import styled from 'styled-components';

export const RegisterButton = styled.button`
background: #1476FF;
border-radius: 20px;
flex-direction: row;
display: flex;
justify-content: center;
align-items: center;
color: white;
border: none;
outline: none;
font-family: 'Montserrat';
font-style: normal;
font-weight: 600;
font-size: 16px;
`
export const TourButton = styled.button`
border: none;
border-radius: 20px;
color: white;
background: #1476FF;
font-size: 14px;
font-weight: bold;
padding: 2px 12px;
`
export const NotificationContainer = styled.button`
    background: ${({ active }) => active ? '#f3f8fb' : "white"};
    border: none;
    border-bottom: 1px solid #ebe8e8;
    border-left: ${({ active }) => active ? '2px solid #87ccf8' : ""};
    padding: 0.5rem 1rem;
    width: 100%;
    cursor: pointer;
    :hover{
        background: #e2eff6;
    }
`
export const NotificationHeading = styled.p`
    font-weight: 600;
    font-size: 1rem;
`
export const NotificatonLabel = styled.p`
    font-size: 12px;
    cursor: pointer;
    text-decoration: underline;
`
export const Span = styled.span`
    text-transform: capitalize;
    color: var(--neutral-colors-800, '#0B0E2C');
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
`
export const NotificationHeader = styled.p`
    font-weight: 700;
    font-size: 0.8rem;
`
export const NotificationBody = styled.p`
    font-size: 0.7rem;
`
export const Badge = styled.span`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.35em 0.65em;
    font-size: 10px;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 100%;
    background: #023a02;
    height: 16px;
    width: 16px;
    margin: 0;
    position: absolute;
    margin-left: 18px;
    margin-bottom: 10px;
`