import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Edit from '../../assets/Images/ic-edit.png'
import Delete from '../../assets/Images/ic-delete.png'
import WalletIcon from '../../assets/Images/WalletIcon.png'
import axios from 'axios'
import { API_URLs } from '../../Utils/ApiUrls'
import Modal from 'react-bootstrap/Modal';
import ButtonLoader from '../../Component/Loader/ButtonLoader'
import DataLoader from '../../Component/Loader/DataLoader'
import { CardContainer, ChangeButton, ProfileHeading, SecurityCard, SubHeading } from './StyledComponents'
import AddBankAccount from '../PayMethod/AddBankAccount'
import { v4 as uuidv4 } from 'uuid';
import { useThemeContext } from '../../Context/ThemeContext'

const BoldPara = styled.p`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
`
const Span = styled.span`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #7A7A7A;
    text-transform: capitalize;
`
const Currency = styled.p`
   font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    text-transform: uppercase;
    border-radius: 6px;
    color: #1476FF;
    background: rgb(167, 213, 252);
    background: -moz-linear-gradient(58deg, rgba(167, 213, 252, 1) 0%, rgba(203, 255, 252, 1) 59%, rgba(170, 251, 206, 1) 100%);
    background: -webkit-linear-gradient(58deg, rgba(167, 213, 252, 1) 0%, rgba(203, 255, 252, 1) 59%, rgba(170, 251, 206, 1) 100%);
    background: linear-gradient(58deg, rgba(167, 213, 252, 1) 0%, rgba(203, 255, 252, 1) 59%, rgba(170, 251, 206, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#a7d5fc", endColorstr="#aafbce", GradientType=1);
`

export default function PaymentMethod() {
    const {isDarkTheme} = useThemeContext();
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [isBankEdit, setIsBankEdit] = useState(false);
    const [singleBankDetails, setSingleBankDetails] = useState([])

    const [bankDetails, setBankDetails] = useState([])
    const [loading, setLoading] = useState(false)
    const [refresh, setRefresh] = useState(false);

    const [onDelete, setOnDelete] = useState({
        id: '',
        loading: false
    })

    const handleSetRefresh = () => setRefresh(!refresh)

    const getBankDetails = () => {
        setLoading(true)
        axios.get(API_URLs.bankPost, {
            headers: {
                'accept': 'application/json',
                "Authorization": `Bearer ${localStorage.getItem('jwt')}`
            }
        })
            .then((res) => {           
                setBankDetails(res.data.banks)            
                setLoading(false)
            })
            .catch((error) => {
                setLoading(false)            
            })
    }

    const deleteBank = async (id) => {
        setOnDelete({
            id,
            loading: true
        })
        try {
            await axios.delete(API_URLs.deleteBank(id), {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('jwt')}`
                }
            })
            setOnDelete({
                id: "",
                loading: false
            })
        } catch (error) {
            setOnDelete({
                id: "",
                loading: false
            })
        }
    }
    useEffect(() => {
        if (!onDelete.loading) {
            getBankDetails()
        }
    }, [onDelete, refresh])

    return (
        <div>
            <CardContainer className='container py-4 px-4'>
                <div className='d-flex flex-column flex-md-row justify-content-between'>
                    <div className='d-flex flex-column w-100 w-md-50'>
                        <ProfileHeading className=''>Bank Accounts</ProfileHeading>
                    </div>

                    <div className='pt-2'>
                        <ChangeButton className='px-3 py-1' onClick={() => {
                            setSingleBankDetails({})
                            setIsBankEdit(false)
                            handleShow()
                        }}>Add Account</ChangeButton>
                    </div>
                </div>
                <SubHeading>
                    Add your bank account details below. You can share these details with your trade partner via trade chat, for bank transfer trades.
                </SubHeading>
                {
                    bankDetails?.length > 0 ? bankDetails.map((details) => {
                        return (
                            <div className='pt-4' key={uuidv4()}>
                                <SecurityCard className='p-3'>
                                    <div className='d-flex gap-3'>
                                        <BoldPara className='w-25'>Bank Name</BoldPara>
                                        <Span>: {details?.BankName}</Span>
                                    </div>
                                    <div className='d-flex gap-3'>
                                        <BoldPara className='w-25'>Account Type</BoldPara>
                                        <Span>: {details?.accountType}</Span>
                                    </div>
                                    <div className='d-flex justify-content-between align-items-lg-center flex-column flex-lg-row gap-2 gap-lg-0'>
                                        <div className='d-flex gap-3 align-items-center justify-content-between justify-content-md-start'>
                                            <Currency className='px-4 py-2 m-0'>{details?.currency?.symbol}</Currency>
                                        </div>

                                        <div className='d-flex gap-3'>
                                            <ChangeButton onClick={() => { deleteBank(details?.id) }} className='d-flex align-items-center gap-2 px-3 py-1'>
                                                {
                                                    details?.id === onDelete?.id && onDelete?.loading ?
                                                        <div className='px-4'>
                                                            <ButtonLoader /> <span>Delete</span>
                                                        </div>
                                                        : <>
                                                            <img src={Delete} alt="delete" />
                                                            Delete
                                                        </>
                                                }
                                            </ChangeButton>
                                            <ChangeButton onClick={() => {
                                                setIsBankEdit(true)
                                                setSingleBankDetails(details)
                                                handleShow()
                                            }} className='d-flex align-items-center gap-2 px-3 py-1'>
                                                <img src={Edit} alt="delete"/>
                                                Edit
                                            </ChangeButton>
                                        </div>
                                    </div>
                                </SecurityCard>
                            </div>
                        )
                    }) : <div className='pt-3'>
                        {
                            loading ?
                                <div className='px-4 d-flex justify-content-center align-items-center'>
                                    <DataLoader />
                                </div> :
                                <CardContainer className='p-3 py-4 d-flex justify-content-center'>
                                    <div className='d-flex flex-column justify-content-center align-items-center'>
                                        <img src={WalletIcon} alt="wallet"/>
                                        <Span>No Bank Accounts added</Span>
                                    </div>
                                </CardContainer>

                        }

                    </div>
                }

                <Modal show={show} onHide={handleClose}>
                    <Modal.Header style={{ background: `${isDarkTheme ? '#0c0c0c' : 'white'}`, wordBreak: 'break-word' }} closeButton>
                        <Modal.Title>
                            <ProfileHeading className='m-0'>{isBankEdit ? 'Update Bank Account' : 'Add Bank Account'}</ProfileHeading>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ background: `${isDarkTheme ? '#0c0c0c' : 'white'}`, wordBreak: 'break-word' }} >
                        <AddBankAccount handleClose={handleClose} isBankEdit={isBankEdit} singleBankDetails={singleBankDetails} handleSetRefresh={handleSetRefresh} />
                    </Modal.Body>
                </Modal>
            </CardContainer>
        </div>
    )
}
