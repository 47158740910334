import React, { useEffect } from 'react'
import styled from 'styled-components'

import Image from '../assets/Images/error.png'
import { Link, useNavigate } from 'react-router-dom'

const Container = styled.div`
    min-height: 90vh;
`

const Card = styled.div`
    background: white;
    border-radius: 26px;
    box-shadow: ${({theme})=> theme.boxshadow};
`

const Heading = styled.h1`
    font-family: 'Inter';
    font-size: 124px;
    font-weight: 600;
    color: #1476FF;
`

const Text = styled.p`
    font-family: 'Inter';
    font-size: 24px;
    color: #0B0E2C;
`

const Button = styled.button`
    border: none;
    background: #1476FF;
    font-style: 'Inter';
    font-weight: 700;
    color: white;
    outline: none;
    border-radius: 15px;
    text-decoration: none;
    :hover{
        color: white;
        opacity: 0.9;
    }
`

export default function Error401() {
    const navigate = useNavigate();
    useEffect(()=>{
        navigate('/login')
    },[])
    return (
        <Container className='d-flex align-items-center justify-content-center '>
            <Card className='d-flex flex-column-reverse flex-md-row align-items-center justify-content-center p-5'>
                <div>
                    <Heading>401</Heading>
                    <Text>Oops, You are not authorized!!!</Text>
                    <Button as={Link} to='/login' className='px-4 py-2'>Login</Button> <p>First, to access <strong>Qvani Exchange</strong></p>
                </div>

                <div>
                    <img className='w-100' src={Image} alt="Error 401" />
                </div>
            </Card>
        </Container>
    )
}
