import React from 'react'
import './Contact.css'
import styled from 'styled-components';

const P = styled.p`
font-size: 18px;
z-index: 1;
color: rgb(206, 212, 218);
`
const divStyle = {
    backgroundColor: "#08021C",
    height: "424px"
}

export default function Header (){
    return(
        <div className='header imagebgWrapper row m-0 h-20 d-flex justify-content-center align-items-center' style={divStyle}>
            <div className='col-md-6 text-center z-index-1 position-absolute text-light'>
                <p className='fs-1 mb-1 text-white fw-bold'>Building the future of economic freedom</p>
                <P className='mb-0 text-white  text-center'>Crypto creates economic freedom by ensuring that people can participate fairly in the global economy. One day, everyone will become involved with crypto.</P>
            </div>
        </div>
    );
}