import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom';
import { Sidebar, Menu, MenuItem, SubMenu, useProSidebar } from 'react-pro-sidebar';
import { BiGridAlt } from 'react-icons/bi'
import { MdCreate, MdReportProblem } from 'react-icons/md'
import { BsWallet, BsArrowDownLeft, BsArrowUpRight, BsGraphUpArrow } from 'react-icons/bs'
import { useThemeContext } from '../../Context/ThemeContext';
import SmallLogo from '../../assets/Images/LogoFooter.png'
import { useAPIContextHook } from '../../Context/ApiContext';
import { GrHomeRounded } from "react-icons/gr";
import './Sidebar.css'

export default function SidebarComponent() {

    const { cryptoCurrenciesList } = useAPIContextHook();
    const { isDarkTheme, screenWidth } = useThemeContext()
    const location = useLocation();
    const { collapsed, toggleSidebar } = useProSidebar();
    
    return (
        <div>
            <Sidebar
                className="side-bar"
                breakPoint='lg'
                backgroundColor={isDarkTheme ? 'black' : 'white'} >

                <div
                    className='py-3'
                    style={{ marginLeft: "2rem" }}
                >
                    <Link to="/dashboard/user-dashboard">
                        <img src={SmallLogo} alt="LOGO" width={140} />
                    </Link>

                </div>
                <hr className='mt-0 mx-3' />
                <Menu
                    menuItemStyles={{
                        button: ({ level, active }) => {
                            if (level === 0 || level === 1)
                                return {
                                    color: active ? '#1476FF' : `${({ theme }) => theme.text}`,
                                    backgroundColor: active && `${isDarkTheme ? '#3f4045' : '#F4F7FF'}`,
                                    fontFamily: 'Inter',
                                    fontStyle: 'normal',
                                    fontWeight: 500,
                                    borderRadius: '67px',
                                    ":hover": {
                                        backgroundColor: `${isDarkTheme && '#464647'}`
                                    }
                                };
                        },
                    }}
                    className={collapsed ? 'px-0' : 'px-3'}>  
                    <MenuItem
                        onClick={() => {
                            if (screenWidth <= 990) {
                                toggleSidebar()
                            }
                        }}
                        active={location.pathname.includes('/dashboard/user-dashboard')}
                        component={<Link to='/dashboard/user-dashboard' />}
                        icon={<GrHomeRounded size={15} />}
                    > Dashboard </MenuItem>

                    <MenuItem className='walletoption'
                        onClick={() => {
                            if (screenWidth <= 990) {
                                toggleSidebar()
                            }
                        }}
                        active={location.pathname.includes('/dashboard/wallet')}
                        component={<Link to='/dashboard/wallet' />}
                        icon={<BsWallet />}
                    > Wallet </MenuItem>

                    <MenuItem className='createofferoption'
                        onClick={() => {
                            if (screenWidth <= 990) {
                                toggleSidebar()
                            }
                        }}
                        active={location.pathname.includes('/dashboard/create-offer')}
                        component={<Link to='/dashboard/create-offer' />}
                        icon={<MdCreate />}
                    > Create Offer </MenuItem>

                    <MenuItem
                        onClick={() => {
                            if (screenWidth <= 990) {
                                toggleSidebar()
                            }
                        }}
                        className='myofferoption'
                        active={location.pathname.includes('/dashboard/myoffer')}
                        component={<Link to='/dashboard/myoffer' />}
                        icon={<BsWallet />}
                    > My Offers </MenuItem>

                    <SubMenu
                        icon={<BsArrowDownLeft />} label="Buy">
                        {
                            cryptoCurrenciesList?.length > 0 && cryptoCurrenciesList.map((crypto) => {
                                return (
                                    <MenuItem
                                        rootStyles={{
                                            backgroundColor: `${isDarkTheme && 'black'}`
                                        }}
                                        onClick={() => {
                                            if (screenWidth <= 990) {
                                                toggleSidebar()
                                            }
                                        }}
                                        key={crypto?._id}
                                        active={location.pathname.includes(`/dashboard/buy/${crypto?._id}`)}
                                        component={<Link to={`/dashboard/buy/${crypto?._id}`} />} > Buy {crypto?.name}
                                    </MenuItem>
                                );
                            })
                        }
                    </SubMenu>

                    <SubMenu icon={<BsArrowUpRight />} label="Sell">
                        {
                            cryptoCurrenciesList?.length > 0 && cryptoCurrenciesList.map((crypto) => {
                                return (
                                    <MenuItem
                                        rootStyles={{
                                            backgroundColor: `${isDarkTheme && 'black'}`
                                        }}
                                        onClick={() => {
                                            if (screenWidth <= 990) {
                                                toggleSidebar()
                                            }
                                        }}
                                        key={crypto?._id}
                                        active={location.pathname.includes(`/dashboard/sell/${crypto?._id}`)}
                                        component={<Link to={`/dashboard/sell/${crypto?._id}`} />} > Sell {crypto?.name} </MenuItem>
                                )
                            })
                        }
                    </SubMenu>

                    <MenuItem className='myofferoption'
                        onClick={() => {
                            if (screenWidth <= 990) {
                                toggleSidebar()
                            }
                        }}
                        active={location.pathname.includes('/dashboard/activetrades')}
                        component={<Link to='/dashboard/activetrades' />}
                        icon={<BsGraphUpArrow />}
                    > Initiated Trades</MenuItem>

                    <MenuItem
                        onClick={() => {
                            if (screenWidth <= 990) {
                                toggleSidebar()
                            }
                        }}
                        active={location.pathname.includes('/dashboard/disputes')}
                        component={<Link to='/dashboard/disputes' />}
                        icon={<MdReportProblem />}
                    > My Disputes </MenuItem>
                </Menu >
            </Sidebar >
        </div>
    )
}
