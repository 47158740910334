// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.myoffer-tabs .nav-link {
    display: flex;
    align-items: center;
    border: none;
    background: transparent;
    font-style: normal;
    font-weight: 600;
    color: #6F7182;
}

.myoffer-tabs .nav-item .active {
    display: flex;
    align-items: center;
    background: transparent;
    border: none;
    color: #1476FF;
}

.myoffer-tabs {
    border-bottom: 1px solid #e9e6e6 !important;
}

.expended-row {
    border-bottom: 1px solid darkgray !important;
    background: beige !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/ViewMyOffer/Index.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,YAAY;IACZ,uBAAuB;IACvB,kBAAkB;IAClB,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,YAAY;IACZ,cAAc;AAClB;;AAEA;IACI,2CAA2C;AAC/C;;AAEA;IACI,4CAA4C;IAC5C,4BAA4B;AAChC","sourcesContent":[".myoffer-tabs .nav-link {\n    display: flex;\n    align-items: center;\n    border: none;\n    background: transparent;\n    font-style: normal;\n    font-weight: 600;\n    color: #6F7182;\n}\n\n.myoffer-tabs .nav-item .active {\n    display: flex;\n    align-items: center;\n    background: transparent;\n    border: none;\n    color: #1476FF;\n}\n\n.myoffer-tabs {\n    border-bottom: 1px solid #e9e6e6 !important;\n}\n\n.expended-row {\n    border-bottom: 1px solid darkgray !important;\n    background: beige !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
