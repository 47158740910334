import React, { useState, useEffect, useCallback } from 'react';
import Btc from '../../images/Home/btc.png';
import { BsFillSendFill, BsThreeDots } from 'react-icons/bs';
import { BiCopy } from 'react-icons/bi'
import { MdCallReceived } from "react-icons/md";
import { MdCallMade } from "react-icons/md";
import { useFormik } from 'formik';
import { GiRecycle } from 'react-icons/gi'
import * as Yup from 'yup';
import OTPInput from "otp-input-react";
import { Label, Input, ImgDiv, QRdiv, TabNavigationButton, Button, ReqButton, P } from './StyledComponent'
import './Wallet.css'
import axios from 'axios';
import QRCode from "react-qr-code";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { API_URLs } from '../../Utils/ApiUrls';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
import Usdt from '../../images/Home/usdt.png'
import Matic from '../../images/Home/matic.png'
import Ether from '../../images/Home/ether.png'
import Usdc from '../../images/Home/USDC.png'
import CustomModal from '../../hooks/CustomModal';
import ButtonLoader from '../../Component/Loader/ButtonLoader';
import CrossIconWhite from '../../assets/WhiteCrossLogo.svg'
import CrossIconBlack from '../../assets/cross-logo.svg'
import { useThemeContext } from '../../Context/ThemeContext';
import { darkTheme, lightTheme } from '../../Theme/theme';
import { useAuthContextHook } from '../../Context/AuthContext';
import { useAPIContextHook } from '../../Context/ApiContext';
import { useWalletContextHook } from '../../Context/WalletContext';
import DataTable, {createTheme} from 'react-data-table-component';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {Para} from '../Auth/SignUp/Otp'

const DivWithMax = styled.div`
display: flex;
background: ${({theme})=>theme.body};
padding: 5px;
box-shadow: ${({theme})=>theme.boxshadow};
border: ${({theme})=>theme.border};
border-radius: 15px;
margin-top: 11px;
`
const ButtonWithMax = styled.button`
border: none;
outline: none;
background: #1476FF;
width: 15%;
color: white;
border-radius: 15px;
`
const InputWithMax = styled.input`
width: 85%;
border: none;
background: ${({theme})=>theme.body};
color: ${({theme})=>theme.text};
outline: none;
`
export const Small = styled.small`
border-radius: 20px;
background: aliceblue;
padding: 1px 9px;
color: #0088ff;
font-size: 10px;
font-weight: 600;
`
const Div = styled.div`
margin-top: 1rem;
background: ${({theme})=> theme.body=='#F7F9FC' ? 'white' : theme.body};
padding: 0px !important;
border-radius: 20px;
border: ${({theme})=> theme.border};
box-shadow: ${({theme})=> theme.boxshadow};
`
const HoldingCard = styled.div`
background: ${({theme})=> theme.body=='#F7F9FC' ? 'white' : theme.body};
border: ${({theme})=>theme.border};
box-shadow: ${({theme})=> theme.boxshadow};
border-radius: 20px;
`
export default function CryptoBalance({shown}) {
    const { getWallet, arr, getInBTC, arr1, getCryptoTransactionList} = useWalletContextHook();
    const { userDetails } = useAuthContextHook();
    const { cryptoCurrencyOptions, sendNotification} = useAPIContextHook();
    const { isDarkTheme } = useThemeContext()
    const [amount, setAmount] = useState(0)
    const navigate = useNavigate();
    const location = useLocation();
    const [copySuccess, setCopySuccess] = useState('');
    const [isSubmit, setisSubmit] = useState(false);

    const [showModal, setShowModal] = useState(false);
    const [modalData, setModalData] = useState(null);
    const [navigateLink, setNavigateLink] = useState('');
    const handleShowModal = (data) => {
        setModalData(data);
        setShowModal(true);
    };
    const handleCloseModal = () => {
        setShowModal(false)
    };

    const [otpSent, setOtpSent]=useState(false);
    const [otpValue, setOtpValue]=useState('');
    const [otpHash, setOtpHash]=useState(false);
    const [isTwoFa, setIsTwoFa]=useState(false);
    const [isError, setIsError] = useState({errorMessage:'', errorStatus:false})
    const handleSentOtpModal=(data)=>{ 
        setOtpSent(true); 
        setIsTwoFa(data);
    }

    const handleNotSentOtpModal=()=>{
        setOtpSent(false);

        setAdminFee('');
        setEstGasFee('')
        formik1.setValues({
            cryptoAmount: '',
            currency: ''
        })
        window.history.pushState("", document.title, location.pathname);
        setOtpValue('');
        setOtpHash('');
        setGasFeeConvert('');
    }

    const [show1, setShow1] = useState(false);
    const [data1, setdata1] = useState([])
    const handleClose1 = () => {
        setShow1(false);
    }
    const handleShow1 = (data) => {
        setShow1(true);
        setAmount(data.balance)
    }

    //recieve modal states
    const [show2, setShow2] = useState(false)
    const handleClose2 = () => {
        setShow2(false)
        setCopySuccess('')
    };
    const handleShow2 = (data) => {
        setShow2(true);
        setdata1(data)
    }

    const [verify2FA, setVerify2FA]=useState(false);
    const copyToClipBoard = useCallback(async copyMe => {
        setCopySuccess('');
        try {
            await navigator.clipboard.writeText(copyMe);
            setCopySuccess('Copied!');
        } catch (err) {
            setCopySuccess('Failed to copy!');
        }
    }, [])

    const isValid=(value)=>{
        let isValids = true;
        if(value.cryptoAmount <= 0 || !value?.cryptoAmount ){
            setIsError({...isError, errorMessage:'Please enter amount greater than zero.' })
            isValids = false
            return isValids;
        }
        return isValids;
    }
    // send internal to external transaction
    const intToExt = (values) => {
        if(!isValid(values)){
            return;
        }
        setVerify2FA(true);
        axios.post(API_URLs.sendWithdrawal, {
            amount: values.cryptoAmount,
            recieverAddress: values.walletAddress,
            currencyType: values.currency.symbol,
            otp: otpValue,
            token: otpHash
        }, {
            headers: {
                accept: 'application/json',
                Authorization: `Bearer ${localStorage.getItem('jwt')}`
            }
        })
            .then((res) => {
                console.log('transferXX::', res)
                handleClose1();
                getCryptoTransactionList();
                handleNotSentOtpModal();
                handleShowModal(res?.data?.msg ? res?.data?.msg : 'Your Withdrawl Request Send Successfully!!');
                setNavigateLink('');
                setVerify2FA(false)
                localStorage.removeItem('qrcode');
                setisSubmit(false);
                sendNotification(userDetails?.user?._id,
                    `Transaction`,
                    `Your withdrawal of ${res?.data?.data?.amount} ${res?.data?.data?.currency} has been completed successfully.`,
                    // 'Your amount has been withdrawal successfully',
                    'transaction',
                    { transactionType: 'wallet' })
                    getWallet()
                })
                .catch((error) => {
                    localStorage.removeItem('qrcode');
                    setisSubmit(false);
                    setVerify2FA(false)
                    handleNotSentOtpModal();
                    handleShowModal(error?.response?.data?.message ? error?.response?.data?.message : 'Some Error Occured!!')
                    handleClose1();
                })
    }

    console.log('isError',isError)

    // send internal to internal transaction
    const intToInt = async (values) => {
        setVerify2FA(true);
        if (values?.currency?.symbol === 'eth') {
            try {
                let res = await axios.post(API_URLs.sendETH, {
                    amount: values.cryptoAmount,
                    reciverNickName: values.walletAddress,
                    otp: otpValue,
                    token: otpHash
                }, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('jwt')}`
                    }
                })
                setisSubmit(false);
                getCryptoTransactionList();
                handleNotSentOtpModal();
                handleShowModal(res?.data?.msg ? res?.data?.msg : 'Send Successfully!!')
                setNavigateLink('');
                localStorage.removeItem('qrcode');
                let reciever = res?.data?.receiverId;

                sendNotification(reciever,
                    `Transaction`,
                    `${values.cryptoAmount} ETH has been credited to your account`,
                    'transaction',
                    { transactionType: 'wallet' })

                sendNotification(userDetails?.user?._id,
                    `Transaction`,
                    `${values.cryptoAmount} ETH has been debited from your account`,
                    'transaction',
                    { transactionType: 'wallet' })
                
                setVerify2FA(false);
                getWallet();
            } catch (error) {
                
                setVerify2FA(false);
                sendNotification(userDetails?.user?._id,
                    `Transaction`,
                    `Transaction failed due to some issue`,
                    'transaction',
                    { transactionType: 'wallet' })

                localStorage.removeItem('qrcode');
                setNavigateLink('');
                setisSubmit(false);
                handleNotSentOtpModal();
                handleShowModal(error?.response?.data?.message ? error?.response?.data?.message : 'Some Error Occured!!')
            }
        }
        else if (values?.currency?.symbol === 'btc') {
            try {
                let res = await axios.post(API_URLs.sendBTC, {
                    amountToSend: Number(values.cryptoAmount),
                    reciverNickName: values.walletAddress,
                    otp: otpValue,
                    token: otpHash
                }, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('jwt')}`
                    }
                })
                handleShowModal(res?.data?.msg ? res?.data?.msg : 'Send Successfully!!')
                getCryptoTransactionList();
                handleNotSentOtpModal();
                localStorage.removeItem('qrcode');
                setNavigateLink('');
                let reciever = res?.data?.receiverId;
                sendNotification(reciever,
                    `Transaction`,
                    `${values.cryptoAmount} BTC has been credited to your account`,
                    'transaction',
                    { transactionType: 'wallet' })
    
                sendNotification(userDetails?.user?._id,
                    `Transaction`,
                    `${values.cryptoAmount} BTC has been debited from your account`,
                    'transaction',
                    { transactionType: 'wallet' })

                setisSubmit(false);
                getWallet()
                setVerify2FA(false)
            } catch (error) {
                setVerify2FA(false)
                sendNotification(userDetails?.user?._id,
                    `Transaction`,
                    `Transaction failed due to some issue`,
                    'transaction',
                    { transactionType: 'wallet' })

                localStorage.removeItem('qrcode');
                setisSubmit(false);
                handleNotSentOtpModal();
                handleShowModal(error?.response?.data?.message ? error?.response?.data?.message : 'Some Error Occured!!')
                setNavigateLink('');
            }
        }
        else if (values?.currency?.symbol === 'usdc') {
            try {
                let res = await axios.post(API_URLs.sendUSDC, {
                    amountToSend: values.cryptoAmount,
                    reciverNickName: values.walletAddress,
                    otp: otpValue,
                    token: otpHash
                }, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('jwt')}`
                    }
                })
                handleShowModal(res?.data?.msg ? res?.data?.msg : 'Send Successfully!!')
                setNavigateLink('');
                handleNotSentOtpModal();
                localStorage.removeItem('qrcode');
                let reciever = res?.data?.receiverId;

                sendNotification(reciever,
                    `Transaction`,
                    `${values.cryptoAmount} USDC has been credited to your account`,
                    'transaction',
                    { transactionType: 'wallet' })
    
                sendNotification(userDetails?.user?._id,
                    `Transaction`,
                    `${values.cryptoAmount} USDC has been debited from your account`,
                    'transaction',
                    { transactionType: 'wallet' })

                getWallet();
                getCryptoTransactionList();
                setisSubmit(false);
                setVerify2FA(false)
            } catch (error) {
                
                setVerify2FA(false)
                sendNotification(userDetails?.user?._id,
                    `Transaction`,
                    `Transaction failed due to some issue`,
                    'transaction',
                    { transactionType: 'wallet' })

                setisSubmit(false);
                handleNotSentOtpModal();
                localStorage.removeItem('qrcode');
                handleShowModal(error?.response?.data?.message ? error?.response?.data?.message : 'Some Error Occured!!')
                setNavigateLink('');
            }
        }
        else if (values?.currency?.symbol === 'usdt') {
            try {
                let res = await axios.post(API_URLs.sendUSDT, {
                    amountToSend: values.cryptoAmount,
                    reciverNickName: values.walletAddress,
                    otp: otpValue,
                    token: otpHash
                }, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('jwt')}`
                    }
                })
                handleShowModal(res?.data?.msg ? res?.data?.msg : 'Send Successfully!!')
                setNavigateLink('');
                handleNotSentOtpModal();
                localStorage.removeItem('qrcode');
                let reciever = res?.data?.receiverId;
                sendNotification(reciever,
                    `Transaction`,
                    `${values.cryptoAmount} USDT has been credited to your account`,
                    'transaction',
                    { transactionType: 'wallet' })
    
                sendNotification(userDetails?.user?._id,
                    `Transaction`,
                    `${values.cryptoAmount} USDT has been debited from your account`,
                    'transaction',
                    { transactionType: 'wallet' })

                getCryptoTransactionList();
                setisSubmit(false);
                getWallet();
                setVerify2FA(false)
            } catch (error) {
                
                setVerify2FA(false)
                sendNotification(userDetails?.user?._id,
                    `Transaction`,
                    `Transaction failed due to some issue`,
                    'transaction',
                    { transactionType: 'wallet' })

                setisSubmit(false);
                setNavigateLink('');
                handleNotSentOtpModal();
                localStorage.removeItem('qrcode');
                handleShowModal(error?.response?.data?.message ? error?.response?.data?.message : 'Some Error Occured!!')
            }
        }
        else {
            try {
                let res = await axios.post(API_URLs.sendMATIC, {
                    amount: values.cryptoAmount,
                    reciverNickName: values.walletAddress,
                    otp: otpValue,
                    token: otpHash
                }, {
                    headers: {
                        accept: "application/json",
                        Authorization: `Bearer ${localStorage.getItem('jwt')}`
                    }
                })
                getCryptoTransactionList();
                handleNotSentOtpModal();
                localStorage.removeItem('qrcode');
                handleShowModal(res?.data?.msg ? res?.data?.msg : 'Send Successfully!!')
                setNavigateLink('');
                let reciever = res?.data?.receiverId;
                sendNotification(reciever,
                    `Transaction`,
                    `${values.cryptoAmount} MATIC has been credited to your account`,
                    'transaction',
                    { transactionType: 'wallet' })
    
                sendNotification(userDetails?.user?._id,
                    `Transaction`,
                    `${values.cryptoAmount} MATIC has been debited from your account`,
                    'transaction',
                    { transactionType: 'wallet' })

                getWallet();
                setVerify2FA(false)
            }
            catch (error) {

                setVerify2FA(false)
                sendNotification(userDetails?.user?._id,
                    `Transaction`,
                    `Transaction failed due to some issue`,
                    'transaction',
                    { transactionType: 'wallet' })
                    
                setNavigateLink('');
                handleNotSentOtpModal();
                localStorage.removeItem('qrcode');
                handleShowModal(error?.response?.data?.message ? error?.response?.data?.message : 'Some Error Occured')
            }
        }
    }

    // send modal form
    const formik1 = useFormik({
        initialValues: { walletAddress: '', cryptoAmount: '', currency: "" },
        validationSchema: Yup.object({
            currency: Yup.object().shape({
                value: Yup.string().required("Currency is Required"),
            }),
            walletAddress: Yup.string().required('This Field is Required'),
            cryptoAmount: Yup.string().required('Amount is Required'),
        }),
        onSubmit: (value) => {
           if( !isValid(value)){
            return
           }
            setisSubmit(true)
            axios.post(API_URLs.verify2faTxn,{},{
                headers: {
                    accept: 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('jwt')}`
                }
            })
            .then((res)=>{
           // console.log(res);
                setisSubmit(false)
                if(res.data.isTwoFa){
                    if(res.data.responses.img){
                        localStorage.setItem('qrcode', res.data.responses.img)
                    }
                    setOtpHash(res?.data?.responses?.token);
                }
                else{
                    setOtpHash(res?.data?.responses);
                }
                handleSentOtpModal(res?.data?.isTwoFa);
                handleClose1();
            })
            .catch((err)=>{
           // console.log(err);
            })
        }
    })

    const handleVerify2FA=()=>{
        location.hash.includes('external-wallet') ? intToExt(formik1.values) : intToInt(formik1.values)
    }
console.log('formik1.values',formik1.values)
    const handleSetMax = () => {
        let main = amount - Number(estGasFee);
        formik1.setValues({
            walletAddress: formik1?.values?.walletAddress, 
            currency: formik1?.values?.currency,
            cryptoAmount: main.toString()
        })
    }

    useEffect(() => {
        getWallet();
        getInBTC();
        if (!userDetails?.user?.currency) {
            handleShowModal("Select your preferred currency, first!!");
            setNavigateLink('/dashboard/settings#preference')
        }
    }, [])

    const CurrencyObj = {
        ETH: { name: 'Ethereum', shortName: 'Ether',img: Ether},
        BTC: { name: 'Bitcoin', shortName: 'Btc',img: Btc},
        USDC: { name: 'USD Coin', shortName: 'Usdc',img: Usdc},
        USDT: { name: 'Tether', shortName: 'Usdt',img: Usdt},
        MATIC: { name: 'Polygon', shortName: 'Matic',img: Matic},  
        LTC: { name: 'Polygon', shortName: 'Matic',img: Matic}  
    }
    
    // wallet column format
    const walletColumn = [
        {
            name: <p className='fw-bold mb-0 fs-6'>CURRENCY</p>,
            selector: (row) => <div className='d-flex justify-content-between align-items-center gap-1'>
                <ImgDiv>
                    <img src={CurrencyObj[row.currency]?.img} alt='' width='100%' />
                </ImgDiv>
                <div className='d-flex gap-1'>
                    <p className='mb-0 fw-bold'>{CurrencyObj[row.currency]?.name}</p>
                    <Small className='d-none d-md-block text-secondary'>{CurrencyObj[row.currency]?.shortName}</Small>
                </div>
            </div>
        },
        {
            name: <p className='fw-bold mb-0 fs-6'>AMOUNT</p>,
            selector: (row) => <div>
                {row?.balance}
            </div>
        },
        {
            name: <p className='fw-bold mb-0 fs-6'>IN {userDetails?.user?.currency ? userDetails?.user?.currency?.symbol : 'USD'}</p>,
            selector: (row) => <div>
                {row?.convert}
            </div>
        },
        {
            name: <p className='fw-bold mb-0 fs-6 text-center'>ACTION</p>,
            cell: (row) => <div className='d-flex justify-content-between' >
                <div className='d-md-flex d-none'>
                    <div className='d-flex flex-column gap-1 text-align-center justify-content-center sendcryptobuttonfortour' title='Send' onClick={() => handleShow1(row)}>
                        <BsFillSendFill size={17} style={{ color: '#1476FF', alignSelf: 'center' }} />
                        <small className='mb-0 d-flex flex-row text-secondary'>Send</small>
                    </div>
                    <div className='d-flex flex-column gap-1 px-3 text-align-center justify-content-center getcryptobuttonfortour' title='Send' onClick={() => handleShow2(row)}>
                        <MdCallReceived size={17} style={{ color: '#1476FF', alignSelf: 'center' }} />
                        <small className='mb-0 text-secondary'>Receive</small>
                    </div>
                    <div className='d-flex flex-column gap-1 text-align-center justify-content-center convertbutton' onClick={() => navigate('/dashboard/wallet#convert')} title='Convert'>
                        <GiRecycle size={17} style={{ color: '#1476FF', alignSelf: 'center' }} />
                        <small className='mb-0 text-secondary'>Convert</small>
                    </div>
                    <div className="dropdown px-2 moreoptionbutton">
                        <a className="" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                            <BsThreeDots />
                        </a>
                        <ul className="dropdown-menu bg-white" aria-labelledby="dropdownMenuButton1">
                            < Link className="dropdown-item" to={`/dashboard/buy/${row.currency_id}`}>Buy</Link>
                            < Link className="dropdown-item" to={`/dashboard/sell/${row.currency_id}`}>Sell</Link>
                        </ul>
                    </div>
                </div>
                <div className="dropdown px-2 d-md-none d-sm-block d-flex justify-content-center align-items-center">
                    <a className="" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                        <BsThreeDots />
                    </a>
                    <ul className="dropdown-menu dropdown-menu-on-small bg-white" aria-labelledby="dropdownMenuButton1">
                        <div className='d-flex gap-1 mb-1 sendcryptobutton' title='Send' onClick={() => handleShow1()}>
                            <BsFillSendFill size={17} style={{ color: '#1476FF', alignSelf: 'center' }} />
                            <small className='mb-0 d-flex flex-row text-center text-secondary'>Send</small>
                        </div>
                        <div className='d-flex gap-1 mb-1 px-3 getcryptobutton ' title='Send' onClick={() => handleShow2(row)}>
                            <MdCallReceived size={17} style={{ color: '#1476FF', alignSelf: 'center' }} />
                            <small className='mb-0 text-secondary'>Receive</small>
                        </div>
                        <div className='d-flex gap-1 mb-1 convertbutton' onClick={() => navigate('/dashboard/wallet#convert')} title='Convert'>
                            <GiRecycle size={17} style={{ color: '#1476FF', alignSelf: 'center' }} />
                            <small className='mb-0 text-secondary'>Convert</small>
                        </div>
                        <div className='d-flex gap-1 px-3'>
                            <MdCallReceived size={17} style={{ color: '#1476FF', alignSelf: 'center' }} />
                            < Link className="dropdown-item px-0 text-secondary" to={'/dashboard/buy'}>Buy</Link>
                        </div>
                        <div className='d-flex gap-1 px-3'>
                            <MdCallReceived size={17} style={{ color: '#1476FF', alignSelf: 'center' }} />
                            < Link className="dropdown-item px-0 text-secondary" to={'/dashboard/sell'}>Sell</Link>
                        </div>
                    </ul>
                </div>
            </div>

        }
    ]
    const walletColumnforDash = [
        {
            name: <p className='fw-bold mb-0 fs-6'>CURRENCY</p>,
            selector: (row) => <div className='d-flex justify-content-between align-items-center gap-1'>
                <ImgDiv>
                    <img src={CurrencyObj[row.currency].img} alt='' width='100%' />
                </ImgDiv>
                <div className='d-flex gap-1'>
                    <p className='mb-0 fw-bold'>{CurrencyObj[row.currency].name}</p>
                    <Small className='d-none d-md-block text-secondary'>{CurrencyObj[row.currency].shortName}</Small>
                </div>
            </div>
        },
        {
            name: <p className='fw-bold mb-0 fs-6'>AMOUNT</p>,
            selector: (row) => <div>
                {row.balance}
            </div>
        },
        {
            name: <p className='fw-bold mb-0 fs-6'>IN {userDetails?.user?.currency ? userDetails?.user?.currency?.symbol : 'USD'}</p>,
            selector: (row) => <div>
                {row.convert}
            </div>
        }
    ]
    createTheme('solarized', {
        background: {
            default: `${isDarkTheme ? "#0c0c0c" : 'white'}`
        },
        action: {
            hover: 'rgba(0,0,0,.08)',
        },
        text: {
            secondary: `${isDarkTheme ? "white" : 'black'}`
        },
    });
    const customStyles = {
        header: {
            style: {
                fontSize: '20px',
                color: '#0B0E2C',
                fontFamily: 'Inter',
                fontStyle: 'normal',
                fontWeight: '600',
            },
        },
        headRow: {
            style: {
                fontFamily: 'Inter',
                fontStyle: 'normal',
                fontSize: '12px',
                ...( isDarkTheme ? {borderTop: '1px solid #302f30'}:
                { border: '1px solid lightgray'}),
                color: `${isDarkTheme ? "#e9e9e9":'#6F7182'}`,
                backgroundColor: `${isDarkTheme ? "#0c0d0d":"#F7F9FC"}`,
                textTransform: 'uppercase'
            },
        },
        rows: {
            style: {
                color: `${isDarkTheme && 'white'}`,
                backgroundColor: `${isDarkTheme && 'rgba(8, 9, 14, 0.96)'}`,
                borderTop: '1px solid lightgray',
                '&:not(:last-of-type)': {
                    borderBottom: 'none',
                },
            }
        },
        headCells: {
            style: {
                display: 'flex',
                alignItems: 'center'
            },
        },
        cells: {
            style: {
                display: 'flex',
                alignItems: 'center'
            },
        }
    }
    const styles = {
        control: (styles) => ({
            ...styles,
            background: 'transparent',
            borderRadius: '52px',
            width: '100%',
            height: '50px',
            color: 'white',
            boxShadow: `${isDarkTheme ? darkTheme.boxshadow : lightTheme.boxshadow}`,
            border: `${isDarkTheme ? darkTheme.border : lightTheme.border}`, "&:hover": {
                border: `${isDarkTheme ? darkTheme.border : lightTheme.border}`
            },
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: isDarkTheme && state.isHovered ? '#302F30' : `${({theme})=> theme.body}`,
            ':hover': {
              backgroundColor:  `${isDarkTheme ? '#302F30' : '#D9EDE7'}` ,
            },
            color: isDarkTheme && state.isSelected ? '#1476FF' : `${({theme})=> theme.text}`,
        }),
        indicatorSeparator: () => ({
            all: 'unset'
        }),
        indicatorsContainer: (styles) => ({
            ...styles, color: isDarkTheme ? 'white' : 'black', 'div:nth-child(2)': {
                color: isDarkTheme ? 'white' : lightTheme.text
            }
        }),
        menu: (styles) => ({
            ...styles, background: isDarkTheme ? 'rgb(27, 27, 28)' : 'white',
            color: isDarkTheme ? 'white' : lightTheme.text,
        })
    }
    
    const isTabSelected = location.hash.includes('qvani-wallet')
        || location.hash.includes('external-wallet')

    const[estGasFee, setEstGasFee] = useState('')
    const[adminFee, setAdminFee] = useState('')
    const[gasFeeConvert, setGasFeeConvert] = useState('');
    const[calculationFees, setCalculationFees] = useState({})
    const handleCalculate = (values, type)=>{
        axios.get(API_URLs.getEstimation(values?.currency?._id, values?.cryptoAmount, values?.walletAddress, type),{
            headers: {
                accept: 'application/json',
                Authorization: `Bearer ${localStorage.getItem('jwt')}`
            }
        })
        .then((res)=>{
            setCalculationFees(res?.data?.result);
            for( let key in res.data.result){
                if(key !== 'adminFee'){
                    setEstGasFee(res.data.result[key])
                    axios.get(API_URLs.cryptoToFiat + '?from=' + formik1.values.currency.value + '&amount=' + res.data.result[key] + '&target=' + userDetails?.user?.currency?._id,{
                        headers: {
                            accept: 'application/json',
                            Authorization: `Bearer ${localStorage.getItem('jwt')}`
                        }
                    })
                    .then((res)=>{
                        setGasFeeConvert(res.data.convertedRate)
                    })
                    .catch((error)=>{ })
                }
                else {
                    setAdminFee(res?.data?.result[key])
                }
            }
        })
        .catch((err)=>{
       console.log('Error', err);
            handleShowModal(err?.response?.data?.message)
            setNavigateLink('')
            // toast.dark(err?.response?.data?.message ? `${err?.response?.data?.message}, enter correct data` : 'Some Error Occured while calculating Gas Fee!!')
        })
    }

    console.log(calculationFees, 'this is icalculated gas fee');
    const calEst = ()=>{    
        if(formik1?.values?.walletAddress && formik1?.values?.cryptoAmount && formik1?.values?.currency){
            location.hash.includes('external-wallet') ? handleCalculate(formik1.values, 'external') : handleCalculate(formik1.values, 'internal') 
        }
    }

    const [time, setTime] = useState(600);

    useEffect(() => {
        const interval = setInterval(() => {
          if (time > 0) {
            setTime(time - 1);
          } else {
            clearInterval(interval);
          }
        }, 1000);
  
        return () => clearInterval(interval);
    }, [time]);

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    };

    console.log(gasFeeConvert, 'this is gas fee')

    return (
        <div>
            { shown &&  <div className='row m-0 d-flex justify-content-center'>
                <HoldingCard className='totalholdingboxfortour col-md-4 py-2 px-3 col-sm-12 d-flex flex-column justify-content-center align-items-center card'>
                    <p className='mb-0 fw-bold'>Total Holdings</p>
                    <small className='mb-0 text-secondary'>Total Value of wallet Assets</small>
                    <hr className='my-2' />
                    <p className='mb-0 fw-bold'>{arr1 ? arr1[0]?.amount : '0'} BTC</p>
                    <p className='mb-0'><span style={{fontSize: '17px', fontWeight: '700'}}>≈</span> {arr1[0]?.convert ? arr1[0]?.convert?.toFixed(2) : '0'} {userDetails?.user?.currency?.symbol}</p>
                </HoldingCard>
            </div>}
            <div className='row m-0 d-flex flex-wrap allwalletdetailsfortour' >
                <Div>
                    <p style={{ margin: '13px', fontSize: '16px', fontWeight: '700' }}>Assets</p>
                    <DataTable className='mywallettable allwalletdetailsfortour'
                        data={arr}
                        columns={shown ? walletColumn : walletColumnforDash}
                        customStyles={customStyles}
                        highlightOnHover
                        theme='solarized'
                        style={{overflowX: 'hidden'}}
                    />
                </Div>

                <Modal style={{ background: 'transparent' }} show={show1} onHide={handleClose1}>
                    <Modal.Header style={{ background: isDarkTheme ? '#0c0c0c' : 'white'}} >
                        <Modal.Title><MdCallMade /> Send Your Crypto</Modal.Title>
                        <img role='button' onClick={handleClose1} src={isDarkTheme ? CrossIconWhite : CrossIconBlack} alt='Close' />
                    </Modal.Header>
                    <Modal.Body style={{ background: isDarkTheme ? '#0c0c0c' : 'white'}}>
                        <form onSubmit={formik1.handleSubmit} className='d-flex flex-column gap-3'>
                            <div>
                                <Label>Select Currency</Label>
                                <Select
                                    className="select"
                                    name='currency'
                                    id='currency'
                                    placeholder='Select Currency'
                                    height='100%' width='100%'
                                    options={cryptoCurrencyOptions}
                                    styles={styles}
                                    onChange={(selectedValues) => {
                                        let event = { target: { name: 'currency', value: selectedValues } }
                                        formik1.handleChange(event)
                                    }}
                                    onBlur={(selectedValues) => {
                                        let event = { target: { name: 'currency', value: selectedValues } }
                                        formik1.handleBlur(event)
                                    }}
                                    value={formik1.values.currency}
                                />
                                <small>
                                    {formik1.errors.currency && formik1.touched.currency && (
                                        <div className="input-feedback text-danger auth-error text-start">{String(formik1.errors.currency?.value).replace('.value', '')}</div>
                                    )}
                                </small>
                            </div>
                            <div>
                                <div className='d-flex justify-content-between'>
                                    <Label htmlFor='walletAddress'>Enter Wallet Address</Label>
                                    <div className='tab-navigator'>
                                        <ul className="nav nav-tabs crypto-send d-flex gap-3" id="myTab" role="tablist">
                                            <li className="nav-item text-decoration-none" >
                                                <TabNavigationButton
                                                    className={`nav-link gap-1 ${isTabSelected ? location.hash.includes('qvani-wallet') ? 'active' : '' : 'active'}`}
                                                    id="qvani-wallet-tab"
                                                    data-bs-toggle="tab"
                                                    data-bs-target="#qvani-wallet"
                                                    type="button"
                                                    role="tab"
                                                    aria-controls="qvani-wallet"
                                                    walletAddress                        
                                                    onClick={() => navigate('#qvani-wallet')}
                                                    aria-selected="true">
                                                    Qvani
                                                </TabNavigationButton>
                                            </li>
                                            <li className="nav-item text-decoration-none" >
                                                <TabNavigationButton
                                                    className={`nav-link gap-1 ${isTabSelected ? location.hash.includes('external-wallet') ? 'active' : '' : ''}`}
                                                    id="external-wallet-tab"
                                                    data-bs-toggle="tab"
                                                    data-bs-target="#external-wallet"
                                                    type="button"
                                                    role="tab"
                                                    aria-controls="external-wallet"
                                                    onClick={() => navigate('#external-wallet')}
                                                    aria-selected="true">
                                                    Address
                                                </TabNavigationButton>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="tab-content" id="myTabContent">
                                    <div
                                        className={`tab-pane fade ${isTabSelected ? location.hash.includes('qvani-wallet') ? 'show active' : '' : 'show active'}`}
                                        id="qvani-wallet"
                                        role="tabpanel"
                                        aria-labelledby="qvani-wallet-tab">
                                        <Input
                                            id='walletAddress'
                                            name='walletAddress'
                                            placeholder='Enter Wallet Address Here'
                                            value={formik1.values.walletAddress}
                                            onChange={formik1.handleChange}
                                            onBlur={(event)=>{
                                                calEst()
                                                formik1.handleBlur(event)
                                            }}
                                        />
                                        <small>
                                            {formik1.errors.walletAddress && formik1.touched.walletAddress && (
                                                <div className="input-feedback text-danger auth-error text-start">{formik1.errors.walletAddress}</div>
                                            )}
                                        </small>
                                    </div>
                                    <div
                                        className={`tab-pane fade ${isTabSelected ? location.hash.includes('external-wallet') ? 'show active' : '' : ''}`}
                                        id="external-wallet"
                                        role="tabpanel"
                                        aria-labelledby="external-wallet-tab">
                                        <Input
                                            id='walletAddress'
                                            name='walletAddress'
                                            placeholder='Enter Wallet Address Here'
                                            value={formik1.values.walletAddress}
                                            onChange={formik1.handleChange}
                                            onBlur={(event)=>{
                                                calEst()
                                                formik1.handleBlur(event)
                                            }}
                                        />
                                        <small>
                                            {formik1.errors.walletAddress && formik1.touched.walletAddress && (
                                                <div className="input-feedback text-danger auth-error text-start">{formik1.errors.walletAddress}</div>
                                            )}
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <Label htmlFor='cryptoAmount'>Enter Amount</Label>
                                <DivWithMax>
                                    <InputWithMax
                                        id='cryptoAmount'
                                        name='cryptoAmount'
                                        placeholder={`Enter Amount (${amount} is maximum)`}
                                        value={formik1.values.cryptoAmount}
                                        onChange={(e)=>{
                                            setIsError({...isError,errorMessage:''})
                                            formik1.handleChange(e)
                                        }}
                                        onBlur={(event)=>{
                                            calEst()
                                            formik1.handleBlur(event)
                                        }}
                                    />
                                    <ButtonWithMax onClick={() => handleSetMax()} type='button'>Max</ButtonWithMax>
                                </DivWithMax>
                                <small>
                                    {formik1.errors.cryptoAmount && formik1.touched.cryptoAmount && (
                                        <div className="input-feedback text-danger auth-error text-start">{formik1.errors.cryptoAmount}</div>
                                    )}
                                    {isError?.errorMessage && <p className='m-0 text-danger'>{isError?.errorMessage}</p> }
                                </small>
                            </div>
                            <div>
                                <ReqButton disabled={isSubmit} type='submit'>
                                    {
                                        isSubmit ?
                                            <p className='mb-0 d-flex justify-content-center align-items-center'><ButtonLoader /><span>Send</span></p> : <span>Send</span>
                                    }
                                </ReqButton>
                            </div>
                        </form>
                        <span className='mt-3 d-flex'>
                            <h6>Gas Fee: </h6>
                            {console.log('formik1?.values?.currency !=', calculationFees)}
                            <small>{calculationFees && Object.keys(calculationFees).length > 0  && `${formik1?.values?.currency !='' && calculationFees[formik1?.values?.currency?.symbol]} ${formik1?.values?.currency && formik1?.values?.currency?.symbol}`}
                                {gasFeeConvert >= 0 &&  ` ≈ ${gasFeeConvert} ${userDetails?.user?.currency && userDetails?.user?.currency?.symbol}`}
                            </small>
                        </span>
                        <span className='mt-3 d-flex'>
                            <h6>Admin Fee: </h6>
                            <small>{calculationFees?.adminFee ? `${calculationFees?.adminFee} ${formik1?.values?.currency?.symbol}` : `0 ${formik1?.values?.currency && formik1?.values?.currency?.symbol}`}</small>
                        </span>
                    </Modal.Body>
                </Modal>

                <Modal show={show2} onHide={handleClose2}>
                    <Modal.Header style={{ background: isDarkTheme ? '#0c0c0c' : 'white'}} >
                        <Modal.Title>Your {data1?.currency} Address</Modal.Title>
                        <img role='button' onClick={handleClose2} src={isDarkTheme ? CrossIconWhite : CrossIconBlack} alt='Close' />
                    </Modal.Header>
                    <Modal.Body style={{ background: isDarkTheme ? '#0c0c0c' : 'white'}}>
                        <div className='d-flex p-3' >
                            {
                                data1.address &&
                                <QRdiv className='bg-white rounded-2 p-2'>
                                    <QRCode
                                        size={256}
                                        style={{ height: "auto", maxWidth: "100px" }}
                                        value={data1.address}
                                        viewBox={`0 0 256 256`}
                                    />
                                </QRdiv>
                            }
                        </div>
                        <div>
                            <p className='fw-bold fs-4 m-0 mt-2'>Your {data1.currency} Address:</p>
                            <small className='fw-bold'>Use this address to deposit {data1.currency}</small><br />
                            <div className='d-flex gap-2'>
                                <small>{data1.address}</small>
                                <BiCopy onClick={() => copyToClipBoard(data1.address)} role='button' title='copy' size={17} />
                            </div>
                            <small className='fw-bold'>{copySuccess}</small>
                        </div>
                    </Modal.Body>
                </Modal>

                <Modal show={otpSent}>
                    <Modal.Header className='d-flex align-items-center justify-content-between'>
                        {/* <Modal.Title className='fw-bold fs-5'>Verify its you</Modal.Title> */}
                        <img role='button' onClick={handleNotSentOtpModal} src={isDarkTheme ? CrossIconWhite : CrossIconBlack} alt='Close' />
                    </Modal.Header>
                    <Modal.Body>
                    <div className='d-flex justify-content-center align-items-center verify-two-fa-txn m-0 flex-column'>
                        {
                            localStorage.getItem('qrcode') &&
                            <img className='w-50' src={localStorage.getItem('qrcode')} alt="QR Code" />
                        }
                        <p className='text-center mb-2'>Successfully One Time Password To Your Email Address</p>
                        <OTPInput
                            className='OtpInputBox'
                            value={otpValue}
                            onChange={setOtpValue}
                            autoFocus
                            OTPLength={isTwoFa ? 6 : 4}
                            otpType="number"
                            disabled={false} secure
                        />
                        {time === 0 ?
                            <Para className='text-center pt-3 mb-1'>Verification code has been expired</Para> :
                            <Para className='text-center pt-3 mb-1'>Verification code will expire in {formatTime(time)} minutes</Para>
                        }
                        <ReqButton disabled={verify2FA}  onClick={()=>handleVerify2FA()} className='text-center'>{ verify2FA ? 'verifying...':'Verify'}</ReqButton>
                    </div>
                    </Modal.Body>
                </Modal>
                
                {showModal && (<CustomModal
                    show={showModal}
                    handleClose={handleCloseModal}
                    navigateLink={navigateLink}
                    data={modalData}
                />)}
            </div>
        </div>
    );
}
CryptoBalance.propTypes = {
    loader: PropTypes.bool.isRequired,
    completeeOffer: PropTypes.shape().isRequired,
};


