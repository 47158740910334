import React, { useEffect, useState } from 'react';
import SmallLogo from '../../assets/Images/LogoFooter.png';
import { Text, Logo, logo, CRDiv } from './Styled';
import { useNavigate, Link } from 'react-router-dom';
import styled from 'styled-components';
import { SlArrowUp, SlArrowDown } from "react-icons/sl";
import './Footer.css';
import { useAPIContextHook } from '../../Context/ApiContext';
import { useAuthContextHook } from '../../Context/AuthContext';

const Div = styled.div`
  margin-left: -5px;
@media (min-width: 1200px) {
    width: 80%;
}
`

const AccordionItem = styled.div`
//   border-top: 1px solid #ccc;
//   padding-top: 1rem;
//   margin-top: 1rem;
`

const AccordionTitle = styled.p`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  margin-bottom: 0.5rem;
`

export default function Footer() {
    const navigate = useNavigate();
    const {cryptoCurrencyOptions} = useAPIContextHook();
    const [activeAccordion, setActiveAccordion] = useState(null);
    const [additionService, setAdditionService]=useState([]);
    const { IsLogedIn } = useAuthContextHook();
    const toggleAccordion = (index) => {
        if (activeAccordion === index) {
            setActiveAccordion(null);
        } else {
            setActiveAccordion(index);
        }
    };
    const data = [
        {
            head: "Company",
            option: [
                {
                    name: "About Qvani Technologies",
                    link: 'about'
                },
                {
                    name: "Careers",
                    link: '//careers.qvani.com'
                },
                {
                    name: "Qvani Referral Rewards Program",
                    link: 'rewards'
                },
                {
                    name: "Qvani Academy",
                    link: 'about'
                },
                {
                    name: "Qvani Blog",
                    link: '//qvani.com/blog',
                    external: true,
                },
                {
                    name: "Contact us",
                    link: 'contact'
                }
            ]
        },
        {
            head: "Services",
            option: [...additionService,
                {
                    name: "Convert Crypto",
                    link: `${ IsLogedIn ? 'wallet#convert': 'login'}`
                },
                {
                    name: "Qvani Crypto Wallet",
                    link: `${ IsLogedIn ? 'wallet': 'login'}`
                }
            ]
        },
        {
            head: "Legal",
            option: [
                {
                    name: "Terms of Use",
                    link: 'terms'
                },
                {
                    name: "Privacy Policy",
                    link: 'privacy-policy'
                }
            ]
        },
        {
            head: "Support",
            option: [
                // {
                //     name: "24/7 Chat Support",
                //     link: 'contact'
                // },
                {
                    name: "Help Center",
                    link: '//support.qvani.com'
                },
                {
                    name: "Qvani Trading Fees",
                    link: 'rewards'
                }
            ]
        }
    ]

    useEffect(()=>{
        const arr=[];
        for(var key in cryptoCurrencyOptions){
            let row = cryptoCurrencyOptions[key];
            
            if(row?.symbol != 'usdt'){
                arr.push({
                    name: `Buy ${row?.symbol?.toUpperCase()}`,
                    ...( IsLogedIn ? { link: `buy/${row?._id}` }:{ link: 'login' })
                    
                })
                arr.push({
                    name: `Sell ${row?.symbol?.toUpperCase()}`,
                    ...( IsLogedIn ? { link: `sell/${row?._id}` }:{ link: 'login' })
                })
            }
        }

        setAdditionService(arr);
    },[])

    return (
        <>
            <div className='container-fluid pt-4 d-flex flex-column flex-md-row flex-wrap border border-top-1 gap-2 bg-white'>
                <div className='col-lg-3 col-md-5 pt-4 px-sm-1 px-md-5 d-flex flex-column'>
                    <div className='d-flex mb-2 justify-content-center justify-content-md-start' onClick={() => navigate('/home')}>
                        <img src={SmallLogo} alt='Small Logo' className='logo-footer'/>
                    </div>
                    <Div className='d-flex justify-content-around justify-content-md-start flex-wrap mt-4'>
                        {logo.map((data) => {
                            return <Logo as={Link} to={data.link} target='_blank'>{data.logo}</Logo>;
                        })}
                    </Div>
                </div>
                {
                    data.map((data) => {
                        return <div className='d-none d-lg-block col-md-2 pt-3'>
                            <p className='mb-2 fw-bold'>{data.head}</p>
                            <div className='d-flex flex-column gap-2'>
                            {
                                    data.option.map((subdata) => {
                                        return subdata.external ? 
                                            <Text as="a" href={subdata.link} target="_blank" rel="noopener noreferrer">{subdata.name}</Text>
                                            :
                                            <Text as={Link} to={subdata.link}>{subdata.name}</Text>;
                                    })
                                }
                            </div>
                        </div>
                    })
                }
                <div className='col-md-5 col-12 mt-1 mt-md-3'>{data.map((item, index) => (
                    <AccordionItem className='d-block d-lg-none' key={index}>
                        <AccordionTitle onClick={() => toggleAccordion(index)}>
                            <span className='fw-bold'>{item.head}</span>
                            {activeAccordion === index ? <SlArrowUp /> : <SlArrowDown />}
                        </AccordionTitle>
                        {activeAccordion === index && (
                            <div className='d-flex flex-column gap-2'>
                                 {item.option.map((subdata, subIndex) => (
                                    subdata.external ? 
                                        <Text key={subIndex} as="a" href={subdata.link} target="_blank" rel="noopener noreferrer">{subdata.name}</Text>
                                        :
                                        <Text key={subIndex} as={Link} to={subdata.link}>{subdata.name}</Text>
                                ))}
                            </div>
                        )}
                    </AccordionItem>
                ))}</div>
            </div>
            <CRDiv>
                <Text className='text-center fw-semibold'>Copyright © 2024 Qvani.com. All Rights Reserved.</Text>
            </CRDiv>
        </>
    );
}



