import { useEffect, useRef } from 'react';

const useDetectFormChanges = (isDirty, navigate) => {
  const initialRender = useRef(true);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      return;
    }

    const handleBeforeUnload = (event) => {
      if (isDirty) {
        const message = 'You have unsaved changes. Are you sure you want to leave?';
        event.returnValue = message;
        return message;
      }
    };

    const handleNavigation = (e) => {
      if (isDirty && !window.confirm('You have unsaved changes. Are you sure you want to leave?')) {
        e.preventDefault();
      } else {
        window.location.reload();
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    window.addEventListener('popstate', handleNavigation);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('popstate', handleNavigation);
    };
  }, [isDirty, navigate]);
};

export default useDetectFormChanges;
