import styled from "styled-components";

export const Container = styled.div`
    background: ${({ theme }) => theme.background};
    min-height: 89vh;
`

export const CardContainer = styled.div`
background: ${({theme})=>theme.body === "#F7F9FC" ? 'white': theme.body};
border: ${({theme})=>theme.border};
box-shadow: ${({theme})=>theme.boxshadow};
border-radius: 30px;
`
export const Label1 = styled.label`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.06em;
    text-transform: uppercase;
    color: #6F7182;
`
export const Small = styled.small`
font-size: 12px;
`
export const ProfileImage = styled.img`
    width: 130px;
    height: 130px;
    border: 1px solid lightgrey;
    border-radius: 100%;
    background: gray;
`

export const ProfileHeading = styled.h3`
font-family: 'Inter';
color: ${({theme})=>theme.text === "#121620" ? '#0B0E2C': theme.text};
font-style: normal;
font-weight: 700;
font-size: 24px;
line-height: 34px;
text-transform: capitalize;
word-break: break-all;
`

export const SubHeading = styled.p`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    margin-top: 3px;
    margin-bottom: 0px;
    font-size: 16px;
    color: #6F7182;
`

export const TransparentButton = styled.button`
    background: #FFFFFF;
    border: 1px solid #E9EAF3;
    box-shadow: 0px 4px 10px rgba(20, 20, 43, 0.04);
    border-radius: 40px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #0B0E2C;
`

export const ProfileHeading5 = styled.h5`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    text-transform: capitalize;
    word-break: break-all;
`

export const Label = styled.label`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.06em;
    text-transform: uppercase;
    color: #6F7182;
`

export const Para = styled.p`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: ${({theme})=>theme.text};
    text-transform: capitalize;
`
export const Para1 = styled.p`
    font-family: 'Inter';
    color: ${({theme})=>theme.text};
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
`

export const TabButton = styled.button`
    font-size: 0.8rem;
`
export const Heading = styled.p`
    font-family: 'Montserrat';
    font-weight: 700;
    color: #3545EE;
    font-size: 1.5rem;
`
export const P = styled.p`
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 0.9rem;
    color: ${({ theme }) => theme.text};
`

export const Card = styled.div`
    overflow: hidden;
    border: 1px solid black;
    border-radius: 45px;
    padding: 0px 2px;
    margin: 0px 0px 20px 0px;
`

export const CardText = styled.span`
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 0.9rem;
    color: #7A7A7A;
`

export const ActiveButton = styled.button`
    background: #3545EE;
    border-radius: 35px;
    border: 1px solid #3545EE;
    color: white;
    padding: 4px 10px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
`

export const DisableButton = styled.button`
    background: #b02b2b;
    border-radius: 35px;
    border: 1px solid #b02b2b;
    color: white;
    padding: 4px 10px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
`

export const SecurityCard = styled.div`
    background: #FFFFFF;
    border: ${({ active }) => active ? '2.5px solid #1476FF' : '1px solid #E9EAF3'};
    box-shadow: 0px 2px 10px rgba(25, 93, 194, 0.07);
    border-radius: 30px;
`

export const ImageContainer = styled.div`
    width: 120px;
    height: 120px;
    background: #C1C1C1;
    /* border: 6px solid ${props => props.color}; */
    border-radius: 100px;
    overflow: hidden;
`
export const Image = styled.img`
    width: 120px;
    height: 120px;
    background: #C1C1C1;
    /* border: 6px solid ${props => props.color}; */
    border-radius: 100px;
    overflow: hidden;
`

export const DeleteButton = styled.button`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: #FFFFFF;
    border: 2px solid #3545EE;
    border-radius: 20px;
    width: 100%;
    font-family: Inter;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    color: rgb(48, 51, 80);
`
export const SubmitButton = styled.button`
    width: 100%;
    background: #3545EE;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-family: Inter;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    color: white;
    border: 2px solid #3545EE;
    border-radius: 20px;
    margin-top: 20px;
`
export const Span = styled.span`
  margin-left: -35px;
`
export const ChangeButton = styled.button`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: #1476FF;
    border: 2px solid #1476FF;
    border-radius: 20px;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: white;
    width: 100%;
    letter-spacing: 0.5px;
    transition: all 0.2s;
    :not(:disabled):hover{
        opacity: 0.9;
    }
    :active{
        transform: scale(0.99);
    }
`
export const ImgDiv = styled.div`
    border:  ${({ theme }) => theme.border};
    display: flex;
    border-radius: 23px;
    justify-content: space-around;
    margin-top: 10px;
`
export const InputLabel = styled.label`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    color: #303350;
`

export const Input = styled.input`
    all: unset;
    background: #FFFFFF;
    border: 1px solid #E9EAF3;
    box-shadow: 0px 2px 12px rgba(11, 22, 44, 0.05);
    border-radius: 52px;
    outline: none;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #33343c;
    cursor: initial;
    
    :disabled{
        opacity: 0.8;
        color: #6F7182;
    }
`
export const PasswordInput = styled.input`
    all: unset;
    background: #FFFFFF;
    border: 1px solid #E9EAF3;
    box-shadow: 0px 2px 12px rgba(11, 22, 44, 0.05);
    border-radius: 52px;
    outline: none;
    padding: 7px;
    width: 90%;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #6F7182;

    :disabled{
        opacity: 0.5;
    }
`
export const InputContainer = styled.div`
    background: #FFFFFF;
    border: 1px solid #E9EAF3;
    box-shadow: 0px 2px 12px rgba(11, 22, 44, 0.05);
    border-radius: 52px;
    outline: none;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #6F7182;
`

export const TextSpan = styled.span`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #6F7182;
`

export const TabNavigationButton = styled.button`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
`

export const HR = styled.hr`
    color: #E9EAF3;
`