// import React, { Suspense } from 'react';
// import { useAuthContextHook } from '../Context/AuthContext'
// import Loader from '../Component/Loader/Loader';
// import { Outlet } from 'react-router-dom';
// import Error401 from '../pages/Error401';

// export default function ProtectedRoutes() {

//     const { IsLogedIn, loading } = useAuthContextHook();

//     if(loading){
//         return <Loader/>
//     }
//     return (
//         <Suspense fallback={<Loader/>}>
//         {
//             IsLogedIn && <Outlet />
//         }
//         </Suspense>
//     )
// }

import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuthContextHook } from '../Context/AuthContext';
import { isSessionExpired } from '../Utils/checkSession';

const ProtectedRoutes = () => {
  const { IsLogedIn } = useAuthContextHook();

  if (!IsLogedIn || isSessionExpired()) {
    localStorage.removeItem('jwt');
    return <Navigate to="/login" />;
  }

  return <Outlet />;
};

export default ProtectedRoutes;
