import styled from "styled-components";

export const Label = styled.label`
font-size: 14px;
margin-top: 10px;
font-weight: 600;
`
export const Input = styled.input`
padding: 5px 10px;
width: 100%;
border: ${({theme})=> theme.border};
box-shadow: ${({theme})=> theme.boxshadow};
border-radius: 25px;
outline: none;
margin: 5px 0px;
padding: 7px;
font-size: 1rem;
background: ${({theme})=> theme.background};
color: ${({theme})=> theme.text};
`
export const ImgDiv = styled.div`
width: 21px;
`
export const Div = styled.div`
margin-top: 1rem;
background: white;
padding: 0px !important;
border-radius: 20px;
border: ${({theme})=> theme.border};
box-shadow: ${({theme})=> theme.boxshadow};
`
export const QRdiv = styled.div`
border: ${({theme})=> theme.border};
`
export const TabNavigationButton = styled.button`
font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 16px;
`


export const Button = styled.button`
background: rgb(20, 118, 255);
border-radius: 30px;
border: 1px solid rgb(20, 118, 255);
color: white;
padding: 2px 12px;
margin: 2px 3px;
display: flex;
flex-direction: row;
-webkit-box-align: center;
align-items: center;
gap: 3px;
font-family: Montserrat;
font-style: normal;
font-weight: 600;
font-size: 13px;
`
export const ReqButton = styled.button`
width: 100%;
border: none;
background: #1476FF;
color: white;
border-radius: 20px;
margin-top: 12px;
padding: 8px 2px;
`
export const P = styled.p`
font-family: 'Poppins';
font-style: normal;
font-weight: 600;
font-size: 20px;
`
export const Error = styled.p`
font-size: 12px;
font-weight: 600;
color: red;
`