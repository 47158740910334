import React, { useState, useContext, createContext, useEffect } from "react";
import { API_URLs } from "../Utils/ApiUrls";
import axios from "axios";
import { useAuthContextHook } from "./AuthContext";
export const WalletContextHook = createContext();
export const useWalletContextHook = () => {
    return useContext(WalletContextHook);
}
export default function WalletContext({ children }) {
    const [arr, setarr] = useState([])
    const [arr1, setarr1] = useState([])
    const [dataLoader, setDataLoader] = useState(false)
    const { userDetails } = useAuthContextHook();
    const [cryptoLoader, setCryptoLoader] = useState(true)
    const [cryptoTransactionList, setCryptoTransactionList] = useState([]);

    const convertHandle = async (currency, balance, crypto) => {
        try {
            let res = await axios.get(API_URLs.cryptoToFiat + '?from=' + crypto + '&amount=' + balance + '&target=' + currency)
            return res.data.convertedRate;
        } catch (error) {

        }
    }
    
    const getInBTC = ()=>{
        axios.get(API_URLs.getInBTC,{
            headers: {
                accept: 'application/json',
                Authorization: `Bearer ${localStorage.getItem('jwt')}`
            }
        })
        .then(async (res)=>{

            let convert = await convertHandle(userDetails?.user?.currency?._id, res.data.BTC, "649990b9e757fa00277940b6");
            let data =[];
            data.push({
                amount: res.data.BTC,
                convert: convert
            })
            setarr1(data)
        })
        .catch((error)=>{

    })}
    
    const getWallet = () => {
        setDataLoader(true)
        axios.get(API_URLs.listCryptoWallets, {
            headers: {
                'accept': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('jwt')}`
            }
        })
            .then(async (res) => {
    
                let cryptoDetails = res.data
                let dArray = []
                for (let key in cryptoDetails) {
                    let rowdata = cryptoDetails[key];
                    let convert = 0;
                    const balance = ((rowdata.balance - rowdata?.actualSpendAmount) + rowdata.vbalance) - (rowdata.offerLockedAmount + rowdata.withdrawLockedAmount);
                    // const balance = ((rowdata.balance - rowdata?.actualSpendAmount) + rowdata.vbalance) - (rowdata.offerLockedAmount + rowdata.withdrawLockedAmount) - rowdata.btcLightningLocked;

                    if(balance > 0){
                        convert = await convertHandle(userDetails?.user?.currency?._id, balance, rowdata.currency_id);
                    }

                    let coindata = {
                        balance: balance,
                        isBlocked: rowdata?.isBlocked,
                        currency_id: rowdata?.currency_id,
                        address: rowdata?.address,
                        currency: rowdata?.currency,
                        id: rowdata?._id,
                        convert: convert?.toFixed(3),
                    }
                    dArray.push(coindata);
                }
    
                setarr(dArray);
                setDataLoader(false)
            })
            .catch((error) => {
                setDataLoader(false)
    
            })
    }

    const getCryptoTransactionList = () => {
        setCryptoLoader(true)
        axios.get(API_URLs.listCryptoTransactions, {
            headers: {
                'accept': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('jwt')}`
            }
        })
            .then((res) => {
                setCryptoLoader(false)
                let transaction2 = res?.data?.transaction2;
                setCryptoTransactionList(transaction2);
            })
            .catch((error) => {
                setCryptoLoader(false)
            })
    }

    // useEffect(()=>{
    //     getCryptoTransactionList();
    // }, [])

    const data = {
        getWallet,
        arr,
        getInBTC,
        arr1,
        getCryptoTransactionList,
        cryptoTransactionList, 
        dataLoader, 
        cryptoLoader,
    }
    return (

        <WalletContextHook.Provider value={{ ...data }}>
            {children}
        </WalletContextHook.Provider>
    );
}