import React from 'react'
import styled from 'styled-components';
import img1 from '../../assets/Images/idea.svg'
import img2 from '../../assets/Images/smart-tv.svg'
import img3 from '../../assets/Images/security.svg'
import img4 from '../../assets/Images/culture.png'
import img5 from '../../assets/Images/background-culture.png'

const Button = styled.button`
border-radius: 20px;
border: none;
width: 90px;
font-size: 14px;
font-weight: 600;
`

const P = styled.p`
color: #202939;
margin-bottom: 10px;
font-size: 32px;
font-weight: 700;
// text-align: center;
`

const Div = styled.div`
padding: 2rem 2rem;
background-color: #3545EE;
border-radius: 16px;
@media (max-width: 992px) {
    width: 90%; 
    margin-inline: auto;
}
`

const Img = styled.img`
left: -100px;
object-fit: cover;
border-radius: 20px;
margin-top: 2rem;

@media (min-width: 1200px){
    width: 90%;
    position: relative;
    top: 25%;
    height: 90vh;
}
`

const Culture = () => {
    return (
        <div className='mt-5'>
            <div className='row d-flex gap-5'>
                <Div className='col-lg-7 col-sm-12 p-2 px-md-4 mx-auto mx-md-0 d-flex flex-column justify-content-center gap-1 text-light order-sm-1 order-lg-0'>
                    <div className='d-flex align-items-center text-light'>
                        <img src={img1} alt="" />
                        <div className='ps-5'>
                            <P className='text-light'>Innovation</P>
                            <p className='pe-4 text-white text-justify fs-6'>With a long-term outlook on delivering robust and scalable solutions, we invest heavily in research and development to strengthen the Bitcoin ecosystem.</p>
                        </div>
                    </div>
                    <hr />
                    <div className='d-flex align-items-center text-light'>
                        <img src={img2} alt="" />
                        <div className='ps-5'>
                            <P className='text-light'>Smart Process</P>
                            <p className='pe-4 text-white text-justify fs-6'>Together with the Qvani community, we advocate for responsible rules to make the benefits of crypto available around the world. We prioritize earning trust by consistently adhering to responsible and ethical standards.</p>
                        </div>
                    </div>
                    <hr />
                    <div className='d-flex align-items-center text-light'>
                        <img src={img3} alt="" />
                        <div className='ps-5'>
                            <P className='text-light'>Security</P>
                            <p className='pe-4 text-white text-justify fs-6'>Applying cutting-edge cryptography and security engineering, we’re building the technology that makes financial markets more efficient. </p>
                        </div>
                    </div>
                    <hr />
                </Div>

                <div className='d-flex col-sm-12 flex-column mt-3 mt-md-0 col-lg-4 order-sm-0 order-lg-1'>
                    <Button className='mx-5'>Culture</Button>
                    <P className='text-center mx-3 text-md-start'>What drives every decision in Qvani Technologies team</P>
                    <div className='d-none d-md-none d-lg-block'>
                        <Img src={img4} alt="--" />
                    </div>
                </div>
                <div className='d-flex justify-content-center order-sm-2'>
                        <img src={img5} alt="" className='d-lg-none' width={'90%'} />
                </div>
            </div>
        </div>
    )
}

export default Culture