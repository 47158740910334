// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/Images/Blue-Background.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ContactHead{
    /* background: lineat-gradient('#EEF2F6, #FFFFFF, #EEF2F6, #FFFFFF'); */
    background: linear-gradient(217deg, #FFFFFF, rgba(255,0,0,0) 70.71%),
            linear-gradient(127deg, rgba(0,255,0,.8), #EEF2F6 70.71%),
            linear-gradient(336deg, #EEF2F6, rgba(0,0,255,0) 70.71%);
}


.imagebgWrapper {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
}

@media screen and (max-width: 720px){
    .imagebgWrapper{
        background-position: top 0 right 57%;  
    }

}

.text-justify {
    text-align: justify;
  }`, "",{"version":3,"sources":["webpack://./src/otherPages/Contact/Contact.css"],"names":[],"mappings":"AAAA;IACI,uEAAuE;IACvE;;oEAEgE;AACpE;;;AAGA;IACI,yDAAgE;IAChE,wBAAwB;IACxB,4BAA4B;IAC5B,sBAAsB;IACtB,gBAAgB;AACpB;;AAEA;IACI;QACI,oCAAoC;IACxC;;AAEJ;;AAEA;IACI,mBAAmB;EACrB","sourcesContent":[".ContactHead{\n    /* background: lineat-gradient('#EEF2F6, #FFFFFF, #EEF2F6, #FFFFFF'); */\n    background: linear-gradient(217deg, #FFFFFF, rgba(255,0,0,0) 70.71%),\n            linear-gradient(127deg, rgba(0,255,0,.8), #EEF2F6 70.71%),\n            linear-gradient(336deg, #EEF2F6, rgba(0,0,255,0) 70.71%);\n}\n\n\n.imagebgWrapper {\n    background-image: url('../../assets/Images/Blue-Background.png');\n    background-position: top;\n    background-repeat: no-repeat;\n    background-size: cover;\n    overflow: hidden;\n}\n\n@media screen and (max-width: 720px){\n    .imagebgWrapper{\n        background-position: top 0 right 57%;  \n    }\n\n}\n\n.text-justify {\n    text-align: justify;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
