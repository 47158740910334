import React, { useState } from 'react'
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Dropdown from 'react-bootstrap/Dropdown';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Logo from '../../assets/Images/Logo.png'
import Logo2 from '../../assets/Images/LogoBlue.png'
import { Button, Button1 } from './StyleComponent';
import { HiArrowLongRight } from "react-icons/hi2";
import { useAPIContextHook } from '../../Context/ApiContext';
import { CiUser } from "react-icons/ci";
import { RxHamburgerMenu } from "react-icons/rx";
import { AiOutlineClose } from "react-icons/ai";
import './PublicNavbar.css'
import styled from 'styled-components';

const DropDownButton =styled.button`
background: none;
border: none;
outline: none;
width: 100%;
:hover{
  background: none;
}
`
function PublicNavbar() {
const {cryptoCurrencyOptions} = useAPIContextHook();
  const [toggleClick, setToggleClick] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const getNavbarColor = () => {
    if (location.pathname === '/' || location.pathname === '/home' || location.pathname === '/about') {
      return ['#08021C', 'white', 'white'];
    } else {
      return ['#ffffff', 'dark', '#1476FF'];
    }
  };
  
  const getLogo = () => {
    if (location.pathname === '/' || location.pathname === '/home' || location.pathname === '/about') {
      return Logo;
    } else {
      return Logo2;
    }
  }
  const HandLeft =(string)=>{
    navigate('/login')
    localStorage.setItem('pending-route',string)
  }

  const navOption = [
    {
      name: 'Create Offer',
      link: '/dashboard/create-offer'
    },
    {
      name: 'Gift Card Center',
      link: '/dashboard/wallet'
    },
    {
      name: 'Wallet',
      link: '/dashboard/wallet'
    },
    {
      name: 'Convert',
      link: '/dashboard/wallet#convert'
    },
  ]
  return (
    <Navbar expand="xl" className="position-sticky top-0" style={{ backgroundColor: getNavbarColor()[0], zIndex: '2' }}>
      <div className='container-fluid px-sm-5'>
        <Navbar.Brand className='d-flex align-items-center' as={Link} to="/home"><img src={getLogo()} alt={Logo} /></Navbar.Brand>
        <div className='d-flex d-xl-none flex-end align-items-center'>
          <Nav.Link className={`d-xl-none px-0`} as={Link} to="login" style={{color: getNavbarColor()[1]}}><CiUser color={getNavbarColor()[2]} size={28}/></Nav.Link>
          
          <Dropdown>
            <Dropdown.Toggle className='outline-none bg-transparent border-0'  id="dropdown-basic">
            {toggleClick ? <AiOutlineClose size={24} /> : <RxHamburgerMenu size={24} />}
            </Dropdown.Toggle>
            <Dropdown.Menu align='end'>
              <Dropdown.Item className={`fw-bold text-start text-black`} onClick={()=>HandLeft('/dashboard.user-dashboard')}>Buy Crypto</Dropdown.Item>
              <Dropdown.Item className={`fw-bold text-start text-black`} onClick={()=>HandLeft('/dashboard.user-dashboard')}>Sell Crypto</Dropdown.Item>
              {
                navOption.map((data)=>{
                  return <Dropdown.Item className={`fw-bold text-start text-black`} onClick={()=>HandLeft(data.link)}>{data.name}</Dropdown.Item>
                })
              }
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="mx-auto d-none d-xl-flex gap-2">
          <NavDropdown className='fw-bold' role='button' title={<span className={`text-${getNavbarColor()[1]}`}>Buy Crypto</span>} id="basic-nav-dropdown">
            {
              cryptoCurrencyOptions.map((data)=>{
                return <DropDownButton onClick={()=>HandLeft(`/dashboard/buy/${data._id}`)}><NavDropdown.Item as={Link} className='text-start'>Buy {data.name}</NavDropdown.Item></DropDownButton>
              })
            }
          </NavDropdown>
          <NavDropdown className='fw-bold' title={<span className={`text-${getNavbarColor()[1]}`}>Sell Crypto</span>} id="basic-nav-dropdown">
            {
              cryptoCurrencyOptions.map((data)=>{
                return <DropDownButton onClick={()=>HandLeft(`/dashboard/sell/${data._id}`)}><NavDropdown.Item role='button' as={Link} className='text-start'>Sell {data.name}</NavDropdown.Item></DropDownButton>
              })
            }
          </NavDropdown>
          {
            navOption.map((data)=>{
              return <Nav.Link ><DropDownButton className={`fw-bold text-start text-${getNavbarColor()[1]}`} onClick={()=>HandLeft(data.link)}>{data.name}</DropDownButton></Nav.Link>
            })
          }
        </div>
        <div className='d-none d-xl-flex'>
          <Nav.Link className='pe-0' as={Link} to='/login'>
            <Button className={`text-${getNavbarColor()[1]}`}>Log in</Button>
          </Nav.Link>
          <Nav.Link as={Link} to={'/signup'}>
          {/* <Nav.Link as={Link} to={window.location.protocol === "http:" ? '/signup' : '/coming-soon'}> */}
            <Button1>Sign Up<HiArrowLongRight className='ms-2' size={20} /></Button1>
          </Nav.Link>
        </div>
      </div>
    </Navbar>
  )
}

export default PublicNavbar;