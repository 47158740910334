import React from 'react'
import styled from 'styled-components'
import img1 from '../../assets/Images/Frame 1.png'
import img2 from '../../assets/Images/Frame 2.png'
import img3 from '../../assets/Images/Frame 3.png'

const Div = styled.div`
padding: 1rem 2.5rem;
background-color: #F9F9F9;
margin-top: 10px;
border-radius: 24px;
`
const Commisions = () => {
    return (
        <>
            <div className='d-flex flex-column flex-wrap align-items-center mt-5'>
                <div className='text-center w-10    '>
                    <h2 className='fw-bold'>The Highest Commissions in the Industry</h2>
                    <p className='fs-5 text-center mt-3 text-secondary'>You make money every time the people you introduce to Qvani, and the people they invite, trade on Qvani.</p>
                </div>
                <div className='d-flex flex-column flex-lg-row gap-3'>
                    <Div>
                        <div className='d-flex gap-3 align-items-center'>
                            <img src={img1} alt="" className='h-100' />
                            <h3 className='fw-bold fs-4'>Earn 30% commission from trading fees</h3>
                        </div>
                        <p className='text-secondary fs-6 mt-3'>When the people you invite trade on Qvani.</p>
                    </Div>
                    <Div>
                        <div className='d-flex gap-3 align-items-center'>
                            <img src={img2} alt="" className='h-100' />
                            <h3 className='fw-bold fs-4'>Earn another 10% commission from trading fees</h3>
                        </div>
                        <p className='text-secondary fs-6 mt-3'>When people invited by your friends trade on Qvani.
                        </p>
                    </Div>
                    <Div>
                        <div className='d-flex gap-3 align-items-center'>
                            <img src={img3} alt="" className='h-100' />
                            <h3 className='fw-bold fs-4'>Your friends will receive 10% discount</h3>
                        </div>
                        <p className='text-secondary fs-6 mt-3'>Your friends will receive 10% discount on trading fees.
                        </p>
                    </Div>
                </div>
            </div>

        </>
    )
}

export default Commisions