import React from 'react'
import { ProfileHeading } from '../Profile/StyledComponents'
import SettingsTabs from './SettingsTab'

export default function Settings() {
    return (
        <div className='p-2 p-md-3'>
            <ProfileHeading>Settings</ProfileHeading>
            <SettingsTabs />
        </div>
    )
}
