import React from 'react'
import img from '../../images/Home/Image.png'
import {P, Button} from './Styled'
import star from '../../assets/Images/Star.png'
import one from '../../assets/Images/1.png'
import two from '../../assets/Images/2.png'
import three from '../../assets/Images/3.png'
import './index.css'
const arr = [
  {
    img: star,
    head: 'Earn 30% commission from trading fees',
    subhead: 'When the people you invite trade on Qvani.'
  },
  {
    img: star,
    head: 'Earn 10% commission from trading fees',
    subhead: 'When people invited by your friends buy trade on Qvani.'
  },
  {
    img: star,
    head: 'Your friends will receive 10% discount',
    subhead: 'Your friends will receive 10% discount on trading fees.'
  }
]

const howTo =[
  {
    img: <img src={one}/>,
    para: 'Share your Affiliate code or link on social media, crypto forums, or communities. You can find your unique code or link under your Affiliate Dashboard'
  },
  {
    img: <img src={two}/>,
    para:'Educate your affiliates about crypto and how to trade on Qvani. Every time they buy Bitcoin, you get 30% of their escrow fee. You also make 10% of the escrow fee every time one of their affiliates buys Bitcoin on Qvani. Your friends will receive 10% discount on trading fees.'
  },
  {
    img: <img src={three}/>,
    para:'Track your affiliates’ activity from your Affiliate Dashboard and watch your earnings automatically build up. You can cash out your earnings to your Qvani wallet.'
  }
]
function Index() {
  return (
    <div className='container-fluid px-0 bg-white'>
      <div className='row m-0 d-flex justify-content-center align-items-center'>
        <div className='col-md-3 col-sm-10'>
          <P>Qvani Affiliate Rewards </P>
          <p className='mb-0 text-secondary'>Get paid for bringing people to Qvani. Refer friends to trade on Qvani and receive up to 30% commission from their trading fees. When people invited by your friends (i.e., your sub-affiliates), you will further receive second-level commission. You're just steps away from receiving your rewards.</p>
          <div className='text-center'>
            <Button> Join Us </Button>
          </div>
        </div>
        <div className='col-md-7 d-none d-md-block'>
            <img src={img}/>
        </div>
      </div>
      <div className='row m-0 d-flex justify-content-center align-items-center'>  
        <div className='col-md-5 text-center'>
            <P className='mb-0'>The Highest Commissions in the Industry</P>
            <p>You make money every time the people you introduce to Qvani, and the people they invite, trade on Qvani.com.</p>
            {
              arr.map((data)=>{
                return <div className='my-5'>
                  <div><img src={data.img}/></div>
                  <p className='mb-1 fs-5 fw-bold'>{data.head}</p>
                  <p>{data.subhead}</p>
                </div>
              })
            }
        </div>
      </div>
      <div className='row m-0 d-flex justify-content-center align-items-center'>
          <div className='col-md-5 text-center'>
              <P>How To Refer Your Friends</P>
              {
              howTo.map((data)=>{
                return <div className='my-5'>
                  <button 
                    style={{
                      border: 'none',
                      padding: '11px 24px',
                      borderRadius: '77px',
                      background: '#FBE8FF' }} > {data.img}</button>
                  <p>{data.para}</p>
                </div>
              })
            }
          </div>
      </div>
      <div className='row m-0 d-flex justify-content-center align-items-center py-5' style={{background: '#EEF2F6'}}>
        <div className='col-md-5 text-center'>
            <P>We share up to 30% of our revenue with the Qvani community.</P>
        </div>
      </div>
    </div>
  )
}

export default Index