
import styled from 'styled-components';
import { FaTelegramPlane, FaLinkedinIn, FaRedditAlien, FaInstagram, FaFacebookF } from "react-icons/fa";
import { BsTwitterX } from "react-icons/bs";
import { IoMail } from "react-icons/io5";
import { RiInstagramFill } from "react-icons/ri";
import { BsFillChatTextFill } from "react-icons/bs";

export const Text = styled.p`
    padding: 0;
    margin-bottom: 7px;
    font-size: 14px;
    color: #495057;
    font-family: 'Inter';
    font-style: normal;
    text-decoration: none;
    font-weight: 400;
`
export const Logo = styled.div`
    background: #EFF0FE;
    padding: 8px;
    border-radius: 50px;
    margin: 5px;
`
export const Logo1 = styled.div`
    background: #EFF0FE;
    // padding: 5px;
    border-radius: 50px;
    // margin: 5px;
`
export const CRDiv = styled.div`
    background: white;
    padding: 25px;
    border-top: -1px solid #EEF2F6;
`
export const logo = [
    {
        logo: <FaFacebookF color='#3545EE' size={30}/>,
        link: 'https://www.facebook.com/QvaniApp'
    },
    {
        logo: <BsTwitterX color='#3545EE' size={30}/>,
        link: 'https://twitter.com/QvaniApp'
    },
    {
        logo: <FaLinkedinIn color='#3545EE' size={30}/>,
        link: 'https://www.linkedin.com/company/qvaniapp'
    },
    {
        logo: <RiInstagramFill color='#3545EE' size={30}/>,
        link: 'https://www.instagram.com/qvaniapp/'
    },
    {
        logo: <FaRedditAlien color='#3545EE' size={30}/>,
        link: 'https://www.reddit.com/r/QvaniApp/'
    },
    {
        logo: <FaTelegramPlane color='#3545EE' size={28}/>,
        link: 'https://t.me/+lIWgDUoFOwNkNzJh'
    },
    // {
    //     logo: <IoMailOutline color='#3545EE' size={30}/>,
    //     link: 'mailto:help@qvani.com'
    // }
];

export const logo1 =[
    {
        img: <IoMail color='#3545EE' size={40}/>,
        head: 'Email',
        subhead: 'support@qvani.com' 
    },
    {
        img: <BsFillChatTextFill color='#3545EE' size={40}/>,
        head: 'Live Chat',
        subhead: 'support.qvani.com'
    }    
]
