import React from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components';
import { CiLocationOff } from 'react-icons/ci'
import PropTypes from 'prop-types';

const Button = styled.button`
padding: 13px 30px;
border-radius: 46px;
font-size: 18px;
background-color: rgb(20, 118, 255);
color: white;
border: none;
`
export default function Block({ handleCloseBlockModal }) {
    return (
        <div className='d-flex flex-column align-items-center px-4'>
            <CiLocationOff color='red' size={40} />
            <h5 className='mt-2 fs-6 lh-lg' style={{wordSpacing: "6px"}}>Your IP Address shows that you are attempting to access our services from a restricted jurisdiction. We are unable to provide services to users in your region and apologise for any inconvenience caused.</h5>
            <Button onClick={() => handleCloseBlockModal()}>OK</Button>
        </div>
    );
}
Block.propTypes = {
    handleCloseBlockModal: PropTypes.func.isRequired,
};