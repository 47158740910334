import { style } from "@mui/system";
import styled from "styled-components";
export const P = styled.p`
font-size: 15px;
font-weight: 600;
margin-bottom: 0px;
margin-top: 10px;
`
export const Para = styled.p`
font-size: 13px;
font-weight: 400;
color: #000000;
`
export const Card = styled.div`
// border: ${({theme})=>theme.border};
// box-shadow: ${({theme})=>theme.boxshadow};
// border-radius: 20px;
// padding: 17px 33px;
`