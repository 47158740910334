import React from 'react'
import styled from 'styled-components'
import Image from '../assets/Images/error.png'
import { Link } from 'react-router-dom'

const Container = styled.div`
min-height: 90vh;
`

const Card = styled.div`
background: white;
border-radius: 26px;
box-shadow: ${({theme})=> theme.boxshadow};
`

const Text = styled.p`
font-family: Inter;
font-size: 36px;
font-weight: 600;
color: #2d2e67;
`

const Button = styled.button`
border: none;
background: #1476FF;
font-style: 'Inter';
font-weight: 700;
color: white;
outline: none;
border-radius: 15px;
text-decoration: none;
:hover{
    color: white;
    opacity: 0.9;
}
`

export default function AddProfile() {
    return (
        <Container className='d-flex align-items-center justify-content-center '>
            <Card className='d-flex flex-column-reverse flex-md-row align-items-center justify-content-center p-5'>
                <div>
                    <Text>First Complete Your Profile!!</Text>
                    <Button as={Link} to='/dashboard/settings#profile' className='px-4 py-2'>Complete It!!</Button>
                </div>
                <div>
                    <img className='w-100' src={Image} alt="Error 401" />
                </div>
            </Card>
        </Container>
    )
}
