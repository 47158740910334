import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  body {
    background: ${({ theme }) => theme.body} !important;
    color: ${({ theme }) => theme.text};
    box-shadow: ${({theme})=> theme.boxshadow};
    // border: ${({theme})=> theme.border};
  }
`;

export const lightTheme = {
  body: '#F7F9FC',
  sidebarActive: '',
  text: '#121620',
  background: 'white',
  font: '#000000',
  cardBG: '#ffffff',
  heading: '#3545EE',
  boxshadow: '0px 0px 10px #E9EAF3',
  border: '1px solid #E9EAF3',
  inputBG: '#FFFFFF'
};

export const darkTheme = {
  body: 'black',
  text: '#f1f1f1',
  background: '#000000',
  font: '#ffffff',
  cardBG: 'rgba(255, 255, 255, 0.1)',
  heading: '#ffffff',
  boxshadow: '0px 0px 5px #302f30',
  border: '1px solid #302f30',
  inputBG: '#000000'
};