// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.response-modal{
    display: flex !important;
    justify-content: center;
    align-items: center;
}
.response-modal > div {
    width: 360px;
}
.response-modal > div > div {
    border-radius: 30px;
    box-shadow: 0px 0px 1px #E9EAF3;
}`, "",{"version":3,"sources":["webpack://./src/hooks/CustomModal.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;IACxB,uBAAuB;IACvB,mBAAmB;AACvB;AACA;IACI,YAAY;AAChB;AACA;IACI,mBAAmB;IACnB,+BAA+B;AACnC","sourcesContent":[".response-modal{\n    display: flex !important;\n    justify-content: center;\n    align-items: center;\n}\n.response-modal > div {\n    width: 360px;\n}\n.response-modal > div > div {\n    border-radius: 30px;\n    box-shadow: 0px 0px 1px #E9EAF3;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
