import styled from "styled-components";

export const SideDrawer = styled.div`
    position: fixed;
    height: 100vh;
    overflow: hidden;
    right: 0;
    width: ${({ open }) => open ? '300px' : '0px'};
    background: rgba(255,255,255, 0.99);
    z-index: 20;
    top: 0;
    transition: all 0.4s;
`
export const Linkname = styled.p`
    text-decoration: none;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    color: #1476FF;
    text-transform: capitalize;
`
export const Para = styled.p`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: ${({theme})=> theme.text};
`
export const ParaPayment = styled.p`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  color: ${({theme})=> theme.text};
`
export const GrayPara = styled.p`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  color: ${({theme})=> theme.text};
`


export const Paragraph = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 0.9rem;
  color: ${({theme})=> theme.text};
`
export const BottomRow = styled.div`
`
export const Button = styled.button`
  background: #1476FF;
  border: 1px solid #1476FF;
  padding: 12px 15px;
  border-radius: 20px;
  color: white;
  font-size: 14px;
  box-shadow: 0px 0px 5px #0257FB30;
  font-weight: 600;
`

export const CardContainer = styled.div`
  border: 1px solid #3545EE;
  border-radius: 8px;
  min-width: 200px;
  padding: 10px 0;
  background: ${({ theme }) => theme.cardBG} 
`

export const BodyWrapper = styled.div`
  background: rgb(255, 255, 255);
  box-shadow: ${({theme})=> theme.boxshadow};

`