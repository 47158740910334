import React, { useCallback, useEffect, useState, useRef, useInsertionEffect } from 'react'
import { Heading, Card, CardHeading, DisputeCard, CardSubHeading, ChatButton, DisputeButton, CardButton, DisputeInput, Label, ImgDiv, TextSpan, Button, StatusText, FeedInput, CancelButton, PRButton, Timer, ConfirmP, Ins, DivConfirm, CompleteCard, UploadLabel} from './StyledComponent';
import { useFormik } from 'formik';
import './Index.css'
import { BsArrowLeftShort, BsChatDots } from 'react-icons/bs';
import {FaRegCopy } from 'react-icons/fa';
import ChatScreen from './ChatScreen';
import { useThemeContext } from '../../Context/ThemeContext';
import axios from 'axios';
import OTPInput from "otp-input-react";
import * as Yup from 'yup';
import { RxCross2 } from 'react-icons/rx';
import Select from 'react-select';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { API_URLs } from '../../Utils/ApiUrls';
import Modal from 'react-bootstrap/Modal';
import Loader from '../../Component/Loader/Loader';
import ButtonLoader from '../../Component/Loader/ButtonLoader';
import { useAuthContextHook } from '../../Context/AuthContext';
import ReactStars from "react-rating-stars-component"
import { useAPIContextHook } from '../../Context/ApiContext';
import Error404 from '../Error404';
import { P } from '../CreateOffer/StyledComponents';
import Joyride, { STATUS } from "react-joyride";
import CustomModal from '../../hooks/CustomModal';
import moment from 'moment/moment';
import { v4 as uuidv4 } from 'uuid';
import { lightTheme, darkTheme } from '../../Theme/theme';
import {Para} from '../Auth/SignUp/Otp'
const steps = [
    {
        target: ".chatscreen",
        content: <div className='text-secondary p-0'>
            <p className='fs-6 fw-bold mb-0'>Chat Window</p>
            <p className='mb-0 fs-6'>This is where you chat with your trade partner and exchange information needed to complete the trade.</p>
        </div>,
        placement: "right",
        isFixed: true,
        disableBeacon: true
    },
    {
        target: ".moderatebutton",
        content: <div className='text-secondary'>
            <p className='fs-6 fw-bold mb-0'>Moderator Status</p>
            <p className='mb-0 fs-6'>If you’re ever wondering whether you’re talking to an actual Qvani moderator, this is how you’ll know.</p>
        </div>,
        isFixed: true,
        placement: "auto"
    },
    {
        target: ".sc-cfxfcM",
        content: <div className='text-secondary'>
            <p className='fs-6 fw-bold mb-0'>Trade Information</p>
            <p className='mb-0 fs-6'>This area shows everything you need to know about the trade and all actions you can take.</p>
        </div>,
        isFixed: true,
        placement: "auto"
    },
    {
        target: ".releasebutton",
        content: <div className='text-secondary'>
            <p className='fs-6 fw-bold mb-0'>Confirm Payment</p>
            <p className='mb-0 fs-6'>You’ll need to click this button to let your trade partner know that you’ve made the payment or release the crypto for the trade.</p>
        </div>,
        isFixed: true,
        placement: "auto"
    },
    {
        target: ".cancelbuttonfortour",
        content: <div className='text-secondary'>
            <p className='fs-6 fw-bold mb-0'>Cancel Trade</p>
            <p className='mb-0 fs-6'>Having second thoughts or don’t want to continue with the trade? Just click this button to leave the trade.</p>
        </div>,
        isFixed: true,
        placement: "auto"
    },
    {
        target: '.tradeinstructionfortour',
        content: <div className='text-secondary'>
            <p className='fs-6 fw-bold mb-0'>Trade Instructions</p>
            <p className='mb-0 fs-6'>This is where you’ll see all your trade partner’s expectations. Be sure to follow these instructions exactly to complete the trade smoothly.</p>
        </div>,
        isFixed: true,
        placement: "auto"
    },
    {
        target: '.reportbuttonfortour',
        content: <div className='text-secondary'>
            <p className='fs-6 fw-bold mb-0'>Help is Always Around</p>
            <p className='mb-0 fs-6'>If you face any issues with the trade, click this button and we’ll help you out.</p>
        </div>,
        isFixed: true,
        placement: "auto"
    }
]
const canceloption = [
    {
        value: 'I started the trade by mistake',
        label: 'I started the trade by mistake'
    },
    {
        value: 'Unresponsive trade partner.',
        label: 'Unresponsive trade partner.'
    },
    {
        value: "The trade partner is violating the Qvani's Terms of use",
        label: "The trade partner is violating the Qvani's Terms of use"
    },
    {
        value: "I got another better offer.",
        label: "I got another better offer."
    }
];

export const CountdownTimer = ( {handleChangeTimeUp, startTime, timeLimit}) => {
  const [timeLeft, setTimeLeft] = useState(timeLimit);

  useEffect(() => {
    // Calculate the end time
    const endTime = new Date(new Date(startTime).getTime() + timeLimit * 60000);
    
    // Update the countdown every second
    const timer = setInterval(() => {
      const now = new Date();
      const timeDifference = endTime - now;

      if (timeDifference <= 0) {
        clearInterval(timer);
        handleChangeTimeUp(true);
        console.log("yes it true now")
        setTimeLeft(0);
      } else {
        setTimeLeft(Math.floor(timeDifference / 1000));
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [startTime, timeLimit]);

  // Format timeLeft into minutes and seconds
  const minutes = Math.floor(timeLeft / 60);
  const seconds = timeLeft % 60;

  return (
    <span>{`${minutes}:${seconds < 10 ? '0' : ''}${seconds}`}</span>
  );
};

export const OfferTimer = ({ initiateTime, paidTime, durationInMinutes }) => {
    const [timeLeft, setTimeLeft] = useState(durationInMinutes * 60); // Convert minutes to seconds
    const [isPaid, setIsPaid] = useState(false);
  
    useEffect(() => {
      // Convert initiateTime and paidTime to Date objects
      const initiateDate = new Date(initiateTime);
      const paidDate = paidTime ? new Date(paidTime) : null;
  
      if (paidDate) {
        // Calculate the remaining time at the moment of payment
        const elapsedSeconds = Math.floor((paidDate - initiateDate) / 1000);
        const remainingTime = Math.max(durationInMinutes * 60 - elapsedSeconds, 0);
        
        setIsPaid(true);
        setTimeLeft(remainingTime);
      } else {
        // Start the timer
        const interval = setInterval(() => {
          setTimeLeft(prev => {
            const newTimeLeft = prev - 1;
            if (newTimeLeft <= 0) {
              clearInterval(interval);
              return 0;
            }
            return newTimeLeft;
          });
        }, 1000);
  
        return () => clearInterval(interval);
      }
    }, [initiateTime, paidTime, durationInMinutes]);
  
    const minutes = Math.floor(timeLeft / 60);
    const seconds = timeLeft % 60;
  
    return (
      <div>
        {isPaid ? (<span>{minutes}:{seconds < 10 ? `0${seconds}` : seconds}</span>)
        : (<span>{minutes}:{seconds < 10 ? `0${seconds}` : seconds}</span>)
        }
      </div>
    );
};

export default function Trade() {

    const [disputeCategory, setDisputeCategory] = useState([])
    const [imgName, setImgName] = useState('Upload Image')
    const [imgElement, setImgElement] = useState();
    const [reportLoader, setReportLoader] = useState(false);
    const [feedbackLoader, setFeedbackLoader] = useState(false);
    const [paidLoader, setPaidLoader] = useState(false);
    const [isTradeFound, setIsTradeFound] = useState(true);
    const [showReport, setShowReport] = useState(false);
    const [rating, setRating] = useState(0)
    const [feedbackMsg, setFeedbackMsg] = useState('')
    const [showFeedback, setShowFeedback] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false)
    const [showCancel, setShowCancel] = useState(false)
    const [cancelMsg, setCancelMsg] = useState([])
    const [releaseState, setReleaseState] = useState(false)
    const [showTour, setShowTour] = useState(false)
    const [showModal, setShowModal] = useState(false);
    const [modalData, setModalData] = useState(null);
    const [navigateLink, setNavigateLink] = useState('');
    const [loader, setLoader] = useState(false);
    const [copySuccess, setCopySuccess] = useState('');
    const [transactionData, setTransactionData] = useState({});
    const [fullScreenLoader, setFullScreenLoader] = useState(true);
    const [bankAccountOptions, setBankAccountOptions] = useState([]);
    const [selectedBank, setSelectedBank] = useState("");
    const [timer, setTimer] = useState(`30:00`); 
    const [showChat, setShowChat] = useState(false);
    const [otpSent, setOtpSent]=useState(false);
    const [otpValue, setOtpValue]=useState('');
    const [otpHash, setOtpHash]=useState(false);
    const [isTwoFa, setIsTwoFa]=useState(false);
    const [paidConfirm, setPaidConfirm] = useState(false);
    
    const location = useLocation();
    const [isTimeUp, setIsTimeUp] = useState(false);
    const handleChangeTimeUp=(data)=>{
        setIsTimeUp(data);
    }

    const handleSentOtpModal=(data)=>{ 
        setOtpSent(true); 
        setIsTwoFa(data);
    }
    const handleNotSentOtpModal=()=>{
        setOtpSent(false);
        setOtpValue('');
        setOtpHash('');
    }
    
    const handleSendOtpForVerification =()=>{
        axios.post(API_URLs.verify2faTxn,{},{
            headers: {
                accept: 'application/json',
                Authorization: `Bearer ${localStorage.getItem('jwt')}`
            }
        })
        .then((res)=>{
            handleCloseConfirm();
            if(res.data.isTwoFa){
                setOtpHash(res.data.responses.token);
            } else {
                setOtpHash(res.data.responses);
            }
            handleSentOtpModal(res.data.isTwoFa);
        })
        .catch((err)=>{
        })
    }
    const { fetchBankAccounts, fetchPaypal, sendNotification, fetchCardDetail, fetchUpiId } = useAPIContextHook()
    const { isDarkTheme } = useThemeContext()
    const { userDetails } = useAuthContextHook();
    const navigate = useNavigate()
    const Ref = useRef(null);
    const params = useParams()
    const { offerId, txnHash } = params;
    
    const handleCloseReport = () => setShowReport(false);
    const handleShowReport = () => setShowReport(true);
    
    const handleCloseFeedback = () => setShowFeedback(false);
    const handleShowFeedback = () => setShowFeedback(true);

    const handleShowConfirm = () => setShowConfirm(true)
    const handleCloseConfirm = () => setShowConfirm(false)

    const handleShowCancel = () => setShowCancel(true)
    const handleCloseCancel = () => setShowCancel(false)
    const handleShowModal = (data) => {
        setModalData(data);
        setShowModal(true);
    };
    const handleCloseModal = () => {
        setShowModal(false)
    };

    const handleReleaseState = (value) => {
        setReleaseState(value)
    }

    const ratingChanged = (newRating) => {
        setRating(newRating)
    };
    const feedMsgchangeHandle = (e) => {
        setFeedbackMsg(e.target.value)
    }
    const handleSubmitFeedback = () => {
        setFeedbackLoader(true)
        setRating(0);
        axios.post(API_URLs.sendFeedback, {
            tradeId: params.offerId,
            ...(transactionData.user._id === userDetails.user._id ?
                {
                    feedbackFor: transactionData?.trade_user?._id
                } :
                {
                    feedbackFor: transactionData.user._id
                }
            ),
            feedbackBy: userDetails.user._id,
            feedback: feedbackMsg,
            rating: rating,
            initiateId: txnHash
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('jwt')}`
            }
        })
           
            .then((res) => {
                setFeedbackLoader(false)
                handleShowModal(res.data.msg ? res.data.msg : 'Feedback Added Successfully');
                setNavigateLink('');
                handleCloseFeedback()
                setFeedbackMsg('');
                setRating(0);

                const url = new URL(window.location.href);
                url.search = '';
                window.history.replaceState({}, '', url.pathname + url.search);
            })
            .catch((error) => {
                setFeedbackLoader(false)
                handleShowModal(error?.response?.data?.msg ? error?.response?.data?.msg : 'Some Error Occured');
                setNavigateLink('');
                setFeedbackMsg("");
                setRating(0);

                const url = new URL(window.location.href);
                url.search = '';
                window.history.replaceState({}, '', url.pathname + url.search);
            })
    }

    useEffect(() => {
        axios.get(API_URLs.getDisputeCatogory, {
            headers: {
                'accept': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('jwt')}`
            }
        })
            .then((res) => {

                let Data = []
                for (let key in res?.data?.res_obj) {
                    let rowData = res?.data?.res_obj[key]
                    Data.push({
                        value: rowData.disputeName,
                        label: rowData.disputeName,
                        id: rowData._id
                    })
                }
                setDisputeCategory(Data)
            })
            .catch((error) => {
                console.log('Error', error);
            })
    }, [])

    //report api 
    const formik1 = useFormik({
        initialValues: {
            query: '',
            category: '',
            image: ''
        },
        validationSchema: Yup.object().shape({
            query: Yup.string().required('Query is Required'),
        }),
        onSubmit: (values) => {
            setReportLoader(true);
            const formData = new FormData();
            formData.append('query', values.query)
            formData.append('category', values.category.id)
            formData.append('image', imgElement)
            formData.append('tradeId', offerId)
            formData.append('initiateId', txnHash)

            axios.post(API_URLs.tradeDispute + '/', formData,
                {
                    headers: {
                        'accept': 'multipart/form-data',
                        'Authorization': `Bearer ${localStorage.getItem('jwt')}`
                    }  
                })
                .then((res) => {
                    axios.post(API_URLs.sendChat, {
                        sender: userDetails?.user?._id,
                        tradeId: offerId,
                        category: 'dispute',
                        initiateId: txnHash,
                        message: "A dispute in progress. The dispute will be investigated by Qvani moderators, and a decision will be made based on the evidence provided by both parties. Qvani moderators resolve disputes by evaluating trade terms, offer instructions, proof of payment, trade chat interaction, user reputation, past trading history, as well as data submitted and/or collected"
                    }, {
                        headers: {
                            accept: 'application/json',
                            Authorization: `Bearer ${localStorage.getItem('jwt')}`
                        }
                    })
                        .then((res) => {
                            handleCloseReport();
                            setReportLoader(false);
                            formik1.setValues({
                                ...formik1.values,
                                query: '',
                                category: ''
                            })
                            
                        })
                        .catch((error) => {
                        })
                })
                .catch((error) => {
                    setReportLoader(false);
                })
        }
    })
    
    const getTransactionDetails = useCallback(async () => {
        try {
            let res = await axios.get(API_URLs.getTransactionDetails(offerId, txnHash), {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('jwt')}`
                }
            })
            setTransactionData(res?.data)
            setFullScreenLoader(false)
            if (!res.data?.checkout?.iHavePaid) {
                clearTimer(getDeadTime(res.data?.hash_details?.trade_time));
            }
        } catch (error) {
            setIsTradeFound(false)
            setFullScreenLoader(false)
        }
    }, [offerId, txnHash])

    const isTradeCancelled = transactionData?.hash_details?.status === "REJECT"

    const getTimeRemaining = (e) => {
        const total = Date.parse(e) - Date.parse(new Date());
        const seconds = Math.floor((total / 1000) % 60);
        const minutes = Math.floor((total / 1000 / 60) % 60);
        return {
            total, minutes, seconds
        };
    }
    const startTimer = (e) => {
        let { total, minutes, seconds }
            = getTimeRemaining(e);
        if (total >= 0) {
            setTimer((minutes > 9 ? minutes : '0' + minutes) + ':'
                + (seconds > 9 ? seconds : '0' + seconds)
            )
        } else {
            setTimer('00:00')
        }
    }
    const clearTimer = (e) => {
        if (Ref.current) clearInterval(Ref.current);
        const id = setInterval(() => {
            startTimer(e);
        }, 1000)
        Ref.current = id;
    }

    const getDeadTime = (time) => {
        let currentTime = new Date();
        let deadline = new Date(time);
        deadline.setSeconds(deadline.getSeconds() - currentTime.getSeconds());
        return deadline;
    }

    // copy address function 
    const copyToClipBoard = useCallback(async copyMe => {
        setCopySuccess('');
        try {
            await navigator.clipboard.writeText(copyMe);
            setCopySuccess('Copied!');
        } catch (err) {
            setCopySuccess('Failed to copy!');
        }
    }, [])

    // seller bank get 
    let handleFetchBankAccounts = async () => {
        try {
            let res = await fetchBankAccounts()
            let options = res?.length > 0 && res.map((bank) => {
                return {
                    ...bank,
                    value: bank?.id,
                    label: <div className='d-flex'>
                        <P className='m-0'>{bank?.accountNo}</P> -
                        <P className='m-0 text-uppercase'>{bank?.BankName}</P>
                    </div>
                }
            })
            setBankAccountOptions(options)
        } catch (error) {
        }
    }
    let fetchUpiIdFetch = async () => {
        try {
            let res = await fetchUpiId()
            let option = res?.length > 0 && res.map((upi) => {
                return {
                    ...upi,
                    value: upi?.id,
                    label: <div>
                        <P className='mb-0'>{upi?.upiId}</P>
                    </div>
                }
            })
            setBankAccountOptions(option)
        } catch (error) {
        }
    }
    let handleFetchPaypalDetails = async () => {
        try {
            let res = await fetchPaypal()
            let options = res?.length > 0 && res.map((card) => {
                return {
                    ...card,
                    value: card?.id,
                    label: <div className='d-flex'>
                        <P className='mb-0'>{card?.authType}</P> -
                        <P className='mb-0'>{card?.authType === 'username' ? card?.username : card?.authType === 'mobile' ? card?.mobile : card?.authType === 'email' ? card?.email : ''}</P>
                    </div>
                }
            })
            setBankAccountOptions(options)
        }
        catch (error) {
        }
    }
    let handleFetchCardDetails = async () => {
        try {
            let res = await fetchCardDetail()
            let options = res?.length > 0 && res.map((card) => {
                return {
                    ...card,
                    value: card?._id,
                    label: <div className='d-flex'>
                        <P className='mb-0'>{card?.cardNo}</P> -
                        <P className='mb-0'>{card?.cardHolderName}</P>
                        <P className='mb-0'>{card?.cardType}</P>
                    </div>
                }
            })
            setBankAccountOptions(options)


        }
        catch (error) {
        }
    }

    useEffect(() => {
        const chatInterval = setInterval(() => {
            getTransactionDetails()
        }, 5000);
        return () => {
            clearInterval(chatInterval)
        }
    }, [getTransactionDetails])

    // cancel trade api 
    const handleCancelTransaction = async () => {
        setLoader(true)
        try {
            let res = await axios.delete(API_URLs.cancelTransaction(offerId, txnHash, cancelMsg.target.value.value), {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('jwt')}`
                }
            })
            console.log('handleCancelTransaction')
            handleShowModal(res.data.msg ? res.data.msg : 'Trade has been cancelled succesfully');
            setNavigateLink(`/dashboard/offer/${offerId}`);
        } catch (error) {
            setLoader(false)
            handleShowModal(error?.response?.data?.message ? error?.response?.data?.message : "Some Error Occured!!");
            setNavigateLink('');
        }
    };
    // paid and release functionality
    const handleIhavePaid = async () => {
        setPaidLoader(true)
        try {
            let res = await axios.get(API_URLs.iHavePaid(offerId, txnHash), {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('jwt')}`
                }
            })
            sendNotification(transactionData?.user?._id,
                `New Message from ${transactionData?.hash_details?.user?.name}`,
                'Buyer have paid the amount',
                'trade',
                { transactionType: 'trade/' },
                {
                    transactionIds: [
                        {
                            idName: 'offerId',
                            id: offerId
                        },
                        {
                            idName: 'txnHash',
                            id: txnHash
                        }
                    ]
                })
            await getTransactionDetails()
            setPaidLoader(false)
            setPaidConfirm(false)
            handleShowModal(res.data.msg ? res.data.msg :"Paid succesfully!! Wait for seller's response");
            setNavigateLink('');
        } catch (error) {
            setPaidLoader(false)
            setPaidConfirm(false)
            handleShowModal(error?.response?.data?.message ? error?.response?.data?.message : "Some Error Occured!!");
            setNavigateLink('');
        }
    }
    const handleReleasePayment = async () => {
        setPaidLoader(true)
        try {
            let res = await axios.get(API_URLs.releasePayment(offerId, txnHash, otpHash, otpValue), {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('jwt')}`
                }
            })

            sendNotification(transactionData?.hash_details?.user?._id,
                `New Message from ${transactionData?.user?.name}`,
                'Seller has Released the Crypto',
                'trade',
                { transactionType: 'trade/' },
                {
                    transactionIds: [
                        {
                            idName: 'offerId',
                            id: offerId
                        },
                        {
                            idName: 'txnHash',
                            id: txnHash
                        }
                    ]
                }
            )
            handleNotSentOtpModal();
            setPaidLoader(false)
            handleCloseConfirm();
            handleShowModal(res.data.msg ? res.data.msg :"Crypto released succesfully");
            setNavigateLink(`${location.pathname}/?feedback=true`);
        } catch (error) {
            setPaidLoader(false)
            handleNotSentOtpModal();
            handleShowModal(error?.response?.data?.message ? error?.response?.data?.message : "Some Error Occured!!");
            setNavigateLink('');                     
        }
    }

    const handleReleaseOrPay = async () => {
        if ((transactionData?.trade_details?.offer_type === "sell"
            && transactionData?.trade_details?.user === userDetails?.user?._id) || (
                transactionData?.trade_details?.offer_type === "buy"
                && transactionData?.trade_details?.user !== userDetails?.user?._id
            )
        ) {
            handleReleasePayment()
        } else {
            handleIhavePaid()
        }
    }

    useEffect(() => {
        getTransactionDetails();
    }, [])
    
    useEffect(()=>{
        if(transactionData?.trade_details?.payment_method === "Bank Transfer"){
            handleFetchBankAccounts()
        } else if(transactionData?.trade_details?.payment_method === "PayPal"){
            handleFetchPaypalDetails()
        } else if(transactionData?.trade_details?.payment_method === "ANY Credit/Debit Card"){
            handleFetchCardDetails()
        } else if(transactionData?.trade_details?.payment_method === "UPI Transfer"){
            fetchUpiIdFetch()
        }
    },[transactionData])
    

    // send bank api
    const handleBankSendButton = async (e) => {
        e.preventDefault()
        try {
            let res = await axios.post(API_URLs.sendChat, {
                sender: userDetails?.user?._id,
                tradeId: offerId,
                category: 'normal',
                initiateId: txnHash,
                ...(transactionData?.trade_details?.payment_method === "Bank Transfer" ?
                    {
                        message: `${selectedBank?.accountNo ? 'Account No : ' + selectedBank?.accountNo + ',' : ""} 
                                ${selectedBank?.BankName ? 'Bank Name : ' + selectedBank?.BankName + ',' : ""} 
                                ${selectedBank?.zipCode ? 'Postal Code : ' + selectedBank?.zipCode + ',' : ""}`
                    } : transactionData?.trade_details?.payment_method === "PayPal" ? 
                    {
                        message: `${selectedBank?.authType}: ${selectedBank[selectedBank?.authType]}`
                    } : transactionData?.trade_details?.payment_method === "UPI Transfer" ? 
                    {
                        message: `UPI ID: ${selectedBank?.upiId}`
                    } : transactionData?.trade_details?.payment_method === "ANY Credit/Debit Card" ? 
                    {
                        message: `Card No : ${selectedBank?.cardNo}
                            Card Type : ${selectedBank?.cardType}
                            Card Holder Name : ${selectedBank?.cardHolderName}
                         `
                    } : ''
                    )
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('jwt')}`
                }
            })
            sendNotification(transactionData?.user?._id,
                `New Message from ${transactionData?.user?.name}`,
                "Bank Details Shared",
                'trade',
                { transactionType: 'trade/' },
                {
                    transactionIds: [
                        {
                            idName: 'offerId',
                            id: offerId
                        },
                        {
                            idName: 'txnHash',
                            id: txnHash
                        }
                    ]
            })
            handleShowModal(res.data.msg ? res.data.msg :"Bank Details Sent!!");
            setNavigateLink('');
        } catch (error) {
            handleShowModal(error?.response?.data?.message ? error?.response?.data?.message : "Some Error Occured!!");
            setNavigateLink('');
        }
    }
    const handleShowJoy = () => {
        setShowTour(true)
    }
    const joyrideRef = useRef(null);
    const handleCloseJoy = (data) => {
        const { status } = data;
        if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
            setShowTour(false)
        }
    }

    useEffect(()=>{
        const query = new URLSearchParams(location.search);
        if (query.get('feedback')) {
            handleShowFeedback()
        }
    },[location])

    const styles = {
        control: (styles) => ({
            ...styles,
            background: 'transparent',
            borderRadius: '52px',
            width: '100%',
            height: '50px',
            color: 'white',
            boxShadow: `${isDarkTheme ? darkTheme.boxshadow : lightTheme.boxshadow}`,
            border: `${isDarkTheme ? darkTheme.border : lightTheme.border}`, "&:hover": {
                border: `${isDarkTheme ? darkTheme.border : lightTheme.border}`
            },
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: isDarkTheme && state.isHovered ? '#302F30' : `${({theme})=> theme.body}`,
            ':hover': {
              backgroundColor:  `${isDarkTheme ? '#302F30' : '#D9EDE7'}` ,
            },
            color: isDarkTheme && state.isSelected ? '#1476FF' : `${({theme})=> theme.text}`,
        }),
        indicatorSeparator: () => ({
            all: 'unset'
        }),
        indicatorsContainer: (styles) => ({
            ...styles, color: isDarkTheme ? 'white' : 'black', 'div:nth-child(2)': {
                color: isDarkTheme ? 'white' : lightTheme.text
            }
        }),
        menu: (styles) => ({
            ...styles, background: isDarkTheme ? 'rgb(27, 27, 28)' : 'white',
            color: isDarkTheme ? 'white' : lightTheme.text,
        })
    }

    const [time, setTime] = useState(600);
    useEffect(() => {
        const interval = setInterval(() => {
          if (time > 0) {
            setTime(time - 1);
          } else {
            clearInterval(interval);
          }
        }, 1000);
  
        return () => clearInterval(interval);
    }, [time]);

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    };
    if(fullScreenLoader){
        return <Loader/>;
    }

    return (
        isTradeFound ?
        <>
            <Joyride
                showProgress={false}
                ref={joyrideRef}
                run={showTour}
                showSkipButton={true}
                steps={steps}
                scrollToFirstStep={true}
                continuous={true}
                styles={{
                    buttonNext: {
                        background: '#1476FF'
                    },
                    options: {
                        primaryColor: '#1476FF',
                    }
                }}
                callback={handleCloseJoy}
            />

            <div className='container mt-3'>
                <div className='row m-0'>
                    <div className='col-md-8 col-lg-6'>
                        <div className='d-flex justify-content-between align-items-center'>
                            <Heading>
                                <BsArrowLeftShort size={35} onClick={() => navigate(-1)} />
                                Trade Started
                            </Heading>
                        </div>

                        <div className='d-flex flex-column gap-2 flex-sm-row justify-content-md-between'>
                            <Button className='px-3 py-1' onClick={() => navigate(`/dashboard/offer/${params.offerId}`)}>View Offer</Button>
                            <Button className='px-3 py-1' onClick={() => handleShowJoy()}>Take a Tour</Button>
                        </div>

                        {
                            ((transactionData?.trade_details?.offer_type === "sell" && (transactionData?.trade_details?.payment_method === "Bank Transfer" || transactionData?.trade_details?.payment_method === "PayPal" || transactionData?.trade_details?.payment_method === "ANY Credit/Debit Card" || transactionData?.trade_details?.payment_method === "UPI Transfer")
                                && transactionData?.trade_details?.user === userDetails?.user?._id) || (
                                    transactionData?.trade_details?.offer_type === "buy" && (transactionData?.trade_details?.payment_method === "Bank Transfer" || transactionData?.trade_details?.payment_method === "PayPal" || transactionData?.trade_details?.payment_method === "ANY Credit/Debit Card" || transactionData?.trade_details?.payment_method === "UPI Transfer")
                                    && transactionData?.trade_details?.user !== userDetails?.user?._id
                                )) &&
                                <Card className='d-flex flex-column mt-3 p-3'>
                                    <CardHeading>Share
                                        {
                                            transactionData?.trade_details?.payment_method === "Bank Transfer" ? " Bank Transfer" :
                                            transactionData?.trade_details?.payment_method === "PayPal"  ? " PayPal"  :
                                            transactionData?.trade_details?.payment_method === "ANY Credit/Debit Card"  ? " ANY Credit/Debit Card"  :
                                            transactionData?.trade_details?.payment_method === "UPI Transfer" ? " UPI Transfer" : ''
                                        } Detail in Chat</CardHeading>
                                    <Select className="select"
                                        width='100%'
                                        name="accountType"
                                        id='accountType'
                                        options={bankAccountOptions || []}
                                        styles={styles}
                                        onChange={(selectedValues) => {
                                            setSelectedBank(selectedValues)
                                        }}
                                    />
                                    <div>
                                        <PRButton onClick={handleBankSendButton} 
                                        disabled={!selectedBank || transactionData?.hash_details?.status === "REJECT" || (transactionData?.checkout?.iHavePaid && transactionData?.checkout?.release_payment) } 
                                        className='mt-3 sharebankbutton'>
                                            <p className='m-0 px-3' style={{ color: 'white', fontWeight: 500 }}>Send</p>
                                        </PRButton>
                                    </div>
                                </Card>
                        }

                        {transactionData?.isDisputeRaise && <DisputeCard className='mt-2'>
                            <p className='mb-0'>Dispute is in Progress</p>
                            <p className='mb-0'>Modetator is Investing</p>
                        </DisputeCard>}

                        {
                            (transactionData?.checkout?.iHavePaid && transactionData?.checkout?.release_payment ) && <CompleteCard className='mt-2'>
                                <p className='mb-0'>The Trade is Completed!!</p>
                            </CompleteCard> 
                        }

                        {
                            transactionData?.hash_details?.status === "REJECT" && <DisputeCard className='mt-2'>
                                <p className='mb-0'>The Trade is Cancelled</p>
                            </DisputeCard>
                        }

                        { (transactionData?.hash_details?.status === "PENDING" || transactionData?.hash_details?.status === "EXPIRE")  &&
                            <>{
                            (transactionData?.trade_details?.offer_type === "sell"
                                && transactionData?.trade_details?.user === userDetails?.user?._id) || (
                                    transactionData?.trade_details?.offer_type === "buy"
                                    && transactionData?.trade_details?.user !== userDetails?.user?._id
                                ) ? 
                                // Seller Card
                                <Card className='d-flex justify-content-center align-items-center p-3 mt-3'>
                                    <div>
                                        <CardSubHeading className='m-0'>
                                            Once you confirmed the payment from the buyer, click "Release". Crypto will be transfered immediately from escrow to the buyer's wallet. This procedure cannot be reversed.
                                        </CardSubHeading>
                                    </div>
                                    <div className='d-flex flex-column gap-2 justify-content-center align-items-center'>
                                        <Timer className='mb-0'>
                                            {/* { transactionData?.checkout?.iHavePaid ?
                                                <OfferTimer 
                                                    initiateTime={transactionData?.tradeTimes?.intiateTime} 
                                                    durationInMinutes={transactionData?.tradeExpireInMin} 
                                                    paidTime={transactionData?.tradeTimes?.paidTime} 
                                                } /> :  */}
                                                
                                                <CountdownTimer 
                                                    handleChangeTimeUp={handleChangeTimeUp} 
                                                    startTime={transactionData?.tradeTimes?.intiateTime} 
                                                    timeLimit={transactionData?.tradeExpireInMin} 
                                                />
                                                </Timer>
                                        <PRButton
                                            onClick={handleShowConfirm}
                                            disabled={paidLoader || transactionData?.checkout?.release_payment || !transactionData?.checkout?.iHavePaid}
                                            className='text-center py-1 releasebutton'>
                                            {
                                                paidLoader ? <ButtonLoader /> : 'Release'
                                            }
                                        </PRButton>   
                                    </div>
                                </Card> :
                                // For Buyer 
                                <Card className='d-flex justify-content-center align-items-center p-3 mt-3'>
                                    <div>
                                        <CardSubHeading className='m-0'>
                                            Once you have paid the seller and uploaded proof of your payment, click "Paid". Your payment is not confirmed unless you click "Paid" and if you forget to do so, the trade will eventually expire and the cryptocurrency will go back from the trade escrow to the seller.
                                        </CardSubHeading>
                                    </div>
                                    <div className='d-flex flex-column gap-2 justify-content-center align-items-center'>
                                        <Timer className='mb-0'>
                                            {/* { transactionData?.checkout?.iHavePaid ?
                                                <OfferTimer 
                                                    initiateTime={transactionData?.tradeTimes?.intiateTime} 
                                                    durationInMinutes={transactionData?.tradeExpireInMin} 
                                                    paidTime={transactionData?.tradeTimes?.paidTime} 
                                                    /> : 
                                                } */}
                                                <CountdownTimer 
                                                    handleChangeTimeUp={handleChangeTimeUp} 
                                                    startTime={transactionData?.tradeTimes?.intiateTime} 
                                                    timeLimit={transactionData?.tradeExpireInMin} 
                                                />
                                        </Timer>
                                        <PRButton
                                            onClick={()=>{setPaidConfirm(true)}}
                                            disabled={paidLoader || transactionData?.checkout?.iHavePaid || transactionData?.hash_details?.status === "EXPIRE" }
                                            className='text-center px-4 py-1 releasebutton'>
                                            { paidLoader ? <ButtonLoader /> : 'I Paid' }
                                        </PRButton>
                                    </div>
                                </Card>
                            }</>
                        }

                        <Card className='d-flex flex-column p-3 mt-3 justify-content-center align-items-center tradeinstructionfortour'>
                            <CardHeading>Trade Information</CardHeading>
                            <div className='d-flex flex-wrap mb-1 gap-1'>
                                <small className='text-secondary'>Trade started on : </small>
                                <small>{moment(transactionData?.hash_details?.createdAt).format("DD-MM-YYYY h:mm:ss a")}</small>
                            </div>

                            {/* <div className='d-flex flex-wrap mb-1 gap-1'>
                                <small className='text-secondary'>Trade Expired on : </small>
                                <small>{moment(transactionData?.tradeExpireTime).format("DD-MM-YYYY h:mm:ss a")}</small>
                            </div> */}

                            <div className='d-flex flex-wrap mb-1 gap-1'>
                                <small className='text-secondary'>Trade ID : </small> 
                                <small className='d-flex gap-1 align-items-center'>
                                    <div className='d-flex flex-column'>
                                        <span>{transactionData?.hash_details?._id}</span>
                                        <StatusText>{copySuccess}</StatusText>
                                    </div>
                                    <FaRegCopy onClick={() => copyToClipBoard(transactionData?.hash_details?._id)} />
                                </small>
                            </div>

                            <div className='d-flex flex-wrap mb-1 gap-1'>
                                <small className='text-secondary'>Rate : </small>
                                <small>{transactionData?.trade_details?.cryptoToFiatPrice?.toFixed(4)} {transactionData?.trade_details?.preffered_currency?.symbol}/{transactionData?.trade_details?.cryptoCurrency?.symbol}</small>
                            </div>

                            <div className='d-flex flex-wrap mb-1 gap-1'>
                                <small className='text-secondary'>Is Paid : </small>
                                <small>{ transactionData?.checkout?.iHavePaid ? `${moment(transactionData?.tradeTimes?.paidTime).format("DD-MM-YYYY h:mm:ss a")} buyer have paid` : "Buyer haven't paid yet" }</small>
                            </div>

                            <div className='d-flex flex-wrap mb-1 gap-1'>
                                <small className='text-secondary'>Is Release : </small> 
                                <small>{ transactionData?.checkout?.release_payment ? `${moment(transactionData?.tradeTimes?.releaseTime).format("DD-MM-YYYY h:mm:ss a")} seller has released` : "Seller hasn't released yet" }</small>
                            </div>
                            <div className='d-flex flex-wrap mb-1 gap-1'>
                                <small className='fw-bold'>Note : </small>
                                <small>You have 24 hours to raise dispute.</small>
                            </div>
                        </Card>

                        <Card className='d-flex flex-row p-3 mt-3'>
                            <div>
                                <CardHeading>Please follow <span className='text-capitalize'>{transactionData?.user?.name}</span>'s instructions</CardHeading>
                                <CardSubHeading className='m-0'>
                                    {
                                        transactionData?.trade_details?.offer_tags?.length > 0 && transactionData?.trade_details?.offer_tags.map((offer, index) => {
                                            return (
                                                <li className='m-0' key={uuidv4()}> {offer}</li>
                                            )
                                        })
                                    }
                                </CardSubHeading>
                                <CardSubHeading className='m-0'>
                                    <li>Say Hi, And wait for my response.</li>
                                    
                                </CardSubHeading>
                            </div>
                        </Card>

                        <div className='d-flex flex-wrap justify-content-between'>
                            <CardButton   
                                onClick={handleShowReport}
                                // disabled={transactionData?.canDisputeRaise}
                                disabled={
                                    (transactionData?.checkout?.iHavePaid === true && transactionData?.checkout?.release_payment === true ) || 
                                    transactionData?.isDisputeRaised || 
                                    !(transactionData?.hash_details?.status === "EXPIRE") ||
                                    (transactionData?.hash_details?.status === "EXPIRE" && transactionData?.checkout?.iHavePaid === false)
                                }
                                className='px-4 reportbuttonfortour py-1 mt-3'> Raise Dispute </CardButton>
                            <CancelButton
                                disabled={
                                    transactionData?.checkout?.iHavePaid || 
                                    isTradeCancelled || 
                                    transactionData?.isDisputeRaised ||
                                    transactionData?.hash_details?.status === "EXPIRE"
                                } 
                                onClick={handleShowCancel} 
                                className='px-4 cancelbuttonfortour py-1 mt-3'> Cancel Trade </CancelButton>    
                            <CardButton
                                disabled={!(transactionData?.checkout?.iHavePaid === true && transactionData?.checkout?.release_payment === true) || transactionData?.isDisputeRaised || transactionData?.feedbackProvided || isTradeCancelled}
                                onClick={handleShowFeedback}
                                className='px-4 py-1 mt-3 givefeedbackbutton'> 
                                Provide Feedback 
                            </CardButton>
                        </div>
                    </div>
                    <div className='p-3 d-none d-lg-block col-lg-6'>
                        <ChatScreen transactionData={transactionData} releaseState={releaseState} handleReleaseState={handleReleaseState} />
                    </div>
                </div>
                <div className='d-block d-lg-none'>
                    <ChatButton onClick={()=>setShowChat(true)}> <BsChatDots size={40} color='white'/> </ChatButton>
                </div>
            </div>

            <Modal show={showReport} onHide={handleCloseReport}>
                <Modal.Header  style={{ background: isDarkTheme ? '#0c0c0c' : 'white'}} closeButton>
                    <Modal.Title>Raise Dispute</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ background: isDarkTheme ? '#0c0c0c' : 'white'}}>
                    <form onSubmit={formik1.handleSubmit}>
                        <div className='mt-2'>
                            <Label htmlFor='query'>Dispute Summary</Label>
                            <div>
                                <DisputeInput
                                    placeholder='Summarize the reason for dispute'
                                    name='query'
                                    id='query'
                                    value={formik1.values.query}
                                    onChange={formik1.handleChange}
                                    onBlur={formik1.handleBlur}
                                />
                            </div>
                        </div>
                        <div className='mt-2'>
                            <Label htmlFor='category'>Category</Label>
                            <Select
                                placeholder='Select Category'
                                name='category'
                                id='category'
                                options={disputeCategory}
                                styles={styles}
                                onChange={(selectedValues) => {
                                    let event = { target: { name: 'category', value: selectedValues } }
                                    formik1.handleChange(event)
                                }}
                                onBlur={(selectedValues) => {
                                    let event = { target: { name: 'category', value: selectedValues } }
                                    formik1.handleBlur(event)
                                }}
                                value={formik1.values.category}
                            />
                        </div>
                        <div className='mt-2'>
                            <ImgDiv>
                                <TextSpan className='d-flex justify-content-between align-items-center w-75 ps-3'>
                                    {String(imgName).length > 20 ? String(imgName).slice(0, 20) + '...' : String(imgName)}
                                </TextSpan>
                                <UploadLabel className='label'>
                                    <input
                                        className='d-none'
                                        onChange={(e) => {
                                            setImgName(e.target.files[0].name)
                                            setImgElement(e.target.files[0])
                                        }}
                                        name='image'
                                        type="file"
                                    />
                                    <span>Upload</span>
                                </UploadLabel>
                            </ImgDiv>
                        </div>
                        <div className='mt-2'>
                            <DisputeButton disabled={reportLoader} type='submit'>
                                {
                                    reportLoader && <ButtonLoader />
                                }
                                Start Dispute
                            </DisputeButton>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>

            <Modal show={showFeedback} onHide={handleCloseFeedback}>
                <Modal.Header style={{ background: isDarkTheme ? '#0c0c0c' : 'white'}}>
                    <Modal.Title>Give Your Feedback</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ background: isDarkTheme ? '#0c0c0c' : 'white'}}>
                    <div className='d-flex justify-content-center align-items-center'>
                        <ReactStars
                            count={5}
                            onChange={ratingChanged}
                            size={50}
                            activeColor="#ffd700"
                            isHalf={true}
                            emptyIcon={<i className="far fa-star"></i>}
                            halfIcon={<i className="fa fa-star-half-alt"></i>}
                            fullIcon={<i className="fa fa-star"></i>}
                        />
                    </div>
                    <p className='mb-0 fw-bold'>Message</p>
                    <FeedInput
                        value={feedbackMsg}
                        onChange={feedMsgchangeHandle}
                        placeholder='Enter Your Feedback Here'
                    ></FeedInput>
                    <DisputeButton onClick={handleSubmitFeedback} disabled={rating === 0} type='submit'>
                        {
                            feedbackLoader ? <ButtonLoader /> : 'Send'
                        }
                    </DisputeButton>
                </Modal.Body>
            </Modal>

            <Modal show={showCancel} onHide={handleCloseCancel}>
                <Modal.Header style={{ background: isDarkTheme ? '#0c0c0c' : 'white'}}>
                    <Modal.Title>Cancel Trade</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ background: isDarkTheme ? '#0c0c0c' : 'white'}}>
                    <p className='mb-0 fw-bold'>Give, why are you cancel this trade</p>
                    <Select
                        options={canceloption}
                        styles={styles}
                        onChange={(selectedValues) => {
                            let event = { target: { name: 'accountType', value: selectedValues } }
                            setCancelMsg(event)
                        }}
                    />
                    <DisputeButton className='mt-3' onClick={() => handleCancelTransaction()} disabled={loader} type='submit'>
                        {
                            loader ? <ButtonLoader /> : 'Cancel Trade'
                        }
                    </DisputeButton>
                </Modal.Body>
            </Modal>

            <Modal show={showConfirm} onHide={handleCloseConfirm}>
                <Modal.Header style={{ background: isDarkTheme ? '#0c0c0c' : 'white'}} className='d-flex justify-content-between'>
                    <Modal.Title>Self Declaration</Modal.Title>
                    <RxCross2 onClick={() => handleCloseConfirm()} />
                </Modal.Header>
                <Modal.Body style={{ background: isDarkTheme ? '#0c0c0c' : 'white'}}>
                    <ConfirmP className='mb-0'>You are about to send <span className='fw-bold'>${transactionData?.checkout?.crypto_amount} ${transactionData?.trade_details?.cryptoCurrency?.symbol}</span> to the buyer's wallet. If you have not received your payment, Click <span className='fw-bold' onClick={handleShowCancel}>Cancel</span> and return to the trade chat.</ConfirmP>
                    <DisputeButton className='mt-3' onClick={()=> handleSendOtpForVerification()} disabled={paidLoader} type='submit'>
                        {
                            paidLoader ?
                                <><ButtonLoader /> <span>Release</span></>
                                : 'Release'
                        }
                    </DisputeButton>
                </Modal.Body>
            </Modal>

            <Modal show={paidConfirm} onHide={()=>setPaidConfirm(false)}>
                <Modal.Header style={{ background: isDarkTheme ? '#0c0c0c' : 'white'}} className='d-flex justify-content-between'>
                    <Modal.Title>Self Declaration</Modal.Title>
                    <RxCross2 onClick={() => setPaidConfirm(false)} />
                </Modal.Header>  
                <Modal.Body style={{ background: isDarkTheme ? '#0c0c0c' : 'white'}}>
                    <ConfirmP className='mb-0'>If you have followed the seller's instructions and paid, click  <span className='fw-bold'>Paid</span> within the time limit. If you have missed a step or haven't paid, click <span className='fw-bold' onClick={handleShowCancel}>Cancel</span> to return to chat with the seller. <span className='fw-bold'></span></ConfirmP>
                    {/* <DivConfirm className='flex-column '> */}
                        {/* <Ins>Instruction</Ins> */}
                        <ConfirmP>Otherwise, the trade will be automatically canceled and the crypto will be returned to the seller's wallet.</ConfirmP>
                    {/* </DivConfirm> */}
                    <DisputeButton onClick={()=> handleReleaseOrPay()} disabled={paidLoader} type='submit'>
                        {
                            paidLoader ? <><ButtonLoader/> Paid</> : 'Paid'
                        }
                    </DisputeButton>
                </Modal.Body>
            </Modal>

            <Modal show={otpSent}>
                <Modal.Header style={{ background: isDarkTheme ? '#0c0c0c' : 'white'}}>
                    <Modal.Title className='fw-bold fs-5'>Verify its you</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ background: isDarkTheme ? '#0c0c0c' : 'white'}}>
                <div className='d-flex justify-content-center align-items-center verify-two-fa-txn m-0 flex-column'>
                    <div className='mb-1'>
                        <Para className='text-center' >We sent successfully Verification Code to your Email Address</Para>
                    </div>
                    <OTPInput
                        className='otp-input-box-trade'
                        value={otpValue}
                        onChange={setOtpValue}
                        autoFocus
                        OTPLength={isTwoFa ? 6 : 4}
                        otpType="number"
                        disabled={false} secure
                    />
                    {time === 0 ?
                        <Para className='text-center pt-3 mb-1'>Verification code has been expired</Para> :
                        <Para className='text-center pt-3 mb-1'>Verification code will expire in {formatTime(time)} minutes</Para>
                    }
                    <DisputeButton onClick={()=>handleReleaseOrPay()} className='mt-2 text-center'>{paidLoader ? <ButtonLoader/> : 'Verify'}</DisputeButton>
                </div>
                </Modal.Body>
            </Modal>

            <Modal show={showChat} onHide={()=>setShowChat(false)}>
                <div onClick={()=>setShowChat(false)}>Close</div>
                <Modal.Body style={{ background: isDarkTheme ? 'black' : 'white', padding: '0px !important' }}>
                    <ChatScreen transactionData={transactionData} releaseState={releaseState} handleReleaseState={handleReleaseState} />
                </Modal.Body>
            </Modal>

            {showModal && (<CustomModal
                show={showModal}
                handleClose={handleCloseModal}
                navigateLink={navigateLink}
                data={modalData}
            />
            )}
        </> :
        <Error404 />
    );
}