// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Cardpositive {
    /* background-color: rgb(154, 204, 154); */
    background-color: #e9f8e9;
    border: 1px solid #e9f8e9;
    border-radius: 8px;
}

.Cardnegative {
    background-color: #f5e2e5;
    border-radius: 8px;
    border: 1px solid #fff9fa;
}

.feedtext {
    font-size: 14px;
    font-weight: 500;
}

.publicuserbackground {
    background: rgba(233 234 243 1);
}

.publicuser-tabs .nav-link {
    display: flex;
    align-items: center;
    border: none;
    background: transparent;
    font-style: normal;
    font-weight: 600;
    color: #6F7182;
}

.publicuser-tabs .nav-item .active {
    display: flex;
    align-items: center;
    background: transparent;
    border: none;
    color: #1476FF;
}

.publicuser-tabs {
    border-bottom: 1px solid #dee2e6 !important;
}`, "",{"version":3,"sources":["webpack://./src/pages/User/User.css"],"names":[],"mappings":"AAAA;IACI,0CAA0C;IAC1C,yBAAyB;IACzB,yBAAyB;IACzB,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;IACzB,kBAAkB;IAClB,yBAAyB;AAC7B;;AAEA;IACI,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,+BAA+B;AACnC;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,YAAY;IACZ,uBAAuB;IACvB,kBAAkB;IAClB,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,YAAY;IACZ,cAAc;AAClB;;AAEA;IACI,2CAA2C;AAC/C","sourcesContent":[".Cardpositive {\n    /* background-color: rgb(154, 204, 154); */\n    background-color: #e9f8e9;\n    border: 1px solid #e9f8e9;\n    border-radius: 8px;\n}\n\n.Cardnegative {\n    background-color: #f5e2e5;\n    border-radius: 8px;\n    border: 1px solid #fff9fa;\n}\n\n.feedtext {\n    font-size: 14px;\n    font-weight: 500;\n}\n\n.publicuserbackground {\n    background: rgba(233 234 243 1);\n}\n\n.publicuser-tabs .nav-link {\n    display: flex;\n    align-items: center;\n    border: none;\n    background: transparent;\n    font-style: normal;\n    font-weight: 600;\n    color: #6F7182;\n}\n\n.publicuser-tabs .nav-item .active {\n    display: flex;\n    align-items: center;\n    background: transparent;\n    border: none;\n    color: #1476FF;\n}\n\n.publicuser-tabs {\n    border-bottom: 1px solid #dee2e6 !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
