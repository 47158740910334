import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from "formik";
import '../AuthCss/Login.css'
import Icon from '../../../images/Home/qvaniIcon.png'
import * as Yup from "yup";
import { useAuthContextHook } from "../../../Context/AuthContext";
import { FaEye, FaEyeSlash } from 'react-icons/fa'
import Block from '../../Block'
import styled from 'styled-components';
// import Select from 'react-select'
import Select, { components } from 'react-select';

import ButtonLoader from '../../../Component/Loader/ButtonLoader';
import { useThemeContext } from '../../../Context/ThemeContext';
import { Modal } from 'react-bootstrap';
import { useAPIContextHook } from '../../../Context/ApiContext';
import CustomModal from '../../../hooks/CustomModal';
import Logo from '../../../assets/Images/LogoFooter.png'

const Para = styled.p`
  font-size: 0.8rem;
  font-family: 'Inter';
  color: #6F7182;
`
const Container = styled.div`
  background: ${({ theme }) => theme.body === "#F7F9FC" ? 'white' : theme.body};
  border: ${({ theme }) => theme.border};
  box-shadow: ${({ theme }) => theme.boxshadow};
  border-radius: 14px;
  max-width: 430px;
`
export const Input = styled.input`
background: transparent;
color: ${({ theme }) => theme.text};
border: ${({ theme }) => theme.border};
box-shadow: ${({ theme }) => theme.boxshadow};
border-radius: 30px;
padding: 5px;
width: 100%;
padding-right: 44px !important;
outline: none;
`

const Span = styled.span`
  margin-left: -35px;
`
const P = styled.p`
font-size: 13px;
font-weight: 500;
font-family: 'Inter';
color: #6F7182;
`
export const InputPhone = styled.input`
border: none;
outline: none;
width: 75%;
border-radius: 20px;
// box-shadow: ${({ theme }) => theme.boxshadow};
`
// const DivPhone = styled.div`
// display: inline-flex;
// width: 100%;
// border: 1px solid #E9EAF3;
// box-shadow: ${({theme})=> theme.boxshadow};
// border-radius: 20px;
// `



// const CustomMenuList = (props) => {
//   const [searchValue, setSearchValue] = useState('');
//   // const [disabled, setDisabled] = useState(false); // Initialize as disabled

//   const handleInputChange = (event) => {
//     console.log('Input change event:', event.target.value);
//     setSearchValue(event.target.value);
//   };

//   const filteredOptions = props.options.filter((option) =>
//     option.label.toLowerCase().includes(searchValue.toLowerCase())
//   );

//   console.log('Filtered options:', filteredOptions);

//   return (
//     <components.MenuList {...props}>
//       <div style={{ padding: '8px' }}>
//         <input
//           type="text"
//           placeholder="Search..."
//           value={searchValue}
//           onChange={handleInputChange}
//           style={{ width: '100%', padding: '8px', boxSizing: 'border-box' }}
//           // disabled={disabled} // Disable input when component is disabled
//         />
//       </div>
//       {filteredOptions.map((option) => (
//         <components.Option key={option.value} {...props} data={option}>
//           {option.label}
//         </components.Option>
//       ))}
//     </components.MenuList>
//   );
// };

// const CustomMenuList = ({ children, ...props }) => {
//   const [searchValue, setSearchValue] = useState('');

//   const handleInputChange = (event) => {
//     setSearchValue(event.target.value);
//   };

//   const { options } = props;

//   const filteredOptions = options.filter((option) =>
//     option.label.toLowerCase().includes(searchValue.toLowerCase())
//   );

//   return (
//     <components.MenuList {...props}>
//       <div style={{ padding: '8px' }}>
//         <input
//           type="text"
//           placeholder="Search..."
//           value={searchValue}
//           onChange={handleInputChange}
//           style={{ width: '100%', padding: '8px', boxSizing: 'border-box' }}
//         />
//       </div>
//       {filteredOptions.map((option) => (
//         <components.Option key={option.value} {...props} data={option}>
//           {option.label}
//         </components.Option>
//       ))}
//     </components.MenuList>
//   );
// };


export default function Login() {
  const { country, blockedCountryList, getYourCountry, isDarkTheme } = useThemeContext();
  const { phoneCode } = useAPIContextHook();

  const [isClicked, setIsClicked] = useState(false);
  const [seePassword, setSeePassword] = useState(false);
  const { handleLogin, IsLogedIn, showModal, handleCloseModal, navigateLink, modalData } = useAuthContextHook();
  const [phone, setPhone] = useState('')

  const [option, setOption] = useState(false)

  const [showBlockModal, setShowBlockModal] = useState(false)
  const handleOpenBlockModal = () => { setShowBlockModal(true) }
  const handleCloseBlockModal = () => { setShowBlockModal(false) }


  const [selected, setSelected] = useState([]);

  const handleChange = (selected) => {
    setSelected({ selected })
  }

  const navigate = useNavigate();

  useEffect(() => { getYourCountry() }, [])
  useEffect(() => {
    if (IsLogedIn) {
      navigate('/dashboard/user-dashboard')
    }


  }, [IsLogedIn, navigate, phone])




  const handleSignIn = async (email, password) => {
    setIsClicked(true);
    await handleLogin(email, password);
    setIsClicked(false);
  }


  const formik = useFormik({
    initialValues: { email: "", password: "", phone: "" },
    validationSchema: Yup.object({
      email: Yup.string().email("Invalid email address"),
      // phone: Yup.string().matches(/^\d{10}$/, 'Invalid phone number'),
      // phone: Yup.string().matches(/^[6-9]\d{9}$/, {message: "Please enter valid number.", excludeEmptyString: false}),
      password: Yup.string().required("Password is required")
    }),
    onSubmit: (values) => {
      if (blockedStatus.length === 1) {
        handleOpenBlockModal();
      } else if (option) {
        const fullPhoneNumber = `${phone.label}${values.phone}`;
        // console.log('Full Phone Number:', fullPhoneNumber);
        handleSignIn(fullPhoneNumber, values.password);
      } else {
        handleSignIn(values.email, values.password);
      }
    }
  });


  let blockedStatus = blockedCountryList.filter((single) => {
    return country === single.iso2
  })

  const styles = {
    control: (styles) => ({
      ...styles,
      // borderRadius: '20px',
      width: '8vw',
      outline: 'none',
      border: `none`, "&:hover": {
        border: `none`
      },
      boxShadow: `none`,
      color: 'white'
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: isDarkTheme && state.isHovered ? '#302F30' : `${({ theme }) => theme.body}`,
      ':hover': {
        backgroundColor: `${isDarkTheme ? '#302F30' : '#D9EDE7'}`,
      },
      color: isDarkTheme && state.isSelected ? '#1476FF' : `${({ theme }) => theme.text}`,
    }),
    indicatorsContainer: (styles) => ({
      ...styles, color: isDarkTheme ? 'white' : 'black', 'div:nth-child(2)': {
        color: isDarkTheme ? 'white' : 'black'
      }
    }),
    menu: (styles) => ({
      ...styles, background: isDarkTheme ? 'rgb(27, 27, 28)' : 'white',
      color: isDarkTheme ? 'white' : 'black',
      width: '28vw'
    })
  }


const filterOptions = (candidate, input) => {
  if (!input) return true;

  const inputValue = input.toLowerCase();
  const label = candidate.label.toLowerCase();
  
  return label.startsWith(inputValue) || label.includes(inputValue);
};


  return (
    <div className="LoginBg w-full row m-0 d-flex justify-content-center align-items-center">
      <div className='p-3 mb-2' style={{ minHeight: '0' }}>
        <Link to='/home'><img src={Logo} alt="" width={'140px'} /></Link>
      </div>
      <div className="d-flex align-items-center justify-content-center">
        <Container className="card col-12 pt-4 col-md-6 col-xl-4 px-2">
          <div className='card-head d-flex flex-column'>
            <img src={Icon} alt='' width='60px' className='align-self-center' />
            <p className="card-heading text-center pt-1 mb-1 fs-2 fw-bolder">Welcome to Qvani</p>
            <Para className='text-center'>We Will Send Verification code for Verification</Para>
          </div>
          <form className='mx-2' onSubmit={formik.handleSubmit}>
            <div className='tabs-navigator login-tabs'>
              <ul id='myTab' className='nav nav-tabs border-0'>
                <li className="nav-item" >
                  <label
                    className='nav-link active p-0 text-dark bg-transparent border-0'
                    id="emailed-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#emailed"
                    type="button"
                    role="tab"
                    aria-controls="emailed"
                    onClick={() => { setOption(false) }}
                    aria-selected="true">
                    Email
                  </label>
                </li>
                <div className='fw-bold' style={{ margin: '0px 1px' }}>|</div>
                <li className="nav-item" >
                  <label
                    className='nav-link p-0 text-dark bg-transparent border-0'
                    id="phone-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#phone"
                    type="button"
                    role="tab"
                    aria-controls="phone"
                    onClick={() => { setOption(true) }}
                    aria-selected="true">
                    Phone Number
                  </label>
                </li>
              </ul>

              <div className='tab-content' id='myTabContent'>
                <div className='tab-pane fade py-3 show active'
                  id="emailed"
                  role="tabpanel"
                  aria-labelledby="emailed-tab">
                  <div className="FormDiv d-flex flex-column">
                    <Input
                      id="email"
                      placeholder='Email Address'
                      type="text"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      className={
                        formik.errors.email && formik.touched.email
                          ? "text-input error px-3 py-2"
                          : "text-input px-3 py-2"
                      }
                    />
                    <small>
                      {formik.errors.email && formik.touched.email && (
                        <div className="input-feedback text-danger auth-error text-start">{formik.errors.email}</div>
                      )}
                    </small>
                  </div>
                </div>
                <div className='tab-pane fade py-3'
                  id='phone'
                  role="tabpanel"
                  aria-labelledby="phone-tab">
                  <div className="FormDiv d-flex justify-content-between">
                    <div className='phone-selectr w-100 d-flex justify-content-between'>
                      <Select
                        options={phoneCode}
                        value={phone}
                        onChange={(selectedValues) => {
                          let arr = { label: selectedValues.value, value: '' }
                          setPhone(arr)
                        }}
                        filterOption={filterOptions}
                        // components={{ MenuList: (props) => <CustomMenuList {...props} options={phoneCode} /> }}
                        // components={{ MenuList: CustomMenuList }}
                        className='logindiv'
                        styles={styles}
                        placeholder="Search"
                      />
                      <InputPhone
                        name='phone'
                        placeholder='Mobile Number'
                        type="text"
                        maxLength={15}
                        value={formik.values.phone}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className={
                          formik.errors.phone && formik.touched.phone
                            ? "text-input error px-2 py-1"
                            : "text-input px-2 py-1"
                        }
                      />
                      <small>
                        {formik.errors.phone && formik.touched.phone && (
                          <div className="input-feedback text-danger auth-error text-start">{formik.errors.phone}</div>
                        )}
                      </small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="FormDiv d-flex m-0 mt-1 flex-column">
              <small className='mx-2 mb-2'>Password</small>
              <div className='FormDiv'>
                <Input
                  id="password"
                  placeholder='Password'
                  type={seePassword ? 'text' : 'password'}
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={
                    formik.errors.password && formik.touched.password
                      ? "text-input px-3 py-2 error"
                      : "text-input px-3 py-2"
                  }
                />
                {seePassword ? <Span onClick={() => setSeePassword(false)}><FaEyeSlash /></Span> :
                  <Span type='button' onClick={() => setSeePassword(true)}><FaEye /></Span>}
              </div>
              {formik.errors.password && formik.touched.password && (
                <div className="input-feedback text-danger text-start auth-error">{formik.errors.password}</div>
              )}
            </div>
            <div>
              <button className='Button d-flex justify-content-center gap-2 align-items-center' placeholder='' type="submit" disabled={isClicked}>
                {
                  isClicked ?
                    <><ButtonLoader /> <span>Sending...</span></> : 'Login'
                }
              </button>
            </div>
            <div className='my-2 d-flex flex-wrap justify-content-between align-items-center'>
              <div className='d-flex text-start align-items-center gap-1'>
                <input type='checkbox' onChange={() => {
                  localStorage.setItem('emailID', formik.values.email)
                  localStorage.setItem('password', formik.values.password)
                }} />
                <P className='mb-0'>Remember me</P>
              </div>
              <Link type='button' className='Forgot text-end text-primary mb-0' to='/forgot-password'>Forgot password ?</Link>
            </div>
            <div className='px-2 text-center'>
              <P>Don't have an account ? <Link to={'/signup'}>Create an account</Link></P>
            </div>
          </form>
        </Container>
      </div>
      <Modal className='restricted-modal' show={showBlockModal}>
        <Modal.Body>
          <Block handleCloseBlockModal={handleCloseBlockModal} />
        </Modal.Body>
      </Modal>

      {showModal && (<CustomModal
        show={showModal}
        handleClose={handleCloseModal}
        navigateLink={navigateLink}
        data={modalData}
      />)}
    </div>
  );
}