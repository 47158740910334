// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nav-item > .active {
    font-weight: 600;
    color: black !important;
}
.nav-item > label {
    font-size: 14px;
}
.signupdiv > div div:nth-child(2) > div{
    padding: 0px;
}
.signupdiv{
    width: 25%;
}
.ForgotButton{
    width: 100%;
    margin-top: 10px;
    border: 1px solid red;
    background: white;
    border-radius: 20px;
    padding: 5px;
    font-weight: 500;
    color: #2a2a2a;
}

.FormDiv{
    position: relative;
}


.selct-cntrr{
    border: 1px solid #E9EAF3;
    box-shadow: 0px 0px 10px #0B162C0D;
    border-radius: 20px;
    
}

.forgotbymobile{
    box-shadow: rgb(233, 234, 243) 0px 0px 10px;
    border-radius: 30px;
    display: flex;
    justify-content: space-between;
    width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/pages/Auth/SignUp/Signup.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,uBAAuB;AAC3B;AACA;IACI,eAAe;AACnB;AACA;IACI,YAAY;AAChB;AACA;IACI,UAAU;AACd;AACA;IACI,WAAW;IACX,gBAAgB;IAChB,qBAAqB;IACrB,iBAAiB;IACjB,mBAAmB;IACnB,YAAY;IACZ,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,kBAAkB;AACtB;;;AAGA;IACI,yBAAyB;IACzB,kCAAkC;IAClC,mBAAmB;;AAEvB;;AAEA;IACI,2CAA2C;IAC3C,mBAAmB;IACnB,aAAa;IACb,8BAA8B;IAC9B,WAAW;AACf","sourcesContent":[".nav-item > .active {\n    font-weight: 600;\n    color: black !important;\n}\n.nav-item > label {\n    font-size: 14px;\n}\n.signupdiv > div div:nth-child(2) > div{\n    padding: 0px;\n}\n.signupdiv{\n    width: 25%;\n}\n.ForgotButton{\n    width: 100%;\n    margin-top: 10px;\n    border: 1px solid red;\n    background: white;\n    border-radius: 20px;\n    padding: 5px;\n    font-weight: 500;\n    color: #2a2a2a;\n}\n\n.FormDiv{\n    position: relative;\n}\n\n\n.selct-cntrr{\n    border: 1px solid #E9EAF3;\n    box-shadow: 0px 0px 10px #0B162C0D;\n    border-radius: 20px;\n    \n}\n\n.forgotbymobile{\n    box-shadow: rgb(233, 234, 243) 0px 0px 10px;\n    border-radius: 30px;\n    display: flex;\n    justify-content: space-between;\n    width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
