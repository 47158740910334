import styled from "styled-components"

export const NextButton = styled.button`
    border: 2px solid #1476FF;
    background-color: #1476FF;
    color: white;
    border-radius: 20px;
    padding: 3px 25px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
`

export const P = styled.p`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
`
export const PrevButton = styled.button`
    border: 2px solid #1476FF;
    background-color: #1476FF;
    color: white;
    border-radius: 20px;
    padding: 3px 25px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
`
export const OfferCard = styled.div`
margin: 9px;
border:  ${({theme})=> theme.border};
box-shadow:  ${({theme})=> theme.boxshadow};
border-radius: 20px;
color: ${({theme})=> theme.text};
background: ${({theme})=>theme.body=='#F7F9FC' ? 'white' : theme.body};
`

export const Button = styled.button`
border: none;
background: transparent;
font-size: 18px;
font-weight: 600;
`

export const Input = styled.input`
    border: none;
    outline: none;
    width: 60%;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    border-radius: 30px;
    color: ${({theme})=> theme.text};
    background: transparent;
`
export const PriceSide = styled.p`
    margin-bottom: 0px;
    border: ${({ theme }) => theme.border};
    border-radius: 23px;
    font-weight: 600;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    display: flex;
    align-items: center;
`
export const Box = styled.span`
    padding: 3px;
    color: rgb(48, 51, 80);
    padding: 7px;
    border-radius: 20px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    border: ${({ theme }) => theme.border};
    background: ${({theme})=>theme.body=='#F7F9FC' ? 'white' : theme.body};
`

export const BoxClickable = styled.span`
    padding: 3px;
    color: rgb(48, 51, 80);
    padding: 7px;
    border-radius: 20px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    border: ${({ theme }) => theme.border};
    background: white;
    cursor: pointer;

    :hover{
        opacity: 0.9;
        background: rgb(48, 51, 80, 0.8);
        color: white;
    }
    :active{
        transform: scale(0.9);
    }
`

export const InputContainer = styled.div`
    border-radius: 40px;
    border: ${({theme})=>theme.border};
    box-shadow: ${({theme})=>theme.boxshadow};
    background: ${({theme})=>theme.body=='#F7F9FC' ? 'white' : theme.body};

    :focus-within{
        border: 1px solid rgb(180, 181, 191);
    }
`

export const SubCardContainer = styled.div`
    border-radius: 15px;
    border: ${({theme})=>theme.border};
    background: ${({theme})=>theme.body=='#F7F9FC' ? 'white' : theme.body};
`

export const SubCardLabel = styled.label`
    border-radius: 15px;
    border: 1px solid rgb(233, 234, 243);
    background: ${({theme})=>theme.body=='#F7F9FC' ? 'white' : theme.body};
`

export const TextArea = styled.textarea`
    background: ${({theme})=>theme.body=='#F7F9FC' ? 'white' : theme.body};
    border: ${({theme})=>theme.border};
    color: ${({theme})=>theme.text};
    box-shadow: 0px 2px 12px rgba(11, 22, 44, 0.05);
    border-radius: 25px;
    outline: none;
    height: 10rem;
    :focus{
        border: 1px solid rgb(180, 181, 191);
    }
`

export const SelectableButton = styled.button`
    border: 1px solid rgb(53, 69, 238);
    background: ${({ active }) => active ? 'rgb(53, 69, 238)' : 'white'};
    color: ${({ active }) => active ? 'white' : 'black'};
    border-radius: 16px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    padding: 4px 10px;
    font-size: 14px;
`
export const BlueButton = styled.button`
color: white;
border: none;
background-color: #1476FF;
font-size: 15px;
font-weight: 700;
font-family: 'Inter';
box-shadow: 0px 0px 10px #0257FB30;
align-items: center;
padding: 10px 19px;
text-decoration: none;
border-radius: 25px;
`
export const WhiteButton = styled.button`
box-shadow: 0px 7px 7px #0257FB30;
border: 1px solid #E9EAF3;
background: white;
margin: 1px 10px;
border-radius: 30px;
padding: 9px 15px;
font-size: 15px;
font-weight: 700;
font-family: 'Inter';
`