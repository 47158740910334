import styled from "styled-components";

export const Button = styled.button`
    border: 1px solid #6F7182;
    border-radius: 5px;
    background: transparent;
    outline: none;
    font-family: 'Inter';
    font-style: normal;
    font-size: 14px;
    font-weight: 600;
    transition: all 0.2s;
    text-decoration: none;
    color: #33343c;

    :hover{
        background: #e7e7e9;
        color: #33343c;
    }
`
export const Tag = styled.p`
font-size: 10px;
margin-top: 2px;
font-weight: 500;
background: white;
margin-bottom: 0px;
border-radius: 10px;
padding: 1px 5px;
`
export const Heading = styled.p`
font-weight: 700 !important;
font-size: 15px;
font-family: 'Inter';
`
export const P = styled.p`
margin-bottom: 0px;
font-size: 14px;
`
export const Span = styled.span`
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 60%;
    background: ${({ active }) => active ? '#1476FF' : '#6F7182'};
    padding: 2px;
    color: white;
    align-self: center;
    width: 20px;
    height: 20px;
    padding-top: 4px;
`