import React from 'react'
import styled from 'styled-components';
import Img1 from '../../assets/Images/CyptoWallet.png';
import Img2 from '../../assets/Images/24-7Support.png';
import Img3 from '../../assets/Images/InviteFriends.png';
import './Index.css'

const Text = styled.p`
    text-align: center;
    font-weight: 600;
    color: ${({ theme }) => theme.text};
`;

const arr = [{

    img: Img1,
    heading: 'Free Bitcoin and Crypto wallet',
    title: 'Get a free Qvani wallet when you sign up to send, receive, and store your Bitcoin or cryptocurrency safely.'
},
{
    img: Img2,
    heading: '24/7 Support',
    title: 'You can always contact our 24/7 support team by messaging or depending on the hours, with a real live support agent. You can also check out our Help Center.'
},
{
    img: Img3,
    heading: 'Invite Friends & Earn',
    title: 'Qvani Referral Rewards Program helps you bring financial freedom to your community. Refer friends to trade on Qvani and receive commission from their trades.'
},
]


export default function Card() {
    return (
        <div className='container-fluid' style={{marginTop: '-150px'}}>
            <div className='row m-0 d-flex flex-column align-items-center justify-content-center'>
                <div className=' col-md-6 col-sm-10'>
                    <Text className='fs-2 fw-bold text-center'>Qvani Exchange is the safest place <br /> to trade, send and receive <br /> cryptocurrencies</Text>
                </div>
            </div>
            <div className='row m-0 mt-4 d-flex flex-wrap gap-4 justify-content-center px-lg-5'>
                {arr.map((data, index) => {
                    return <div key={index} class="card rounded-3 col-md-3 col-sm-7 border-0 shadow mb-5" style={{width: "24rem", height: "350px"}}>
                    <img src={data.img} class={`align-self-center p-4 ${index === 0 && 'card-img-top'} ${index === 1 && 'card-img-top-1'} ${index === 2 && 'card-img-top-2'}`} alt="..." />
                    <div class="card-body pb-0 pt-1 text-center">
                      <h5 class="card-title fw-bold text-primary">{data.heading}</h5>   
                      <small class="card-text text-justify">{data.title}</small>
                    </div>
                  </div>
                })}
            </div>
        </div>
    );
}