import React from 'react'
import img from '../../assets/Images/discount-using-credit-card (1) 1.png'
import Commisions from './Commisions'
import ReferFriends from './ReferFriends'
import styled from 'styled-components'
import './Rewards.css'
import { Link } from 'react-router-dom'

const Div = styled.div`
background-color: #EEF2F6;
padding: 60px 0;
`

const Button = styled.button`
padding: 0.5rem 3rem;
background-color: #0052FF;
border-radius: 32px;
color: white;
border: none;
text-decoration: none;
outline: none;
:hover{
    color: white;
}
`

const Rewards = () => {
    return (
        <>
            <div className='container'>
                <div className='qvani-refral-rwrd d-flex justify-content-center align-items-center row'>
                    <div className='col-md-6 col-sm-12' style={{marginTop: "6rem"}}>
                        <h1 className='fw-bold mb-3 text-center text-md-start'>Qvani Referral Rewards</h1>
                        <p className='rewrds-para fs-5 text-dark text-center mb-1'>Get paid for bringing people to Qvani. Refer friends to trade on Qvani and receive up to 30% commission from their trading fees. When people invited by your friends (i.e., your sub-affiliates), you will further receive second-level commission. You're just steps away from receiving your rewards.</p>
                        <p className='text-dark text-md-primary text-center text-md-start'>{`Learn more>>`}</p>
                        <div className='d-flex justify-content-center justify-content-md-start'>
                        <Button as={Link} to='/login'>Join</Button>

                        </div>
                    </div>
                    <div className='col-md-6 col-sm-12 align-self-end'>
                        <img src={img} alt="" className='ms-5 w-75' />
                    </div>
                </div>
                <Commisions />
                <ReferFriends />
            </div>
            <Div className='row m-0 mt-5 d-flex justify-content-center align-items-center gap-2'>
                <div className='col-sm-10 text-center'>
                    <h2 className='fw-bold fs-1 text-center' 
                    ><span className='d-lg-block'>We share up to 30% of our</span>
                     <span className='d-lg-block'>revenue with the Qvani</span> 
                     <span className='d-lg-block'>community.</span>
                    </h2>
                    <Button as={Link} to='/login'>Join</Button>
                </div>
            </Div>
        </>
    )
}

export default Rewards;