import React, { useState, useEffect, useCallback } from 'react'
import styled from 'styled-components';
import axios from 'axios';
import { FaRegCopy } from 'react-icons/fa'
import { TiTick } from "react-icons/ti";
import { API_URLs } from '../../Utils/ApiUrls';
import { P } from '../Profile/StyledComponents';
import Select from 'react-select'
import { ImgDiv } from './StyledComponent';
import { useAPIContextHook } from '../../Context/ApiContext';
import { useThemeContext } from '../../Context/ThemeContext';
import { darkTheme, lightTheme } from '../../Theme/theme';
import DataLoader from '../../Component/Loader/DataLoader'
import DataTable, {createTheme}from 'react-data-table-component'
const Div = styled.div`
border: ${({theme})=> theme.border};
margin-top: 14px;
border-radius: 20px;
box-shadow: ${({theme})=> theme.boxshadow};
padding: 20px 0px;
display: flex;
background: ${({theme})=> theme.body=='#F7F9FC' ? 'white' : theme.body};
justify-content: center;
align-items: center;
`
export default function Address() {
    const [arr1, setarr1] = useState([]);
    const { isDarkTheme } = useThemeContext()
    const { cryptoCurrencyOptions, customFilterForCrypto } = useAPIContextHook();
    const [copySuccess, setCopySuccess] = useState(false);
    const [currency, setCurrency] = useState(cryptoCurrencyOptions[0]);
    const [loader, setLoader] = useState(true);

    useEffect(() => {
        setLoader(true)
        setCopySuccess(false)
        axios.get(API_URLs.getSingleWallet(currency?.symbol), {
            headers: {
                'accept': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('jwt')}`
            }
        })
            .then((res) => {
                let wallet = [];
                wallet.push(res.data)
                setarr1(wallet);
                setLoader(false)
                setCopySuccess('');
            })
            .catch((error) => {
                setLoader(false)
            })
    }, [currency])

    const copyToClipBoard = useCallback(async copyMe => {
        try {
            await navigator.clipboard.writeText(copyMe);
            setCopySuccess(true);
        } catch (err) {
            setCopySuccess(false);
        }
    }, [])

    const columns = [
        {
            name: 'Address',
            wrap: true,
            minWidth: '280px',
            selector: (row) => <div className='d-flex gap-1 '>
                { !copySuccess ? <FaRegCopy onClick={() => copyToClipBoard(row?.wallet?.address)} role='button' title='copy' size={17} />
                 : <TiTick size={17}/>}
                <p className='mb-0 '>{row?.wallet?.address}</p>
            </div>
           
        },
        {
            name: 'Network',
            selector: (row) => <div className='d-flex fw-bold gap-1 align-items-center'>
                <ImgDiv>
                    <img src={row?.currency?.image} width='100%'/>
                </ImgDiv>
                {row?.currency?.name}
            </div>
        },
        {
            name: 'Status',
            selector: (row)=><div>
                {!row?.currency?.isBlocked ? 
                    <p className='mb-0 fw-bold text-success'>Active</p>
                    :<p className='mb-0 fw-bold text-danger'>Blocked</p>}
            </div>
        },
        {
            name: 'Created On',
            selector: (row) => <div>
                {new Date(row?.wallet?.createdAt).toDateString()}
            </div>
        }
    ]
    createTheme('solarized', {
        background: {
            default: `${isDarkTheme ? "#0c0c0c" : 'white'}`
        },
        action: {
            hover: 'rgba(0,0,0,.08)',
        },
        text: {
            secondary: `${isDarkTheme ? "white" : 'black'}`
        },
    });
    const customStyles = {
        header: {
            style: {
                fontSize: '20px',
                color: '#0B0E2C',
                fontFamily: 'Inter',
                fontStyle: 'normal',
                fontWeight: '600',
            },
        },
        headRow: {
            style: {
                fontFamily: 'Inter',
                fontStyle: 'normal',
                fontSize: '12px',
                ...( isDarkTheme ? {borderTop: '1px solid #302f30'}:
                { border: '1px solid lightgray'}),
                color: `${isDarkTheme ? "#e9e9e9":'#6F7182'}`,
                backgroundColor: `${isDarkTheme ? "#0c0d0d":"#F7F9FC"}`,
                textTransform: 'uppercase'
            },
        },
        rows: {
            style: {
                color: `${isDarkTheme && 'white'}`,
                backgroundColor: `${isDarkTheme && 'rgba(8, 9, 14, 0.96)'}`,
                borderTop: '1px solid lightgray',
                '&:not(:last-of-type)': {
                    borderBottom: 'none',
                },
            }
        },
        headCells: {
            style: {
                display: 'flex',
                alignItems: 'center'
            },
        },
        cells: {
            style: {
                display: 'flex',
                alignItems: 'center'
            },
        }
    }
    const styles = {
        control: (styles) => ({
            ...styles,
            background: 'transparent',
            borderRadius: '52px',
            width: '100%',
            height: '50px',
            color: 'white',
            boxShadow: `${isDarkTheme ? darkTheme.boxshadow : lightTheme.boxshadow}`,
            border: `${isDarkTheme ? darkTheme.border : lightTheme.border}`, "&:hover": {
                border: `${isDarkTheme ? darkTheme.border : lightTheme.border}`
            },
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: isDarkTheme && state.isHovered ? '#302F30' : `${({theme})=> theme.body}`,
            ':hover': {
              backgroundColor:  `${isDarkTheme ? '#302F30' : '#D9EDE7'}` ,
            },
            color: isDarkTheme && state.isSelected ? '#1476FF' : `${({theme})=> theme.text}`,
        }),
        indicatorSeparator: () => ({
            all: 'unset'
        }),
        indicatorsContainer: (styles) => ({
            ...styles, color: isDarkTheme ? 'white' : 'black', 'div:nth-child(2)': {
                color: isDarkTheme ? 'white' : lightTheme.text
            }
        }),
        menu: (styles) => ({
            ...styles, background: isDarkTheme ? 'rgb(27, 27, 28)' : 'white',
            color: isDarkTheme ? 'white' : lightTheme.text,
        })
    }

    return (
        <div>
            <div className='row m-0 d-flex flex-wrap justify-content-center align-items-center' >
                <Div className='col-md-5 card col-sm-12 gap-1'>
                    <small className='text-secondary'>Choose the network name to see the address</small>
                    <Select className="select"
                        name='buyCurrency'
                        id='buyCurrency'
                        options={cryptoCurrencyOptions}
                        filterOption={customFilterForCrypto}
                        styles={styles}
                        onChange={(selectedValues) => {
                            setCurrency(selectedValues)
                        }}
                        value={currency}
                    />
                </Div>
            </div>
            <Div className='col-md-12 card'>
                {
                    loader ? <DataLoader/>
                    :
                        arr1?.length > 0 ?
                            <DataTable
                                data={arr1}
                                customStyles={customStyles}
                                theme='solarized'
                                columns={columns} />
                            : <P>No Wallet found</P>
                }
            </Div>
        </div>
    );
}