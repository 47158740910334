// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/Graphic.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.about-us-qvani{
    background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) right top;
    background-repeat: no-repeat;
    padding: 25px;
}`, "",{"version":3,"sources":["webpack://./src/otherPages/About/index.css"],"names":[],"mappings":"AAAA;IACI,6DAAmD;IACnD,4BAA4B;IAC5B,aAAa;AACjB","sourcesContent":[".about-us-qvani{\n    background: url(../../assets/Graphic.png) right top;\n    background-repeat: no-repeat;\n    padding: 25px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
