import styled from "styled-components";

export const Heading = styled.p`
font-size: 25px;
font-weight: 700;
color: ${({theme})=>theme.text=='#121620' ? '#0B0E2C' : theme.text};
margin-bottom: 0px;
font-family: 'Inter';
`
export const ProfileHeading = styled.p`
font-size: 21px;
font-weight: 700;
padding: 1px 10px;
margin-bottom: 0px;
color: ${({theme})=>theme.text=='#121620' ? '#0B0E2C' : theme.text};
font-family: 'Inter';
`
export const ProfileImg = styled.img`
width: 110px;
height: 110px;
border: 1px solid white;
border-radius: 100%;
background: lightgray;
`
export const BlueButton = styled.button`
color: white;
border: none;
background-color: rgb(20, 118, 255);
font-size: 14px;
box-shadow: 0px 0px 10px rgba(2, 87, 251, 0.19);
align-items: center;
padding: 15px 20px;
text-decoration: none;
border-radius: 20px;
`
export const BlueButton1 = styled.button`
color: white;
border: none;
background-color: rgb(20, 118, 255);
font-size: 14px;
box-shadow: 0px 0px 10px rgba(2, 87, 251, 0.19);
align-items: center;
padding: 10px 15px;
text-decoration: none;
width: 70%;
border-radius: 20px;
`

export const OkButton = styled.button`
color: white;
border: none;
background-color: rgb(20, 118, 255);
font-size: 14px;
box-shadow: 0px 0px 10px rgba(2, 87, 251, 0.19);
align-items: center;
padding: 8px 22px;
text-decoration: none;
border-radius: 18px;
font-weight:600;
`
export const WithdrawButton = styled.button`
color: white;
border: none;
background-color: rgb(20, 118, 255);
font-size: 14px;
box-shadow: 0px 0px 10px rgba(2, 87, 251, 0.19);
align-items: center;
padding: 10px 76px;
text-decoration: none;
border-radius: 18px;
font-weight:600;
`
export const WhiteButton = styled.button`
box-shadow: 0px 0px 10px rgba(20, 20, 43, 0.04);
border: 1px solid #E9EAF3;
background: white;
margin: 1px 10px;
border-radius: 30px;
padding: 9px 15px;
font-size: 12px;
`
export const InputLabel = styled.label`
font-family: 'Inter';
font-style: normal;
font-weight: 700;
font-size: 14px;
color: ${({theme})=>theme.text=='#121620' ? '#303350' : theme.text};
`
export const Input = styled.input`
all: unset;
background: transparent;
border: ${({theme})=>theme.border};
box-shadow: ${({theme})=>theme.boxshadow};
border-radius: 52px;
outline: none;
font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 14px;
color: ${({theme})=>theme.text=='#121620' ? '#33343c' : theme.text};
cursor: initial;
padding: 7px 1px 7px 10px;
width: 100%;
:disabled{
    opacity: 0.8;
    color: ${({theme})=>theme.text=='#121620' ? '#6F7182' : theme.text};
}
`
export const Input1 = styled.input`
background: ${({theme})=> theme.background};
color: ${({theme})=> theme.text};
border: none;
outline: none;
font-family: 'Inter';
font-style: normal;
padding: 7px 1px 7px 10px;
font-weight: 400;
font-size: 14px;
:disabled{
    opacity: 0.8;
    color: #6F7182;
}
`
export const VerifyButton = styled.button`
border: none;
outline: none;
background: none;
font-size: 14px;
font-weight: 600;
color: #1476FF;
width: 5rem;
`
export const InputContainer = styled.div`
border-radius: 52px;
width: 100%;
padding: 0px 0px 0px 16px;
display: flex;
justify-content: space-between;
border: none;
box-shadow: none;
`