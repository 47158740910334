import styled from "styled-components";

export const HeaderHeading = styled.h1`
    font-size: 2rem;
    font-weight: 700;
    font-style: Inter;
    font-style: normal;
    color: black;
`
export const Linkname = styled.p`
    text-decoration: none;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #0B0E2C;
`
export const Sendbutton = styled.button`
    background: #1476FF;
    color: white;
    border: 1px solid #1476FF;
    margin-top: 10px;
    width: 100%;
    border-radius: 20px;
    padding: 6px 1px;
`
export const Disputebutton = styled.button`
    border: 1px solid black;
    background: none;   
    font-family: 'Inter';
    font-style: normal;
    padding: 3px;
    border-radius: 10px;
    font-weight: 500;
    font-size: 14px;
    color: #0B0E2C;
`
export const DisputeLabel = styled.label`
    font-size: 18px;
    font-weight: 500;
`
export const DisputeInput = styled.input`
    margin-top: 5px;
    border: 1px solid #E9EAF3;
    box-shadow: ${({theme})=> theme.boxshadow};
    width: 100%;
    padding: 7px 9px;
    border-radius: 20px;
    outline: none;
`