import styled from "styled-components";

export const Heading = styled.h2`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
`

export const Heading3 = styled.h3`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    margin: 10px;
    font-size: 32px;
    color: #303350;
`

export const WarningBox = styled.div`
    background: #FFFCF5;
    border: 1px solid #FEEECD;
    border-radius: 20px;
`

export const Text = styled.p`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
`

export const Card = styled.div`
    background: ${({theme})=>theme.body=='#F7F9FC' ? 'white' : theme.body};
    box-shadow: ${({theme})=>theme.boxshadow};
    border: ${({theme})=>theme.border};
    border-radius: 30px;
`
export const DisputeCard = styled.div`
background: red;
border: 1px solid #E9EAF3;
box-shadow: 0px 2px 10px rgba(25, 93, 194, 0.07);
border-radius: 30px;
color: white;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
padding: 13px;
font-size: 20px;
font-weight: 700;
`
export const CompleteCard = styled.div`
background: #1476FF;
border: 1px solid #E9EAF3;
box-shadow: 0px 2px 10px rgba(25, 93, 194, 0.07);
border-radius: 30px;
color: white;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
padding: 13px;
font-size: 20px;
font-weight: 700;
`

export const CardHeading = styled.h4`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    color: ${({theme})=>theme.text};
`
export const CardSubHeading = styled.p`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: ${({theme})=>theme.text=='#121620' ? '#303350' : theme.text};

`

export const CardText = styled.p`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #303350;
`
export const Strong = styled.span`
    font-weight: 700;
`

export const CardButton = styled.button`
    background: #1476FF;
    border-radius: 44px;
    outline: none;
    color: white;
    border: 1px solid #1476FF;
    transition: all 0.2s;
    :hover:not(:disabled){
        opacity: 0.9;
    }
    :active{
        transform: scale(0.9);
    }
`
export const ChatButton = styled.button`
    position: fixed;
    top: 83%;
    left: 66%;
    background: rgb(20, 118, 255);
    padding: 13px;
    border: none;
    border-radius: 50px;
`
export const Timer = styled.p`
border: 3px dotted #1476FF;
    padding: 1px 5px;
    border-radius: 20px;
    color: #1476FF;
`
export const PRButton = styled.button`
    background: #1476FF;
    border-radius: 44px;
    outline: none;
    color: white;
    font-weight: 600;
    border: 1px solid #1476FF;
    width: 9rem;
    transition: all 0.2s;
    :hover:not(:disabled){
        opacity: 0.9;
    }
    :active{
        transform: scale(0.9);
    }
`
export const ButtonText = styled.p`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    color: #FFFFFF;
`

export const Span3 = styled.span`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #FFFFFF;
`

export const Span6 = styled.span`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #FFFFFF;
`

export const HorizontalLine = styled.div`
    height: 1px;
    background: #E9EAF3;
    margin: 1rem 0;
    width: 100%;
`

export const CancelButton = styled.button`
    background: ${({theme})=>theme.body=='#F7F9FC' ? '#FCFDFF' : theme.body};
    border: 1px solid #FF5A65;
    border-radius: 44px;
    color: ${({theme})=>theme.text=='#121620' ? '#303350' : theme.text};
    transition: all 0.2s;
    :hover{
        background: #FF5A65;
        color: #FCFDFF;
    }
    :active{
        transform: scale(0.9);
    }
`

export const CardFooterText = styled.p`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #6F7182;
`

export const Small = styled.p`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: #303350;
`

export const Heading6 = styled.h6`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #1476FF;
`

export const Button = styled.button`
    background: #FCFDFF;
    border: 1px solid #6F7182;
    border-radius: 44px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #6F7182;
    transition: all 0.2s;
    :hover{
        background: #6F7182;
        color: #FCFDFF;
    }
    :active{
        transform: scale(0.9);
    }
`

export const BlueText = styled.p`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #1476FF;
`

export const PersonChat = styled.div`
    background: rgb(146 174 216 / 20%);
    border-radius: 10px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
`

export const MyChat = styled.div`
    background: rgb(146 174 216 / 20%);
    border-radius: 10px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
`

export const SendButton = styled.button`
    background: #1476FF;
    box-shadow: 0px 4px 10px rgba(2, 87, 251, 0.19);
    border-radius: 100%;
    outline: none;
    border: 1px solid #1476FF;
    color: white;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s;
    :hover{
        opacity: 0.9;
    }
    :active{
        transform: scale(0.9);
    }
`
export const Input = styled.input`
    background: transparent;
    border: ${({theme})=> theme.border};
    border-radius: 50px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    color: ${({theme})=>theme.text};
    width: 80%;
    font-size: 14px;
    outline: none;
    :placeholder{
        color: white;
    }
    :focus{
        box-shadow: ${({theme})=> theme.boxshadow};
    }
`
export const StatusText = styled.span`
    color: #6F7182;
`
export const DisputeLabel = styled.label`
    font-size: 18px;
    font-weight: 500;
`
export const DisputeInput = styled.textarea`
    box-shadow: ${({theme})=> theme.boxshadow};
    border: ${({theme})=> theme.border};
    width: 100%;
    height: 6.5rem;
    padding: 7px 9px;
    border-radius: 20px;
    outline: none;
`
export const Label = styled.label`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    margin-top: 5px;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.06em;
    text-transform: uppercase;
    color: #6F7182;
`
export const TextSpan = styled.span`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #6F7182;
`
export const FeedInput = styled.textarea`
    border: ${({theme})=> theme.border};
    box-shadow: ${({theme})=> theme.boxshadow};
    color: ${({theme})=> theme.text};
    width: 100%;
    height: 8rem;
    background: transparent;
    outline: none;
    border-radius: 20px;
    padding: 10px;
`
export const ImgDiv = styled.div`
    border:  ${({ theme }) => theme.border};
    display: flex;
    border-radius: 23px;
    padding: 7px 1px;
    justify-content: space-around;
    margin-top: 10px;
`
export const DisputeButton = styled.button`
    border: 2px solid #1476FF;
    background-color: #1476FF;
    color: white;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    padding: 3px 25px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;

    &:disabled{
        opacity: 2px;
    }
`
export const ConfirmP = styled.p`
font-size: 14px;
color: ${({theme})=>theme.text};
margin-bottom: 0px;
`
export const Ins = styled.small`
font-size: 13px;
color: ${({theme})=>theme.text};
`
export const DivConfirm = styled.div`
border: 2px solid biege;
background-color: biege;
padding: 5px;
margin-bottom: 10px;
`
export const UploadLabel = styled.label`
padding: 1px 6px;
border-radius: 10px;
background: #1476FF;
color: white;
font-weight: 600;
:hover:not(:disabled){
    opacity: 0.9;
}
:active{
    transform: scale(0.9);
}
`