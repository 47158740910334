import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import Modal from 'react-bootstrap/Modal';
import OTPInput from "otp-input-react";
import { useAuthContextHook } from '../../Context/AuthContext';
import { ActiveButton, ProfileHeading } from '../Profile/StyledComponents'
import { API_URLs } from '../../Utils/ApiUrls';
import Loader from '../../Component/Loader/Loader';
import { Para } from '../Auth/Login/LoginOtp';
import ButtonLoader from '../../Component/Loader/ButtonLoader';
import styled from 'styled-components';
import { useThemeContext } from '../../Context/ThemeContext';
import CustomModal from '../../hooks/CustomModal';

const RecommendButton = styled.span`
font-size: 12px;
color: #59067c;
border: 1px solid lightgray;
background: #f4f0f0;
padding: 3px 5px;
border-radius: 5px;
`
const option = [
    {
        type: '2FA',
        name: 'Google Authenticator',
        subhead: 'Random time-bound passcode generated by the app.',
        setting: [
            {
                name: 'Login',
                answer: true,
            },
            {
                name: 'Sending Crypto',
                answer: false,
            },
            {
                name: 'Releasing Crypto',
                answer: true,
            }
        ]
    },
    {
        type: 'Email',
        name: 'Email',
        subhead: 'A unique time-bound code sent via email'
    }
]
export default function Security() {
    const { userDetails, getUserDetails } = useAuthContextHook();
    const {isDarkTheme} = useThemeContext();
    const [show, setShow] = useState(false);
    const [loader, setLoader] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [otpValue, setotpValue] = useState('');
    const [token, setToken] = useState('');
    const Ref = useRef(null);
    const [timer, setTimer] = useState('00:00');

    const [showModal, setShowModal] = useState(false);
    const [modalData, setModalData] = useState(null);
    const [navigateLink, setNavigateLink] = useState('');
    const handleShowModal = (data) => {
        setModalData(data);
        setShowModal(true);
    };
    const handleCloseModal = () => {
        setShowModal(false)
    };

    const getTimeRemaining = (e) => {
        const total = Date.parse(e) - Date.parse(new Date());
        const seconds = Math.floor((total / 1000) % 60);
        const minutes = Math.floor((total / 1000 / 60) % 60);
        const hours = Math.floor((total / 1000 / 60 / 60) % 24);
        return {
            total, hours, minutes, seconds
        };
    }

    const startTimer = (e) => {
        let { total, minutes, seconds }
            = getTimeRemaining(e);
        if (total >= 0) {
            setTimer(
                (minutes > 9 ? minutes : '0' + minutes) + ':'
                + (seconds > 9 ? seconds : '0' + seconds)
            )
        }
    }

    const clearTimer = (e) => {
        setTimer('10:00');
        if (Ref.current) clearInterval(Ref.current);
        const id = setInterval(() => {
            startTimer(e);
        }, 1000)
        Ref.current = id;
    }

    const getDeadTime = () => {
        let deadline = new Date();
        deadline.setSeconds(deadline.getSeconds() + 600);
        return deadline;
    }

    useEffect(() => {
        clearTimer(getDeadTime());
    }, []);

    const onClickReset = () => {
        clearTimer(getDeadTime());
    }

    const handle2FA = async (e) => {
        e.preventDefault();
        setLoader(true);
        try {
            let res = await axios.post(API_URLs.twoFa, {}, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('jwt')}`
                }
            })
            setLoader(false);
            setToken(res.data?.response?.token)
            handleShow();
            onClickReset();
        } catch (error) {
            setLoader(false);
            handleShowModal(error?.response.data.message ? error?.response?.data?.message : "Verification Code sent succesfully");
        }
    }

    const verifyTwoFA = async (e) => {
        e.preventDefault()
        setotpValue("")
        setLoader(true);
        try {
            let res = await axios.post(API_URLs.verifyTwoFaToggle, {
                token: token,
                otp: otpValue
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('jwt')}`
                }
            })
            getUserDetails()
            setLoader(false);
            handleClose();
            handleShowModal(res.data.msg ? res?.data?.msg : "2FA Created successfully")
        } catch (error) {
            setLoader(false);
            handleShowModal(error.response?.data?.message ? error.response?.data?.message : "Two FA Failed")
        }
    }


    return (
        <div
        style={{
            ...(
                isDarkTheme ? 
                {
                    border: '1px solid rgb(35 36 36)',
                    boxshadow: '5px 6px 10px rgb(35 36 36)',
                }:
                {
                    border: '2px solid #dee2e6',
                    boxShadow: '0px 0px 2px #dee2e6'
                }
            )   
        }} 
        className='mt-3'>
            {
                loader && <Loader />
            }
            <div className='row m-0'>
                <div className=' col-md-12 pt-2'>
                    <ProfileHeading>Two-Factor Authentication (2FA) Settings</ProfileHeading>
                </div>
            </div>
            <div className='row m-0'>
                <div className='col-md-12 my-4 d-flex flex-column'>
                    {
                        option.map((data, index) => {
                            return (
                               <div key={index}>
                                    <div className='d-flex px-2 mt-2 flex-wrap justify-content-between align-items-center'>
                                        <div className='mx-4'>
                                            <p className='mb-0 fw-bold'>{data.name}{data.type === '2FA' ? <RecommendButton>Recommended</RecommendButton> : ''}</p>
                                            <small className='text-secondary'>{data.subhead}</small>
                                        </div>
                                        <p className='mb-0' onClick={handle2FA}>
                                            {
                                                data.type === '2FA' &&
                                                <>
                                                    {
                                                        userDetails?.user?.twoFa ?
                                                            <span className='text-success fw-bold'>Activated</span> :
                                                            <span className='text-primary fw-bold'>Activate now</span>
                                                    }
                                                </>
                                            }
                                            {
                                                data.type === 'Email' &&
                                                <>
                                                    {
                                                        !userDetails?.user?.twoFa ?
                                                            <span className='text-success fw-bold'>Activated</span> :
                                                            <span className='text-primary fw-bold'>Activate now</span>
                                                    }
                                                </>
                                            }
                                        </p>
                                    </div>
                                </div>
                            );
                        })
                    }
                </div>
            </div>

            <Modal show={show} style={{ background: 'transparent' }} onHide={handleClose} backdrop="static" keyboard={false}>
                <Modal.Header style={{ background: isDarkTheme ? '#0c0c0c' : 'white'}} closeButton>
                    <Modal.Title>
                        <ProfileHeading className='m-0'>Set-Up 2FA</ProfileHeading>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ background: isDarkTheme ? '#0c0c0c' : 'white'}}>
                    <form className='py-3' onSubmit={verifyTwoFA}>
                        <div className='FormDiv d-flex m-0 flex-column'>
                            <Para className='text-center'>We have send successfully verification code to your email address</Para>
                            <OTPInput
                                className='otp-input-box'
                                value={otpValue}
                                onChange={setotpValue}
                                autoFocus
                                OTPLength={4}
                                otpType="number"
                                disabled={false} secure
                            />
                        </div>

                        <Para className='text-center pt-3' >Didn’t Get Verification Code? <span
                            className='text-primary'
                            onClick={async (e) => {
                                if (timer === '00:00' && loader === false) {
                                    e.preventDefault();
                                    handle2FA(e)
                                } else {
                                    toast.warn(`You can resend after ${timer}`)
                                }
                            }}
                        >{
                                loader === true ? "Sending Verification Code..." : "Resend Verification Code"
                            }</span></Para>

                        {timer === '00:00' ?
                            <Para className='text-center'>Verification Code has been expired.</Para> :
                            <Para className='text-center'>Verification Code will expire in {timer} minutes</Para>
                        }

                        <div className='d-flex justify-content-center mt-0'>
                            <ActiveButton className='Button d-flex justify-content-center mt-0' disabled={loader || otpValue.length !== 4} type="submit" >
                                {
                                    loader ? <> <ButtonLoader /> <span>Submit</span></> :
                                        "Submit"
                                }
                            </ActiveButton>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>

            {showModal && (<CustomModal
                show={showModal}
                handleClose={handleCloseModal}
                navigateLink={navigateLink}
                data={modalData}
            />)}
        </div>
    )
}
