import React from "react";
import { useAuthContextHook } from "../../../src/Context/AuthContext";
import { AiOutlineArrowRight } from 'react-icons/ai'
import { useNavigate } from "react-router-dom";




const CryptoTransactionSingle = ({ singleTransDetail, handleClose }) => {

    const navigate = useNavigate();
    const { userDetails } = useAuthContextHook();
    return (
        <div className="px-4 py-2">
            <div className="d-flex flex-column gap-3">
                <div className="d-flex flex-wrap gap-2">
                    <div className="fw-bold w-25">Transaction ID : </div>
                    <div className="text-break">{singleTransDetail?.transactionId}
                        {
                            singleTransDetail?.explorerUrl && 
                            <a target="_blank" href={singleTransDetail?.explorerUrl}><AiOutlineArrowRight/></a>
                        }
                        {singleTransDetail.offertnxIds &&
                            <AiOutlineArrowRight size={17} color="blue" role="button"
                                onClick={() => navigate(`/trade/${singleTransDetail.offertnxIds.offer_id}/${singleTransDetail.offertnxIds.txn_hash}`)} />}
                    </div>
                </div>
                <div className="d-flex flex-wrap gap-2">
                    <div className="fw-bold w-25">Sender Name : </div>
                    <div className="text-break text-capitalize">{singleTransDetail?.fromUserId?.nickNames}</div>
                </div>
                <div className="d-flex flex-wrap gap-2">
                    <div className="fw-bold w-25">Sender Wallet Address : </div>
                    <div className="text-break text-capitalize">{singleTransDetail?.fromWalletId?.address}</div>
                </div>
                {singleTransDetail?.toUserId?.nickNames && <div className="d-flex flex-wrap gap-2">
                    <div className="fw-bold w-25">Receiver Name : </div>
                    <div className="text-break text-capitalize">{singleTransDetail?.toUserId?.nickNames}</div>
                </div>}
                <div className="d-flex flex-wrap gap-2">
                    <div className="fw-bold w-25">Receiver Wallet Address : </div>
                    <div className="text-break text-capitalize">{singleTransDetail?.receiverAddress ? singleTransDetail?.receiverAddress : singleTransDetail?.toWalletId?.address}</div>
                </div>
                <div className="d-flex flex-wrap gap-2">
                    <div className="fw-bold w-25">Currency : </div>
                    <div className="text-break">{singleTransDetail?.currency}</div>
                </div>
                <div className="d-flex flex-wrap gap-2">
                    <div className="fw-bold w-25">{singleTransDetail?.toUserId?.id === userDetails?.user?._id ? "Credited Amount :" : "Debited Amount :"} </div>
                    <div className="text-break">{singleTransDetail?.toUserId?._id === userDetails?.user?._id ? singleTransDetail?.receivedAmount : <>{singleTransDetail?.transactionAmount + singleTransDetail?.adminFee}</>} {singleTransDetail?.currency}</div>
                </div>
                <div className="d-flex flex-wrap gap-2">
                    <div className="fw-bold w-25">Amount (USD) : </div>
                    <div className="text-break">{`$${Number(singleTransDetail?.convertinUSD).toFixed(3)}`}</div>
                </div>
                {
                    !(singleTransDetail?.toUserId?.id === userDetails?.user?._id) &&
                    <>    
                        <div className="d-flex flex-wrap gap-2">
                            <div className="fw-bold w-25">Admin Fee : </div>
                            <div className="text-break">{singleTransDetail?.adminFee} {singleTransDetail?.currency}</div>
                        </div>

                        <div className="d-flex flex-wrap gap-2">
                            <div className="fw-bold w-25">Gas Fee : </div>
                            <div className="text-break">{singleTransDetail?.gasfee} {singleTransDetail?.currency === 'USDC' || singleTransDetail?.currency === 'USDT' ? 'ETH' : singleTransDetail?.currency }</div>
                        </div>
                    </> 
                }
                <div className="d-flex flex-wrap gap-2">
                    <div className="fw-bold w-25">Type : </div>
                    <div className="text-break">
                        {
                            singleTransDetail?.toUserId?.id === userDetails?.user?._id ? <div className="text-success fw-bold">Credited</div> : <div className='text-danger fw-bold'>Debited</div>
                        }
                    </div>
                </div>

                <div className="d-flex flex-wrap gap-2">
                    <div className="fw-bold w-25">Transaction Date : </div>
                    <div className="text-break">{new Date(singleTransDetail?.updatedAt).toDateString()}</div>
                </div>
            </div>
        </div>
    );
};

export default CryptoTransactionSingle;
