import axios from 'axios';
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react'
import { API_URLs } from '../Utils/ApiUrls';
import { useAuthContextHook } from './AuthContext';
export const ApiContextHook = createContext();

export const useAPIContextHook = () => {
    return useContext(ApiContextHook);
}

export default function ApiContext({ children }) {
    const {IsLogedIn} = useAuthContextHook();
    const [country, setCountry] = useState([]);
    const [fiatCurrenciesList, setFiatCurrenciesList] = useState([]);
    const [cryptoCurrenciesList, setCryptoCurrenciesList] = useState([]);
    const [cryptoCurrencyOptions, setCryptoCurrencyOptions] = useState([]);
    const [fiatCurrencyOptions, setFiatCurrencyOptions] = useState([]);
    const [countryOptions, setCountryOptions] = useState([]);
    const [paymentMethodList, setPaymentMethodList] = useState([]);
    const [phoneCode, setPhoneCode] = useState([]);

    const getPhoneCodeList= useCallback(async ()=>{
        await axios.get(API_URLs.phoneCodes)
        .then((res)=>{
            let arr =[];
            for(let key in res.data){
                let single = res.data[key]
                arr.push({
                    label: `${single.name} +${single.phone_code}`,
                    value: `+${single.phone_code}`,
                })
            }
        
            setPhoneCode(arr);
        })
        .catch((error)=>{
        })
    },[])

    const fetchCountry = useCallback(async () => {
        try {
            let res = await axios.get(API_URLs.getAllCountry)
            setCountry(res.data)
            let reFactoredCountries = res.data?.length > 0 && res.data.map((countryObject) => {
                return {
                    ...countryObject,
                    value: countryObject._id,
                    label: <div className="d-flex align-items-center gap-2">
                        {`${countryObject.name} (${countryObject.iso2})`}
                    </div>,
                    name: countryObject?.name,
                }
            })

            setCountryOptions(reFactoredCountries || [])
        } catch (error) {
        
        }

    }, [])

    const fetchFiatCurrency = useCallback(async () => { 
        try {
            let res = await axios.get(API_URLs.getFiatCurrencies,{
                headers: {
                    accept: 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('jwt')}`
                }
            })
        
            setFiatCurrenciesList(res.data)

            let reFactoredFiatCurrencies = res.data?.length > 0 && res.data.map((fiatObject) => {
                return {
                    ...fiatObject,
                    value: fiatObject._id,
                    label: <div className="d-flex align-items-center gap-2">
                        {`${fiatObject.symbol}`}
                    </div>,
                    name: fiatObject?.symbol,
                    image: fiatObject?.image
                }
            })

            setFiatCurrencyOptions(reFactoredFiatCurrencies || [])
        } catch (error) {
        
        }
    }, [])

    const fetchCryptoCurrency = useCallback(async () => {
        try {
            let res = await axios.get(API_URLs.getCryptoCurrencies,{
                headers: {
                    accept: 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('jwt')}`
                }
            })
        
            setCryptoCurrenciesList(res?.data)

            let reFactoredCryptoCurrencies = res.data?.length > 0 && res.data.map((currencyObject) => {
                return {
                    ...currencyObject,
                    value: currencyObject._id,
                    label: <div className="d-flex align-items-center gap-2">
                        <img width='25px' height='25px' src={currencyObject.image} alt="tether" />
                        {currencyObject.name}
                    </div>
                }
            })

            setCryptoCurrencyOptions(reFactoredCryptoCurrencies || [])
        } catch (error) {
        
        }
    }, [])

    const fetchBankAccounts = useCallback(async () => {
        try {
            let res = await axios.get(API_URLs.bankPost, {
                headers: {
                    'accept': 'application/json',
                    "Authorization": `Bearer ${localStorage.getItem('jwt')}`
                }
            })
            return res.data?.banks
        } catch (error) {
        
        }
    }, [])

    const fetchPaymentMethods = useCallback(async ()=>{
        try{
            let res = await axios.get(API_URLs.paymentMethodList,{
                headers: {
                    accept: 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('jwt')}`
                }
            })
        
            setPaymentMethodList(res?.data?.res_obj)
        } catch(error){
        
        }
    },[])

    const fetchCardDetail = useCallback(async () => {
        try {
            let res = await axios.get(API_URLs.card, {
                headers: {
                    'accept': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('jwt')}`
                }
            })
            return res.data?.cards
        } catch (error) {
        
        }
    }, [])

    const fetchUpiId = useCallback(async () => {
        try {
            let res = await axios.get(API_URLs.upi, {
                headers: {
                    'accept': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('jwt')}`
                }
            })
            return res?.data?.upiids
        } catch (error) {
        
        }
    },[])

    const fetchPaypal = useCallback(async () => {
        try {
            let res = await axios.get(API_URLs.paypal, {
                headers: {
                    accept: 'appplication/json',
                    Authorization: `Bearer ${localStorage.getItem('jwt')}`
                }
            })
            return res?.data?.response?.results
        }
        catch (error) {
        
        }
    }, [])

    const customFilterForCrypto = (option, searchText) => {
        return String(option.data?.name).toLowerCase().includes(String(searchText).toLowerCase());
    }

    const customFilterForCountry = (option, searchText) => {
        return String(option.data?.name).toLowerCase().includes(String(searchText).toLowerCase()) ||
        String(option.data?.iso2).toLowerCase().includes(String(searchText).toLowerCase()) ||
        String(option.data?.phone_code).toLowerCase().includes(String(searchText).toLowerCase())
    }

    const fetchStates = useCallback(async (countryID) => {
        try {
            let res = await axios.get(API_URLs.getAllStatesByCountry(countryID))
             return res.data
        } catch (error) {
            return []
        }
    }, [])

    const fetchCities = useCallback(async (countryID, stateCode) => {
        try {
            let res = await axios.get(API_URLs.getAllCitiesByState(countryID, stateCode))
            return res.data
        } catch (error) {
            return []
        }
    }, [])

    const sendNotification = useCallback((id, title, body,type, ...args) => {
        let params = args
        let newObject = {
            notificationType: type,
            notification: {
                title: title,
                body: body
            }
        }
        for (const iterator of params) {
            newObject = {
                ...newObject,
                ...iterator
            } 
        }
        try {
            axios.post(API_URLs.sendNotification(id), newObject, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('jwt')}`
                }
            })
        } catch (error) {
        
        }
    }, [])

    const getNotifications = useCallback(async (jwt, limit, ge) => {
        try {
            await axios.post(API_URLs.getNotifications(), {
                headers: {
                    Authorization: jwt
                }
            })
        } catch (error) {
        
        }
    }, [])

    useEffect(()=>{
        fetchCountry(); 
        fetchCryptoCurrency(); 
        getPhoneCodeList();
        fetchFiatCurrency();
    },[fetchCountry, fetchCryptoCurrency, getPhoneCodeList])

    const exportableData = {
        country,
        fiatCurrenciesList,
        fiatCurrencyOptions,
        cryptoCurrenciesList,
        cryptoCurrencyOptions,
        countryOptions,
        fetchPaymentMethods,
        customFilterForCountry,
        customFilterForCrypto,
        fetchStates,
        fetchCities,
        sendNotification,
        getNotifications,
        fetchPaypal,
        phoneCode,
        fetchBankAccounts,
        fetchCardDetail,
        fetchUpiId,
        paymentMethodList,
    }

    return (
        <ApiContextHook.Provider value={exportableData} >
            {children}
        </ApiContextHook.Provider>
    )
}
