import React, { useEffect, useLayoutEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import { useThemeContext } from '../../Context/ThemeContext';
import { useAuthContextHook } from '../../Context/AuthContext';
import { InputLabel } from '../Profile/StyledComponents';
import {Button} from '../Wallet/StyledComponent'
import styled from 'styled-components';
import Filters from './Filters';
import List from './List';
import { useAPIContextHook } from '../../Context/ApiContext';
import { useParams, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import axios from 'axios';
import { API_URLs } from '../../Utils/ApiUrls';

const Label = styled.label`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 0.9rem;
`
const Heading = styled.p`
font-family: 'Inter';
font-style: normal;
font-weight: 700;
margin-bottom: 0px;
font-size:20px;
line-height: 34px;
color: #0B0E2C;
text-transform: capitalize;
word-break: break-all;
`
const ListCard = styled.div`
  padding: 16px 0px;
  background: ${({theme})=> theme.body=='#F7F9FC' ? 'white' : theme.body};
  margin-top: 16px;
  border-radius: 26px;
  border: ${({theme})=> theme.border};
  box-shadow: ${({theme})=> theme.boxshadow};
`
export default function Sell() {
  const { isDarkTheme, handleSetNavbarHeading } = useThemeContext();
  const { userDetails } = useAuthContextHook();
  const navigate = useNavigate();
  const { cryptoCurrenciesList, fiatCurrencyOptions, fetchFiatCurrency} = useAPIContextHook();
  const params = useParams();
  const { currency } = params;

  const [offersList, setOffersList] = useState([]);
  const [loader, setLoader] = useState(true);
  const [currencyDetail, setCurrencyDetail] = useState({});

  const formik = useFormik({
    initialValues: {
      buyCurrency: '',
      payVia: '',
      spendAmount: '',
      preferredCurrency: '',
      offerLocation: "",
      offerOwnerLocation: '',
      tenMinActive: false
    },
    onSubmit: (values) => {
      fetchOffers(values)
    }
  })

  const fetchOffers = async (values) => {
    try {
      setLoader(true)
      let res = await axios.get(API_URLs.getOfferForCurrency(currency, values.payVia?.value, values.spendAmount, values.preferredCurrency?.value, values.offerLocation?.value, values.offerOwnerLocation.value, formik.values.tenMinActive, 'buy'), {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('jwt')}`
        }
      })

      setOffersList(res?.data?.res_obj?.result)
      setLoader(false)
    } catch (error) {
      setLoader(false)
    }
  }

  useEffect(() => {
    fetchOffers(formik.values)
  }, [currency, formik.values.tenMinActive])


  
  useLayoutEffect(() => {
  
    let cryptoDetail = cryptoCurrenciesList.filter((curr) => {
      
      return curr._id === currency
    })

    setCurrencyDetail(cryptoDetail[0])
  }, [cryptoCurrenciesList, currency])

  useEffect(() => {
    handleSetNavbarHeading("Sell Crypto")
    if(fiatCurrencyOptions?.length === 0){
      fetchFiatCurrency();
    }
  }, [])

  return (
    <div className='p-3 container'>
        <div className="lower">
          <Filters
            currency={currency}
            formik={formik}
          />
          <ListCard>
            <div className='d-flex flex-wrap mx-2 justify-content-between align-items-center'>
              <Heading className={isDarkTheme && 'text-light'} >Offers</Heading>
              <div className="d-flex align-items-center lasttenminutefortour justify-content-end gap-2">
                  <input
                      className='mx-1'
                      name='tenMinActive'
                      id='tenMinActive'
                      value={!formik.values.tenMinActive}
                      checked={formik.values.tenMinActive}
                      onChange={formik.handleChange}
                      type='checkbox' />
                  <InputLabel htmlFor='tenMinActive' className='text-secondary'>Vendors active in last 10 min</InputLabel>
              </div>
            </div>
            <List offersList={offersList} loader={loader} currency={currencyDetail} />
          </ListCard>
        </div>
    </div>
  );
}
