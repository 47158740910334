// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.infobox {
    background: #EBFFFE;
    border-radius: 14px;
}

.stepcounterforcenter>div>div>div>div {
    display: flex !important;
    justify-content: center;
    align-items: center;
}
.accordion-button{
    padding: 5px;
}
.accordion-body{
    padding: 2px;
}
.ant-steps-item-tail{
    padding: 26px 0 0px !important;
}
.ant-steps-vertical{
    height: 36rem !important;
}

.blackmode-on > .ant-steps-vertical > .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
    color: white;
}
.blackmode-on > .ant-steps-vertical > .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
    color: grey;
}
`, "",{"version":3,"sources":["webpack://./src/pages/CreateOffer/CreateOffer.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,mBAAmB;AACvB;;AAEA;IACI,wBAAwB;IACxB,uBAAuB;IACvB,mBAAmB;AACvB;AACA;IACI,YAAY;AAChB;AACA;IACI,YAAY;AAChB;AACA;IACI,8BAA8B;AAClC;AACA;IACI,wBAAwB;AAC5B;;AAEA;IACI,YAAY;AAChB;AACA;IACI,WAAW;AACf","sourcesContent":[".infobox {\n    background: #EBFFFE;\n    border-radius: 14px;\n}\n\n.stepcounterforcenter>div>div>div>div {\n    display: flex !important;\n    justify-content: center;\n    align-items: center;\n}\n.accordion-button{\n    padding: 5px;\n}\n.accordion-body{\n    padding: 2px;\n}\n.ant-steps-item-tail{\n    padding: 26px 0 0px !important;\n}\n.ant-steps-vertical{\n    height: 36rem !important;\n}\n\n.blackmode-on > .ant-steps-vertical > .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {\n    color: white;\n}\n.blackmode-on > .ant-steps-vertical > .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {\n    color: grey;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
