import styled from "styled-components";

export const Div = styled.div`
height: 80%;
margin: 10px;
border: ${({theme})=> theme.border};
border-radius: 20px; 
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
`
export const Heading = styled.p`
font-size: 28px;
font-weight: 700;
margin-bottom: 0px;
padding: 10px;
`
export const Div1 = styled.div`
margin: 10px;
border: ${({theme})=> theme.border};
box-shadow: ${({theme})=> theme.boxshadow};
color: ${({theme})=> theme.text};
border-radius: 20px;
padding: 10px;
overflow: hidden;
`

export const P = styled.p`
margin-bottom: 0px;
font-weight: 600;
font-size: 12px;
color: gray;
`
export const Button = styled.button`
padding: 4px 20px;
background: #1476FF;
border-radius: 30px;
border: 1px solid #1476FF;
color: white;
margin: 3px 5px;
display: flex;
flex-direction: row;
align-items: center;
gap: 10px;
font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 13px;
`
export const Card = styled.div`
min-width: 230px;
border-radius: 15px;
`
export const CardHead = styled.div`
border-bottom: 1px solid #3c2f2f1c;
padding-bottom: 10px;
`
export const ImgDiv = styled.div`
width: 33px;
`
export const Name = styled.p`
color: blue;
font-size: 11px;
margin-bottom: 0px;
background: aliceblue;
padding: 4px;
border-radius: 10px;
font-weight: 600;
`
