import React, { useEffect, useState } from 'react'
import SumsubWebSdk from '@sumsub/websdk-react'
import { useAuthContextHook } from '../../Context/AuthContext';
import axios from 'axios'
import { API_URLs } from '../../Utils/ApiUrls'
import { Oval } from 'react-loader-spinner';

export default function Index() {
    const { userDetails } = useAuthContextHook();
    const [accessToken, setAccessToken] = useState('')
    const [loader, setLoader] = useState(false)
    const handleGetAccessToken = (kycType) => {
        setLoader(true)
        axios.post(API_URLs.getAccessToken, {
            kycType
        }, {
            headers: {
                accept: 'application/json',
                authorization: `Bearer ${localStorage.getItem('jwt')}`
            }
        })
            .then((res) => {
                setLoader(false)
                setAccessToken(res?.data?.token)
            })
            .catch((error) => {
                setLoader(false)
            })
    }

    useEffect(() => {
        if (userDetails.user.kycLevel === "Level 1") {
            handleGetAccessToken('basic-kyc-level')
        }
        else if (userDetails.user.kycLevel === "Level 2" || userDetails.user.POA === 'PENDING') {
            handleGetAccessToken('proof_of_address')
        }
    }, [])

    return (
        <div className='container'>
            <div className='row m-0 d-flex flex-column justify-content-center align-items-center'>
                {
                    loader ?
                        <div className="d-flex flex-column justify-content-center align-items-center">
                            <Oval
                                height={50}
                                width={50}
                                color='black'
                                secondaryColor='black'
                            />
                            <p>please wait..</p>
                        </div>
                        :
                        <SumsubWebSdk
                            accessToken={accessToken}
                            expirationHandler={() => Promise.resolve(accessToken)}
                            config={{
                                lang: "ru-RU",
                                email: userDetails?.user?.email,
                                phone: userDetails?.user?.mobile,
                                i18n: {
                                    document: {
                                        subTitles: {
                                            IDENTITY: "Upload a document that proves your identity"
                                        }
                                    }
                                },
                                onMessage: (type, payload) => {
                               // console.log("WebSDK onMessage", type, payload);
                                },
                                uiConf: {
                                    customCssStr:
                                        ":root {\n  --black: #000000;\n   --grey: #F5F5F5;\n  --grey-darker: #B2B2B2;\n  --border-color: #DBDBDB;\n}\n\np {\n  color: var(--black);\n  font-size: 16px;\n  line-height: 24px;\n}\n\nsection {\n  margin: 40px auto;\n}\n\ninput {\n  color: var(--black);\n  font-weight: 600;\n  outline: none;\n}\n\nsection.content {\n  background-color: var(--grey);\n  color: var(--black);\n  padding: 40px 40px 16px;\n  box-shadow: none;\n  border-radius: 6px;\n}\n\nbutton.submit,\nbutton.back {\n  text-transform: capitalize;\n  border-radius: 6px;\n  height: 48px;\n  padding: 0 30px;\n  font-size: 16px;\n  background-image: none !important;\n  transform: none !important;\n  box-shadow: none !important;\n  transition: all 0.2s linear;\n}\n\nbutton.submit {\n  min-width: 132px;\n  background: none;\n  background-color: var(--black);\n}\n\n.round-icon {\n  background-color: var(--black) !important;\n  background-image: none !important;\n}"
                                },
                                onError: (error) => {
                                    console.error("WebSDK onError", error);
                                }
                            }}
                            options={{ addViewportTag: false, adaptIframeHeight: true }}
                            onMessage={(type, payload) => {
                           // console.log("onMessage", type, payload);
                            }}
                            onError={(data) => console.log("onError", data)}
                        />
                }
            </div>
        </div>
    );
}