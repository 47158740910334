import React, {useEffect, useState} from 'react'
import Select from 'react-select'
import { useFormik } from 'formik';
import * as Yup from 'yup'
import { darkTheme, lightTheme } from "../../Theme/theme";
import { useThemeContext } from '../../Context/ThemeContext';
import styled from 'styled-components';
import { API_URLs } from '../../Utils/ApiUrls';
import axios from 'axios';
import PropTypes from 'prop-types';
import CustomModal from '../../hooks/CustomModal';

export const InputLabel = styled.label`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    color: #303350;
`
export const PrevButton = styled.button`
    border: 2px solid #1476FF;
    background-color: #1476FF;
    color: white;
    border-radius: 20px;
    padding: 3px 25px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
`
export const Input = styled.input`
    all: unset;
    background: transparent;
    border: ${({theme})=>theme.border};
    box-shadow: ${({theme})=>theme.boxshadow};
    border-radius: 52px;
    outline: none;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: ${({theme})=>theme.text};  
    :disabled{
        opacity: 0.8;
        color: #6F7182;
    }
`
const option1 = [
    {
        value: 'visa',
        label: 'visa'
    },
    {
        value: 'mastercard',
        label: 'mastercard'
    },
    {
        value: 'rupay',
        label: 'rupay'
    },
    {
        value: 'amex',
        label: 'amex'
    },
    {
        value: 'diners',
        label: 'diners'
    },
    {
        value: 'discover',
        label: 'discover'
    },
    {
        value: 'jcb',
        label: 'jcb'
    },
    {
        value: 'maestro',
        label: 'maestro'
    },
    {
        value: 'unionpay',
        label: 'unionpay'
    },
]

export default function Card({ handleClose, isCardEdit, singleCardDetails, handleSetRefresh }) {

    const { isDarkTheme } = useThemeContext();
    const [showModal, setShowModal] = useState(false);
    const [modalData, setModalData] = useState(null);
    const [navigateLink, setNavigateLink] = useState('');

    useEffect(() => {
        if (isCardEdit && singleCardDetails) {
            formik.setValues({
                cardHolderName: singleCardDetails.cardHolderName,
                cardType: option1.find(option => option.value === singleCardDetails.cardType) || '',
                cardNo: singleCardDetails.cardNo,
            });
        }
    }, [isCardEdit, singleCardDetails]);

    const handleShowModal = (data) => {
        setModalData(data);
        setShowModal(true);
    };
    const handleCloseModal = () => {
        setShowModal(false)
    };

    const handleAddCard = (values) => {
        axios.post(API_URLs.card, {
            cardHolderName: values.cardHolderName,
            cardType: values.cardType.value,
            cardNo: values.cardNo,
        }, {
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('jwt')}`
            }
        })
            .then((res) => {
                
                handleClose();
                handleShowModal(res.data.msg ? res.data.msg : 'Card Add Successfully!!');
                setNavigateLink('');
                handleSetRefresh();
            })
            .catch((error) => {
                setNavigateLink('');  
                handleShowModal(error?.response?.data?.message ? error?.response?.data?.message : "Some Error Occured");
            })
    }
    const handleEditCard = (values) => {
        axios.patch(API_URLs.updateCard(singleCardDetails?.id), {
            cardHolderName: values.cardHolderName,
            cardNo: values.cardNo,
            cardType: values.cardType.value
        }, {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${localStorage.getItem('jwt')}`
            }
        })
            .then((res) => {
                handleClose()
                handleShowModal(res?.msg ? res.msg : "Details Updated Succesfully");
                setNavigateLink('');
                handleSetRefresh()
            })
            .catch((error) => {
                handleShowModal(error.response?.data?.message ? error.response?.data?.message : "Some Error Occured");
                setNavigateLink('');
            })
    }

    const formik = useFormik({
        initialValues: {
            cardHolderName: '',
            cardType: "",
            cardNo: '',
        },
        validationSchema: Yup.object().shape({
            cardHolderName: Yup.string().required('Card Holder Name is Required'),
            cardNo: Yup.string().required('Card Number is Required'),
            cardType: Yup.object().shape({
                value: Yup.string().required('Card Type is Required')
            })
        }),
        onSubmit: (values) => {
            
            if (isCardEdit) {
                handleEditCard(values)
            } else {
                handleAddCard(values);
            }
        }
    })
    const styles = {
        control: (styles) => ({
            ...styles,
            background: 'transparent',
            borderRadius: '52px',
            width: '100%',
            height: '50px',
            color: 'white',
            boxShadow: `${isDarkTheme ? darkTheme.boxshadow : lightTheme.boxshadow}`,
            border: `${isDarkTheme ? darkTheme.border : lightTheme.border}`, "&:hover": {
                border: `${isDarkTheme ? darkTheme.border : lightTheme.border}`
            },
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: isDarkTheme && state.isHovered ? '#302F30' : `${({theme})=> theme.body}`,
            ':hover': {
              backgroundColor:  `${isDarkTheme ? '#302F30' : '#D9EDE7'}` ,
            },
            color: isDarkTheme && state.isSelected ? '#1476FF' : `${({theme})=> theme.text}`,
        }),
        indicatorSeparator: () => ({
            all: 'unset'
        }),
        indicatorsContainer: (styles) => ({
            ...styles, color: isDarkTheme ? 'white' : 'black', 'div:nth-child(2)': {
                color: isDarkTheme ? 'white' : lightTheme.text
            }
        }),
        menu: (styles) => ({
            ...styles, background: isDarkTheme ? 'rgb(27, 27, 28)' : 'white',
            color: isDarkTheme ? 'white' : lightTheme.text,
        })
    }

    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className='col-md-12'>
                    <InputLabel htmlFor='cardType'>Card Type<span className="text-danger"> *</span></InputLabel>
                    <Select options={option1}
                        styles={styles}
                        name='cardType'
                        id='cardType'
                        onChange={(selectedValues) => {
                            let event = { target: { name: 'cardType', value: selectedValues } }
                            formik.handleChange(event)
                        }}
                        onBlur={(selectedValues) => {
                            let event = { target: { name: 'cardType', value: selectedValues } }
                            formik.handleBlur(event)
                        }}
                        value={formik.values.cardType}
                    />
                    <small>
                        {formik.errors.cardType && formik.touched.cardType && (
                            <div className="input-feedback text-danger auth-error text-start">{String(formik.errors.cardType?.value).replace('.value', '')}</div>
                        )}
                    </small>
                </div>
                <div className='mt-3 d-flex flex-column'>
                    <InputLabel htmlFor='cardNo'>Card Number<span className="text-danger"> *</span></InputLabel>
                    <Input
                        name='cardNo'
                        id='cardNo'
                        placeholder='Enter '
                        className="px-3 py-2"
                        value={formik.values.cardNo}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                    <small>
                        {formik.errors.cardNo && formik.touched.cardNo && (
                            <div className="input-feedback text-danger auth-error text-start">{formik.errors.cardNo}</div>
                        )}
                    </small>
                </div>
                <div className='mt-3 d-flex flex-column'>
                    <InputLabel htmlFor='cardHolderName'>Card Holder Name<span className="text-danger"> *</span></InputLabel>
                    <Input
                        name='cardHolderName'
                        id='cardHolderName'
                        placeholder='Enter '
                        className="px-3 py-2"
                        value={formik.values.cardHolderName}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                    <small>
                        {formik.errors.cardHolderName && formik.touched.cardHolderName && (
                            <div className="input-feedback text-danger auth-error text-start">{formik.errors.cardHolderName}</div>
                        )}
                    </small>
                </div>
                <div className='mt-2 text-center'>
                    <PrevButton type='submit'>{isCardEdit ? "Update" : "Add"}</PrevButton>
                </div>
            </form>
            {showModal && (<CustomModal
                show={showModal}
                handleClose={handleCloseModal}
                navigateLink={navigateLink}
                data={modalData}
            />)}
        </>
    );
}
Card.propTypes = {
    isCardEdit: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleSetRefresh: PropTypes.func.isRequired,
    singleCardDetails: PropTypes.object.isRequired,
};