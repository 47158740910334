import axios from 'axios';
import React, { useCallback, useEffect, useState } from 'react'
import { API_URLs } from '../../Utils/ApiUrls';
import styled from 'styled-components';
import { useNavigate, useLocation } from 'react-router-dom';
import { AiFillWechat } from 'react-icons/ai'
import DataTable, {createTheme} from 'react-data-table-component';
import DataLoader from '../../Component/Loader/DataLoader';
import { useThemeContext } from '../../Context/ThemeContext';
import { TiTick } from 'react-icons/ti';
import { FaRegCopy } from 'react-icons/fa';

const P = styled.p`
font-size: 13px;
color: ${({theme})=>theme.text};
// font-weight: 600;
margin-bottom: 0px;
`
const Heading = styled.p`
font-weight: 700 !important;
font-size: 14px;
margin-bottom: 0px;
`
const Div = styled.div`
padding: 10px 0px;
margin-top: 2rem;
border-radius: 15px;
background: ${({theme})=> theme.body=='#F7F9FC' ? 'white' : theme.body};
border: ${({theme})=> theme.border};
box-shadow: ${({theme})=> theme.boxshadow};
`
export default function Index() {
    const [disputeList, setDisputesList] = useState([])
    const [disputeList1, setDisputesList1] = useState([])
    const {handleSetNavbarHeading, isDarkTheme} = useThemeContext();
    const [loader, setLoader] = useState(true)
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        handleSetNavbarHeading('My Disputes')
        setLoader(true)
        axios.get(API_URLs.tradeDispute + '/disputes', {
            headers: {
                'accept': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('jwt')}`
            }
        })
            .then((res) => {
                setLoader(false)
                
                setDisputesList(res.data);
            })
            .catch((error) => {
                setLoader(false)
                
            })
    }, [])

    useEffect(() => {
        setLoader(true)
        axios.get(API_URLs.dispute + '/disputes', {
            headers: {
                'accept': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('jwt')}`
            }
        })
            .then((res) => {
                setLoader(false)
                
                setDisputesList1(res.data);
            })
            .catch((error) => {
                setLoader(false)
            })
    }, [])
    
    createTheme('solarized', {
        background: {
            default: `${isDarkTheme ? "#0c0c0c" : 'white'}`
        },
        action: {
            hover: 'rgba(0,0,0,.08)',
        },
        text: {
            secondary: `${isDarkTheme ? "white" : 'black'}`
        },
    });

    const customStyles = {
        header: {
            style: {
                fontSize: '20px',
                color: '#0B0E2C',
                fontFamily: 'Inter',
                fontStyle: 'normal',
                fontWeight: '600',
            },
        },
        headRow: {
            style: {
                fontFamily: 'Inter',
                fontStyle: 'normal',
                fontSize: '12px',
                ...(isDarkTheme ? { borderTop: '1px solid #302f30' } :
                    { border: '1px solid lightgray' }),
                color: `${isDarkTheme ? "#e9e9e9" : '#6F7182'}`,
                backgroundColor: `${isDarkTheme ? "#0c0d0d" : "#F7F9FC"}`,
                textTransform: 'uppercase'
            },
        },
        rows: {
            style: {
                color: `${isDarkTheme && 'white'}`,
                backgroundColor: `${isDarkTheme && 'rgba(8, 9, 14, 0.96)'}`,
                borderTop: '1px solid lightgray',
                '&:not(:last-of-type)': {
                    borderBottom: 'none',
                },
                padding: "10px 0px"
            }
        },
        headCells: {
            style: {
                display: 'flex',
                alignItems: 'center'
            },
        },
        cells: {
            style: {
                display: 'flex',
                alignItems: 'center'
            },
        }
    }

    const [copySuccess, setCopySuccess] = useState(false);
    const [copiedAdd, setCopiedAdd] = useState('')

    const copyToClipBoard = useCallback(async copyMe => {
        try {
            await navigator.clipboard.writeText(copyMe);
            setCopiedAdd(copyMe);
            setCopySuccess(true);
        } catch (err) {
            setCopySuccess(false);
        }
    }, [])

    const columns = [
        {
            name: <Heading>Dispute Id</Heading>,
            selector: (row) => <div className='d-flex gap-1 '>
                { (copySuccess && copiedAdd === row?._id) ? <TiTick onMouseLeave={()=>setCopySuccess(false)} size={17}/> : <FaRegCopy onClick={() => copyToClipBoard(row?._id)} role='button' title='copy' size={17} /> }
                <P>{row?._id}</P>
            </div>,
            wrap: true
        },
        {
            name: <Heading>Query</Heading>,
            selector: row => {
                return <div className='d-flex flex-row'>
                    <P>{row.query}</P>
                </div>
            },
            wrap: true
        },
        {
            name: <Heading>Dispute Category</Heading>,
            selector: row => {
                return <div className='d-flex flex-row'>
                    <P>{row?.category?.disputeName}</P>
                </div>
            },
            wrap: true
        },
        {
            name: <Heading>Images</Heading>,
            selector: row => {
                return <div className='d-flex flex-row'>
                    {
                        row?.image ? <img src={row?.image} alt='' width='30px' height='30px' /> : 'no images'
                    }

                </div>
            },
            wrap: true
        },
        {
            name: <Heading>Status</Heading>,
            selector: row => {
                return <div className='d-flex flex-row'>
                    {
                        row.isAdminSolve ? <p className='fw-bold text-success mb-0'>Resolved</p> : <p className='fw-bold text-warning mb-0'>Pending</p>
                    }
                </div>
            },
            wrap: true
        },
        {
            name: <Heading>Solution</Heading>,
            selector: row => {
                return <div className='d-flex flex-row'>
                    <P>{row.solution ? row.solution : '..'}</P>
                </div>
            },
            wrap: true
        },
        {
            name: <Heading>Action</Heading>,
            selector: (row) => <div className='d-flex flex-row justify-content-center align-items-center gap-1' onClick={() => navigate(`/trade/${row.tradeId}/${row.initiateId}`)}>
                <span>View Chat</span> <AiFillWechat size={20} color='blue' />
            </div>
        }
    ]
    return (
        <div className='container'>
            <Div className='disputediv'>
                <DataTable
                    title={<Heading className='mx-2 mb-0'>My Disputes</Heading>}
                    columns={columns}
                    data={disputeList}
                    progressComponent={<div className='d-flex justicy-content-center py-5'>
                        <DataLoader />
                    </div>}
                    progressPending={loader}
                    customStyles={customStyles}
                    pagination
                    theme='solarized'
                />
            </Div>
        </div>
    );
}