import React from 'react'
import { Oval } from 'react-loader-spinner'
import { useThemeContext } from '../../Context/ThemeContext'

export default function DataLoader() {

    const { isDarkTheme } = useThemeContext()

    return (
        <div className='d-flex my-4 justify-content-center'>
            <Oval
            height={45}
            width={45}
            color={isDarkTheme ? '#3545EE' : 'black'}
            wrapperStyle={{}}
            wrapperclassName=""
            visible={true}
            ariaLabel='oval-loading'
            secondaryColor={isDarkTheme ? '#3545EE' : 'black'}
            strokeWidth={3}
            strokeWidthSecondary={2} />
        </div>
    )
}
