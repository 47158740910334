import React, { useState, useRef, Suspense, useEffect } from 'react'
import NavbarComponent from '../Component/Navbar/Navbar'
import SidebarComponent from '../Component/Sidebar/Sidebar'
import { useLocation, Link, Outlet, useNavigate } from 'react-router-dom'
import Loader from '../Component/Loader/Loader'
import Joyride, { STATUS } from "react-joyride";
import Error401 from '../pages/Error401'
import { useAuthContextHook } from '../Context/AuthContext'
import Footer from '../Component/Footer/Index'

const steps = [
    {
        target: ".editprofilebutton",
        content: <div className='text-secondary p-0'>
            <p className='fs-6 fw-bold mb-0'>Edit Profile</p>
            <p className='mb-0 fs-6'>From here you can save your profile information, also you can edit/update your profile.</p>
        </div>,
        placement: "right",
        isFixed: true,
        disableBeacon: true
    },
    {
        target: ".walletoption",
        content: <div className='text-secondary'>
            <p className='fs-6 fw-bold mb-0'>Wallets</p>
            <p className='mb-0 fs-6'>From here you can see your crypto wallet, once your KYC is done.</p>
        </div>,
        isFixed: true,
        placement: "auto"
    },
    {
        target: ".createofferoption",
        content: <div className='text-secondary'>
            <p className='fs-6 fw-bold mb-0'>Create Offer</p>
            <p className='mb-0 fs-6'>From here you can create a new offer for buy and sell the cryto-currency.</p>
        </div>,
        isFixed: true,
        placement: "auto"
    },
    {
        target: ".myofferoption",
        content: <div className='text-secondary'>
            <p className='fs-6 fw-bold mb-0'>My Offer</p>
            <p className='mb-0 fs-6'>From here you can see all the offers, that you're creating on the platform</p>
        </div>,
        isFixed: true,
        placement: "auto"
    },
    {
        target: ".activetradeoption",
        content: <div className='text-secondary'>
            <p className='fs-6 fw-bold mb-0'>Active Trade</p>
            <p className='mb-0 fs-6'>From here, you can see that how many of your offers are initiated by other users on the platform and also you can see that how many offer are there i.e initiated by you.</p>
        </div>,
        isFixed: true,
        placement: "auto"
    },
    {
        target: '.twofaoption',
        content: <div className='text-secondary'>
            <p className='fs-6 fw-bold mb-0'>Two Factor Authentication</p>
            <p className='mb-0 fs-6'>You can make this application more secure to you just by Turning ON the Two-Factor Authentication provided by Google Authenticator.</p>
        </div>,
        isFixed: true,
        placement: "auto"
    }
]
const walletStep = [
    {
        target: '.totalholdingboxfortour',
        content: <div className='text-secondary p-0'>
            <p className='fs-6 fw-bold mb-0'>Holdings</p>
            <p className='mb-0 fs-6'> These are the total holding of you wallet in BTC and also converted rate according to your prefered currency.</p>
        </div>,
        placement: "top",
        isFixed: true,
        disableBeacon: true,
    },
    {
        target: '.allwalletdetailsfortour',
        content: <div className='text-secondary p-0'>
            <p className='fs-6 fw-bold mb-0'>Wallets</p>
            <p className='mb-0 fs-6'> Here you can see all your crypto wallets if your kyc is verified. </p>
        </div>,
        placement: "top",
        isFixed: true,
        disableBeacon: true,
    },
    {
        target: '.sendcryptobuttonfortour',
        content: <div className='text-secondary p-0'>
            <p className='fs-6 fw-bold mb-0'>Send Crypto</p>
            <p className='mb-0 fs-6'>You can send your crypto to wallet to another user of qvani platform and to external wallet as well.</p>
        </div>,
        placement: "auto",
        isFixed: true,
    },
    {
        target: '.getcryptobuttonfortour',
        content: <div className='text-secondary p-0'>
            <p className='fs-6 fw-bold mb-0'>Receive Crypto</p>
            <p className='mb-0 fs-6'>If you want to receive the crypto from any resource like external wallet or from any user, you can simply copy your crypto wallet address from here.</p>
        </div>,
        placement: "auto",
        isFixed: true,
    },
    {
        target: '.convertbutton',
        content: <div className='text-secondary p-0'>
            <p className='fs-6 fw-bold mb-0'>Convert Your Crypto</p>
            <p className='mb-0 fs-6'>From here you can convert your crypto to any other crypto i.e. available on the platform. </p>
        </div>,
        placement: "auto",
        isFixed: true,
    },
    {
        target: '.moreoptionbutton',
        content: <div className='text-secondary p-0'>
            <p className='fs-6 fw-bold mb-0'>More Option</p>
            <p className='mb-0 fs-6'>From here, you can directly go to page to buy/sell the crypto</p>
        </div>,
        placement: 'auto',
        isFixed: true
    },
    {
        target: '.transactionlistfortour',
        content: <div>
            <p className='mb-0 fs-6 fw-bold'>Transaction List</p>
            <p className='mb-0 fs-6'>Here you can see a list of all transactions, you done on this platform.</p>
        </div>,
        placement: 'auto',
        isFixed: true
    },
    {
        target: '.transactionlistviewfortour',
        content: <div>
            <p className='mb-0 fs-6 fw-bold'>View your Transaction</p>
            <p className='mb-0 fs-6'>From here you can see the additional information about the trasaction like all your credit/debit details, how much admin and gas fee was there.</p>
        </div>,
        placement: 'auto',
        isFixed: true
    },
    {
        target: '.transactionlistdownloadfortour',
        content: <div>
            <p className='mb-0 fs-6 fw-bold'>Download your Transaction</p>
            <p className='mb-0 fs-6'>You can download the transaction list from here in CSV format in exel, so that you have a record of all your transactions.</p>
        </div>,
        placement: 'auto',
        isFixed: true
    },

]
const myoffersteps = [
    {
        target: '.myoffersfortour',
        content: <div className='text-secondary p-0'>
            <p className='fs-6 fw-bold mb-0'>Offers</p>
            <p className='mb-0 fs-6'>From Here you can see all your offers that you're creating on the platform. </p>
        </div>,
        placement: "top",
        isFixed: true,
        disableBeacon: true
    },
    {
        target: '.pauseallfortour',
        content: <div className='text-secondary p-0'>
            <p className='fs-6 fw-bold mb-0'>Pause Offers</p>
            <p className='mb-0 fs-6'>You can pause all your offers on a single tap, using this button.</p>
        </div>,
        placement: "bottom",
        isFixed: false,
    },
    {
        target: '.resumeallfortour',
        content: <div className='text-secondary p-0'>
            <p className='fs-6 fw-bold mb-0'>Resume Offers</p>
            <p className='mb-0 fs-6'>Similar as Pause, you can resume all your offers on a single tap, using this button. </p>
        </div>,
        placement: "bottom",
        isFixed: false
    },
    {
        target: '.buyselloffercatogoryfortour',
        content: <div className='text-secondary p-0'>
            <p className='fs-6 fw-bold mb-0'>Your Offers</p>
            <p className='mb-0 fs-6'>Here all your offer are listed and catagorised according to offer type like buy offer or sell offer. </p>
        </div>,
        placement: "right",
        isFixed: false,
    },
    {
        target: '.offerlistpauseresumefortour',
        content: <div className='text-secondary p-0'>
            <p className='fs-6 fw-bold mb-0'>Pause/Resume Offer</p>
            <p className='mb-0 fs-6'>From here you can to pause or resume this offer from platform. </p>
        </div>,
        placement: "right",
        isFixed: false,
    },
    {
        target: '.viewoffersbuttonfortour',
        content: <div className='text-secondary p-0'>
            <p className='fs-6 fw-bold mb-0'>View Offer</p>
            <p className='mb-0 fs-6'>From here you can view your offer and check how its look when listed to another user.</p>
        </div>,
        placement: "right",
        isFixed: false,
    }

]
const buyoffersteps = [
    {
        target: '.otherbuyofferfilter',
        content: <div>
            <p className='mb-0 fs-6 fw-bold'>Offer Filter</p>
            <p className='mb-0 fs-6'>Here are the filters, you can search the offers for trading as per your choice.</p>
        </div>,
            disableBeacon: true
    },
    {
        target: '.findofferfortour',
        content: <div>
            <p className='mb-0 fs-6 fw-bold'>Find Filtered Offer</p>
            <p className='mb-0 fs-6'>You can select all the details above in the form and can find the offer of your choice.</p>
        </div>,
    },
    {
        target: '.resetofferfortour',
        content: <div>
            <p className='mb-0 fs-6 fw-bold'>Reset</p>
            <p className='mb-0 fs-6'>You can reset all the filters that you apply, just by clicking this button.</p>
        </div>,
    },
    {
        target: '.filterofferlistfortour',
        content: <div>
            <p className='mb-0 fs-6 fw-bold'>Buy Offers</p>
            <p className='mb-0 fs-6'>If you like this offer then you can start you trade just by clicking this buy button.</p>
        </div>,
    },
    {
        target: '.lasttenminutefortour',
        content: <div>
            <p className='mb-0 fs-6 fw-bold'>Filter Offer</p>
            <p className='mb-0 fs-6'>You can also filter out the offer on the bases of offer owner last active status, by clicking this option all the offer will come whose owner are active since last 10 minutes.</p>
        </div>,
    }
]
const selloffersteps = [
    {
        target: '.othersbuyoffers',
        content: <div>
            <p className='mb-0 fs-6 fw-bold'> Offers</p>
            <p className='mb-0 fs-6'>From here you can see all the offers that are created by other users on qvani platform to sell their crypto.</p>
        </div>,
    },
    {
        target: '.otherbuyofferfilter',
        content: <div>
            <p className='mb-0 fs-6 fw-bold'>Offer Filter</p>
            <p className='mb-0 fs-6'>Here are the filters, you can search the offers for trading as per your choice.</p>
        </div>,
    },
    {
        target: '.findofferfortour',
        content: <div>
            <p className='mb-0 fs-6 fw-bold'>Find Filtered Offer</p>
            <p className='mb-0 fs-6'>You can select all the details above in the form and can find the offer of your choice.</p>
        </div>,
    },
    {
        target: '.resetofferfortour',
        content: <div>
            <p className='mb-0 fs-6 fw-bold'>Reset</p>
            <p className='mb-0 fs-6'>You can reset all the filters that you apply, just by clicking this button.</p>
        </div>,
    },
    {
        target: '.filterofferlistfortour',
        content: <div>
            <p className='mb-0 fs-6 fw-bold'>Buy Offers</p>
            <p className='mb-0 fs-6'>If you like this offer then you can start you trade just by clicking this buy button.</p>
        </div>,
    },
    {
        target: '.lasttenminutefortour',
        content: <div>
            <p className='mb-0 fs-6 fw-bold'>Filter Offer</p>
            <p className='mb-0 fs-6'>You can also filter out the offer on the bases of offer owner last active status, by clicking this option all the offer will come whose owner are active since last 10 minutes.</p>
        </div>,
    }
]
const createOffer = [
    {
        target: '.createofferformfortour',
        content: <div>
            <p className='mb-0 fs-6 fw-bold'> Create Offer Form</p>
            <p className='mb-0 fs-6'>This form is for creating the offer for buy and sell the crypto just by filling required information in simple steps, and this offer is listed in .</p>
        </div>,
        disableBeacon: true,
        placement: 'right',
        isFixed: false
    },
    {
        target: '.stepscardfortour',
        content: <div>
            <p className='mb-0 fs-6 fw-bold'> Steps Tracker</p>
            <p className='mb-0 fs-6'>This is a kind of tracker of the form i.e. use to create the offer. This will track all the steps taken to create offer.</p>
        </div>,
        placement: 'auto',
        isFixed: false
    },
    {
        target: '.nextbuttonfortour',
        content: <div>
            <p className='mb-0 fs-6 fw-bold'> Next Button</p>
            <p className='mb-0 fs-6'>After filling whole information, you can now move to further steps for creating the offer.</p>
        </div>,
        placement: 'top',
        isFixed: false
    },
]
const dashboard =[
    {
        target: '.offerscardfortour',
        content: <div>
            <p className='mb-0 fs-6 fw-bold'> Offers Graph</p>
            <p className='mb-0 fs-6'>This graph indicates the number of offers, you create over the platform.</p>
        </div>,
        disableBeacon: true,
    },
    {
        target: '.holdingscardfortour',
        content: <div>
            <p className='mb-0 fs-6 fw-bold'> Wallet Graph</p>
            <p className='mb-0 fs-6'>This graph show the total holding that you have in your wallet in BTC form.</p>
        </div>,
        disableBeacon: true,
    },
    {
        target: '.allwalletdetailsfortour',
        content: <div>
            <p className='mb-0 fs-6 fw-bold'> Wallets</p>
            <p className='mb-0 fs-6'>This is the overview of you Qvani Wallet, this will contian your balance with a converted rate as per the prefered currency you set, more functionality like send/receive cryto, you can access in <Link to='wallet'>Wallet page</Link>.</p>
        </div>,
        disableBeacon: true,
    },
    {
        target: '.transactionlistfortour',
        content: <div>
            <p className='mb-0 fs-6 fw-bold'>Transactions</p>
            <p className='mb-0 fs-6'>This is the list of you transaction that you done on Qvani. For more detail about the transaction, you need to visit the <Link to='wallet'>Wallet Page</Link>.</p>
        </div>,
        disableBeacon: true,
    },
]

// export default function MainLayout() {
//     const location = useLocation()
//     const [showTour, setShowTour] = useState(false)
//     const { IsLogedIn } = useAuthContextHook();
//     const navigate = useNavigate()
//     const handleShowJoy = () => {
//         setShowTour(true);
//     }
//     const joyrideRef = useRef(null);
//     const handleCloseJoy = (data) => {
//         const { status } = data;
//         if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
//             setShowTour(false);
//         }
//     }
//     function handleChooseStep(){
//         if(location.pathname === '/dashboard/profile'){ return steps; }
//         else if(location.pathname === '/dashboard/myoffer'){ return myoffersteps; }
//         else if(location.pathname === '/dashboard/wallet'){ return walletStep; }
//         else if(location.pathname === '/dashboard/create-offer'){ return createOffer; }
//         else if(location.pathname=== '/dashboard/user-dashboard'){ return dashboard; }
//         else if(location.pathname.includes('/dashboard/buy/')){ return buyoffersteps; }
//         else { return selloffersteps; }
//     }
//     // useEffect(()=>{
//     //     if(IsLogedIn){
//     //         navigate('dashboard/user-dashboard')
//     //     }
//     // },[])
//     return (
//         <Suspense fallback={<Loader />}>
//             <Joyride
//                 showProgress={false}
//                 ref={joyrideRef}
//                 run={showTour}
//                 showSkipButton={true}
//                 steps={handleChooseStep()}
//                 scrollToFirstStep={true}
//                 continuous={true}
//                 styles={{
//                     buttonNext: {
//                         background: '#1476FF'
//                     },
//                     options: {
//                         primaryColor: '#1476FF',
//                     }
//                 }}
//                 callback={handleCloseJoy}
//             />
            
//             {
//                 IsLogedIn &&
//                 <div className='d-flex'>
//                     <SidebarComponent />
//                     <div className='d-flex flex-column flex-grow-1' style={{ width: "calc(100% - 15%)"}}>
//                         <NavbarComponent handleShowJoy={handleShowJoy} />
//                         <div className='responsive-navbar'>
//                             <Outlet />
//                         </div>
//                     </div>
//                 </div>
//             }
//             <Footer />
//         </Suspense>
//     )
// }

const MainLayout = () => {
    const location = useLocation();
    const [showTour, setShowTour] = useState(false);
    const { isAuth } = useAuthContextHook();
    const navigate = useNavigate();
    const joyrideRef = useRef(null);

    const handleShowJoy = () => {
        setShowTour(true);
    };

    const handleCloseJoy = (data) => {
        const { status } = data;
        if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
            setShowTour(false);
        }
    };

    function handleChooseStep() {
        if (location.pathname === '/dashboard/profile') return steps;
        if (location.pathname === '/dashboard/myoffer') return myoffersteps;
        if (location.pathname === '/dashboard/wallet') return walletStep;
        if (location.pathname === '/dashboard/create-offer') return createOffer;
        if (location.pathname === '/dashboard/user-dashboard') return dashboard;
        if (location.pathname.includes('/dashboard/buy/')) return buyoffersteps;
        return selloffersteps;
    }

    return (
        <Suspense fallback={<Loader />}>
            <Joyride
                showProgress={false}
                ref={joyrideRef}
                run={showTour}
                showSkipButton={true}
                steps={handleChooseStep()}
                scrollToFirstStep={true}
                continuous={true}
                styles={{
                    buttonNext: {
                        background: '#1476FF',
                    },
                    options: {
                        primaryColor: '#1476FF',
                    }
                }}
                callback={handleCloseJoy}
            />
            {isAuth ? (
                <div className='d-flex'>
                    <SidebarComponent />
                    <div className='d-flex flex-column flex-grow-1' style={{ width: "calc(100% - 15%)" }}>
                        <NavbarComponent handleShowJoy={handleShowJoy} />
                        <div className='responsive-navbar'>
                            <Outlet />
                        </div>
                    </div>
                </div>
            ) : (
                <Loader />
            )}
            <Footer />
        </Suspense>
    );
};

export default MainLayout;
