// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logo-footer {
    width: 150px;

}

@media (max-width: 999px) {
    .logo-footer {
        width: 120px;
    }
    
}`, "",{"version":3,"sources":["webpack://./src/Component/Footer/Footer.css"],"names":[],"mappings":"AAAA;IACI,YAAY;;AAEhB;;AAEA;IACI;QACI,YAAY;IAChB;;AAEJ","sourcesContent":[".logo-footer {\n    width: 150px;\n\n}\n\n@media (max-width: 999px) {\n    .logo-footer {\n        width: 120px;\n    }\n    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
