import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Edit from '../../assets/Images/ic-edit.png'
import Delete from '../../assets/Images/ic-delete.png'
import { BsDot } from 'react-icons/bs'
import WalletIcon from '../../assets/Images/WalletIcon.png'
import axios from 'axios'
import { API_URLs } from '../../Utils/ApiUrls'
import Modal from 'react-bootstrap/Modal';
import ButtonLoader from '../../Component/Loader/ButtonLoader'
import DataLoader from '../../Component/Loader/DataLoader'
import { CardContainer, ChangeButton, ProfileHeading, SecurityCard, SubHeading } from './StyledComponents'
import UPI from '../PayMethod/Upi'
import { useAPIContextHook } from '../../Context/ApiContext'
import { v4 as uuidv4 } from 'uuid';
import { useThemeContext } from '../../Context/ThemeContext'

const Para = styled.p`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
`
const BoldPara = styled.p`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
`
const Span = styled.span`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #7A7A7A;
    text-transform: capitalize;
`
const Button = styled.button`
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 0.9rem;
    color: #FFFFFF;
    background: #3545EE;
    border: 2px solid #3545EE;
    border-radius: 20px;
`
const Currency = styled.p`
   font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    color: #1476FF;
    font-size: 16px;
    text-transform: uppercase;
    border-radius: 6px;
    background: rgb(167, 213, 252);
    background: -moz-linear-gradient(58deg, rgba(167, 213, 252, 1) 0%, rgba(203, 255, 252, 1) 59%, rgba(170, 251, 206, 1) 100%);
    background: -webkit-linear-gradient(58deg, rgba(167, 213, 252, 1) 0%, rgba(203, 255, 252, 1) 59%, rgba(170, 251, 206, 1) 100%);
    background: linear-gradient(58deg, rgba(167, 213, 252, 1) 0%, rgba(203, 255, 252, 1) 59%, rgba(170, 251, 206, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#a7d5fc", endColorstr="#aafbce", GradientType=1);
`

export default function PaymentMethod() {
    const {isDarkTheme} = useThemeContext();
    const { fetchUpiId } = useAPIContextHook();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [upiList, setUpiList] = useState([]);
    const [isUpiEdit, setIsUpiEdit] = useState(false)
    const [singleUpiDetails, setSingleUpiDetails] = useState({})
    const [refresh, setRefresh] = useState(false);

    const [onDelete, setOnDelete] = useState({
        id: '',
        loading: false
    })

    const handleSetRefresh = () => setRefresh(!refresh)

    let fetchUpiIdFetch = async () => {
        try {
            let res = await fetchUpiId()
            let option = res?.length > 0 && res.map((upi) => {
                return {
                    ...upi,
                    value: upi?.id,
                    label: <div>
                        <Para className='mb-0'>{upi?.upiId}<BsDot size={30} color={upi?.isActive ? 'green' : 'red'} /></Para>
                    </div>
                }
            })


        
            setUpiList(option)
        } catch (error) {
        }
    }
    const deleteUPI = async (id) => {
        setOnDelete({
            id,
            loading: true
        })
        try {
            const res = await axios.delete(API_URLs.deleteUpi(id), {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('jwt')}`,
                    accept: 'application/json'
                }
            })


            setOnDelete({
                id: "",
                loading: false
            })
        } catch (error) {
            setOnDelete({
                id: "",
                loading: false
            })
        }
    }
    useEffect(() => {
        if (!onDelete.loading) {
            fetchUpiIdFetch()
        }
    }, [onDelete, refresh])

    return (
        <div>
            <CardContainer className='container py-4 px-4'>
                <div className='d-flex flex-column flex-md-row justify-content-between'>
                    <div className='d-flex flex-column w-100 w-md-50'>
                        <ProfileHeading className=''>UPI</ProfileHeading>
                    </div>

                    <div className='pt-2'>
                        <ChangeButton className='px-3 py-1' onClick={() => {
                            setSingleUpiDetails({})
                            setIsUpiEdit(false)
                            handleShow()
                        }}>Add UPI</ChangeButton>
                    </div>
                </div>
                <SubHeading>
                    Add your UPI ID below. You can share these details with your trade partner via trade chat, for UPI type trades.
                </SubHeading>
                {
                    upiList?.length > 0 ? upiList.map((details) => {
                        return (
                            <div className='pt-4' key={uuidv4()}>

                                <SecurityCard className='p-3'>

                                    <div className='d-flex gap-3'>
                                        <BoldPara className='w-25'>UPI ID</BoldPara>
                                        <Span>: {details?.upiId}</Span>
                                    </div>

                                    <div className='d-flex gap-3'>
                                        <BoldPara className='w-25'>Active</BoldPara>
                                        <Span className='d-flex gap-1'>: {details?.isActive ?
                                            <p className='mb-0 fw-bold text-success'>Active</p>
                                            : <p className='mb-0 fw-bold text-danger'>In-Active</p>}</Span>
                                    </div>
                                    <div className='d-flex justify-content-between align-items-lg-center flex-column flex-lg-row gap-2 gap-lg-0'>
                                        <div className='d-flex gap-3 align-items-center justify-content-between justify-content-md-start'>
                                            <Currency className='px-4 py-2 m-0'>{details?.currency?.symbol}</Currency>
                                        </div>
                                        <div className='d-flex gap-3'>
                                            <ChangeButton onClick={() => { deleteUPI(details?.id) }} disabled={onDelete?.loading} className='d-flex align-items-center gap-2 px-3 py-1'>
                                                {
                                                    details?.id === onDelete?.id && onDelete?.loading ?
                                                        <div className='px-4 d-flex'>
                                                            <ButtonLoader /> <span>Delete</span>
                                                        </div>
                                                        : <>
                                                            <img src={Delete} alt="delete"/>
                                                            Delete
                                                        </>
                                                }
                                            </ChangeButton>
                                            <ChangeButton onClick={() => {
                                                setIsUpiEdit(true)
                                                setSingleUpiDetails(details)
                                                handleShow()
                                            }} className='d-flex align-items-center gap-2 px-3 py-1'>
                                                <img src={Edit} alt="delete"/>
                                                Edit
                                            </ChangeButton>
                                        </div>
                                    </div>
                                </SecurityCard>
                            </div>
                        )
                    }) : <div className='pt-3'>
                        {
                            onDelete.loading ?
                                <div className='px-4 d-flex justify-content-center align-items-center'>
                                    <DataLoader />
                                </div> :
                                <CardContainer className='p-3 py-4 d-flex justify-content-center'>
                                    <div className='d-flex flex-column justify-content-center align-items-center'>
                                        <img src={WalletIcon} alt="wallet"/>
                                        <Span>No UPI ID's are added</Span>
                                    </div>
                                </CardContainer>

                        }

                    </div>
                }

                <Modal show={show} onHide={handleClose}>
                    <Modal.Header style={{ background: `${isDarkTheme ? '#0c0c0c' : 'white'}`, wordBreak: 'break-word' }} closeButton>
                        <Modal.Title>
                            <ProfileHeading className='m-0'>{isUpiEdit ? 'Update UPI' : 'Add UPI'}</ProfileHeading>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ background: `${isDarkTheme ? '#0c0c0c' : 'white'}`, wordBreak: 'break-word' }}>
                        <UPI handleClose={handleClose} singleUpiDetails={singleUpiDetails} isUpiEdit={isUpiEdit} handleSetRefresh={handleSetRefresh} />
                    </Modal.Body>
                </Modal>
            </CardContainer>
        </div>
    )
}
