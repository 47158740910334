import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import axios from "axios";
import { API_URLs } from "./Utils/ApiUrls";
const vapidKey = 'BK-MB_3750mL1MpMNHp44OBkl40Nc43RwiTqlHb2CMTPGmopkSamK2jABUj7fkVKwg7K163g3y-8vfyMS1dnxZA'

const firebaseConfig = {
    apiKey: "AIzaSyCn_7xegVNPrThK0VQ-IGsKKHwq19WzZjg",
    authDomain: "qvani-df311.firebaseapp.com",
    projectId: "qvani-df311",
    storageBucket: "qvani-df311.appspot.com",
    messagingSenderId: "57751001429",
    appId: "1:57751001429:web:e3e5bc03a9a4b4c29d37dc",
    measurementId: "G-X30RCZ3B34"
};

// Initialize Firebase
export const requestForToken = async () => {
    const app = await initializeApp(firebaseConfig);
    const messaging = getMessaging(app);
    const analytics = getAnalytics(app);

    return getToken(messaging, { vapidKey: vapidKey })
        .then((currentToken) => {
            if (currentToken) {
                // console.log('current token for client: ', currentToken);
                localStorage.setItem('fcmToken', currentToken)

                axios.post(API_URLs.FcmToken, {
                    fcmDeviceToken: currentToken
                }, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('jwt')}`
                    }
                }).then((res) => {
                }).catch((error) => {
                })
                return currentToken
            }
        })
        .catch((err) => {
        });
};