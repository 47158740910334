import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Edit from '../../assets/Images/ic-edit.png'
import Delete from '../../assets/Images/ic-delete.png'
import WalletIcon from '../../assets/Images/WalletIcon.png'
import axios from 'axios'
import { API_URLs } from '../../Utils/ApiUrls'
import Modal from 'react-bootstrap/Modal';
import ButtonLoader from '../../Component/Loader/ButtonLoader'
import DataLoader from '../../Component/Loader/DataLoader'
import { CardContainer, ChangeButton, ProfileHeading, SecurityCard, SubHeading } from './StyledComponents'
import { useAPIContextHook } from '../../Context/ApiContext'
import Paypal from '../PayMethod/Paypal'
import { useThemeContext } from '../../Context/ThemeContext'

const Para = styled.p`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
`
const BoldPara = styled.p`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
`
const Span = styled.span`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #7A7A7A;
`

export default function PaymentMethod() {
    const { isDarkTheme } = useThemeContext();
    const { fetchPaypal } = useAPIContextHook();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [paypalDetails, setPaypalDetails] = useState([]);
    const [isPaypalEdit, setIsPaypalEdit] = useState(false)
    const [singlePaypalDetails, setSinglePaypalDetails] = useState({})

    const [refresh, setRefresh] = useState(false);

    const [onDelete, setOnDelete] = useState({
        id: '',
        loading: false
    })

    // console.log('dertgvdss', onDelete)

    const handleSetRefresh = () => setRefresh(!refresh)

    let handleFetchPaypalDetails = async () => {
        try {
            let res = await fetchPaypal()
            let options = res?.length > 0 && res.map((card) => {
                return {
                    ...card,
                    value: card?.id,
                    label: <div className='d-flex'>
                        <Para className='mb-0'>{card?.authType}</Para> -
                        <Para className='mb-0'>{card?.authType === 'username' ? card?.username : card?.authType === 'mobile' ? card?.mobile : card?.authType === 'email' ? card?.email : ''}</Para>
                    </div>
                }
            })

            // console.log('responseeeee', res)
            setPaypalDetails(options)
            // console.log('paypaaaaal', options)
        }
        catch (error) {
        }
    }

    const deletePaypal = async (id) => {
        // console.log('idddddd', id)
        setOnDelete({
            id,
            loading: true
        })
        try {
            const res = await axios.delete(API_URLs.deletePayPal(id), {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('jwt')}`
                }
            })

            // console.log('deleteeeeeepayyypal', res)


            // if (res.status === 200) {
            //     // Successfully deleted, update the state to remove the item from the list
            //     setPaypalDetails(prevDetails => {
            //         const updatedDetails = prevDetails.filter(detail => detail.id !== id)
            //         // console.log('Updated PayPal details after deletion:', updatedDetails)
            //         return updatedDetails
            //     })
            // }

            setOnDelete({
                id: "",
                'loading': false
            })
        } catch (error) {
            setOnDelete({
                id: "",
                'loading': false
            })
        }
    }



    useEffect(() => {
        if (!onDelete.loading) {
            handleFetchPaypalDetails()
        }
    }, [onDelete, refresh])

    return (
        <div>
            <CardContainer className='container py-4 px-4'>
                <div className='d-flex flex-column flex-md-row justify-content-between'>
                    <div className='d-flex flex-column w-100 w-md-50'>
                        <ProfileHeading className=''>PayPal ID</ProfileHeading>
                    </div>

                    <div className='pt-2'>
                        <ChangeButton className='px-3 py-1' onClick={() => {
                            setSinglePaypalDetails({})
                            setIsPaypalEdit(false)
                            handleShow()
                        }}>Add Paypal</ChangeButton>
                    </div>
                </div>
                <SubHeading>
                    Add your PayPal Account below. You can share these details with your trade partner via trade chat, for PayPal type trades.
                </SubHeading>
                {
                    paypalDetails?.length > 0 ? paypalDetails.map((details, index) => {
                        if (details?.isActive) {
                            return (
                                <div className='pt-4' key={index}>

                                    <SecurityCard className='p-3'>

                                        <div className='d-flex gap-3'>
                                            <BoldPara className='w-25'>Authorize Type</BoldPara>
                                            <Span>: {details?.authType}</Span>

                                        </div>
                                        <div className='d-flex gap-3'>
                                            <BoldPara className='w-25'> {details?.authType}</BoldPara>
                                            <Span>: {details?.authType === 'username' ? details?.username : details?.authType === 'mobile' ? details?.mobile : details?.authType === 'email' ? details?.email : ''}</Span>
                                        </div>

                                        <div className='d-flex justify-content-end align-items-lg-center flex-column flex-lg-row gap-2 gap-lg-0'>



                                            <div className='d-flex gap-3'>

                                                <ChangeButton onClick={() => { deletePaypal(details?.id); }}
                                                    className='d-flex align-items-center gap-2 px-3 py-1'>

                                                    {
                                                        details?.id === onDelete?.id && onDelete?.loading ?
                                                            <div className='d-flex gap-2 align-items-center px-4'>
                                                                <> <ButtonLoader /> <span>Delete</span></>
                                                            </div>
                                                            : <>
                                                                <img src={Delete} alt="delete" />
                                                                Delete
                                                            </>
                                                    }
                                                </ChangeButton>
                                                <ChangeButton onClick={() => {
                                                    setIsPaypalEdit(true)
                                                    setSinglePaypalDetails(details)
                                                    handleShow()
                                                }} className='d-flex align-items-center gap-2 px-3 py-1'>
                                                    <img src={Edit} alt="delete" />
                                                    Edit
                                                </ChangeButton>
                                            </div>
                                        </div>
                                    </SecurityCard>
                                </div>
                            )
                        } 
                    }) : <div className='pt-3'>
                        {
                            onDelete?.loading ?
                                <div className='px-4 d-flex justify-content-center align-items-center'>
                                    <DataLoader />
                                </div> :
                                <CardContainer className='p-3 py-4 d-flex justify-content-center'>
                                    <div className='d-flex flex-column justify-content-center align-items-center'>
                                        <img src={WalletIcon} alt="wallet" />
                                        <Span>No PayPal Account are added</Span>
                                    </div>
                                </CardContainer>

                        }

                    </div>
                }
            </CardContainer>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header style={{ background: `${isDarkTheme ? '#0c0c0c' : 'white'}`, wordBreak: 'break-word' }} closeButton>
                    <Modal.Title>
                        <ProfileHeading className='m-0'>{isPaypalEdit ? 'Update PayPal Account' : 'Add PayPal Account'}</ProfileHeading>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ background: `${isDarkTheme ? '#0c0c0c' : 'white'}`, wordBreak: 'break-word' }}>
                    <Paypal handleClose={handleClose} singlePaypalDetails={singlePaypalDetails} isPaypalEdit={isPaypalEdit} handleSetRefresh={handleSetRefresh} />
                </Modal.Body>
            </Modal>
        </div>
    )
}
