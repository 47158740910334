// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media only screen and (min-width: 999px) {
    .side-bar {
        position: sticky!important;
        top: 0;
        height: 100vh;
        z-index: 3;
        scrollbar-width: none;
    }
  }`, "",{"version":3,"sources":["webpack://./src/Component/Sidebar/Sidebar.css"],"names":[],"mappings":"AAAA;IACI;QACI,0BAA0B;QAC1B,MAAM;QACN,aAAa;QACb,UAAU;QACV,qBAAqB;IACzB;EACF","sourcesContent":["@media only screen and (min-width: 999px) {\n    .side-bar {\n        position: sticky!important;\n        top: 0;\n        height: 100vh;\n        z-index: 3;\n        scrollbar-width: none;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
