import React, { useCallback, useEffect, useState } from 'react'
import { NotificationBody, NotificationHeading,Unred,Allbutton, SubNotificationHeading, Button} from './StyledComponents'
import axios from 'axios'
import { API_URLs } from '../../Utils/ApiUrls'
import { useNavigate } from 'react-router-dom'
import { GoDotFill } from "react-icons/go";
import { useThemeContext } from '../../Context/ThemeContext'
import { FaArrowLeftLong } from "react-icons/fa6";
import { v4 as uuidv4 } from 'uuid';
import DataLoader from '../../Component/Loader/DataLoader'

export default function Notification() {
    const {handleSetNavbarHeading} = useThemeContext();
    const [notificationsData, setNotificationsData] = useState([]);
    const [unreadCount, setUnreadCount] = useState('');
    const [loader, setLoader]=useState(false);
    const navigate = useNavigate();

    const markAllAsRead = async () => {
        try {
            await axios.patch(API_URLs.readAllNotifications, {}, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('jwt')}`
                }
            })
            getAllNotifications()

        } catch (error) {
        }
    }

    const markAsRead = async (notification) => {
        try {
            await axios.patch(API_URLs.readNotification(notification?._id), {}, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('jwt')}`
                }
            })

            getAllNotifications()

            if (notification?.transactionType && notification?.transactionIds) {
                let pathname = "";
                notification?.transactionIds?.map((res) => {
                    pathname += String(res?.id) + "/"
                })
                let urlNew = `/${notification?.transactionType}${pathname}`
                navigate(urlNew)
            }

        } catch (error) {
        }
    }

    const getAllNotifications = useCallback(async () => {
        setLoader(true);
        try {
            let res = await axios.get(API_URLs.getNotifications, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('jwt')}`
                }
            })
            setNotificationsData(res.data.data.notifications)
            setUnreadCount(res?.data?.data?.unreadNotificaitonCount)
            setLoader(false)
        } catch (error) {
            setLoader(false)
        }
    }, [])

    useEffect(() => {
        getAllNotifications()
        handleSetNavbarHeading('Notifications')
    }, [getAllNotifications])

    return (
        <div className='p-4'>
        <FaArrowLeftLong onClick={()=>navigate(-1)}/>
            <NotificationBody className='card px-3'>
                <div className='d-flex justify-content-between align-items-center'>
                    <NotificationHeading>Notification</NotificationHeading>
                    <div className='d-flex align-items-center'>
                        <Allbutton className='text-decoration-underline' onClick={()=>markAllAsRead()}>Mark All As Read</Allbutton>
                        <Unred className='mb-0'>{unreadCount}</Unred>
                    </div>
                </div>
                {
                    loader ? 
                    <DataLoader/>:
                   <>{ Object.entries(notificationsData).map((data)=>{
                        return data[1]?.length > 0 && <>
                            <hr className=' my-2' />
                            <SubNotificationHeading>{data[0]?.split('_')[0]} {data[0]?.split('_')[1]}</SubNotificationHeading>
                            {
                                data[1].map((subdata)=>{
                                    return <>
                                        <hr className='text-secondary my-1 mx-3'/>
                                        <Button key={uuidv4()}
                                            onClick={()=>{
                                                markAsRead(subdata)
                                                if( subdata?.transactionType.includes('trade')){
                                                    navigate(`/${subdata.transactionType}${subdata.transactionIds[0].id}/${subdata.transactionIds[1].id}`)
                                                }
                                                else{
                                                    navigate('/dashboard/wallet')
                                                }
                                            }} className='d-flex justify-content-between align-items-center'>
                                            <div className='text-start'>
                                                <p className='mb-1'>{subdata?.notificationBody}</p>
                                                <p className='text-secondary fw-bold mb-1'>{subdata?.notificationTitle}</p>
                                            </div>
                                            <div className='d-flex gap-1'>
                                                <p className='mb-1 text-secondary'>{new Date(subdata?.createdAt).toDateString()}</p>
                                                {
                                                    subdata?.status === 'unread' && <GoDotFill color='red'/>
                                                }
                                            </div>
                                        </Button>
                                    </>
                                })
                            }
                        </>
                    })}</>
                }
            </NotificationBody>
        </div>
    )
}
