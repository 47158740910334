import React from 'react'
import Header from './Header'
import Mission from './Mission'
import Value from './Value'
import Career from './Career'
import Culture from './Culture'
function Index() {
  return (
    <div className='container-fluid bg-white px-0 overflow-hidden'>
        <Header/>
        <Mission/>
        <Value/>
        <Culture />
        {/* <Values1/> */}
        <Career/>
    </div>
  )
}

export default Index