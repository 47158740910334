import Innovation from '../../assets/Images/innovation.png'
import Team from '../../assets/Images/Team.png'
import Growth from '../../assets/Images/growth.png'
import Remote from '../../assets/Images/Remote.png'
import Schedule from '../../assets/Images/schedule.png'
import Team1 from '../../assets/Images/aboutTeam.png'
import Launch from '../../assets/Images/Launch.svg'
import Remote1 from '../../assets/Images/Remote.svg'
import Schedule1 from '../../assets/Images/Schedule.svg'
import { FaFacebook, FaInstagram, FaTwitter, FaLinkedin } from "react-icons/fa";

export const data ={
    value: [
        {
            img: Innovation,
            head: 'Innovation',
            sub: "With a long-term outlook on delivering robust and scalable solutions, we invest heavily in research and development to strengthen the Bitcoin ecosystem."
        },
        {
            img: Team,
            head: 'Community',
            sub: "Together with the Qvani community, we advocate for responsible rules to make the benefits of crypto available around the world. We prioritize earning trust by consistently adhering to responsible and ethical standards."
        },
        {
            img: Growth,
            head: 'Security',
            sub: "Applying cutting-edge cryptography and security engineering, we’re building the technology that makes financial markets more efficient by reducing reliance on trust. "
        }
    ],
    career: [
        {
            img: Remote1,
            head: 'Remote work',
            sub: "Lorem ipsum dolor sit amet, consectetur adipiscing elit spendisse varius."
        },
        {
            img: Launch,
            head: 'Personal Development',
            sub: "Lorem ipsum dolor sit amet, consectetur adipiscing elit spendisse varius."
        },
        {
            img: Schedule1,
            head: 'Flexible schedule',
            sub: "Lorem ipsum dolor sit amet, consectetur adipiscing elit spendisse varius."
        },
    ],
    team: [
        {
            img: Team1,
            name: 'Alisa Hester',
            jobTitle: "Job title",
            desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique.",
            icon: [<FaFacebook size={23} color='#1476FF'/>, <FaInstagram size={23} color='#BA24D5'/>, <FaTwitter size={23} color='#1476FF'/>, <FaLinkedin size={23} color='#1476FF'/>]
        },
        {
            img: Team1,
            name: 'Ava Wright',
            jobTitle: "Job title",
            desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique.",
            icon: [<FaFacebook size={23} color='#1476FF'/>, <FaInstagram size={23} color='#BA24D5'/>, <FaTwitter size={23} color='#1476FF'/>, <FaLinkedin size={23} color='#1476FF'/>]
        },
        {
            img: Team1,
            name: 'Lana Steiner',
            jobTitle: "Job title",
            desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique.",
            icon: [<FaFacebook size={23} color='#1476FF'/>, <FaInstagram size={23} color='#BA24D5'/>, <FaTwitter size={23} color='#1476FF'/>, <FaLinkedin size={23} color='#1476FF'/>]
        },
        {
            img: Team1,
            name: 'Lucy Bond',
            jobTitle: "Job title",
            desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique.",
            icon: [<FaFacebook size={23} color='#1476FF'/>, <FaInstagram size={23} color='#BA24D5'/>, <FaTwitter size={23} color='#1476FF'/>, <FaLinkedin size={23} color='#1476FF'/>]
        },
        {
            img: Team1,
            name: 'Kari Rasmussen',
            jobTitle: "Job title",
            desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique.",
            icon: [<FaFacebook size={23} color='#1476FF'/>, <FaInstagram size={23} color='#BA24D5'/>, <FaTwitter size={23} color='#1476FF'/>, <FaLinkedin size={23} color='#1476FF'/>]
        },
        {
            img: Team1,
            name: 'Rene Wells',
            jobTitle: "Job title",
            desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique.",
            icon: [<FaFacebook size={23} color='#1476FF'/>, <FaInstagram size={23} color='#BA24D5'/>, <FaTwitter size={23} color='#1476FF'/>, <FaLinkedin size={23} color='#1476FF'/>]
        },
    ]
}