import React, { useEffect } from 'react'
import { useThemeContext } from '../../Context/ThemeContext';
import { useFormik } from 'formik';
import Select from 'react-select'
import { darkTheme, lightTheme } from '../../Theme/theme';
import { useAPIContextHook } from '../../Context/ApiContext';
import {Title, Input, SubmitButton, TagButton, Card, InputContainer1} from './Styled'
import styled from 'styled-components';
import * as Yup from 'yup';
import './Index.css'
import { useNavigate } from 'react-router-dom';


const initialValues = {
    offerType: 'buy',
    currencyType: '',
    paymentMethod: '',
    amount: '',
    payVia: '',
}

const currency = [
    {
        label: 'BTC',
        value: 'btc'
    },
    {
        label: 'ETH',
        value: 'eth'
    },
    {
        label: 'MATIC',
        value: 'matic'
    },
    {
        label: 'USDC',
        value: 'usdc'
    },
    {
        label: 'USDT',
        value: 'usdt'
    },
]
const payment = [
    {
        label: 'Bank Transfer',
        value: 'Bank Transfer'
    },
    {
        label: 'Paypal',
        value: 'PayPal'
    },
    {
        label: 'UPI',
        value: 'UPI Transfer'
    },
    {
        label: 'Card',
        value: 'ANY Credit/Debit Card'
    }
];

const BuySellButtonContainer = styled.div`
border-radius: 20px;
overflow: auto;
.buy-sell-button{
    border: none;
    outline: none;
    background: white;
    color: black;
    border: 1px solid #EBEBEB;
    width: 50%;
    // border-radius: 15px;
    font-weight: 500;
    padding: 15px;
}
.buy-sell-active{
    background: #3545EE;
    color: white;
}
`

export default function BuySellForm() {
    const {cryptoCurrencyOptions, paymentMethodList, fetchPaymentMethods, fiatCurrencyOptions, customFilterForCrypto} = useAPIContextHook();
    const { isDarkTheme } = useThemeContext();
    const navigate = useNavigate();
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object().shape({
            currencyType: Yup.object().required('CryptoCurrency is Required')
        }),
        onSubmit: (values) => {
            navigate('/login')
            const customLink = `/dashboard${values?.offerType ? `/${values?.offerType}` : '/buy'}${values?.currencyType && `/${values?.currencyType?._id}/`}${values?.paymentMethod && `pay=${values?.paymentMethod?.label}`}${values?.payVia && `fiat=${values?.payVia?.name}`}${values?.amount && `amount=${values?.amount}`}`
            localStorage.setItem('pending-route',customLink);
        }
    })
    const styles = {
        control: (styles) => ({
            ...styles,
            background: 'transparent',
            borderRadius: '16px',
            color: 'white',
            width: '100%',
            height: '50px',
            border: `${isDarkTheme ? darkTheme.border : lightTheme.border}`, "&:hover": {
                border: `${isDarkTheme ? darkTheme.border : lightTheme.border}`
            },
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: isDarkTheme && state.isHovered ? '#302F30' : `${({ theme }) => theme.body}`,
            ':hover': {
                backgroundColor: `${isDarkTheme ? '#302F30' : '#D9EDE7'}`,
            },
            color: isDarkTheme && state.isSelected ? '#1476FF' : `${({ theme }) => theme.text}`,
        }),
        indicatorSeparator: () => ({
            all: 'unset'
        }),
        indicatorsContainer: (styles) => ({
            ...styles, color: isDarkTheme ? 'white' : 'black', 'div:nth-child(2)': {
                color: isDarkTheme ? 'white' : lightTheme.text
            }
        }),
        menu: (styles) => ({
            ...styles, background: isDarkTheme ? 'rgb(27, 27, 28)' : 'white',
            color: isDarkTheme ? 'white' : lightTheme.text,
        })
    }
    const styles1 = {
        control: (styles) => ({
            ...styles,
            background: 'transparent',
            borderRadius: '52px',
            color: 'white',
            width: '6.4rem',
            boxShadow: 'none',
            border: 'none'
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: isDarkTheme && state.isHovered ? '#302F30' : `${({theme})=> theme.body}`,
            ':hover': {
              backgroundColor:  `${isDarkTheme ? '#302F30' : '#D9EDE7'}` ,
            },
            color: isDarkTheme && state.isSelected ? '#1476FF' : `${({theme})=> theme.text}`,
        }),
        indicatorSeparator: () => ({
            all: 'unset'
        }),
        indicatorsContainer: (styles) => ({
            ...styles, color: isDarkTheme ? 'white' : 'black', 'div:nth-child(2)': {
                color: isDarkTheme ? 'white' : lightTheme.text
            }
        }),
        menu: (styles) => ({
            ...styles, background: isDarkTheme ? 'rgb(27, 27, 28)' : 'white',
            color: isDarkTheme ? 'white' : lightTheme.text,
        })
    }
    const handleClickCurrencyButton =(value)=>{
        let currency = cryptoCurrencyOptions.filter((data)=>{
            return data.symbol === value
        })
        formik.setValues({
            ...formik.values,
            currencyType: currency[0]
        })
    }
    const handleClickPaymentButton =(value)=>{
        let payment = paymentMethodList.filter((data)=>{
            let subPay = data.options.filter((subdata)=>{
                return subdata.value === value;
            })
            if(subPay?.length > 0){
                formik.setValues({
                    ...formik.values,
                    paymentMethod: subPay[0]
                })
            }
            return subPay[0];
        })
    }



    useEffect(() => {
        fetchPaymentMethods();
    },[])

    return (
        <div className='row m-0 d-flex flex-column justify-content-center align-items-center'>
            <div className='col-md-7 col-lg-8 col-sm-9'>
                <Card className='p-3 p-md-5 card'>
                    <form onSubmit={formik.handleSubmit}>
                        <BuySellButtonContainer className='d-flex justify-content-center'>
                             <button onClick={()=>{ formik.setValues({...formik.values, offerType: 'buy'})}} type='button' className={`buy-sell-button ${formik.values.offerType==='buy' && 'buy-sell-active'}`} >Buy</button>
                             <button onClick={()=>{ formik.setValues({...formik.values, offerType: 'sell'})}} type='button' className={`buy-sell-button ${formik.values.offerType==='sell' && 'buy-sell-active'}`} >Sell</button>
                        </BuySellButtonContainer>
                        <div className='mb-3 d-flex flex-column'>
                            <Title htmlFor='currencyType'>Cryptocurrency</Title>
                            <Select
                                id='currencyType'
                                name='currencyType'
                                options={cryptoCurrencyOptions}
                                styles={styles}
                                className='text-nowrap'
                                placeholder='Choose your cryptocurrency'
                                value={formik.values.currencyType}
                                onChange={(selectedValues) => {
                                    let event = { target: { name: 'currencyType', value: selectedValues } }
                                    formik.handleChange(event)
                                }}
                                onBlur={(selectedValues) => {
                                    let event = { target: { name: 'currencyType', value: selectedValues } }
                                    formik.handleBlur(event)
                                }}
                            />
                            <div className='mt-2 gap-0 gap-md-3 d-flex groupCurrBtn'>
                                {
                                    currency.map((data)=>{
                                        return <TagButton type='button' onClick={()=>handleClickCurrencyButton(data.value)}>{data.label}</TagButton>
                                    })
                                }
                            </div>
                            {formik.errors.currencyType && <small className='text-danger'>{formik.errors.currencyType}</small>}
                        </div>

                        <div className='mb-3 d-flex flex-column'>
                            <Title htmlFor='paymentMethod' className='text-start mb-0'>Payment Methods</Title>
                            <Select
                                id='paymentMethod'
                                name='paymentMethod'
                                options={paymentMethodList}
                                styles={styles}
                                className='text-nowrap'
                                placeholder='Choose from over 400 Payment Methods'
                                value={formik.values.paymentMethod}
                                onChange={(selectedValues) => {
                                    let event = { target: { name: 'paymentMethod', value: selectedValues } }
                                    formik.handleChange(event)
                                }}
                                onBlur={(selectedValues) => {
                                    let event = { target: { name: 'paymentMethod', value: selectedValues } }
                                    formik.handleBlur(event)
                                }}
                            />
                            <div className=' mt-2 gap-0 gap-md-3 d-flex flex-row groupPayBtn'>
                                {
                                    payment.map((data)=>{
                                        return <TagButton type='button' onClick={()=>handleClickPaymentButton(data.value)}>{data.label}</TagButton>
                                    })
                                }
                            </div>
                        </div>

                        <div className='mb-3 d-flex flex-column'>
                            <Title htmlFor='amount'>Amount</Title>
                            <InputContainer1>
                                <Input 
                                    id='amount'
                                    name='amount'
                                    placeholder='Enter Amount'
                                    value={formik.values.amount}
                                    onChange={formik.handleChange}
                                />
                                <Select
                                    styles={styles1}
                                    name='payVia'
                                    id='payVia'
                                    options={fiatCurrencyOptions}
                                    filterOption={customFilterForCrypto}
                                    value={formik.values.payVia}
                                    onChange={(selectedValues) => {
                                        let event = { target: { name: 'payVia', value: selectedValues } }
                                        formik.handleChange(event)
                                    }}
                                    onBlur={(selectedValues) => {
                                        let event = { target: { name: 'payVia', value: selectedValues } }
                                        formik.handleBlur(event)
                                    }}
                                />
                            </InputContainer1>
                        </div>
                        <div>
                            <SubmitButton className='w-100 mt-3' type='submit'>View Offers</SubmitButton>
                        </div>
                    </form>
                </Card>
            </div>
        </div>
    );
}