import React from 'react'
import styled from 'styled-components'
import {data} from './data'
import {Link} from 'react-router-dom'

const P = styled.p`
color: #202939;
margin-bottom: 0px;
font-size: 28px;
font-weight: 700;
`;

const P1 = styled.p`
color: #3545EE;
margin: 1rem 1rem;
font-size: 24px;
font-weight: 700;
white-space: nowrap;

@media (max-width: 768px){
    font-size: 14px;
    white-space: normal;
    text-align: center;
    margin: 0 1rem;
}
`;

const Button = styled.button`
border-radius: 20px;
border: none;
font-size: 14px;
font-weight: 600;
`;

const Card = styled.div`
display: flex;
// flex-direction: column;
flexe-wrap: wrap;
align-items: center;
justify-content: center;
padding: 1rem 3rem; 
width: 290px;
border: ${({theme})=>theme.border};
box-shadow: ${({theme})=>theme.boxshadow};
border-radius: 14px;
@media (max-width: 768px){
    width: 110px;
    padding: 1rem 0;
}
`;

const Para = styled.p`
font-size: 14px;
color: grey;
`;

const Button1 = styled.button`
background: #3545EE;
border: none;
text-decoration: none;
border-radius: 32px;
width: 10rem;
padding: 8px;
color: white;
&:hover{
    color: white;
}
`;

const Div = styled.div`
background-color: #EFF0FE;
width: 85%;
border-radius: 32px;
margin-top: 5rem;
@media (min-width: 1200px){
    margin-top: 13rem;
}
`;

function Values1() {
  return (
    <Div className='h-auto py-5 #career mx-auto mb-5'>
        <div className='row m-0 pb-3 d-flex justify-content-center align-items-center'>
            <div className='text-center '>
                <Button>Careers</Button>
                <P>Together at Qvani Technologies</P>
                <Para>Our mission is to increase economic freedom in the world. Join us and make an impact on a global scale.</Para>
            </div>
        </div>
        <div className='row m-0 d-flex justify-content-center align-items-center'>
            <div className='col-md-12 d-flex justify-content-center gap-2'>
                {
                    data.career.map((data)=>{
                        return <Card className='card'>
                            <img src={data.img} width={45}/>
                            <P1>{data.head}</P1>
                        </Card>
                    })
                }
            </div>
        </div>
        <p className='fw-bold fs-4 text-center mt-3'>We’re hiring!</p>
        <div className='text-center'>
            <Button1 as={Link} to='//careers.qvani.com' target='_blank'>Open Positions</Button1>
        </div>
    </Div>
  )
}

export default Values1;