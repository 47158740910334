import React from 'react'
import { Outlet } from 'react-router-dom'
import Footer from '../Component/Footer/Index';
import PublicNavbar from '../Component/PublicNavbar/Index';
export default function PublicLayout(){
    return(
        <div className='bg-white'>
        <PublicNavbar/>
        <Outlet/>
        <Footer/>
        </div>
    );
}