import React, { useState } from 'react'
import './SignUp/Signup.css'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Select from 'react-select'
import MailIcon from '../../images/Home/mail.png'
import { useAuthContextHook } from "../../Context/AuthContext";
import styled from 'styled-components';
import ButtonLoader from '../../Component/Loader/ButtonLoader';
import CustomModal from '../../hooks/CustomModal';
import { Input, InputPhone } from './Login/Login'
import { useThemeContext } from '../../Context/ThemeContext';
import { useAPIContextHook } from '../../Context/ApiContext';
import { useNavigate } from 'react-router-dom';
import Logo from '../../assets/Images/LogoFooter.png'

const Heading = styled.h3`
  font-size: 2rem;
  text-align: center;
  font-weight: bolder;
`
const Container = styled.div`
  background: white;
  border-radius: 14px;
  max-width: 500px;
`

export default function Forgot() {
    const [isLoading, setIsLoading] = useState(false);
    const { handleForgotPassword, showModal, handleCloseModal, navigateLink, modalData } = useAuthContextHook();
    const { phoneCode } = useAPIContextHook();
    const { isDarkTheme } = useThemeContext();
    const [phone, setPhone] = useState('')
    const navigate = useNavigate();

    const handleSubmit = async (values) => {
        try {
            setIsLoading(true)
            await handleForgotPassword(values);
            console.log('----values', values);
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
        }
    }

    const formik = useFormik({
        initialValues: { email: '', phone: '' },
        validationSchema: Yup.object({
            email: Yup.string().email('Invalid Email ID'),
            phone: Yup.number()
                .typeError("That doesn't look like a phone number")
                .positive("A phone number can't start with a minus")
                .integer("A phone number can't include a decimal point")
            // .max(15)
            // .min(5)
            // .required('A phone number is required')
            ,
        }),
        onSubmit: (values) => {
      
            if (phone?.label?.length > 0) {

                const checkNumber = `${phone.label}${values.phone}`;
                handleSubmit(phone.label + values.phone)
            }
            else {
                handleSubmit(values.email)
            }
        },
    });

    const styles = {
        control: (styles) => ({
            ...styles,
            borderRadius: '20px',
            outline: 'none',
            border: `none`, "&:hover": {
                border: `none`
            },
            boxShadow: `none`,
            color: 'white'
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: isDarkTheme && state.isHovered ? '#302F30' : `${({ theme }) => theme.body}`,
            ':hover': {
                backgroundColor: `${isDarkTheme ? '#302F30' : '#D9EDE7'}`,
            },
            color: isDarkTheme && state.isSelected ? '#1476FF' : `${({ theme }) => theme.text}`,
        }),
        indicatorsContainer: (styles) => ({
            ...styles, color: isDarkTheme ? 'white' : 'black', 'div:nth-child(2)': {
                color: isDarkTheme ? 'white' : 'black'
            }
        }),
        menu: (styles) => ({
            ...styles, background: isDarkTheme ? 'rgb(27, 27, 28)' : 'white',
            color: isDarkTheme ? 'white' : 'black',
            width: '20vw',
        })
    }
    
    const filterOptions = (candidate, input) => {
        if (!input) return true;
    
        const inputValue = input.toLowerCase();
        const label = candidate.label.toLowerCase();
        
        return label.startsWith(inputValue) || label.includes(inputValue);
    };

    // let modifiedDropdownValue = { label: formik?.values.phoneCode ? formik.values.phoneCode.value : '', value: '' }
    // let placeholderText = modifiedDropdownValue.label ? '' : 'Search';
    return (
        <div className='ForgotBg w-full' style={{ minHeight: "100vh" }}>
            <div className='p-3 mb-2' style={{ minHeight: '0' }} onClick={() => navigate('/home')}>
                <img src={Logo} alt="" width={'140px'} />
            </div>
            <div className="d-flex justify-content-center align-items-center" >
                <Container className='card col-12 col-md-6 col-xl-4 py-4'>
                    <div className='card-head mt-4 d-flex flex-column'>
                        <img src={MailIcon} alt='' width='60px' className='align-self-center' />
                        <Heading className="pt-3">Forgot Password</Heading>
                    </div>
                    <div className='card-body'>
                        <form onSubmit={formik.handleSubmit}>
                            <div className='FormDiv d-flex py-2 m-0 flex-column'>
                                <div className='tabs-navigator'>
                                    <ul id='myTab' className='nav nav-tabs border-0'>
                                        <li className="nav-item mx-1 " >
                                            <label
                                                className='nav-link text-dark active p-0 bg-transparent border-0'
                                                id="emailed-tab"
                                                data-bs-toggle="tab"
                                                data-bs-target="#emailed"
                                                type="button"
                                                role="tab"
                                                aria-controls="emailed"
                                                onClick={() => {
                                                    setPhone('')
                                                    formik.setValues({
                                                        phone: ''
                                                    })
                                                }}
                                                aria-selected="true">
                                                Email
                                            </label>
                                        </li>
                                        <div className='fw-bold' style={{ margin: '0px 1px' }}>|</div>
                                        <li className="nav-item" >
                                            <label
                                                className='nav-link p-0 text-dark bg-transparent border-0'
                                                id="phone-tab"
                                                data-bs-toggle="tab"
                                                data-bs-target="#phone"
                                                type="button"
                                                role="tab"
                                                aria-controls="phone"
                                                onClick={() => {
                                                    setPhone('')
                                                    formik.setValues({
                                                        email: ''
                                                    })
                                                }}
                                                aria-selected="true">
                                                Phone Number
                                            </label>
                                        </li>
                                    </ul>
                                    <div className='tab-content' id='myTabContent'>
                                        <div className='tab-pane fade show active'
                                            id="emailed"
                                            role="tabpanel"
                                            aria-labelledby="emailed-tab">
                                            <div className='FormDiv d-flex py-2 m-0 mt-1 flex-column'>
                                                <Input
                                                    className='px-3 py-2'
                                                    id='email'
                                                    placeholder='Email Address'
                                                    name='email'
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.email}
                                                />
                                                {formik.touched.email && formik.errors.email ? (<div className='text-start px-2 auth-error text-danger'>{formik.errors.email}</div>) : null}
                                            </div>
                                        </div>
                                        <div className='tab-pane fade'
                                            id="phone"
                                            role="tabpanel"
                                            aria-labelledby="phone-tab">
                                            <div className='FormDiv d-flex justify-content-between gap-2 py-2 m-0 mt-1'>
                                                <div className='forgotbymobile'>
                                                <Select
                                                    options={phoneCode}
                                                    value={phone}
                                                    // onChange={(selectedValues) => {
                                                    //     setPhone(selectedValues)
                                                    // }}
                                                    onChange={(selectedValues) => {
                                                        let arr = { label: selectedValues.value, value: '' }
                                                        setPhone(arr)
                                                      }}
                                                    filterOption={filterOptions}
                                                    placeholder='Search'
                                                    className='signupdiv'
                                                    styles={styles}
                                                />
                                                <InputPhone
                                                    id="phone"
                                                    placeholder='Mobile Number'
                                                    type="text"
                                                    value={formik.values.phone}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    className={
                                                        formik.errors.phone && formik.touched.phone
                                                            ? "text-input error px-3 py-2"
                                                            : "text-input px-3 py-2"
                                                    }
                                                />
                                                </div>
                                            </div>
                                            <small>
                                                {formik.errors.phone && formik.touched.phone && (
                                                    <div className="input-feedback text-danger auth-error text-start">{formik.errors.phone}</div>
                                                )}
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className='FormDiv d-flex m-0 mt-3 flex-column'>
                                <Input
                                    className='px-2 py-2'
                                    id="email"
                                    name="email"
                                    placeholder='Email Address'
                                    type="text"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.email}
                                />
                                <small className='text-end text-danger'>
                                    {formik.touched.email && formik.errors.email ? (
                                        <div className='text-danger text-start auth-error'>{formik.errors.email}</div>
                                    ) : null}
                                </small>
                            </div> */}
                            <div className='d-flex flex-column justify-content-center mt-1'>
                                <button className='Button mt-1 d-flex justify-content-center' type="submit">
                                    {
                                        isLoading ?
                                            <> <ButtonLoader /> <span>Sending..</span></> : 'Reset Password'
                                    }
                                </button>
                                <button className='ForgotButton' type='button' onClick={() => navigate('/login')}> Cancel</button>
                            </div>
                        </form>
                    </div>
                </Container>
            </div>
            {showModal && (<CustomModal
                show={showModal}
                handleClose={handleCloseModal}
                navigateLink={navigateLink}
                data={modalData}
            />)}
        </div>
    )
}
