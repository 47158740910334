// import React, {useEffect, useState} from 'react'
// import { BsFillSendFill } from 'react-icons/bs'
// import { GoPlus } from "react-icons/go";
// import { LuCornerRightDown } from "react-icons/lu";
// import QRCode from "react-qr-code";
// import './Wallet.css'
// import {useFormik} from 'formik'
// import * as Yup from 'yup'
// import {useAuthContextHook} from '../../Context/AuthContext'
// import axios from 'axios';
// import { API_URLs } from '../../Utils/ApiUrls';
// import { RxCross2, RxCopy } from "react-icons/rx";
// import { FaEye } from "react-icons/fa";
// import styled from 'styled-components';
// import DataTable, {createTheme}from 'react-data-table-component';
// import { Modal } from 'react-bootstrap';
// import CustomModal from '../../hooks/CustomModal';
// import { Input1 } from '../ProfileNew/StyledComponent';
// import { useThemeContext } from '../../Context/ThemeContext';
// const Heading = styled.p`
// font-size: 20px;
// font-weight: 700;
// margin-bottom: 0px;
// `
// const Copy = styled.button`
// border: 1px solid #ab9f9f;
// padding: 10px;
// font-weight: 500;
// border-radius: 10px;
//   &:active{
//     transform: scaleX(1.1);
// }
// `
// const Label = styled.label`
// font-size: 14px;
// font-weight: 600;
// color: gray;
// `
// const InputContainer = styled.div`
// border-radius: 20px;
// padding: 0px 13px;
// display: flex;
// background: ${({theme})=> theme.background};
// justify-content: space-between;
// border: ${({theme})=> theme.border};
// box-shadow: ${({theme})=> theme.boxshadow};
// `
// const Input = styled.input`
// border: ${({theme})=> theme.border};
// box-shadow: ${({theme})=> theme.boxshadow};
// background: ${({theme})=> theme.background};
// color: ${({theme})=> theme.text};
// padding: 10px;
// outline: none;
// border-radius: 20px;
// `
// const Textarea = styled.textarea`
// border-radius: 20px;
// padding: 0px 13px;
// display: flex;
// outline: none;
// height: 4rem;
// background: ${({theme})=> theme.background};
// color: ${({theme})=> theme.text};
// justify-content: space-between;
// border: ${({theme})=> theme.border};
// box-shadow: ${({theme})=> theme.boxshadow};

// `
// const SubHeading = styled.p`
// font-size: 13px;
// color: #6F7182;
// font-weight: 600;
// `
// const Button = styled.button`
// margin: 10px;
// color: white;
// background: #1476FF;
// border: none;
// padding: 4px 8px;
// width: 6.2rem;
// font-weight: 500;
// font-size: 15px;
// border-radius: 20px;
// `
// const Span1 = styled.span`
// font-size: 15px;
// font-weight: 500;
// color: #443d3d;
// `
// const Span = styled.span`
// font-size: 13px;
// font-weight: 500;
// color: #3e3e3e;
// `
// const QRDiv = styled.div`
// border: ${({theme})=> theme.border};
// padding: 9px;
// border-radius: 10px;
// height: 13rem;
// width: 13rem;
// box-shadow: ${({theme})=> theme.boxshadow};
// `
// const Div = styled.div`
// border: ${({theme})=> theme.border};
// border-radius: 20px;
// box-shadow: ${({theme})=> theme.boxshadow};
// background: ${({theme})=> theme.body=='#F7F9FC' ? 'white' : theme.body};
// `
// export default function Lightning() {
//     const {isDarkTheme} = useThemeContext();
//     const columns=[
//         {
//             name: 'Amount',
//             selector: (row)=><div>
//                 {row?.btc} BTC
//             </div>
//         },
//         {
//             name: 'Description',
//             selector: (row)=><div>
//                 {row?.description ? row?.description : '-'}
//             </div>
//         },
//         {
//             name: 'Status',
//             selector: (row)=><div>
//                 {row?.status}
//             </div>
//         },
//         {
//             name: 'Action',
//             cell: (row)=><div role='button' onClick={()=>handlegetDetails(row?._id)}>
//                 View <FaEye/>
//             </div>
//         }
//     ]

    
//     createTheme('solarized', {
//         background: {
//             default: `${isDarkTheme ? "#0c0c0c" : 'white'}`
//         },
//         action: {
//             hover: 'rgba(0,0,0,.08)',
//         },
//         text: {
//             secondary: `${isDarkTheme ? "white" : 'black'}`
//         },
//     });
//     const customStyles = {
//         header: {
//             style: {
//                 fontSize: '20px',
//                 color: '#0B0E2C',
//                 fontFamily: 'Inter',
//                 fontStyle: 'normal',
//                 fontWeight: '600',
//             },
//         },
//         headRow: {
//             style: {
//                 fontFamily: 'Inter',
//                 fontStyle: 'normal',
//                 fontSize: '12px',
//                 ...( isDarkTheme ? {borderTop: '1px solid #302f30'}:
//                 { border: '1px solid lightgray'}),
//                 color: `${isDarkTheme ? "#e9e9e9":'#6F7182'}`,
//                 backgroundColor: `${isDarkTheme ? "#0c0d0d":"#F7F9FC"}`,
//                 textTransform: 'uppercase'
//             },
//         },
//         rows: {
//             style: {
//                 color: `${isDarkTheme && 'white'}`,
//                 backgroundColor: `${isDarkTheme && 'rgba(8, 9, 14, 0.96)'}`,
//                 borderTop: '1px solid lightgray',
//                 '&:not(:last-of-type)': {
//                     borderBottom: 'none',
//                 },
//             }
//         },
//         headCells: {
//             style: {
//                 display: 'flex',
//                 alignItems: 'center'
//             },
//         },
//         cells: {
//             style: {
//                 display: 'flex',
//                 alignItems: 'center'
//             },
//         }
//     }
    
//     const {userDetails} = useAuthContextHook()
//     const [txnData, setTxnData] = useState([])
//     const [decodeData, setDecodeData] = useState({})
//     const [invoiceDetails,setInvoiceDetails] = useState({})
//     const[copied, setCopied] = useState(false)

//     const [showData, setShowData] = useState(false)
//     const [showModal, setShowModal] = useState(false);
//     const [navigateLink, setNavigateLink] = useState('')
//     const [modalData, setModalData] = useState(null);
//     const handleShowModal = (data) => {
//         setModalData(data);
//         setShowModal(true);
//     };
//     const handleCloseModal = () => {
//         setShowModal(false)
//     };

//     const convertHandle = async (currency, balance, crypto) => {
//         try {
//             let res = await axios.get(API_URLs.cryptoToFiat + '?from=' + crypto + '&amount=' + balance + '&target=' + currency)
//             return res.data.convertedRate;
//         } catch (error) {

//         }
//     }

//     // for recieve
//     const [convert, setConvert] = useState('')
//     const formikR = useFormik({
//         initialValues: {
//             amount: '',
//             message: '',
//         },
//         validationSchema: Yup.object().shape({
//             amount: Yup.string().required('Amount is Required'),
//             message: Yup.string()
//         }),
//         onSubmit: (values)=>{
//             axios.post(API_URLs.createInvoice,{
//                 "amount": values?.amount,
//                 ...(
//                     values?.message?.length > 0 && { "description": values?.message }
//                 )
//               },{
//                 headers: {
//                     Accept: 'application/json',
//                     Authorization: `Bearer ${localStorage.getItem('jwt')}`
//                 }
//             })
//             .then((res)=>{
//                 handleCloseRecieve()
//                 handlegetDetails(res?.data?.mongoId)
//                 getTheData()
//                 setNavigateLink('')
//             })
//             .catch((error)=>{
//                 handleShowModal(error?.response?.data?.message ? error?.response?.data?.message : 'Some Error Occured!!' )
//                 setNavigateLink('')
//                 handleCloseRecieve()
//             })
//         }
//     })
//     const handleConvert =async (data)=>{

//         let convertRate = await convertHandle(userDetails?.user?.currency?._id, data, "649990b9e757fa00277940b6")
//         setConvert(convertRate)
//     }
//     const[showRecieve,setShowRecieve]=useState(false)
//     const handleShowRecieve =()=>{
//         setShowRecieve(true);
//     }
//     const handleCloseRecieve =()=>{
//         setShowRecieve(false);
//     }    

//     // send modal
//     const formikS = useFormik({
//         initialValues: {invoice: ''},
//         validationSchema: Yup.object().shape({
//             invoice: Yup.string().required('Invoice is Required')
//         }),
//         onSubmit: (values)=>{
//             axios.post(API_URLs.sendBTCByLighning,{
//                 invoice: values?.invoice
//             },{
//                 headers: {
//                     Accept: 'application/json',
//                     Authorization: `Bearer ${localStorage.getItem('jwt')}`
//                 }
//             })
//             .then((res)=>{
//                 handleShowModal(res?.data?.msg ? res?.data?.msg : 'Invoice Generated Succesfully!!' )
//                 handleCloseSend()
//                 setNavigateLink('')
//             })
//             .catch((error)=>{
//                 handleShowModal(error?.response?.data?.message ? error?.response?.data?.message : 'Some Error Occured!!' )
//                 handleCloseSend()
//                 setNavigateLink('')
//             })
//         }
//     })

//     const handleDecode =(values)=>{
//         axios.get(API_URLs.decodeInvoice(values),{
//             headers: {
//                 Accept: 'application/json',
//                 Authorization: `Bearer ${localStorage.getItem('jwt')}`
//             }
//         })
//         .then((res)=>{
//             setDecodeData(res.data)
//             setShowData(true)
//         })
//         .catch((error)=>{
//         })
//     }

//     const[showSend,setShowSend]=useState(false)
//     const handleShowSend =()=>{
//         setShowSend(true);
//     }
//     const handleCloseSend =()=>{
//         setShowSend(false);
//         formikS.setValues({
//             invoice: ''
//         })
//         setDecodeData({})
//     }    


//     // show qr modal 
//     const handlegetDetails = (id)=>{
//         axios.get(API_URLs.getInvoiceStatus(id),{
//             headers: {
//                 Accept: 'application/json',
//                 Authorization: `Bearer ${localStorage.getItem('jwt')}`
//             }
//         })
//         .then((res)=>{
//             handleShowQR();
//             setInvoiceDetails(res?.data)
//         })
//         .catch((error)=>{

//         })
//     }
//     const[showQR,setShowQR]=useState(false)
//     const handleShowQR =()=>{
//         setShowQR(true);
//     }
//     const handleCloseQR =()=>{
//         setShowQR(false);
//         setCopied(false)
//     }
//     const getTheData =()=>{
//         const config = {
//             headers: {
//                 Accept: 'application/json',
//                 Authorization: `Bearer ${localStorage.getItem('jwt')}`
//             }
//         }
//         axios.all([axios.get(API_URLs.getLightningTxn, config), axios.get(API_URLs.getBalanceLightning, config)])
//         .then(axios.spread((res1, res2)=>{
//             setTxnData(res1.data);
//         }))
//         .catch(axios.spread((err1, err2)=>{
//         }))
//     }
//     useEffect(()=>{
//         getTheData()
//     },[])

//     const [currentTime, setCurrentTime] = useState(Date.now());
//     const targetTime = new Date(decodeData?.timeExpireDateString).getTime();
//     const timeBetween = targetTime - currentTime;
//     const seconds = Math.floor((timeBetween / 1000) % 60);
//     const minutes = Math.floor((timeBetween / 1000 / 60) % 60);
//     const hours = Math.floor((timeBetween / (1000 * 60 * 60)) % 24);
  
//     useEffect(() => {
//       const interval = setInterval(() => {
//         setCurrentTime(Date.now());
//       }, 1000);
  
//       return () => clearInterval(interval);
//     }, []);

//     return (
//         <>   
//             <div className='row m-0 d-flex justify-content-center align-items-center mt-5'>
//                 <Div className='col-md-7 p-3 card text-center'>
//                     <div className='mx-md-5 mx-0 px-2'>
//                         <Heading>Send and Receive Bitcoin</Heading>
//                         <Heading>on the lightning network</Heading>
//                         <SubHeading className='mb-0'>the easiest, lowest-cost, and fastest way to send and receive Bitcoin.</SubHeading>
//                         <div className='d-flex justify-content-center'>
//                             <Button onClick={()=>handleShowSend()}><BsFillSendFill /> Send</Button>
//                             <Button onClick={()=>handleShowRecieve()}><GoPlus /> Receive</Button>
//                         </div>

//                     </div>
//                 </Div>
//             </div>
//             <div className='row m-0 mt-5 d-flex justify-content-center align-items-center'>
//                 <Div className='px-0 card'>
//                     <p className='p-3 mb-0 fw-bold'>Transaction</p >
//                     <div className='pb-2'>
//                         <DataTable
//                             columns={columns}
//                             data={txnData}
//                             pagination
//                             theme='solarized'
//                             customStyles={customStyles}
//                         />
//                     </div>
//                 </Div>
//             </div>

//             {/* recieve modal */}
//             <Modal show={showRecieve} onHide={handleCloseRecieve}>
//                 <Modal.Header style={{ background: isDarkTheme ? '#0c0c0c' : 'white'}} className='d-flex justify-content-between align-items-center'>
//                     <p className='mb-0 fw-bold text-secondary'>Receive Payment</p>
//                     <RxCross2 color='grey' onClick={handleCloseRecieve}/>
//                 </Modal.Header>
//                 <Modal.Body style={{ background: isDarkTheme ? '#0c0c0c' : 'white'}}>
//                     <form onSubmit={formikR.handleSubmit}>
//                         <div>
//                             <Label htmlFor='amount'>I want to Receive</Label>
//                             <InputContainer className='d-flex'>
//                                 <Input1
//                                     id='amount'
//                                     name='amount'
//                                     className='px-2'
//                                     value={formikR.values.amount}
//                                     onChange={(event)=>{
//                                         handleConvert(event.target.value)
//                                         formikR.handleChange(event)
//                                     }}
//                                     onBlur={formikR.handleBlur}
//                                 />
//                                 <div>
//                                     <p className='fw-bold text-end mb-0'>BTC</p>
//                                     <small className='mb-0 fw-bold text-secondary'>={ convert > 0 &&  convert?.toFixed(2)} {userDetails?.user?.currency?.symbol}</small>
//                                 </div>
//                             </InputContainer>
//                         </div>
//                         <div className='d-flex flex-column mt-2'>
//                             <Label htmlFor='message'>Message (optional)</Label>
//                             <Textarea
//                                 id='message'
//                                 name='message'
//                                 value={formikR.values.message}
//                                 onChange={formikR.handleChange}
//                                 onBlur={formikR.handleBlur}
//                             />
//                         </div>
//                         <div className='d-flex justify-content-end'>
//                             <Button type='submit'>Continue</Button >
//                         </div>
//                     </form>
//                 </Modal.Body>
//             </Modal>

//             {/* send modal */}
//             <Modal show={showSend} onHide={handleCloseSend}>
//                 <Modal.Header style={{ background: isDarkTheme ? '#0c0c0c' : 'white'}} className='d-flex justify-content-between align-items-center'>
//                     <p className='mb-0'>Send Lightning Payment</p>
//                     <RxCross2 color='grey' onClick={handleCloseSend}/>
//                 </Modal.Header>
//                 <Modal.Body style={{ background: isDarkTheme ? '#0c0c0c' : 'white'}}>
//                     <form onSubmit={formikS.handleSubmit}>
//                         <div className='d-flex flex-column'>
//                             <Label htmlFor='invoice'>Invoice</Label>
//                             <Input
//                                 id='invoice'
//                                 name='invoice'
//                                 value={formikS.values.invoice}
//                                 onChange={formikS.handleChange}
//                                 onBlur={(event)=>{
//                                     formikS.handleBlur(event)
//                                     handleDecode(event.target.value)
//                                 }}
//                             />
//                         </div>
//                         <div className='d-flex justify-content-end'>
//                             <Button type='submit'>Pay</Button>
//                         </div>
//                     </form>
//                     {showData ? <div className='d-flex flex-column'>
//                         <div className='d-flex gap-1 align-items-center'>
//                             <Span1 className='fw-bold'>Amount: </Span1>
//                             <Span className='test-secondary'>{decodeData?.btc} BTC</Span>
//                         </div>
//                         <div className='d-flex gap-1 align-items-center'>
//                             <Span1 className='fw-bold'>Fee: </Span1>
//                             <Span className='test-secondary'>{decodeData?.Fee} BTC</Span>
//                         </div>
//                         <div className='d-flex gap-1 align-items-center'>
//                             <Span1 className='fw-bold'>Creation Time: </Span1>
//                             <Span className='test-secondary'>{new Date(decodeData?.timestampString).toDateString()}</Span>
//                         </div>
//                         <div className='d-flex gap-1 align-items-center'>
//                             <Span1 className='fw-bold'>Expire Time: </Span1>
//                             <Span className='test-secondary'>{new Date(decodeData?.timeExpireDateString).toDateString()}</Span>
//                         </div>
//                         <div className='d-flex gap-1 align-items-center'>
//                             <Span1 className='fw-bold'>Time Left: </Span1>
//                             <Span className='test-secondary'>{hours}:{minutes}:{seconds}</Span>
//                         </div>
//                     </div> : ''}
//                 </Modal.Body>
//             </Modal>

//             {/* QR modal */}
//             <Modal show={showQR} onHide={handleCloseQR}>
//                 <Modal.Header style={{ background: isDarkTheme ? '#0c0c0c' : 'white'}} className='d-flex justify-content-between'>
//                     <Modal.Title>Receive Payment</Modal.Title>
//                     <RxCross2 color='grey' onClick={handleCloseQR}/>
//                 </Modal.Header>
//                 <Modal.Body style={{ background: isDarkTheme ? '#0c0c0c' : 'white'}}>
//                     {
//                         !invoiceDetails?.status?.includes("failed") ?
//                         <div className='d-flex flex-column justify-content-center align-items-center'>
//                             <QRDiv className='mt-3'>
//                                 <QRCode
//                                     size={206}
//                                     style={{ height: "auto", maxWidth: "100%", width: "100%" }}
//                                     value={invoiceDetails?.payment_request ? invoiceDetails?.payment_request : 'Qvani Exchange Lightning' }
//                                 />
//                             </QRDiv>
//                             <p className='text-center mx-3'>Scan this QR code with a Lightning compatible wallet or share the invoice below.</p>
//                             <Copy className='mt-3' title={copied ? 'copied' :'click to copy'} 
//                             onClick={()=>{
//                                 navigator.clipboard.writeText(invoiceDetails?.payment_request)
//                                 setCopied(true)
//                             }}>
//                                 <RxCopy/>
//                                 {copied ? "Copied":"Copy Invoice"}
//                             </Copy> 
//                             <hr/>
//                             <LuCornerRightDown/>
//                             <p className='mb-1'>{invoiceDetails?.transactionType?.includes('credit') ? 'Incoming Amount' : 'Outgoing Amount'}</p>
//                             <h5>{invoiceDetails?.transactionType?.includes('credit') ? '+' : '-'} {invoiceDetails?.btc} BTC</h5>
//                             {
//                                 invoiceDetails?.description ? 
//                                 <>"{invoiceDetails?.description}"</>:''
//                             }
//                         </div> :
//                         <div className='text-center'>
//                             <LuCornerRightDown/>
//                             <p className='mb-1'>{invoiceDetails?.transactionType?.includes('credit') ? 'Incoming Amount' : 'Outgoing Amount'}</p>
//                             <h5>{invoiceDetails?.transactionType?.includes('credit') ? '+' : '-'} {invoiceDetails?.btc} BTC</h5>
//                             {
//                                 invoiceDetails?.description ? 
//                                 <>"{invoiceDetails?.description}"</>:''
//                             }
//                             <p className='fw-bold text-danger'>This trade is failed, as the time is expire!!</p>
//                         </div>
//                     }
//                 </Modal.Body>
//             </Modal>

//             {/* popups */}
//             {showModal && (<CustomModal
//                 show={showModal}
//                 handleClose={handleCloseModal}
//                 navigateLink={navigateLink}
//                 data={modalData}/>
//             )}
//         </>)         
// };