import React, { useState, useEffect } from "react";
import * as Yup from 'yup';
import axios from 'axios';
import styled from 'styled-components';
import Select from 'react-select'
import { useFormik } from "formik";
import { MdOutlineArrowBack } from 'react-icons/md'
import { API_URLs } from '../../Utils/ApiUrls';
import { useThemeContext } from '../../Context/ThemeContext';
import { darkTheme, lightTheme } from "../../Theme/theme";
import ButtonLoader from "../../Component/Loader/ButtonLoader";
import { Input, InputLabel } from "../Profile/StyledComponents";
import { useAPIContextHook } from "../../Context/ApiContext";
import PropTypes from 'prop-types';
import CustomModal from '../../hooks/CustomModal';

const Button = styled.button`
    width: 100%;
    background-color: #1476FF;
    color: white;
    border-radius: 15px;
    border: none;
`
const StartingValue = {
    accountType: '',
    Bankcountry: '',
    currency: '',
    BankName: '',
    holderName: '',
    customBankDetails: '',
    IFSC: '',
    accountNo: '',
    bicCode: '',
    CountryResidency: '',
    state: '',
    city: '',
    zipCode: '',
    address: ''
}

const option1 = [
    {
        value: 'personal',
        label: 'Personal Account'
    },
    {
        value: 'business',
        label: 'Business Account'
    },
];

export default function AddBankAccount({ handleClose, isBankEdit = false, singleBankDetails = {}, handleSetRefresh }) {

    const { isDarkTheme } = useThemeContext();
    const [loading, setLoading] = useState(false);
    const [next, setNext] = useState(false);
    const { countryOptions, customFilterForCountry, fiatCurrencyOptions, customFilterForCrypto, fetchStates, fetchCities } = useAPIContextHook()

    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [allBankList, setAllBankList] = useState([]);
    const [bankList, setBankList] = useState([]);

    const [showModal, setShowModal] = useState(false);
    const [modalData, setModalData] = useState(null);
    const [navigateLink, setNavigateLink] = useState('');
    const handleShowModal = (data) => {
        setModalData(data);
        setShowModal(true);
    };
    const handleCloseModal = () => {
        setShowModal(false)
    };

    const handleAddBankSubmit = (values) => {
        setLoading(true)
        axios.post(API_URLs.bankPost, {
            accountType: values.accountType?.value,
            Bankcountry: values.Bankcountry?.value,
            currency: values.currency?.value,
            BankName: values.BankName?.value,
            holderName: values.holderName,
            customBankDetails: values.customBankDetails,
            IFSC: values.IFSC,
            accountNo: values.accountNo,
            bicCode: values.bicCode,
            CountryResidency: values.CountryResidency?.value,
            state: values.state?.value,
            city: values?.city?.value,
            zipCode: values.zipCode,
            address: values.address,
        }, {
            headers: {
                "Authorization": `Bearer ${localStorage.getItem('jwt')}`,
                'Content-Type': 'application/json'
            }
        })
            .then((res) => {
                
                handleShowModal(res?.data?.msg ? res?.data?.msg : 'Details Added Successfully!!');
                setNavigateLink('');
                setLoading(false)
                handleClose();
                handleSetRefresh();
            })
            .catch((error) => {
                setLoading(false)
                handleShowModal(error?.response?.data?.message ? error?.response?.data?.message : "Some Error Occured");
                setNavigateLink('');
            })
    }

    const handleEditBankDetails = (values) => {
        setLoading(true)
        axios.patch(`${API_URLs.bankPost}/${singleBankDetails?.id}`, {
            accountType: values.accountType?.value,
            Bankcountry: values.Bankcountry?.value,
            currency: values.currency?.value,
            BankName: values.BankName?.value,
            holderName: values.holderName,
            customBankDetails: values.customBankDetails,
            IFSC: values.IFSC,
            accountNo: values.accountNo,
            bicCode: values.bicCode,
            CountryResidency: values.CountryResidency?.value,
            state: values.state?.value,
            city: values?.city?.value,
            zipCode: values.zipCode,
            address: values.address,
        }, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('jwt')}`
            }
        }).then((res) => {
            handleShowModal(res?.data?.msg ? res?.data?.msg : 'Details Updated Successfully!!');
            setNavigateLink('');
            setLoading(false)
            handleClose();
            handleSetRefresh()
        })
            .catch((error) => {
                setLoading(false)
                handleShowModal(error?.response?.data?.message ? error?.response?.data?.message : "Some Error Occured");
                setNavigateLink('');
            })
    }

    let nameregex = /^[a-zA-Z ]{2,50}$/
    let bicregex = /^[a-zA-Z0-9]{8,12}$/
    let numberregex = /^[0-9]+$/
    let address = /^[A-Za-z0-9\s\.,#\-]+$/
    const formik = useFormik({
        initialValues: StartingValue,
        validationSchema: Yup.object().shape({
            accountType: Yup.object().shape({
                value: Yup.string().required('Account Type is Required'),
            }),
            Bankcountry: Yup.object().shape({
                value: Yup.string().required("Bank Country is Required"),
            }),
            currency: Yup.object().shape({
                value: Yup.string().required("Currency is Required"),
            }),
            BankName: Yup.object().shape({
                value: Yup.string().required("Bank Name is Required"),
            }),
            holderName: Yup.string().min(3, 'Enter a Valid Account Holder Name').max(30, 'Enter a Valid Account Holder Name').matches(nameregex, "Please Enter alphabets('a-z', 'A-Z') only.").required('Holder Name is Required'),
            customBankDetails: Yup.string().max(30, 'Enter a valid Details'),
            IFSC: Yup.string().max(15, 'Enter a Valid IFSC Code').matches(bicregex, 'Enter a Valid IFSC Code, doesnot accept special characters.'),
            accountNo: Yup.string().min(6, 'Enter a Valid Account Number').max(30, 'Enter a Valid Account Number').matches(numberregex, "Please Enter numbers ('0-9') only").required('Account No. is Required'),
            bicCode: Yup.string().min(8, 'Enter a valid BIC Code').max(11, 'Enter a valid BIC Code').matches(bicregex, "Invalid BIC Code"),
            CountryResidency: Yup.object().shape({
                value: Yup.string(),
            }),
            state: Yup.object().shape({
                value: Yup.string(),
            }),
            city: Yup.object().shape({
                value: Yup.string(),
            }),
            zipCode: Yup.string().min(3, 'Enter a valid Zip Code').max(10, 'Enter a valid Zip Code').matches(numberregex, 'Enter a valid Zip Code'),
            address: Yup.string().min(3, 'Enter a valid Address').matches(address, 'Address is not accepting special characters.')
        }),
        onSubmit: (values) => {
            if (isBankEdit) {
                handleEditBankDetails(values)
            } else {
                handleAddBankSubmit(values);
            }
        }
    })

    const handleNext = (e) => {
        e.preventDefault();
        if (formik.values.accountType && formik.values.Bankcountry && formik.values.currency) {
            setNext(true)
        }
    }

    useEffect(() => {
        let accountType = option1.filter((option) => {
            return option.value === singleBankDetails.accountType
        })

        let bankCountry = countryOptions.filter((option) => {
            return option.value === singleBankDetails.Bankcountry?._id
        })

        let currency = fiatCurrencyOptions.filter((option) => {
            return option.value === singleBankDetails.currency?.id
        })

        let filteredCountryResidency = countryOptions.filter((option) => {
            return option.value === singleBankDetails.CountryResidency?._id
        })

        handleFetchState(filteredCountryResidency[0]?._id).then((stateList) => {

            let filteredState = stateList.filter((option) => {
                return option.value === singleBankDetails.state
            })

            

            handleFetchCity(filteredCountryResidency[0]?._id, filteredState[0]?.value).then((cityList) => {

                let filteredCity = cityList.filter((option) => {
                    return option.value === singleBankDetails.city
                })

                

                if (isBankEdit) {
                    formik.setValues({
                        accountType: accountType[0],
                        Bankcountry: bankCountry[0],
                        currency: currency[0],
                        BankName: {
                            value: singleBankDetails.BankName,
                            label: singleBankDetails.BankName,
                        },
                        holderName: singleBankDetails.holderName,
                        customBankDetails: singleBankDetails.customBankDetails,
                        IFSC: singleBankDetails.IFSC,
                        accountNo: singleBankDetails.accountNo,
                        bicCode: singleBankDetails.bicCode,
                        CountryResidency: filteredCountryResidency[0],
                        state: filteredState[0],
                        city: filteredCity[0],
                        zipCode: singleBankDetails.zipCode,
                        address: singleBankDetails.address
                    })
                }

            })

        })

    }, [isBankEdit, singleBankDetails])


    const handleFetchState = async (countryID) => {
        try {
            let list = []

            if (countryID) {
                let res = await fetchStates(countryID)

                let filteredCountry = countryOptions?.filter((country) => {
                    return country?.value === countryID
                })

                formik.setValues({
                    ...formik.values, CountryResidency: filteredCountry[0], state: "", city: ""
                })

                list = res.map((st) => {
                    return {
                        value: st?.code,
                        label: st?.name
                    }
                })

                setStateList(list)
            }
            
            return list
        } catch (error) {
        }
    }

    const handleFetchCity = async (countryId, stateId) => {
        try {
            let list = [];

            if (countryId && stateId) {
                let res = await fetchCities(countryId, stateId)

                let filteredState = stateList?.filter((state) => {
                    return state?.value === stateId
                })

                formik.setValues({
                    ...formik.values, state: filteredState[0], city: ""
                })
                list = res.map((ct) => {
                    return {
                        value: ct?.name,
                        label: ct?.name
                    }
                })

                setCityList(list)
            }

            return list
        } catch (error) {

            return []
        }
    }

    const getAllBanks = () => {
        axios.get(API_URLs.allBanks, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('jwt')}`
            }
        }).then((res) => {

            setAllBankList(res.data)
        }).catch((err) => {

        })
    }

    useEffect(() => {
        getAllBanks()
    }, [])

    useEffect(() => {
        let filteredBanks = allBankList.filter((bank) => {
            return String(bank?.name).toLowerCase() === String(formik.values.Bankcountry?.name).toLowerCase()
        })
        let selectOptions = filteredBanks[0]?.bank?.map((bank) => {
            return ({
                label: bank,
                value: bank
            })
        })

        console.log('filteredBanks', selectOptions);
        setBankList(selectOptions)
    }, [allBankList, formik.values.Bankcountry])

    const styles = {
        control: (styles) => ({
            ...styles,
            background: 'transparent',
            borderRadius: '52px',
            width: '100%',
            height: '50px',
            color: 'white',
            boxShadow: `${isDarkTheme ? darkTheme.boxshadow : lightTheme.boxshadow}`,
            border: `${isDarkTheme ? darkTheme.border : lightTheme.border}`, "&:hover": {
                border: `${isDarkTheme ? darkTheme.border : lightTheme.border}`
            },
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: isDarkTheme && state.isHovered ? '#302F30' : `${({theme})=> theme.body}`,
            ':hover': {
              backgroundColor:  `${isDarkTheme ? '#302F30' : '#D9EDE7'}` ,
            },
            color: isDarkTheme && state.isSelected ? '#1476FF' : `${({theme})=> theme.text}`,
        }),
        indicatorSeparator: () => ({
            all: 'unset'
        }),
        indicatorsContainer: (styles) => ({
            ...styles, color: isDarkTheme ? 'white' : 'black', 'div:nth-child(2)': {
                color: isDarkTheme ? 'white' : lightTheme.text
            }
        }),
        menu: (styles) => ({
            ...styles, background: isDarkTheme ? 'rgb(27, 27, 28)' : 'white',
            color: isDarkTheme ? 'white' : lightTheme.text,
        })
    }
    return (
        <>
            {
                next ?
                    <form onSubmit={formik.handleSubmit}>
                        <MdOutlineArrowBack onClick={() => setNext(false)} />
                        <div className='mt-3 d-flex flex-column'>
                            <InputLabel htmlFor="BankName">Bank Name<span className="text-danger"> *</span></InputLabel>

                            <Select options={bankList}
                                styles={styles}
                                name='BankName'
                                id='BankName'
                                onChange={(selectedValues) => {
                                    let event = { target: { name: 'BankName', value: selectedValues } }
                                    formik.handleChange(event)
                                }}
                                onBlur={(selectedValues) => {
                                    let event = { target: { name: 'BankName', value: selectedValues } }
                                    formik.handleBlur(event)
                                }}
                                value={formik.values.BankName}
                                placeholder="Select Bank"
                            />
                            <small>
                                {formik.errors.BankName && formik.touched.BankName && (
                                    <div className="input-feedback text-danger auth-error text-start">{formik.errors.BankName?.value}</div>
                                )}
                            </small>


                            <div className='mt-3 d-flex flex-column'>
                                <InputLabel htmlFor="holderName">Account Holder's Name<span className="text-danger"> *</span></InputLabel>

                                <Input
                                    type="text"
                                    placeholder='Enter Account Holder Name'
                                    name="holderName"
                                    id='holderName'
                                    value={formik.values.holderName}
                                    className="px-3 py-2"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />

                                <small>
                                    {formik.errors.holderName && formik.touched.holderName && (
                                        <div className="input-feedback text-danger auth-error text-start">{formik.errors.holderName}</div>
                                    )}
                                </small>
                            </div>
                            <div className='mt-3 d-flex flex-column'>
                                <InputLabel htmlFor="customBankDetails">Custom Bank Details</InputLabel>

                                <Input
                                    type="text"
                                    placeholder="Enter Custom Bank Details"
                                    name="customBankDetails"
                                    id='customBankDetails'
                                    value={formik.values.customBankDetails}
                                    className="px-3 py-2"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur} />

                                <small>
                                    {formik.errors.customBankDetails && formik.touched.customBankDetails && (
                                        <div className="input-feedback text-danger auth-error text-start">{formik.errors.customBankDetails}</div>
                                    )}
                                </small>
                            </div>
                            <div className='mt-3 d-flex flex-column'>
                                <InputLabel htmlFor="IFSC">IFSC</InputLabel>

                                <Input
                                    placeholder='Enter IFSC Code'
                                    type="text"
                                    id='IFSC'
                                    name="IFSC"
                                    value={formik.values.IFSC}
                                    className="px-3 py-2"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />

                                <small>
                                    {formik.errors.IFSC && formik.touched.IFSC && (
                                        <div className="input-feedback text-danger auth-error text-start">{formik.errors.IFSC}</div>
                                    )}
                                </small>
                            </div>
                            <div className='mt-3 d-flex flex-column'>
                                <InputLabel htmlFor="accountNo">Account Number<spam className='text-danger'> *</spam></InputLabel>

                                <Input
                                    placeholder='Enter Account Number'
                                    type="text"
                                    name="accountNo"
                                    id='accountNo'
                                    value={formik.values.accountNo}
                                    className="px-3 py-2"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />

                                <small>
                                    {formik.errors.accountNo && formik.touched.accountNo && (
                                        <div className="input-feedback text-danger auth-error text-start">{formik.errors.accountNo}</div>
                                    )}
                                </small>
                            </div>

                            <div className='mt-3 d-flex flex-column'>
                                <InputLabel htmlFor="bicCode">SWIFT / BIC code</InputLabel>

                                <Input
                                    placeholder='Enter SWIFT / BIC code'
                                    type="text"
                                    name="bicCode"
                                    id='bicCode'
                                    value={formik.values.bicCode}
                                    className="px-3 py-2"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />

                                <small>
                                    {formik.errors.bicCode && formik.touched.bicCode && (
                                        <div className="input-feedback text-danger auth-error text-start">{formik.errors.bicCode}</div>
                                    )}
                                </small>
                            </div>
                            <div className='mt-3 d-flex flex-column'>
                                <InputLabel htmlFor="CountryResidency">Country</InputLabel>
                                <Select options={countryOptions}
                                    filterOption={customFilterForCountry}
                                    placeholder='Select Country'
                                    styles={styles}
                                    name='CountryResidency'
                                    id='CountryResidency'
                                    onChange={async (selectedValues) => {
                                        let event = { target: { name: 'CountryResidency', value: selectedValues } }
                                        formik.handleChange(event)
                                        handleFetchState(selectedValues?.value)
                                    }}
                                    onBlur={(selectedValues) => {
                                        let event = { target: { name: 'CountryResidency', value: selectedValues } }
                                        formik.handleBlur(event)
                                    }}

                                    value={formik.values.CountryResidency}
                                />
                                <small>
                                    {formik.errors.CountryResidency && formik.touched.CountryResidency && (
                                        <div className="input-feedback text-danger auth-error text-start">{String(formik.errors.CountryResidency?.value).replace('.value', '')}</div>
                                    )}
                                </small>
                            </div>
                            <div className='mt-3 d-flex flex-column'>
                                <InputLabel htmlFor="state">State</InputLabel>
                                <Select options={stateList}
                                    placeholder='Select State'
                                    styles={styles}
                                    name='state'
                                    id='state'
                                    onChange={(selectedValues) => {
                                        let event = { target: { name: 'state', value: selectedValues } }
                                        formik.handleChange(event)
                                        handleFetchCity(formik.values.CountryResidency?.value, selectedValues?.value)
                                    }}
                                    onBlur={(selectedValues) => {
                                        let event = { target: { name: 'state', value: selectedValues } }
                                        formik.handleBlur(event)
                                    }}
                                    value={formik.values.state}
                                />
                                <small>
                                    {formik.errors.state && formik.touched.state && (
                                        <div className="input-feedback text-danger auth-error text-start">{String(formik.errors.state?.value).replace('.value', '')}</div>
                                    )}
                                </small>
                            </div>
                            <div className='mt-3 d-flex flex-column'>
                                <InputLabel htmlFor="city">City</InputLabel>

                                <Select options={cityList}
                                    placeholder='Select City'
                                    styles={styles}
                                    name='city'
                                    id='city'
                                    onChange={(selectedValues) => {
                                        let event = { target: { name: 'city', value: selectedValues } }
                                        formik.handleChange(event)
                                    }}
                                    onBlur={(selectedValues) => {
                                        let event = { target: { name: 'city', value: selectedValues } }
                                        formik.handleBlur(event)
                                    }}
                                    value={formik.values.city}
                                />

                                <small>
                                    {formik.errors.city && formik.touched.city && (
                                        <div className="input-feedback text-danger auth-error text-start">{formik.errors.city?.value}</div>
                                    )}
                                </small>
                            </div>
                            <div className='mt-3 d-flex flex-column'>
                                <InputLabel htmlFor="zipCode">Zip Code</InputLabel>

                                <Input
                                    placeholder='Enter Zip Code'
                                    type="text"
                                    name="zipCode"
                                    id='zipCode'
                                    value={formik.values.zipCode}
                                    className="px-3 py-2"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />

                                <small>
                                    {formik.errors.zipCode && formik.touched.zipCode && (
                                        <div className="input-feedback text-danger auth-error text-start">{formik.errors.zipCode}</div>
                                    )}
                                </small>
                            </div>
                            <div className='mt-3 d-flex flex-column'>
                                <InputLabel htmlFor="address">Address</InputLabel>

                                <Input
                                    placeholder='Enter your Address'
                                    type="text"
                                    name="address"
                                    id='address'
                                    value={formik.values.address}
                                    className="px-3 py-2"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                            </div>
                            <small>
                                {formik.errors.address && formik.touched.address && (
                                    <div className="input-feedback text-danger auth-error text-start">{formik.errors.address}</div>
                                )}
                            </small>
                        </div>
                        <div>
                            <Button disabled={loading} className="my-3 py-2 d-flex justify-content-center align-items-center" type="submit">
                                {
                                    loading ?
                                        <> <ButtonLoader /> <span>{isBankEdit ? 'Update' : 'Submit'}</span></> : isBankEdit ? 'Update' : 'Submit'
                                }
                            </Button>
                        </div>
                    </form> :
                    <form onSubmit={formik.handleSubmit} className="d-flex flex-column gap-2">
                        <div className='col-md-12'>
                            <InputLabel htmlFor='accountType'>Account Type<span className="text-danger"> *</span></InputLabel>
                            <Select options={option1}
                                styles={styles}
                                name='accountType'
                                id='accountType'
                                onChange={(selectedValues) => {
                                    let event = { target: { name: 'accountType', value: selectedValues } }
                                    formik.handleChange(event)
                                }}
                                onBlur={(selectedValues) => {
                                    let event = { target: { name: 'accountType', value: selectedValues } }
                                    formik.handleBlur(event)
                                }}
                                value={formik.values.accountType}
                            />
                            <small>
                                {formik.errors.accountType && formik.touched.accountType && (
                                    <div className="input-feedback text-danger auth-error text-start">{String(formik.errors.accountType?.value).replace('.value', '')}</div>
                                )}
                            </small>
                        </div>
                        <div className='col-md-12'>
                            <InputLabel htmlFor='Bankcountry'>Bank Account Country<span className="text-danger"> *</span></InputLabel>
                            <Select options={countryOptions}
                                filterOption={customFilterForCountry}
                                styles={styles}
                                name='Bankcountry'
                                id='Bankcountry'
                                onChange={(selectedValues) => {
                                    let event = { target: { name: 'Bankcountry', value: selectedValues } }
                                    formik.handleChange(event)
                                }}
                                onBlur={(selectedValues) => {
                                    let event = { target: { name: 'Bankcountry', value: selectedValues } }
                                    formik.handleBlur(event)
                                }}
                                value={formik.values.Bankcountry}
                            />
                            <small>
                                {formik.errors.Bankcountry && formik.touched.Bankcountry && (
                                    <div className="input-feedback text-danger auth-error text-start">{String(formik.errors.Bankcountry?.value).replace('.value', '')}</div>
                                )}
                            </small>
                        </div>
                        <div className='col-md-12'>
                            <InputLabel htmlFor='currency'>Currency<span className="text-danger"> *</span></InputLabel>
                            <Select options={fiatCurrencyOptions}
                                filterOption={customFilterForCrypto}
                                styles={styles}
                                name='currency'
                                id='currency'
                                onChange={(selectedValues) => {
                                    let event = { target: { name: 'currency', value: selectedValues } }
                                    formik.handleChange(event)
                                }}
                                onBlur={(selectedValues) => {
                                    let event = { target: { name: 'currency', value: selectedValues } }
                                    formik.handleBlur(event)
                                }}
                                value={formik.values.currency}
                            />
                            <small>
                                {formik.errors.currency && formik.touched.currency && (
                                    <div className="input-feedback text-danger auth-error text-start">{String(formik.errors.currency?.value).replace('.value', '')}</div>
                                )}
                            </small>
                        </div>
                        <div>
                            <Button disabled={loading} type='button' className="my-3 py-2" onClick={handleNext}>Next</Button>
                        </div>
                    </form>
            }
            {showModal && (<CustomModal
                show={showModal}
                handleClose={handleCloseModal}
                navigateLink={navigateLink}
                data={modalData}
            />)}
        </>
    );
}
AddBankAccount.propTypes = {
    isBankEdit: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleSetRefresh: PropTypes.func.isRequired,
    singleBankDetails: PropTypes.object.isRequired,
};