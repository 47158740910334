import React from 'react'
import PaymentMethod from './PaymentMethod';
import PaymentMethodUPI from './PaymentMethodUPI';
import PaymentMethodCard from './PaymentMethodCard';
import PaymentMethodPaypal from './PaymentMethodPaypal';
export default function PaymentMethodIndex() {
    
    return (
        <div className='tab-navigator'>
            <ul className="nav nav-tabs" id="myTab">
                <li className="nav-item" >
                    <button
                        className={'nav-link active'}
                        id="bank-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#bank"
                        type="button"
                        role="tab"
                        aria-controls="bank"
                        aria-selected="true"
                    >
                        Bank Account
                    </button>
                </li>
                <li className="nav-item" >
                    <button
                        className={'nav-link'}
                        id="upi-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#upi"
                        type="button"
                        role="tab"
                        aria-controls="upi"
                        aria-selected="false"
                    >
                        UPI
                    </button>
                </li>
                <li className="nav-item" >
                    <button
                        className={'nav-link'}
                        id="paypal-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#paypal"
                        type="button"
                        role="tab"
                        aria-controls="paypal"
                        aria-selected="false"
                    >
                        PayPal
                    </button>
                </li>
                <li className="nav-item" >
                    <button
                        className={'nav-link'}
                        id="card-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#card"
                        type="button"
                        role="tab"
                        aria-controls="card"
                        aria-selected="false"
                    >
                        Credit/Debit Card
                    </button>
                </li>
            </ul>
            <div className="tab-content" id="myTabContent">
                <div
                    className={'tab-pane fade py-3 px-2 show active'}
                    id="bank"
                    role="tabpanel"
                    aria-labelledby="bank-tab"
                >
                    <PaymentMethod />
                </div>
                <div
                    className={'tab-pane fade py-3 px-2'}
                    id="upi"
                    role="tabpanel"
                    aria-labelledby="upi-tab"
                >
                    <PaymentMethodUPI />
                </div>
                <div
                    className={'tab-pane fade py-3 px-2'}
                    id="paypal"
                    role="tabpanel"
                    aria-labelledby="paypal-tab"
                >
                    <PaymentMethodPaypal />
                </div>
                <div
                    className={'tab-pane fade py-3 px-2'}
                    id="card"
                    role="tabpanel"
                    aria-labelledby="card-tab"
                >
                    <PaymentMethodCard />
                </div>
            </div>
        </div>
    );
}