import React from 'react'
import img from '../../images/Home/Image1.png'
import { logo, Logo, Logo1, logo1 } from '../../Component/Footer/Styled'
import './index.css'
import styled from 'styled-components'
import { Link } from 'react-router-dom'


const P = styled.p`
font-size: 40px;
font-weight: 700;
margin-bottom: 7px;
color: #222222;
@media only screen and (max-width: 600px) {
    text-align: center;
}
`

export const Div = styled.div`
    width: 250px;
    height: 180px;
    background-color: #F0F1FF;
    border: 1px solid #D7DAFC;
    border-radius: 12px;
`

const isEmail = (subhead) => subhead.includes('@');

export default function About() {
    return (
        <div className='container bg-white overflow-hidden'>
            <div className='row py-5 m-0'>
                <div>
                    <P>Contacts</P>
                </div>
                <div className='d-flex flex-column gap-2 mt-5 flex-wrap justify-content-center align-items-center '>
                    <p className='d-md-none d-lg-none fs-3 fw-bold text-center'>Join the Qvani Community</p>
                    <p className='fs-3 fw-bold text-center'>We'd love to hear from you</p>
                    <div className='d-flex flex-wrap justify-content-center gap-3'>
                    {
                        logo1.map((data) => {
                            const href = isEmail(data.subhead) ? `mailto:${data.subhead}` : `https://${data.subhead}`;

                            return <a href={href} target='_blank' className='text-decoration-none'>
                                <Div className='d-flex justify-content-center align-items-center flex-column'>
                                    <Logo1>{data.img}</Logo1>
                                    <p className='mb-0 fw-bold text-decoration-none text-dark'>{data.head}</p>
                                    <p className='text-decoration-none text-secondary fw-semibold'>{data.subhead}</p>
                                </Div>
                            </a>
                        })
                    }
                    </div>
                </div>
            </div>
            <div className='py-5 mt-3 d-flex flex-column justify-content-center'>
                <div className='fw-bold fs-4 mx-auto'>
                    <p className='text-center fs-3'>Join the Qvani Community on Social Media</p>
                </div>
                <div className='d-flex flex-wrap gap-4 justify-content-center align-items-center '>
                    {
                        logo.map((data) => {
                            return <div className='d-flex justify-content-center align-items-center rounded-3' style={{width: "100px", height: "100px", backgroundColor: "#EFF0FE"}}>
                                <Logo as={Link} target='_blank' to={data.link}>{data.logo}</Logo>
                                </div>
                           
                        })
                    }
                </div>
            </div>
        </div>
    );
}