import styled from 'styled-components';

export const P = styled.p`
color: #092256;
font-size: 27px;
font-weight: 700;
`
export const Small = styled.small`
font-size: 14px;
font-weight: 400;
color: #092256;
margin-botton: 30px;
`
export const Card = styled.div`
width: 460px;
padding: 20px;
border-radius: 20px;
border: none;
box-shadow: 0px 0px 10px #1979c21c;
.steppers{
    width: 100%;
    display: flex;
    gap: 8px;
    align-items: center;
}
.active{
    background: #1476FF !important;
}
.count{
    font-size: 13px;
}
.step{
    width: 40%;
    background: aliceblue;
    border-radius: 20px;
    height: 1.7vh;
}
`
export const Row = styled.div`
height: 75vh;
display: flex;
justify-content: center;
align-items: center;
`
export const Label = styled.label`
font-size: 14px;
font-weight: 600;
color: #303350;
`
export const Input = styled.input`
border: none;
outline: none;
width: 97%;
padding: 7px;
border-radius: 20px;
`
export const Button = styled.button`
width: 100%;
border: none;
outline: none;
padding: 8px;
color: white;
background: #1476FF;
border-radius: 20px;
box-shadow: 0px 0px 10px #0257FB30;
`