import React, { useEffect, Suspense } from 'react'
import { Link, Navigate, Route, Routes } from 'react-router-dom';
import './App.css';
import 'react-toastify/dist/ReactToastify.css'
import { ThemeProvider } from 'styled-components';
import { ToastContainer } from 'react-toastify';
import { ProSidebarProvider } from 'react-pro-sidebar'
import AuthContext, { useAuthContextHook }  from './Context/AuthContext';
import WalletContext from './Context/WalletContext';
import ThemeContext, { ThemeContextVar } from './Context/ThemeContext';
import { lightTheme, darkTheme, GlobalStyles } from './Theme/theme';
import ApiContext from './Context/ApiContext';
import ProgressBar from './hooks/use-nprogress';
import Home from './otherPages/Home/Index'
import CookieConsent from "react-cookie-consent";
import User from './pages/User/User'
import ScrollToTop from './hooks/ScrollToTop'
import Rewards from './otherPages/Rewards/Rewards.jsx'
import Tems from './otherPages/Tems/Index.jsx'
import Privacy from './otherPages/Privicy/Index.jsx'
import Affiliate from './otherPages/Affiliate/Index.jsx'
import Notification from './pages/Notification/Notification'
import Login from './pages/Auth/Login/Login'
import Contact from './otherPages/Contact/Index'
import About from './otherPages/About/Index'
import CreateOffer from './pages/CreateOffer/Index'
import Buy from './pages/Buy/Index'
import Sell from './pages/Sell/Index'
import ViewOffer from './pages/ViewOffer/Index'
import ViewMyOffer from './pages/ViewMyOffer/Index'
import Settings from './pages/Settings/Index'
import Dashboard from './pages/Dashoard/Index'
import MainLayout from './Layouts/MainLayout'
import LoaderLayout from './Layouts/LoaderLayout'
import ProtectedRoutes from './Layouts/ProtectedRoutes'
import Error404 from './pages/Error404'
import LoginOtp from './pages/Auth/Login/LoginOtp'
import TwoFaLogin from './pages/Auth/Login/TwoFaLogin'
import SignUpPage from './pages/Auth/SignUp/SignUpPage'
import SignUpOtp from './pages/Auth/SignUp/Otp'
import PhoneVerification from './pages/Auth/SignUp/PhoneVerification.jsx'
import ResetPassword from './pages/Auth/ResetPassword'
import ForgotPassword from './pages/Auth/Forgot'
import Wallet from './pages/Wallet/WalletTab'
import Dispute from './pages/ViewDispute/Index'
import Kyc from './pages/Kyc/Index'
import AddProfile from './pages/AddProfile'
import TradeScreen from './pages/TradeScreen/Index1'
import ActiveTrades from './pages/ActiveTrades/Index'
import PublicLayout from './Layouts/PublicLayout.jsx';
import ComingSoon from './pages/ComingSoon.jsx';
function App() {

  useEffect(() => {
    console.clear();
    console.warn = function () { };
    console.error = function () { };

    return () => {
      console.clear();
      console.warn = consoleWarn;
      console.error = consoleError;
    };
  }, []);

  const consoleWarn = console.warn.bind(console);
  const consoleError = console.error.bind(console);

  return (
    <>
      <Suspense >
        <ProSidebarProvider>
          <ThemeContext>
            <AuthContext>
              <WalletContext>
                <ApiContext>
                  <ThemeContextVar.Consumer>
                    {
                      (theme) => (
                        <ThemeProvider theme={theme?.isDarkTheme ? darkTheme : lightTheme}>
                          <GlobalStyles />
                          <ProgressBar />
                          <ScrollToTop />
                          <div dir={theme.direction}>
                            <Routes>
                              <Route exact path='' element={<LoaderLayout />} >
                                <Route path='' element={<Navigate to={localStorage.getItem('jwtOut') ? '/dashboard/user-dashboard' : '/home'} />} />
                                <Route path='login' element={<Login />} />
                                <Route path='coming-soon' element={<ComingSoon/>}/>
                                <Route path='login/otp' element={<LoginOtp />} />
                                <Route path='signup' element={<SignUpPage />} />
                                <Route path='signup/:referId' element={<SignUpPage />} />
                                <Route path='signup/otp' element={<SignUpOtp />} />
                                <Route path='signup/phone-verification' element={<PhoneVerification/>} />
                                
                                <Route path='' element={<PublicLayout/>}>
                                  { !localStorage.getItem('jwtOut') && <Route path='/home' element={<Home />} />}
                                  <Route path='about' element={<Contact />} />
                                  <Route path='contact' element={<About />} />
                                  <Route path='terms' element={<Tems />} />
                                  <Route path='privacy-policy' element={<Privacy />} />
                                  <Route path='affiliate-programs' element={<Affiliate />} />
                                  <Route path='rewards' element={<Rewards />} />
                                </Route>
                                
                                <Route path='forgot-password' element={<ForgotPassword />} />
                                <Route path='reset-password' element={<ResetPassword />} />
                                <Route path='two-fa-login' element={<TwoFaLogin />} />

                                <Route path='dashboard' element={<MainLayout />} >
                                  <Route path='' element={<ProtectedRoutes />}>
                                    <Route path='user-dashboard' element={<Dashboard />} />
                                    <Route path='user/:id' element={<User />} />
                                    <Route path='wallet' element={<Wallet />} />
                                    <Route path='createoffer/:id' element={<CreateOffer />} />
                                    <Route path='create-offer' element={<CreateOffer />} />
                                    <Route path='offer/:offerId' element={<ViewOffer />} />
                                    <Route path='sell/:currency' element={<Sell />} />
                                    <Route path='buy/:currency' element={<Buy />} />
                                    <Route path='sell/:currency/:filter' element={<Sell />} />
                                    <Route path='buy/:currency/:filter' element={<Buy />} />
                                    <Route path='myoffer' element={<ViewMyOffer />} />
                                    <Route path='disputes' element={<Dispute />} />
                                    <Route path='settings' element={<Settings />} />
                                    <Route path='activetrades' element={<ActiveTrades />} />
                                    <Route path='notifications' element={<Notification />} />
                                    <Route path='kyc' element={<Kyc />} />
                                    <Route path='add-your-profile' element={<AddProfile />} />
                                    <Route path='about' element={<Contact />} />
                                    <Route path='home' element={<Home />} />
                                    <Route path='contact' element={<About />} />
                                    <Route path='terms' element={<Tems />} />
                                    <Route path='privacy-policy' element={<Privacy />} />
                                    <Route path='affiliate-programs' element={<Affiliate />} />
                                    <Route path='rewards' element={<Rewards />} />
                                  </Route>
                                </Route>
                                <Route path='' element={<ProtectedRoutes />}>
                                  <Route path='trade/:offerId/:txnHash' element={<TradeScreen />} />
                                </Route>
                                <Route path='*' element={<Error404 />} />
                              </Route >
                            </Routes >
                          </div >
                        </ThemeProvider >
                      )
                    }
                  </ThemeContextVar.Consumer >
                </ApiContext >
              </WalletContext >
            </AuthContext >
          </ThemeContext >
        </ProSidebarProvider>
      </Suspense >
      <ToastContainer/>

      <CookieConsent
        location="bottom"
        enableDeclineButton={true}
        buttonText="Accept"
        declineButtonText="Reject"
        cookieName="qvani.com"
        flipButtons={false}
        declineButtonStyle={{background: '#8c868642', color: 'black', fontSize: "15px", fontWeight: '600', border: 'none', outline: 'none', borderRadius: '20px', padding: '8px 13px'}}
        style={{ background: "white", color:'black', width: '320px', wordBreak: 'break-word', margin: '0px 0px 15px 15px', borderRadius: '15px', justifyContent: 'center'}}
        buttonStyle={{ margin: '5px', color: "white", fontSize: "15px", fontWeight: '600', background: '#1476FF', borderRadius: '20px', padding: '8px 13px' }} >
        <p className='mb-0 text-center'>Cookies help us deliver the best experience on our website. By using our website, you agree to the use of cookies. View our <Link to='/privacy-policy'>Privacy Policy</Link></p>
      </CookieConsent>  
    </>
  );
}

export default App;