import axios from 'axios';
import React, { useState, useEffect } from 'react'
import { API_URLs } from '../../Utils/ApiUrls';
import DataTable, { createTheme } from 'react-data-table-component';
import styled from 'styled-components';
import { useThemeContext } from '../../Context/ThemeContext';
const Card = styled.div`
border: ${({ theme }) => theme.border};
color: ${({ theme }) => theme.text};
background: ${({ theme }) => theme.body == '#F7F9FC' ? 'white' : theme.body};
box-shadow: ${({ theme }) => theme.boxshadow};
`
export default function UserAct() {
    const [actList, setActList] = useState([]);
    const { isDarkTheme } = useThemeContext();

    useEffect(() => {
        axios.get(API_URLs.userAct, {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${localStorage.getItem('jwt')}`
            }
        })
            .then((res) => {
                setActList(res.data);
            })
            .catch((error) => {
            })
    }, [])

    const columns = [
        {
            name: 'Activity',
            selector: (row) => <div>
                User-{row.status}
            </div>
        },
        {
            name: 'Country',
            selector: (row) => <div>
                {row.from.split('-')[0]}
            </div>
        },
        {
            name: 'IP Address',
            selector: (row) => <div>
                {row.ipAdddress}
            </div>
        },
        {
            name: 'Browser Details',
            selector: (row) => <div>
                {row.deviceDetails}
            </div>
        },
        {
            name: 'Date',
            selector: (row) => <div>
                {new Date(row.updatedAt).toLocaleString()}
            </div>
        },
    ];

    createTheme('solarized', {
        background: {
            default: `${isDarkTheme ? "#0c0c0c" : 'white'}`
        },
        action: {
            hover: 'rgba(0,0,0,.08)',
        },
        text: {
            secondary: `${isDarkTheme ? "white" : 'black'}`
        },
    });
    const customStyles = {
        header: {
            style: {
                fontSize: '20px',
                color: '#0B0E2C',
                fontFamily: 'Inter',
                fontStyle: 'normal',
                fontWeight: '600',
            },
        },
        headRow: {
            style: {
                fontFamily: 'Inter',
                fontStyle: 'normal',
                fontSize: '12px',
                ...(isDarkTheme ? { borderTop: '1px solid #302f30' } :
                    { border: '1px solid lightgray' }),
                color: `${isDarkTheme ? "#e9e9e9" : '#6F7182'}`,
                backgroundColor: `${isDarkTheme ? "#0c0d0d" : "#F7F9FC"}`,
                textTransform: 'uppercase'
            },
        },
        rows: {
            style: {
                color: `${isDarkTheme && 'white'}`,
                backgroundColor: `${isDarkTheme && 'rgba(8, 9, 14, 0.96)'}`,
                borderTop: '1px solid lightgray',
                '&:not(:last-of-type)': {
                    borderBottom: 'none',
                },
            }
        },
        headCells: {
            style: {
                display: 'flex',
                alignItems: 'center'
            },
        },
        cells: {
            style: {
                display: 'flex',
                alignItems: 'center'
            },
        }
    }

    return (
        <Card className="card px-0 mt-3 py-2">
            <p className='fw-bold my-1 px-2'>Account Activity</p>
            <DataTable
                data={actList}
                columns={columns}
                customStyles={customStyles}
                pagination
                showPageSizeOptions={true}
                paginationRowsPerPageOptions={[5, 10, 20, 25, 50, 100]}
                paginationResetDefaultPage={true}
                paginationPerPage={5}
                fixedHeader
                highlightOnHover
                responsive={true}
                theme='solarized'
            />

        </Card>
    )
}