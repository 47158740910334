import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Card, CardFooterText, HorizontalLine, Input, MyChat, PersonChat, SendButton, Text } from './StyledComponent'
import { RxDotFilled } from 'react-icons/rx'
import { GrAttachment } from 'react-icons/gr'
import { TbSend } from 'react-icons/tb'
import IconProfile from '../../assets/ic-profile.svg'
import { useAuthContextHook } from '../../Context/AuthContext'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { API_URLs } from '../../Utils/ApiUrls'
import { useAPIContextHook } from '../../Context/ApiContext'
import DataLoader from '../../Component/Loader/DataLoader'
import PropTypes from 'prop-types';
import styled from 'styled-components'
import { IoClose } from "react-icons/io5";

const ImagePreviewer = styled.div`
position: fixed;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
z-index: 1000;
background: rgba(0, 0, 0, 0.8);
padding: 20px;
border-radius: 10px;
max-width: 90vw;
max-height: 90vh;

img{
    max-width: 70vw;
    max-height: 70vh;
}
`
export default function ChatScreen({ transactionData, handleReleaseState }) {

    const params = useParams()
    const { offerId, txnHash } = params;
    const ref = useRef()
    const fileRef = useRef()
    const { userDetails } = useAuthContextHook();
    const { sendNotification } = useAPIContextHook();
    const [message, setMessage] = useState("");
    const [reload, setReload] = useState(false);
    const [chatData, setChatData] = useState([]);
    const [sendLoader, setSendLoader] = useState(false);

    const [hoveredImage, setHoveredImage] = useState(null);


    const getChats = useCallback(async () => {
        try {
            let res = await axios.post(API_URLs.getChat, {
                tradeId: offerId,
                initiateId: txnHash
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('jwt')}`,
                    Accept: 'application/json'
                }
            })
            setChatData(res.data?.chats)
            handleReleaseState(res?.data?.iHavePaid)
        } catch (error) {
            console.log('Error', error);
        }
    }, [offerId, txnHash, chatData])



    const handleSendButton = useCallback(async (e) => {
        e.preventDefault()
        setSendLoader(true)
        try {
            await axios.post(API_URLs.sendChat, {
                sender: userDetails?.user?._id,
                category: 'normal',
                tradeId: offerId,
                initiateId: txnHash,
                message: message
            }, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('jwt')}`
                }
            })
            setSendLoader(false)
            sendNotification(transactionData?.user?._id,
                `New Message from ${transactionData?.user?.nickNames}`,
                message,
                'chat',
                { transactionType: 'trade/' },
                {
                    transactionIds: [
                        {
                            idName: 'offerId',
                            id: offerId
                        },
                        {
                            idName: 'txnHash',
                            id: txnHash
                        }
                    ]
                })
            setReload(!reload)
            setMessage("")
        } catch (error) {
            setSendLoader(false)
        }
    }, [offerId, txnHash, message, transactionData])

    const handleSendFile = useCallback(async (e) => {
        try {
            let formData = new FormData()
            formData.append('sender', userDetails?.user?._id)
            formData.append('tradeId', offerId)
            formData.append('category', 'normal')
            formData.append('initiateId', txnHash)
            formData.append('message', e)
            await axios.post(API_URLs.addProof, formData, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('jwt')}`,
                    "Content-Type": 'multipart/form-data'
                }
            })

            sendNotification(transactionData?.user?._id,
                `New Message from ${transactionData?.user?.nickNames}`,
                `${transactionData?.user?.nickNames} sent a Image`,
                'chat',
                { transactionType: 'trade/' },
                {
                    transactionIds: [
                        {
                            idName: 'offerId',
                            id: offerId
                        },
                        {
                            idName: 'txnHash',
                            id: txnHash
                        }
                    ]
                })
            setReload(!reload)
        } catch (error) {
        }
    }, [offerId, txnHash, message, transactionData])

    const handleChangeFile = (e) => {
        handleSendFile(e?.target?.files[0])
    }

    useEffect(() => {
        const chatInterval = setInterval(() => {
            getChats()
        }, 3000);

        return () => {
            clearInterval(chatInterval)
        }
    }, [getChats, reload])

    useEffect(() => {
        if (ref.current) {
            ref.current.scrollTop = ref.current.scrollHeight;
        }
    }, [chatData])

    const getAvailablity = (date) => {
        return new Date().getMinutes() - new Date(date).getMinutes();
    }

    return (
        <Card className='chatscreen'>

            {
                (transactionData?.trade_details?.user === userDetails?.user?._id) ?
                    <div className='d-flex flex-column flex-sm-row p-3 justify-content-between gap-3 gap-sm-0'>

                        <div className='d-flex justify-content-center align-items-center gap-2'>
                            <img src={transactionData?.trade_user?.avatar || IconProfile} alt="icon" height='48px' width='48px' style={{ borderRadius: '50%' }} />
                            <div>
                                <Text className='m-0'>{transactionData?.trade_user?.nickNames}</Text>
                                <CardFooterText className='m-0'>
                                    {
                                        getAvailablity(transactionData?.trade_user?.lastActive) === 0 ? 
                                        <> <RxDotFilled color="green" size={20} /> Online </> : <> { getAvailablity(transactionData?.trade_user?.lastActive) } min ago </>
                                    }
                                </CardFooterText>
                            </div>
                        </div>
                    </div> :
                    <div className='d-flex flex-column flex-sm-row p-3 justify-content-between gap-3 gap-sm-0'>
                        <div className='d-flex justify-content-center align-items-center gap-2'>
                            <img src={transactionData?.user?.avatar || IconProfile} alt="icon" height='48px' width='48px' style={{ borderRadius: '50%' }} />
                            <div>
                                <Text className='m-0'>{transactionData?.user?.nickNames}</Text>
                                <CardFooterText className='m-0'>
                                    {
                                        getAvailablity(transactionData?.trade_user?.lastActive) === 0 ? 
                                        <> <RxDotFilled color="green" size={20} /> Online </> : <> { getAvailablity(transactionData?.trade_user?.lastActive) } min ago </>
                                    }
                                </CardFooterText>
                            </div>
                        </div>
                    </div>
            }
            <HorizontalLine className='mb-1' />
            <div className='px-5 mx-4 moderatebutton'>
                <CardFooterText className='m-0 ps-2'>
                    <RxDotFilled size={20} /> Moderator unavailable
                </CardFooterText>
            </div>

            <HorizontalLine className='mt-1' />

            <div ref={ref} className='d-flex flex-column' style={{ maxHeight: '50vh', overflow: 'auto' }}>

                <div className='d-flex gap-2 p-2 align-items-start w-75'>
                    <div>
                        <img 
                            src={transactionData?.user?.avatar || IconProfile} 
                            alt="icon" 
                            height='48px' 
                            width='48px' 
                            style={{ borderRadius: '50%' }} />
                    </div>
                    <div className='d-flex flex-column'>
                        <PersonChat className={'p-3'} style={{ background: "#1476FF", color: "white" }} >

                            {`This Trade is of ${transactionData?.checkout?.crypto_amount} ${transactionData?.trade_details?.cryptoCurrency?.symbol} at the rate of ${transactionData?.trade_details?.cryptoToFiatPrice?.toFixed(4)} ${transactionData?.trade_details?.preffered_currency?.symbol}/${transactionData?.trade_details?.cryptoCurrency?.symbol} via ${transactionData?.trade_details?.payment_method}. Sender's ${transactionData?.trade_details?.cryptoCurrency?.name} is now in escrow and it’s safe to make your payment. Your trade partner will share their name and ID. Buyer make the payment of ${transactionData?.hash_details?.payable_amount} ${transactionData?.trade_details?.preffered_currency?.symbol}. Send the receipt confirmation number to your trade partner and mark the trade as "Paid". Wait for your trade partner to confirm your payment. Your trade partner will release the ${transactionData?.trade_details?.cryptoCurrency?.symbol} to you`}

                        </PersonChat>
                    </div>
                </div>

                <div className='d-flex gap-2 p-2 align-items-start w-75 align-self-end'>
                    <div className='d-flex flex-column align-items-end'>
                        <MyChat className={'p-3'} style={{ background: "#32CD32", color: "white" }}>
                            {`This Trade is of ${transactionData?.checkout?.crypto_amount} ${transactionData?.trade_details?.cryptoCurrency?.symbol} at the rate of ${transactionData?.trade_details?.cryptoToFiatPrice?.toFixed(4)} ${transactionData?.trade_details?.preffered_currency?.symbol}/${transactionData?.trade_details?.cryptoCurrency?.symbol} via ${transactionData?.trade_details?.payment_method}. Sender's ${transactionData?.trade_details?.cryptoCurrency?.name} is now in escrow and it’s safe to make your payment. Your trade partner will share their name and ID. Buyer make the payment of ${transactionData?.hash_details?.payable_amount} ${transactionData?.trade_details?.preffered_currency?.symbol}. Send the receipt confirmation number to your trade partner and mark the trade as "Paid". Wait for your trade partner to confirm your payment. Your trade partner will release the ${transactionData?.trade_details?.cryptoCurrency?.symbol} to you`}
                        </MyChat>
                    </div>
                    <div>
                        <img src={userDetails?.user?.avatar || IconProfile} alt="icon" height='48px' width='48px' style={{ borderRadius: '50%' }} />
                    </div>
                </div>

                {
                    chatData?.length > 0 && chatData?.map((chat, index) => {
                        if (chat.sender === userDetails?.user?._id) {
                            return (
                                <div key={index} className='d-flex gap-2 p-2 align-items-start w-75 align-self-end justify-content-end'>
                                    <div className='d-flex flex-column align-items-end'>
                                        {
                                            chat?.type === "image" ?
                                                <img 
                                                    onMouseEnter={() => setHoveredImage(chat?.message)}
                                                    // onMouseLeave={() => setHoveredImage(null)}
                                                    src={chat?.message} 
                                                    alt="" 
                                                    className='w-50' 
                                                    style={{ cursor: 'pointer' }} /> :
                                                <MyChat className='p-3' style={{ background: `${chat?.message.includes('dispute') && 'red'}`, color: `${chat?.message.includes('Dispute Query') && 'white'}` }}>
                                                    {chat?.message}
                                                </MyChat>
                                        }
                                        <CardFooterText>{chat?.time ? <>{new Date(chat?.time).toDateString()} {new Date(chat?.time).toLocaleTimeString()} </> : ''}</CardFooterText>
                                    </div>
                                    <div>
                                        <img src={userDetails?.user?.avatar || IconProfile} alt="icon" height='48px' width='48px' style={{ borderRadius: '50%' }} />
                                    </div>
                                </div>
                            )
                        }
                        else {
                            return (
                                <div key={index} className='d-flex gap-2 p-2 align-items-start w-75'>
                                    <div>
                                        <img src={transactionData?.trade_user?.avatar || IconProfile} alt="icon" height='48px' width='48px' style={{ borderRadius: '50%' }} />
                                    </div>
                                    <div className='d-flex flex-column'>
                                        {/* {
                                            chat?.type === "image" ?
                                                <img src={chat?.message} alt="" className='w-50' /> :
                                                <PersonChat className='p-3' style={{ background: `${chat?.message.includes('Dispute Query') && 'red'}`, color: `${chat?.message.includes('Dispute Query') && 'white'}`}}>
                                                    {chat?.message}
                                                </PersonChat>
                                        } */}
                                        {
                                            chat?.type === "image" ? (
                                                <img
                                                    src={chat?.message}
                                                    alt="Chat Image"
                                                    className='w-50'
                                                    style={{ cursor: 'pointer' }}
                                                    onMouseEnter={() => setHoveredImage(chat?.message)}
                                                    // onMouseLeave={() => setHoveredImage(null)} 
                                                />
                                            ) : (
                                                <PersonChat className='p-3' style={{ background: chat?.message.includes('dispute') && 'red', color: chat?.message.includes('Dispute Query') && 'white' }}>
                                                    {chat?.message} <br/>
                                                    <small className='fw-bold'>{chat?.role === "ADMIN" && "(ADMIN)"}</small>
                                                </PersonChat>
                                            )
                                        }
                                        <CardFooterText>{chat?.time && <>{new Date(chat?.time).toDateString()} {new Date(chat?.time).toLocaleTimeString()} </>}</CardFooterText>
                                    </div>
                                </div>
                            )
                        }
                    })
                }

                {
                    sendLoader && <div className='d-flex justify-content-center'><DataLoader /></div>
                }
            </div>

            {hoveredImage && (
                <ImagePreviewer>
                    <div className='d-flex justify-content-end'>
                        <button className='border-0 bg-transparent' onClick={()=>setHoveredImage(null)}><IoClose color='white'/></button>
                    </div>
                    <img src={hoveredImage} alt="Full size preview" />
                </ImagePreviewer>)
            }
            <HorizontalLine />

            <form onSubmit={handleSendButton} className='d-flex p-3 gap-3 align-items-center mb-3'>
                <div>
                    <GrAttachment onClick={() => {
                        fileRef.current.click()
                    }} size={20} role='button' />
                    <input ref={fileRef}
                        disabled={
                            (transactionData?.checkout?.iHavePaid && transactionData?.checkout?.release_payment) ||
                            (transactionData?.hash_details?.status === "REJECT" && !transactionData?.isDisputeRaised)
                        }
                        onChange={(e) => {
                            handleChangeFile(e)
                        }} type="file" className='d-none' />
                </div>
                <Input value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    disabled={
                        (transactionData?.checkout?.iHavePaid && transactionData?.checkout?.release_payment) ||
                        ((transactionData?.hash_details?.status === "REJECT" && !transactionData?.isDisputeRaised))
                    }
                    className='flex-grow-1 py-2 px-3'
                    type="text" placeholder={transactionData?.checkout?.iHavePaid && transactionData?.checkout?.release_payment ? 'This Offer is Completed and chat is no longer available' : (transactionData?.hash_details?.status === "REJECT" && !transactionData?.isDisputeRaised) ? 'The Trade is Cancelled and the chats are no longer available.' : 'Type a message'} />
                <SendButton disabled={sendLoader || (transactionData?.checkout?.iHavePaid && transactionData?.checkout?.release_payment) || (transactionData?.hash_details?.status === "REJECT" && !transactionData?.isDisputeRaised)} type='submit'><TbSend size={25} /></SendButton>
            </form>
        </Card>
    )
}
ChatScreen.propTypes = {
    handleReleaseState: PropTypes.func,
    transactionData: PropTypes.shape().isRequired,
};
