import React from 'react'

export default function LogOutIcon({ fill = 'white', width = '20', height = '20' }) {
    return (
        <svg width={width} height={height} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.96 23.9266H4.98997C4.43981 23.9266 3.99333 23.4801 3.99333 22.93V4.98999C3.99333 4.43983 4.43986 3.99335 4.98997 3.99335H13.96C14.5111 3.99335 14.9566 3.54785 14.9566 2.99671C14.9566 2.44556 14.5111 1.99997 13.96 1.99997H4.98997C3.3415 1.99997 2 3.34151 2 4.98999V22.9299C2 24.5784 3.3415 25.9199 4.98997 25.9199H13.96C14.5111 25.9199 14.9566 25.4744 14.9566 24.9233C14.9566 24.3721 14.5111 23.9266 13.96 23.9266Z" fill={fill} />
            <path d="M25.704 13.2503L19.6442 7.27033C19.2535 6.88362 18.6217 6.88863 18.2349 7.28032C17.8482 7.672 17.8522 8.30288 18.2449 8.68959L22.5754 12.9633H10.9713C10.4201 12.9633 9.97461 13.4088 9.97461 13.9599C9.97461 14.5111 10.4201 14.9566 10.9713 14.9566H22.5754L18.2449 19.2303C17.8523 19.617 17.8493 20.2479 18.2349 20.6396C18.3276 20.7336 18.4381 20.8083 18.5599 20.8593C18.6818 20.9103 18.8125 20.9365 18.9446 20.9365C19.1977 20.9365 19.4509 20.8409 19.6442 20.6495L25.704 14.6695C25.7979 14.5767 25.8726 14.4662 25.9235 14.3444C25.9745 14.2226 26.0008 14.0919 26.0009 13.9599C26.0009 13.6929 25.8943 13.4387 25.704 13.2503Z" fill={fill} />
        </svg>
    )
}
