import styled from "styled-components";

export const Button1 = styled.button`
background: #3545EE;
color: white;
font-weight: 600;
font-size: 14px;
border-radius: 12px;
border: none;
width: 7rem;
height: 30px;
`
export const Button = styled.button`
font-weight: 700;
font-size: 14px;
border: 2px solid #3545EE;
border-radius: 12px;
background: transparent;
height: 30px;
width: 5.4rem;
`