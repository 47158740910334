import styled from "styled-components";

export const SubHeading = styled.p`
    font-size: 1rem;
    font-family: Inter;
    font-style: normal;
    font-weight: 700;
    color: ${({theme})=> theme.text==='#121620'? '#0B0E2C': theme.text};
`

export const UnderHeading = styled.p`
    font-size: 0.875rem;
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    color: #6F7182;
`
export const CardContainer2 = styled.div`
    background: #FFFFFF;
    box-shadow: 0px 2px 10px rgba(25, 93, 194, 0.07);
    border-radius: 30px;
`
export const OtherHeading = styled.p`
font-weight: 600;
    font-size: 14px;
`