import React, { useState } from 'react'
import styled from 'styled-components';
import OTPInput from "otp-input-react";
import { useLocation } from 'react-router-dom';
import { useAuthContextHook } from '../../../Context/AuthContext';
import ButtonLoader from '../../../Component/Loader/ButtonLoader';
import Icon from '../../../images/Home/qvaniIcon.png'

const Container = styled.div`
background: white;
border-radius: 14px;
max-width: 430px;
`
const Para = styled.p`
font-size: 1rem;
font-weight: 500;
`
export default function TwoFaLogin() {

    const location = useLocation();
    const { handleVerifyTwoFa } = useAuthContextHook();

    const [otpValue, setOtpValue] = useState('');
    const [loader, setLoader] = useState(false);

    const verifyTwoFa = async (e) => {
        e.preventDefault();
        try {
            setLoader(true)
            await handleVerifyTwoFa(otpValue)
            setLoader(false)
        } catch (error) {
            setLoader(false)
        }
    }
    console.log(location)
    return (
        <div className='LoginBg w-full row m-0 d-flex justify-content-center align-items-center'>
            <div className='d-flex align-items-center justify-content-center'>
                <Container className="card col-12 col-md-6 col-xl-4">
                    <div className='card-head mt-4 d-flex flex-column'>
                        <img src={Icon} alt='' width='60px' className='align-self-center' />
                        <p className="card-heading text-center pt-3 mb-1 fs-2 fw-bolder">Welcome to Qvani</p>
                    </div>
                    <div className="card-body">
                        <form onSubmit={verifyTwoFa} >

                            <div className='FormDiv d-flex m-0 justify-content-center'>
                                {
                                    localStorage.getItem('qrcode')?.length &&
                                    <img className='w-50' src={localStorage.getItem('qrcode')} alt="QR Code" />
                                }
                            </div>

                            <div className='FormDiv d-flex m-0 flex-column'>
                                {
                                    location?.state?.qrCode ?
                                        <Para className='text-center'>Scan The above QR Code from Google Authenticator on your phone and enter the code below.</Para> :
                                        <Para className='text-center'>Enter the code from your google authenticator below.</Para>
                                }
                                <div className='px-3 d-flex justify-content-center'>
                                    <OTPInput
                                        className='OtpInputBox1'
                                        value={otpValue}
                                        onChange={setOtpValue}
                                        autoFocus
                                        OTPLength={6}
                                        otpType="number"
                                        disabled={false} secure
                                        inputStyles={{ margin: '0.2rem', width: '20px !important', height: '20px ' }}
                                    />
                                </div>
                            </div>

                            <div className='d-flex justify-content-center my-3'>
                                <button className='Button mt-0 d-flex justify-content-center align-items-center' type="submit" disabled={loader || otpValue.length < 6}>
                                    {
                                        loader ? <> <ButtonLoader /> <span>Login</span></> : 'Login'
                                    }
                                </button>
                            </div>
                        </form>
                    </div>
                </Container>
            </div>
        </div>
    )
}
