import React, { useCallback, useState } from 'react'
import { Button, Heading, P } from './StyledComponent'
import DataTable ,{createTheme} from 'react-data-table-component'
import { useNavigate } from 'react-router-dom';
import { useThemeContext } from '../../Context/ThemeContext';
import PropTypes from 'prop-types';
import DataLoader from '../../Component/Loader/DataLoader';
import { TiTick } from 'react-icons/ti';
import { FaRegCopy } from 'react-icons/fa';

export default function Approved({ completeOffer, loader }) {
    const {isDarkTheme} = useThemeContext();
    const navigate = useNavigate();

    const [copySuccess, setCopySuccess] = useState(false);
    const [copiedAdd, setCopiedAdd] = useState('')

    const copyToClipBoard = useCallback(async copyMe => {
        try {
            await navigator.clipboard.writeText(copyMe);
            setCopiedAdd(copyMe);
            setCopySuccess(true);
        } catch (err) {
            setCopySuccess(false);
        }
    }, [])

    const columns = [
        {
            name: <Heading className='mb-0'>Trade Id</Heading>,
            selector: (row) => <div className='d-flex gap-1 '>
                { (copySuccess && copiedAdd === row?._id) ? <TiTick onMouseLeave={()=>setCopySuccess(false)} size={17}/> : <FaRegCopy onClick={() => copyToClipBoard(row?._id)} role='button' title='copy' size={17} /> }
                <p className='mb-0 '>{row?._id}</p>
            </div>,
            wrap: true
        },
        {
            name: <Heading className='mb-0'>Margin</Heading>,
            selector: row => {
                return <div className='d-flex flex-row'>
                    <P>{row?.offer_id?.offer_margin}%</P>
                </div>
            }
        },
        {
            name: <Heading className='mb-0'>Type</Heading>,
            selector: row => {
                return <div className='d-flex flex-row'>
                    <P>{row?.offer_id?.offer_type}</P>
                </div>
            }
        },
        {
            name: <Heading className='mb-0'>Initiate By</Heading>,
            selector: row => {
                return <div className='d-flex flex-row'>
                    <P>{row?.user?.nickNames}</P>
                </div>
            }
        },
        {
            name: <Heading className='mb-0'>Pay Amount</Heading>,
            selector: row => {
                return <P>{row?.payable_amount}</P>
            }
        },
        {
            name: <Heading className='mb-0'>Date</Heading>,
            selector: row => {
                return <P>{new Date(row?.createdAt)?.toDateString()}</P>
            }
        },
        {
            name: <Heading className='mb-0'>Action</Heading>,
            selector: row => {
                return <div className='d-flex flex-wrap gap-1'>
                    <Button disabled={row?.isBlocked} onClick={() => navigate(`/trade/${row?.offer_id?._id}/${row?._id}`)} to={'/dashboard/offer/' + row?._id}>View</Button>
                </div>
            }
        }
    ]
    createTheme('solarized', {
        background: {
            default: `${isDarkTheme ? "#0c0c0c" : 'white'}`
        },
        action: {
            hover: 'rgba(0,0,0,.08)',
        },
        text: {
            secondary: `${isDarkTheme ? "white" : 'black'}`
        },
    });
    const customStyles = {
        header: {
            style: {
                fontSize: '20px',
                color: '#0B0E2C',
                fontFamily: 'Inter',
                fontStyle: 'normal',
                fontWeight: '600',
            },
        },
        headRow: {
            style: {
                fontFamily: 'Inter',
                fontStyle: 'normal',
                fontSize: '12px',
                ...( isDarkTheme ? {borderTop: '1px solid #302f30'}:
                { border: '1px solid lightgray'}),
                color: `${isDarkTheme ? "#e9e9e9":'#6F7182'}`,
                backgroundColor: `${isDarkTheme ? "#0c0d0d":"#F7F9FC"}`,
                textTransform: 'uppercase'
            },
        },
        rows: {
            style: {
                color: `${isDarkTheme && 'white'}`,
                backgroundColor: `${isDarkTheme && 'rgba(8, 9, 14, 0.96)'}`,
                borderTop: '1px solid lightgray',
                '&:not(:last-of-type)': {
                    borderBottom: 'none',
                },
            }
        },
        headCells: {
            style: {
                display: 'flex',
                alignItems: 'center'
            },
        },
        cells: {
            style: {
                display: 'flex',
                alignItems: 'center'
            },
        }
    }
    return (
        <div>
            <DataTable
                pagination
                customStyles={customStyles}
                data={completeOffer}
                columns={columns}
                progressPending={loader}
                theme='solarized'
                progressComponent={<div className='d-flex justify-content-center py-5'><DataLoader /></div>}
            />
        </div>
    );
}

Approved.propTypes = {
    loader: PropTypes.bool.isRequired,
    completeeOffer: PropTypes.shape().isRequired,
};