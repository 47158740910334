import React, { useEffect, useState } from 'react'
import { useAuthContextHook } from "../../../Context/AuthContext";
import { Formik, useFormik } from "formik";
import './Signup.css';
import { useLocation, Link, useParams } from 'react-router-dom';
import * as Yup from "yup";
import styled from 'styled-components';
import ButtonLoader from '../../../Component/Loader/ButtonLoader';
import { useAPIContextHook } from '../../../Context/ApiContext';
import Select from 'react-select'
import { useThemeContext } from '../../../Context/ThemeContext';
import { useNavigate } from 'react-router-dom';
import SmallLogo from '../../../assets/Images/LogoBlue.png'
import { FaEye, FaEyeSlash } from 'react-icons/fa6';


const P = styled.p`
font-size: 13px;
font-weight: 500;
font-family: 'Inter';
color: #6F7182;
margin-bottom: 2px;
`
const InputPhone = styled.input`
border: none;
outline: none;
width: 75%;
border-radius: 20px;
border: ${({ theme }) => theme.border};
box-shadow: ${({ theme }) => theme.boxshadow};
`

const Input = styled.input`
background: ${({ theme }) => theme.body === "#F7F9FC" ? 'white' : theme.body};
color: ${({ theme }) => theme.text};
border: ${({ theme }) => theme.border};
box-shadow: ${({ theme }) => theme.boxshadow};
border-radius: 30px;
padding: 5px;
width: 100%;
padding-right: 44px !important;
outline: none;
`

const Span = styled.span`
top: 44px;
    right: 2rem;
    position: absolute;
`



export default function SignUp() {

    const navigate = useNavigate();
    const params = useParams();
    const { handleSignUp } = useAuthContextHook();
    const location = useLocation();
    const { isDarkTheme } = useThemeContext();
    const [isclicked, setIsClicked] = useState(false);
    const { phoneCode } = useAPIContextHook();
    const [phone, setPhone] = useState('')
    const [isChecked, setIsChecked] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [seePassword, setSeePassword] = useState(false);
    const [phoneErrorMsg, setPhoneErrMsg] = useState('');

    const formik = useFormik({
        initialValues: { email: "", phone: "", password: '', refercode: '' },
        validationSchema: Yup.object().shape({
            email: Yup.string().email('Invalid Email ID'),
            phone: Yup.string().matches(/^\d{10}$/, 'Invalid phone number'),
            password: Yup.string().min(8, "Password must be minimum 8 characters")
                .matches(/[A-Z]/, 'atleast 1 uppercase letter required')
                .matches(/[a-z]/, 'atleast 1 lowercase letter required')
                .matches(/[0-9]/, 'atleast 1 number required')
                .matches(/[!@#$%^&*]/, 'atleast 1 special character required'),

        }),
        onSubmit: async (values) => {

            if (!isChecked) {return setAlertMessage('Please accept the terms before proceeding.');}
            else { setAlertMessage('')}

            if(!phone && values.email.length > 0){
                try{
                    setIsClicked(true);
                    await handleSignUp(values?.email, values.password, values.refercode);
                    setIsClicked(false);
                } catch(err){
                    setIsClicked(false);
                }
            }
            if(phone){
                try {
                    setIsClicked(true);
                    await handleSignUp(`${phone?.value}${values?.phone}`, values.password, values.refercode);
                    setIsClicked(false);
                    
                } catch (error) {
                    setIsClicked(false);
                }
            } else { setPhoneErrMsg('fill it!!') }
        }
    });

    // console.log(phoneErrorMsg, phone)

    const handleCheckBoxChange = () => {
        setIsChecked(!isChecked);
    
    }

    useEffect(() => {
        if(params?.referId?.includes('@')){
            formik.setValues({
                email: params.referId
            })
        }
        if (params.referId && !params?.referId?.includes('@')) {
            formik.setValues({
                refercode: params.referId
            })
        }
   // console.log(params)
    }, [params])
    useEffect(() => {
        formik.setValues({
            refercode: location?.search?.split('?')[1]?.split('=')[1]
        })
    }, [location.search])

    const styles = {
        control: (styles) => ({
            ...styles,
            borderRadius: '20px',
            outline: 'none',
            width: '5rem',
            border: '1px solid white', "&:hover": {
                border: `1px solid white`
            },
            color: 'white'

        }),
        indicatorSeparator: () => ({
            all: 'unset'
        }),
        indicatorsContainer: (styles) => ({
            ...styles, color: 'black', 'div:nth-child(2)': {
                color: 'black',
                padding: '0px'
            }
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: isDarkTheme && state.isHovered ? '#302F30' : `${({ theme }) => theme.body}`,
            ':hover': {
                backgroundColor: `${isDarkTheme ? '#302F30' : '#D9EDE7'}`,
            },
            color: isDarkTheme && state.isSelected ? '#1476FF' : `${({ theme }) => theme.text}`,
        }),
        menu: (styles) => ({
            ...styles, background: 'white',
            color: 'black',
            width: '7rem'
        })
    }

    return (
        <>
            <div className='container-fluid'>
                <div className='row m-0'>
                    <div className='col-md-8'>
                        <div className='mb-1 '>
                            <p className='fw-bold pt-3 fs-3 mb-0'>Create an Account</p>
                        </div>
                        <form onSubmit={formik.handleSubmit}>
                            {/* <div className='FormDiv d-flex py-2 m-0 flex-column'>
                                <div className='tabs-navigator'>
                                    <ul id='myTab' className='nav nav-tabs border-0'>
                                        <li className="nav-item mx-1 " >
                                            <label
                                                className='nav-link text-dark active p-0 bg-transparent border-0'
                                                id="emailed-tab"
                                                data-bs-toggle="tab"
                                                data-bs-target="#emailed"
                                                type="button"
                                                role="tab"
                                                aria-controls="emailed"
                                                onClick={() => { setPhone('') }}
                                                aria-selected="true">
                                                Email
                                            </label>
                                        </li>
                                        <div className='fw-bold' style={{ margin: '0px 1px' }}>|</div>
                                        <li className="nav-item" >
                                            <label
                                                className='nav-link p-0 text-dark bg-transparent border-0'
                                                id="phone-tab"
                                                data-bs-toggle="tab"
                                                data-bs-target="#phone"
                                                type="button"
                                                role="tab"
                                                aria-controls="phone"
                                                onClick={()=>{
                                                    formik.setValues({
                                                        email: ''
                                                    })
                                                }}
                                                aria-selected="true">
                                                Phone Number
                                            </label>
                                        </li>
                                    </ul>
                                    <div className='tab-content' id='myTabContent'>
                                        <div className='tab-pane fade show active'
                                            id="emailed"
                                            role="tabpanel"
                                            aria-labelledby="emailed-tab">
                                            <div className='FormDiv d-flex py-2 m-0 mt-1 flex-column'>
                                                <Input
                                                    className='px-3 py-2'
                                                    id='email'
                                                    placeholder='Email Address'
                                                    name='email'
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.email}
                                                />
                                                {formik.touched.email && formik.errors.email ? (<div className='text-start px-2 auth-error text-danger'>{formik.errors.email}</div>) : null}
                                            </div>
                                        </div>
                                        <div className='tab-pane fade'
                                            id="phone"
                                            role="tabpanel"
                                            aria-labelledby="phone-tab">
                                            <div className='FormDiv d-flex justify-content-between py-2 m-0 mt-1'>
                                                <Select
                                                    options={phoneCode}
                                                    value={phone}
                                                    onChange={(selectedOption) => {
                                                        setPhone(selectedOption);
                                                        setPhoneErrMsg('')
                                                    }}
                                                    placeholder='code'
                                                    className='signupdiv'
                                                    styles={styles}
                                                />
                                                <InputPhone
                                                    id="phone"
                                                    placeholder='Mobile Number'
                                                    type="text"
                                                    value={formik.values.phone}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    className={
                                                        formik.errors.phone && formik.touched.phone
                                                            ? "text-input error px-3 py-2"
                                                            : "text-input px-3 py-2"
                                                    }
                                                />
                                                <small>
                                                    {formik.errors.phone && formik.touched.phone && (
                                                        <div className="input-feedback text-danger auth-error text-start">{formik.errors.phone}</div>
                                                    )}
                                                </small>
                                            </div>
                                            {phoneErrorMsg?.length > 0 && <div className='text-danger auth-error text-start'>{phoneErrorMsg}</div>}

                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            <div className='FormDiv d-flex py-2 m-0 flex-column'>
                                <small className='mx-2 mb-2'>Email*</small>
                                <Input
                                    className='px-3 py-2'
                                    id='email'
                                    placeholder='Email Address'
                                    name='email'
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.email}
                                />
                                {formik.touched.email && formik.errors.email ? (<div className='text-start px-2 auth-error text-danger'>{formik.errors.email}</div>) : null}
                            </div>
                            <div className='FormDiv d-flex py-2 m-0 flex-column'>
                                <small className='mx-2 mb-2'>Create Password*</small>
                                <Input
                                    className='px-3 py-2'
                                    id='password'
                                    placeholder='Password'
                                    type={seePassword ? 'text' : 'password'}
                                    name='password'
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.password}
                                />
                                {seePassword ? <Span onClick={() => setSeePassword(false)}><FaEyeSlash /></Span> : <Span type='button' onClick={() => setSeePassword(true)}><FaEye /></Span>}
                                {formik.touched.password && formik.errors.password ? (<div className='text-start px-2 auth-error text-danger'>{formik.errors.password}</div>) : null}
                            </div>
                            <div className='FormDiv d-flex py-2 m-0 flex-column'>
                                <small className='mx-2 mb-2'>Referral Code</small>
                                <Input
                                    className='px-3 py-2'
                                    id='refercode'
                                    placeholder='Referral Code'
                                    name='refercode'
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.refercode}
                                />
                                {formik.touched.refercode && formik.errors.refercode ? (<div className='text-start px-2 auth-error text-danger'>{formik.errors.refercode}</div>) : null}
                            </div>
                            <div className='mt-3 px-2'>

                                <P ><input type='checkbox' checked={isChecked} onChange={handleCheckBoxChange} className='me-2' />By creating an account, I agree to Qvani's <Link to='/terms'>Terms of Use</Link> and <Link to='/privacy-policy'>Privacy Policy.</Link></P>
                            </div>
                            <div className='d-flex flex-column justify-content-center mt-0'>
                                {alertMessage && <p className='text-danger ms-2 mb-2' style={{ fontSize: "12px" }}>{alertMessage}</p>}
                                <button className='Button d-flex justify-content-center align-items-center gap-2' disabled={isclicked} type='submit'>
                                    {
                                        isclicked ? <> <ButtonLoader /><span> Checking...</span></> : 'Sign up'
                                    }
                                </button>
                            </div>
                            <div className='mt-3'>
                                <P className='mb-0'>Already have an Account? <Link to='/login'>Login Here</Link> </P>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}