import React from 'react'
import { Oval } from 'react-loader-spinner'
import { useThemeContext } from '../../Context/ThemeContext'

export default function ButtonLoader() {

    const { isDarkTheme } = useThemeContext()

    return (
        <Oval
            height={15}
            width={15}
            color={isDarkTheme ? 'white' : 'black'}
            wrapperStyle={{}}
            wrapperclassName=""
            visible={true}
            ariaLabel='oval-loading'
            secondaryColor={isDarkTheme ? 'white' : 'black'}
            strokeWidth={7}
            strokeWidthSecondary={2} />
    )
}
