// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .tab-navigate .nav-tabs .nav-link.active .tabs-navigator .nav-tabs .nav-link:focus {
    border-bottom:1px solid blue !important;
} */
`, "",{"version":3,"sources":["webpack://./src/pages/Settings/Refer.css"],"names":[],"mappings":"AAAA;;GAEG","sourcesContent":["/* .tab-navigate .nav-tabs .nav-link.active .tabs-navigator .nav-tabs .nav-link:focus {\n    border-bottom:1px solid blue !important;\n} */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
