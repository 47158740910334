import React, { useState } from 'react'
import { useLocation } from 'react-router';
import LockIcon from '../../images/Home/Lock.png'
import './AuthCss/Login.css'
import * as Yup from 'yup';
import { useFormik } from 'formik';
import styled from 'styled-components';
import { FaEye, FaEyeSlash } from 'react-icons/fa'
import { useAuthContextHook } from "../../Context/AuthContext";
import ButtonLoader from '../../Component/Loader/ButtonLoader';
import CustomModal from '../../hooks/CustomModal';
import Logo from '../../assets/Images/LogoFooter.png'
import { useNavigate } from 'react-router-dom';

const Heading = styled.h3`
    font-size: 1.5rem;
    text-align: center;
    font-weight: bolder;
`

const Span = styled.span`
  margin-left: -35px;
`
const initialValues = {
    password: '',
    cpassword: '',
}

const Container = styled.div`
  background: white;
  border-radius: 14px;
  max-width: 550px;
`

const Img = styled.span`
    width: 113px;
    height: 113px;
    background-color: #F4F7FF;
    justify-content: center;
    align-items: center;
    display: flex;
    border-radius: 26px;
}
`

export default function ResetPassword() {
    const { handleResetPassword, showModal, handleCloseModal, navigateLink, modalData } = useAuthContextHook();
    const [loader, setLoader] = useState(false);
    const [seePassword, setSeePassword] = useState(false);
    const [seeCPassword, setSeeCPassword] = useState(false);

    const navigate = useNavigate();

    const location = useLocation();
    let token = location.search?.split('?')[1]?.split('=')[1];

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object({
            password: Yup.string().required('Password is Required').min(8)
                .matches(/[A-Z]/, 'atleast 1 uppercase letter required')
                .matches(/[a-z]/, 'atleast 1 lowercase letter required')
                .matches(/[1-9]/, 'atleast 1 number required')
                .matches(/[!@#$%^&*]/, 'atleast 1 special character required'),
            cpassword: Yup.string().oneOf([Yup.ref('password'), null], 'Confirm password should be same as New  password').required("Confirm Password is Required")
        }),
        onSubmit: async (values) => {
            try {
                setLoader(true)
                await handleResetPassword(values.password, token);
                setLoader(false)
            } catch (error) {
                setLoader(false)
            }
        },
    });

    return (
        <div className='ResetBg w-full' style={{ minHeight: "100vh" }}>
            <div style={{ minHeight: '0', padding: '10px' }} onClick={() => navigate('/home')}>
                    <img src={Logo} alt="" width={'140px'} />
                </div>
            <div className="d-flex justify-content-center align-items-center">       
                <Container className='card col-12 col-md-6 col-xl-6 px-4 py-2 shadow-sm'>
                    <div className='card-head mt-4 d-flex flex-column justify-content-center align-items-center'>
                        <Img><img src={LockIcon} alt='' width='60px' className='align-self-center' /></Img>
                        <Heading className="pt-2">Reset your password</Heading>
                    </div>
                    <div className='card-body'>
                        <form onSubmit={formik.handleSubmit}>
                            <div className='d-flex m-0 mt-3 flex-column'>
                                <div className='FormDiv'>
                                    <input
                                        placeholder='Enter New Password'
                                        name='password'
                                        id='password'
                                        className='py-3 px-5 shadow-sm rounded-pill w-100 border-0'
                                        type={seePassword ? 'text' : 'password'}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.password}
                                    />
                                    {seePassword ? <Span type='button' onClick={() => setSeePassword(false)}><FaEyeSlash /></Span>
                                        : <Span type='button' onClick={() => setSeePassword(true)}><FaEye /></Span>}
                                </div>
                                {
                                    formik.errors.password && formik.touched.password ? (<div className='text-danger auth-error text-start'>{formik.errors.password}</div>) : null
                                }
                            </div>
                            <div className='d-flex m-0 mt-3 flex-column'>
                                <div className='FormDiv'>
                                    <input
                                        placeholder='Confirm New Password'
                                        name='cpassword'
                                        id='cpassword'
                                        type={seeCPassword ? 'text' : 'password'}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.cpassword}
                                        className={
                                            formik.errors.cpassword && formik.touched.cpassword
                                                ? "text-input py-3 px-5 shadow error shadow-sm rounded-pill w-100 border-0"
                                                : "text-input py-3 px-5 shadow shadow-sm rounded-pill w-100 border-0"
                                        }
                                    />
                                    {seeCPassword ? <Span type='button' onClick={() => setSeeCPassword(false)}><FaEyeSlash /></Span>
                                        : <Span type='button' onClick={() => setSeeCPassword(true)}><FaEye /></Span>}
                                </div>

                                {
                                    formik.errors.cpassword && formik.touched.cpassword ? (<div className='text-danger text-start auth-error'>{formik.errors.cpassword}</div>) : null
                                }
                            </div>

                            <div className='d-flex justify-content-center mt-2'>
                                <button className='Button d-flex justify-content-center py-3' type="submit" disabled={loader}>
                                    {
                                        loader ? <> <ButtonLoader /> <span>wait...</span></> : 'Reset'
                                    }
                                </button>
                            </div>
                        </form>
                    </div>
                </Container>
            </div>
            {showModal && (<CustomModal
                show={showModal}
                handleClose={handleCloseModal}
                navigateLink={navigateLink}
                data={modalData}
            />)}
        </div>
    )
}
