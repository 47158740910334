import React,{useState} from 'react'
import { SubP, InputContainer, Input1 } from './Styled'
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import './Index.css'

const Button = styled.button`
border: none;
    background: #5423E7;
    color: white;
    padding: 7px 30px;
    border-radius: 32px;
    margin-right: 5px;
    white-space: nowrap;
`
const ImageWrapper = styled.div`
    width:100%;
    heigh:100%;
    position:absolute;
    background:transparent;
    z-index:1;
`
export default function Trade() {
    const [email, setEmail]=useState('');
    const handleChange=(e)=>{
        setEmail(e.target.value);
    }
    const navigate = useNavigate();
    return (<>
        <div className='container-fluid p-0 imagebgWrapper' style={{ backgroundColor: '#08021C', minHeight: '50rem' }}>
            <div className='row m-0'>
                <div className='col-md-12 text-center text-white my-5 d-flex flex-column justify-content-center align-items-center'>
                    <p className='header-text trde-crpt text-white fs-1 fw-bold' style={{ zIndex: "1" }}>Trade Cryptocurrencies on Qvani Exchange</p>
                    <SubP className='header-text text-white  mt-5'>P2P Crypto trading with over 400+ payment methods via Qvani Crypto exchange</SubP>

                </div>
            </div>
            <div className='row m-0 d-flex flex-column justify-content-center align-items-center position-relative z-index-1'>
                <div className='col-md-6 col-sm-9'>
                    <InputContainer>
                        <Input1
                            name='email'
                            value={email}
                            onChange={handleChange}
                            placeholder='Your Email/Phone number'
                        />
                        <Button onClick={() =>{ navigate(`/signup/${email}`)}}>Sign Up</Button>
                        {/* <Button onClick={() =>{ navigate(`${window.location.protocol === "http:" ? `/signup/${email}` : '/coming-soon'}`)}}>Sign Up</Button> */}
                        
                    </InputContainer>
                </div>
            </div>

        </div>

    </>);
}