import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import Approved from './Approved'
import Reject from './Reject'
import Pending from './Pending'
import { FcCancel } from 'react-icons/fc'
import { MdPending } from 'react-icons/md'
import { GiCheckMark } from 'react-icons/gi'
import './Index.css'
import axios from 'axios';
import { API_URLs } from '../../Utils/ApiUrls';
import { Span } from './StyledComponent'
import { useThemeContext } from '../../Context/ThemeContext';
import { ProfileHeading } from '../Profile/StyledComponents';
import { useAuthContextHook } from '../../Context/AuthContext';


export default function Index() {

    const { handleSetNavbarHeading } = useThemeContext()
    const { userDetails } = useAuthContextHook();
    const [loader, setLoader] = useState(true)

    const [activeOffer, setActiveOffer] = useState([])
    const [activeOffer1, setActiveOffer1] = useState([])
    const [rejectOffer, setRejectOffer] = useState([])
    const [pendingOffer, setPendingOffer] = useState([])
    const [completeOffer, setCompleteOffer] = useState([])
    const navigate = useNavigate();
    const location = useLocation();
    const isTabSelected = location.hash.includes('rejected-offers')
        || location.hash.includes('pending-offers')
        || location.hash.includes('approved-offers')

    const handleForMyOfferList = () => {
        axios.get(API_URLs.activetrade + 'myOffers', {
            headers: {
                'accept': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('jwt')}`
            }
        })
            .then((res) => {
                setLoader(false)
                setActiveOffer(res?.data?.res_obj);
            })
            .catch((error) => {
                setLoader(false)
            })
    }
    const handleForOtherOfferList = () => {
        axios.get(API_URLs.activetrade + 'otherOffers', {
            headers: {
                'accept': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('jwt')}`
            }
        })
            .then((res) => {
                setLoader(false)
                setActiveOffer1(res.data);
            })
            .catch((error) => {
                setLoader(false)
            })
    }
    useEffect(() => {
        setLoader(true)
        handleForOtherOfferList()
        handleForMyOfferList()
    }, [])

    // Array.prototype.push.apply(activeOffer, activeOffer1);

    useEffect(() => {
        let reject = activeOffer.filter((option) => {
            return option.status === "REJECT" || option.status === "EXPIRE"
        })
        setRejectOffer(reject)

        let pending = activeOffer.filter((option) => {
            return option.status === "PENDING"
        })
        setPendingOffer(pending)

        let complete = activeOffer.filter((option) => {
            return option.status === 'APPROVE'
        })
        setCompleteOffer(complete)
    }, [activeOffer])

    useEffect(() => {
        handleSetNavbarHeading("Initiated Trades")
    }, [])


    return (
        <div className='p-3 container'>
            <ProfileHeading>Initiated Trades</ProfileHeading>
            <div className='container-fluid'>
                <div>
                    <ul className="nav nav-tabs myoffer-tabs gap-3 border-0" id="myTab">
                        <div className="nav-item" >
                            <button
                                className={`nav-link d-flex gap-1 align-items-center ${isTabSelected ? location.hash.includes('pending-offers') ? 'active' : '' : 'active'}`} id="pending-offers-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#pending-offers"
                                type="button"
                                role="tab"
                                aria-controls="pending-offers"
                                onClick={() => navigate('#pending-offers')}
                                aria-selected="true"
                                >
                                <MdPending />
                                Active Trades
                                <Span active={isTabSelected ? location.hash.includes('pending-offers') ? true : false : true}>{pendingOffer?.length}</Span>
                            </button>
                        </div>
                        <div className="nav-item" >
                            <button
                                className={`nav-link d-flex gap-1 align-items-center ${isTabSelected ? location.hash.includes('rejected-offers') ? 'active' : '' : ''}`}
                                id="rejected-offers-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#rejected-offers"
                                type="button"
                                role="tab"
                                aria-controls="rejected-offers"
                                onClick={() => navigate('#rejected-offers')}
                                aria-selected="true">
                                <FcCancel />
                                Canceled Trades
                                <Span active={isTabSelected ? location.hash.includes('rejected-offers') ? true : false : false}>{rejectOffer?.length}</Span>
                            </button>
                        </div>
                        <div className="nav-item" >
                            <button
                                className={`nav-link d-flex gap-1 align-items-center ${isTabSelected ? location.hash.includes('approved-offers') ? 'active' : '' : ''}`} id="approved-offers-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#approved-offers"
                                type="button"
                                role="tab"
                                aria-controls="approved-offers"
                                onClick={() => navigate('#approved-offers')}
                                aria-selected="true">
                                <GiCheckMark />
                                Completed Trades
                                <Span active={isTabSelected ? location.hash.includes('approved-offers') ? true : false : false}>{completeOffer?.length}</Span>
                            </button>
                        </div>
                    </ul>
                </div>
                <div className="tab-content offerlist mt-4" id="myTabContent">
                    <div
                        className={`tab-pane fade px-2 ${isTabSelected ? location.hash.includes('pending-offers') ? 'show active' : '' : 'show active'}`}
                        id="pending-offers"
                        role="tabpanel"
                        aria-labelledby="pending-offers-tab">
                        <Pending pendingOffer={pendingOffer} loader={loader} />
                    </div>
                    <div
                        className={`tab-pane  px-2 ${isTabSelected ? location.hash.includes('rejected-offers') ? 'show active' : '' : ''}`}
                        id="rejected-offers"
                        role="tabpanel"
                        aria-labelledby="rejected-offers-tab">
                        <Reject rejectOffer={rejectOffer} loader={loader} />
                    </div>
                    <div
                        className={`tab-pane fade px-2 ${isTabSelected ? location.hash.includes('approved-offers') ? 'show active' : '' : ''}`}
                        id='approved-offers'
                        role='tabpanel'
                        aria-labelledby='approved-offers-tab'>
                        <Approved completeOffer={completeOffer} loader={loader} />
                    </div>
                </div>
            </div>
        </div>
    );
}  