import React from 'react'
import { useAuthContextHook } from "../../../Context/AuthContext";
import '../AuthCss/Login.css'
import SideImg from '../../../images/Home/Illustration.png'
import styled from 'styled-components';
import SignUp from './SignUp'
import Otp from './Otp'
import {Link} from 'react-router-dom'
import CustomModal from '../../../hooks/CustomModal';
import SmallLogo from '../../../assets/Images/LogoBlue.png'
const Imgdiv = styled.div`
height: 78vh;
`

export default function SignUpPage() {
    const {step, setStep, showModal, handleCloseModal, navigateLink, modalData} = useAuthContextHook()
    const getComponent = () => {
        switch (step) {
            case 0: return <SignUp/>
            case 1: return <Otp step={step} setStep={setStep}/>
        }
    }
    return (
        <div className='SignUpBg w-full row m-0'>
            <div className='col-md-6 col-sm-12 pb-5'>
            <div className='d-flex my-3'>
                <Link to='/home'><img src={SmallLogo} /></Link>
            </div>
            <div className='d-flex mt-5 flex-column justify-content-center align-items-center '>
                {getComponent()}
            </div>
            </div>
            <div className='col-md-6 d-none d-md-flex align-items-center justify-content-center signupimgbg'>
                <Imgdiv>
                    <img src={SideImg} alt='' height='100%' width='90%' />
                </Imgdiv>
            </div>
            {showModal && (<CustomModal
                show={showModal}
                handleClose={handleCloseModal}
                navigateLink={navigateLink}
                data={modalData}
            />)}
        </div>
    );
}