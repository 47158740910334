import React from 'react'
import Img from '../../assets/Images/ValueImage.png'
import Img1 from '../../assets/Images/user-rectangle.svg'
import Img2 from '../../assets/Images/Vector.png'
import styled from 'styled-components'




const P = styled.p`
color: #202939;
margin-bottom: 5px;
font-size: 30px;
font-weight: 700;
`
const P1 = styled.p`
margin-bottom: 5px;
margin-top: 7px;
font-size: 20px;
font-weight: 600;
`
const Button = styled.button`
border-radius: 20px;
border: none;
font-size: 14px;
font-weight: 600;
`
const Para = styled.p`
font-size: 16px;
text-align: justify;
`



function Value() {
  return (
    <div className='container bg-white'>
        <div className='row m-0 pt-5 pb-2 d-flex justify-content-center  align-items-center'>
            <div className='col-md-6'>
                <Button>Values</Button>
                <P>Putting Our Users First</P>
                <Para>Users are at the heart of everything we do. From the beginning, we’ve made user protection our top priority. That is why we’ve embedded state-of-the-art security measures and strict data privacy controls across the entire Qvani platform.</Para>
                <div className='d-flex flex-md-nowrap flex-wrap pt-2'>
                    <div className='text-start px-2'>
                        <img src={Img1} alt='--'/>  
                        <P1>Accountability</P1>  
                        <Para>We are more and more accountable to our customers. We must constantly stay in contact with our customers and work together to manage and foster a great community.</Para>
                    </div>
                    <div className='text-start px-2'>
                        <img src={Img2} alt='--'/>  
                        <P1>Passion</P1>    
                        <Para>As an exchange for, by, and of the people, Qvani is developing a community where everyone can benefit from growth and have their opinions heard.</Para>
                    </div>
                </div>
            </div>
            <div className='d-none d-md-block col-md-6'>
                <img src={Img} width={'100%'} />
            </div>
        </div> 
    </div>
  )
}

export default Value