// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.myoffer-tabs{
    border-bottom: 2px solid grey !important;
}
.myoffer-tabs > .nav-item > .nav-link {
    background: transparent;
    border: none;
    color: darkgray;
}

.myoffer-tabs > .nav-item > .active{
    color: #1476FF;
    background: transparent;
    border: none;
    border-bottom: 2px solid #1476FF;
}`, "",{"version":3,"sources":["webpack://./src/pages/ActiveTrades/Index.css"],"names":[],"mappings":"AAAA;IACI,wCAAwC;AAC5C;AACA;IACI,uBAAuB;IACvB,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI,cAAc;IACd,uBAAuB;IACvB,YAAY;IACZ,gCAAgC;AACpC","sourcesContent":[".myoffer-tabs{\n    border-bottom: 2px solid grey !important;\n}\n.myoffer-tabs > .nav-item > .nav-link {\n    background: transparent;\n    border: none;\n    color: darkgray;\n}\n\n.myoffer-tabs > .nav-item > .active{\n    color: #1476FF;\n    background: transparent;\n    border: none;\n    border-bottom: 2px solid #1476FF;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
