import React from 'react'
import styled from 'styled-components';
const Div = styled.div`
height: 100vh;
display: flex;
justify-content: center;
flex-direction: column;
align-items: center;
`
const P1 = styled.p`
font-size: 30px;
margin-bottom: 10px;
`
const P2 = styled.p`
font-size: 70px;
font-weight: 600;
`
export default function ComingSoon(){
    return(
        <Div>
            <P1>We're Working on it.</P1>
            <P2>Coming Soon !!</P2>
        </Div>
    );
}