import styled from "styled-components";

export const P = styled.p`
color: #202939;
font-size: 33px;
font-weight: 600;
`
export const Button = styled.button`
border: none;
background: #1476FF;
color: white;
font-size: 17px;
font-weight: 600;
border-radius: 20px;
padding: 2px 15px;
`