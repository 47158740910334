import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import { InputLabel } from '../Profile/StyledComponents'
import { BlueButton } from '../ProfileNew/StyledComponent'
import { useThemeContext } from '../../Context/ThemeContext'
import { darkTheme, lightTheme } from '../../Theme/theme'
import { Input } from '../CreateOffer/StyledComponents'
import { useAPIContextHook } from '../../Context/ApiContext'
import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { Formik } from 'formik';
import PropTypes from 'prop-types';

const InputContainer = styled.div`
border-radius: 52px;
width: 100%;
border: ${({ theme }) => theme.border};
box-shadow: ${({ theme }) => theme.boxshadow};
background: ${({ theme }) => theme.body == '#F7F9FC' ? 'white' : theme.body};
`
const P = styled.p`
font-size: 16px;
color: ${({ theme }) => theme.text};
font-weight: 600;
`

const CancelButton = styled.button`
border: 1px solid red;
color: ${({ theme }) => theme.text};
border-radius: 20px;
padding: 11px 20px;
background: transparent;
font-weight: 600;
`
const Div = styled.div`
border: ${({ theme }) => theme.border};
background: ${({ theme }) => theme.body == '#F7F9FC' ? 'white' : theme.body};
border-radius: 25px;
box-shadow: ${({ theme }) => theme.boxshadow};
`

export default function Filters({ currency, formik }) {

    const { isDarkTheme } = useThemeContext();
    const { cryptoCurrencyOptions, fiatCurrencyOptions, customFilterForCrypto, countryOptions, customFilterForCountry, paymentMethodList, fetchPaymentMethods } = useAPIContextHook()
    const navigate = useNavigate()
    const params = useParams();
    const styles = {
        control: (styles) => ({
            ...styles,
            background: 'transparent',
            borderRadius: '52px',
            width: '100%',
            height: '50px',
            color: 'white',
            boxShadow: `${isDarkTheme ? darkTheme.boxshadow : lightTheme.boxshadow}`,
            border: `${isDarkTheme ? darkTheme.border : lightTheme.border}`, "&:hover": {
                border: `${isDarkTheme ? darkTheme.border : lightTheme.border}`
            },
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: isDarkTheme && state.isHovered ? '#302F30' : `${({ theme }) => theme.body}`,
            ':hover': {
                backgroundColor: `${isDarkTheme ? '#302F30' : '#D9EDE7'}`,
            },
            color: isDarkTheme && state.isSelected ? '#1476FF' : `${({ theme }) => theme.text}`,
        }),
        indicatorSeparator: () => ({
            all: 'unset'
        }),
        indicatorsContainer: (styles) => ({
            ...styles, color: isDarkTheme ? 'white' : 'black', 'div:nth-child(2)': {
                color: isDarkTheme ? 'white' : lightTheme.text
            }
        }),
        menu: (styles) => ({
            ...styles, background: isDarkTheme ? 'rgb(27, 27, 28)' : 'white',
            color: isDarkTheme ? 'white' : lightTheme.text,
        })
    }
    const styles1 = {
        control: (styles) => ({
            ...styles,
            background: 'transparent',
            borderRadius: '52px',
            width: '6.2rem',
            color: 'white',
            boxShadow: `${isDarkTheme ? darkTheme.boxshadow : lightTheme.boxshadow}`,
            border: `${isDarkTheme ? darkTheme.border : lightTheme.border}`, "&:hover": {
                border: `${isDarkTheme ? darkTheme.border : lightTheme.border}`
            },
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: isDarkTheme && state.isHovered ? '#302F30' : `${({ theme }) => theme.body}`,
            ':hover': {
                backgroundColor: `${isDarkTheme ? '#302F30' : '#D9EDE7'}`,
            },
            color: isDarkTheme && state.isSelected ? '#1476FF' : `${({ theme }) => theme.text}`,
        }),
        indicatorSeparator: () => ({
            all: 'unset'
        }),
        indicatorsContainer: (styles) => ({
            ...styles, color: isDarkTheme ? 'white' : 'black', 'div:nth-child(2)': {
                color: isDarkTheme ? 'white' : lightTheme.text
            }
        }),
        menu: (styles) => ({
            ...styles, background: isDarkTheme ? 'rgb(27, 27, 28)' : 'white',
            color: isDarkTheme ? 'white' : lightTheme.text,
        })
    }

    useEffect(() => {
        const pay = params?.filter?.split('fiat')[0]?.split('pay=')[1];
        const fiat = params?.filter?.split('amount')[0]?.split('fiat=')[1];
        const amount = params?.filter?.split('amount=')[1];

        const fiatCurr = fiatCurrencyOptions.filter((data) => {
            return data.name === fiat;
        })

        if (Array.isArray(paymentMethodList)) {

            const myPay = paymentMethodList.filter((data) => {
                const subpay = data.options.filter((subdata) => {
                    return subdata.value === pay;
                })
                if (subpay?.length > 0) {
                    formik.setValues({
                        ...formik.values,
                        payVia: subpay[0],
                        preferredCurrency: fiatCurr[0],
                        spendAmount: amount,
                    })
                }
                return subpay[0];
            })
        }

        // formik.setValues({
        //     ...formik.values,
        // })

    }, [params && paymentMethodList])

    useEffect(() => {
        let res = cryptoCurrencyOptions.filter((curr) => {
            return curr?._id === currency
        })
        formik.setValues({
            ...formik.values, buyCurrency: res[0]
        })
    }, [currency, cryptoCurrencyOptions])

    useEffect(() => {
        if (paymentMethodList?.length === 0) {
            fetchPaymentMethods()
        }
    }, [])
    return (
        <Div className='p-3 otherbuyofferfilter'>
            <P>Filter Your Searches</P>
            <form onSubmit={formik.handleSubmit}>
                <div className='row m-0'>
                    <div className='col-md-6 col-sm-12 d-flex flex-column gap-1'>
                        <InputLabel htmlFor="">Sell</InputLabel>
                        <Select className="select"
                            width='100%'
                            name='buyCurrency'
                            id='buyCurrency'
                            options={cryptoCurrencyOptions}
                            filterOption={customFilterForCrypto}
                            styles={styles}
                            onChange={(selectedValues) => {
                                navigate(`/dashboard/sell/${selectedValues?.value}`)
                            }}
                            value={formik.values.buyCurrency}
                        />
                    </div>
                    <div className='col-md-6 col-sm-12 d-flex flex-column gap-1'>
                        <InputLabel>Pay Via</InputLabel>
                        <Select className="select"
                            width='100%'
                            name='payVia'
                            id='payVia'
                            options={paymentMethodList}
                            styles={styles}
                            onChange={(selectedValues) => {
                                let event = { target: { name: 'payVia', value: selectedValues } }
                                formik.handleChange(event)
                            }}
                            onBlur={(selectedValues) => {
                                let event = { target: { name: 'payVia', value: selectedValues } }
                                formik.handleBlur(event)
                            }}
                            value={formik.values.payVia}
                        />
                    </div>
                </div>

                <div className='row m-0'>
                    <div className='col-md-6 col-sm-12 d-flex flex-column gap-1'>
                        <InputLabel className='mt-2'>I want to spend</InputLabel>
                        <InputContainer className='p-1 d-flex align-items-center justify-content-between'>
                            <Input name='spendAmount' id='spendAmount'
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.spendAmount}
                            />
                            <Select className="select"
                                name='preferredCurrency'
                                id='preferredCurrency'
                                options={[
                                    {
                                        value: '',
                                        label: 'Any'
                                    }, ...fiatCurrencyOptions
                                ]}
                                filterOption={customFilterForCrypto}
                                styles={styles1}
                                onChange={(selectedValues) => {
                                    let event = { target: { name: 'preferredCurrency', value: selectedValues } }
                                    formik.handleChange(event)
                                }}
                                onBlur={(selectedValues) => {
                                    let event = { target: { name: 'preferredCurrency', value: selectedValues } }
                                    formik.handleBlur(event)
                                }}
                                value={formik.values.preferredCurrency}
                            />

                        </InputContainer>
                    </div>
                    <div className='col-md-6 col-sm-12 d-flex flex-column gap-1'>
                        <InputLabel htmlFor="" className='mt-2'>Offer Location</InputLabel>
                        <Select className="select"
                            width='100%'
                            name='offerLocation'
                            id='offerLocation'
                            filterOption={customFilterForCountry}
                            options={[
                                {
                                    value: "",
                                    label: 'Any'
                                },
                                ...countryOptions
                            ]}
                            styles={styles}
                            onChange={(selectedValues) => {
                                let event = { target: { name: 'offerLocation', value: selectedValues } }
                                formik.handleChange(event)
                            }}
                            onBlur={(selectedValues) => {
                                let event = { target: { name: 'offerLocation', value: selectedValues } }
                                formik.handleBlur(event)
                            }}
                            value={formik.values.offerLocation}
                        />
                    </div>
                </div>

                <div className='row m-0'>
                    <div className='col-md-6 col-sm-12 d-flex flex-column'>

                        <div className="form-check form-switch d-flex justify-content-between w-100 mx-0 px-0 ">
                            <InputLabel htmlFor="flexSwitchCheckDefault1" className="mt-2">Offer Owner Location</InputLabel>
                        </div>
                        <Select className="select"
                            width='100%'
                            name='offerOwnerLocation'
                            id='offerOwnerLocation'
                            options={[
                                {
                                    value: "",
                                    label: 'Any'
                                },
                                ...countryOptions
                            ]}
                            filterOption={customFilterForCountry}
                            styles={styles}
                            onChange={(selectedValues) => {
                                let event = { target: { name: 'offerOwnerLocation', value: selectedValues } }
                                formik.handleChange(event)
                            }}
                            onBlur={(selectedValues) => {
                                let event = { target: { name: 'offerOwnerLocation', value: selectedValues } }
                                formik.handleBlur(event)
                            }}
                            value={formik.values.offerOwnerLocation}
                        />
                    </div>
                    <div className='col-md-4 col-sm-12 d-flex flex-wrap align-items-bottom justify-content-center gap-2 pt-4'>
                        <BlueButton className='findofferfortour' type='submit'>Find Offer </BlueButton>
                        <CancelButton className='resetofferfortour' onClick={() => {
                            formik.setValues({
                                payVia: '',
                                spendAmount: '',
                                preferredCurrency: '',
                                offerLocation: "",
                                offerOwnerLocation: '',
                            })
                        }}>Reset All</CancelButton>
                    </div>
                </div>
            </form>
        </Div>
    )
}
Filters.propTypes = {
    currency: PropTypes.string.isRequired,
    formik: PropTypes.instanceOf(Formik).isRequired,
};