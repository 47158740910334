import React, { useEffect, useState } from 'react'
import Buyoffer from './Buyoffer';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import Selloffer from './Selloffer';
import axios from 'axios';
import './Index.css'
import { MdOutlineBlock } from 'react-icons/md'
import { CgUnblock } from 'react-icons/cg'
import { API_URLs } from '../../Utils/ApiUrls';
import styled from 'styled-components';
import { Oval } from 'react-loader-spinner';
import CustomModal from '../../hooks/CustomModal';
import { BsArrowDownLeft, BsArrowUpRight } from 'react-icons/bs'
import { useThemeContext } from '../../Context/ThemeContext';
import { useAuthContextHook } from '../../Context/AuthContext';




const BlockButton = styled.button`
background-color: white;
font-weight: 600;
font-size: 13px;
font-family: 'Inter';
width: 10rem;
border-radius: 15px;
border: 1px solid #E9EAF3;
outline: none;
padding: 5px 10px;
transition: all 0.2s;
display: flex;
justify-content: center;
:hover{ 
    border: 1px solid #1476FF;
    background:  #1476FF;
    color: white;
}
`
const P = styled.p`
font-family: 'Inter';
font-size: 24px;
font-weight: 800;
`

const Button = styled.button`
    border: ${({theme})=>theme.border};
    border-radius: 40px;
    background: #1476FF;
    outline: none;
    font-family: 'Inter';
    font-style: normal;
    font-size: 14px;
    font-weight: 600;
    transition: all 0.2s;
    text-decoration: none;
    color: white;

    :hover{
        background: #3545EE;
        color: #33343c;
    }
`
const Span = styled.span`
border-radius: 100%;
display: flex;
align-items: center;
justify-content: center;
font-size: 60%;
background: ${({theme})=> theme.body=='#F7F9FC' ? 'white' : theme.body};
padding: 2px;
color: black;
align-self: center;
width: 20px;
height: 20px;
padding-top: 4px;
`
const Div = styled.div`
border-radius: 20px;
background: ${({theme})=> theme.body=='#F7F9FC' ? 'white' : theme.body};
margin-top: 2rem;
font-family: 'Inter';
box-shadow: ${({theme})=> theme.boxshadow};
border: ${({theme})=> theme.border};
`
export default function Index() {
    const [blockAllLoader, setBlockAllLoader] = useState(false);
    const [unblockAllLoader, setUnblockAllLoader] = useState(false);
    const [offerdata, setofferdata] = useState([])
    const [buyofferdata, setBuyofferdata] = useState([])
    const [sellofferdata, setSellofferdata] = useState([])
    const { userDetails } = useAuthContextHook()
    const { handleSetNavbarHeading, isDarkTheme } = useThemeContext()
    const [dataLoader, setDataLoader] = useState(true)
    const navigate = useNavigate();
    const location = useLocation();
    const isTabSelected = location.hash.includes('buyoffer')
        || location.hash.includes('selloffer')

    function getAllOffers() {
        setDataLoader(true)
        axios.get(API_URLs.createOffer, {
            headers: {
                'accept': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('jwt')}`
            }
        })
            .then((res) => {
                setDataLoader(false)
                setofferdata(res.data);
            })
            .catch((error) => {
                setDataLoader(false)
            })
    }

     const [showModal, setShowModal] = useState(false);
     const [modalData, setModalData] = useState(null);
     const [navigateLink, setNavigateLink] = useState('');
     const handleShowModal = (data) => {
         setModalData(data);
         setShowModal(true);
     };
     const handleCloseModal = () => {
         setShowModal(false)
     };

    useEffect(() => {
        if(offerdata?.length === 0){
            getAllOffers()
        }
    }, [])

    useEffect(() => {
        let buyofferdata = offerdata.filter((offer) => {
            return offer.offer_type === 'buy'
        })
        setBuyofferdata(buyofferdata)
        let sellofferdata = offerdata.filter((offer) => {
            return offer.offer_type === 'sell'
        })
        setSellofferdata(sellofferdata)
    }, [offerdata])

    const handleBlockAll = () => {
        setBlockAllLoader(true);
        axios.put(API_URLs.createOffer + '/blockall', {}, {
            headers: {
                'accept': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('jwt')}`
            }
        })
            .then((res) => {
                setBlockAllLoader(false);
                handleShowModal(res.data.msg ? res.data.msg : 'All Offers Paused Successfully!!')
                setNavigateLink('');
                getAllOffers();
            })
            .catch((error) => {
                setBlockAllLoader(false);
                handleShowModal(error.response?.data?.message ? error.response?.data?.message : "Some Error Occured");
                setNavigateLink('');
            })
    }
    const handleUnblockAll = () => {
        setUnblockAllLoader(true);
        axios.put(API_URLs.createOffer + '/unblockall', {}, {
            headers: {
                'accept': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('jwt')}`
            }
        })
            .then((res) => {
                setUnblockAllLoader(false);
                handleShowModal(res.data.msg ? res.data.msg : 'All Offers Resume Successfully!!');
                setNavigateLink('');
                getAllOffers();
            })
            .catch((error) => {
                setUnblockAllLoader(false);
                handleShowModal(error.response?.data?.message ? error.response?.data?.message : "Some Error Occured");
                setNavigateLink('');
            })
    }
    useEffect(() => {
        handleSetNavbarHeading('My Offers')
    }, [])

    return (
        <div className='p-3 container-fluid myoffersfortour'>
            <div className='d-flex justify-content-between align-items-center'>
                <P className='mb-0 text-nowrap'>My Offers</P>
                {
                    offerdata.length === 0 ? '' :
                        <div className='d-flex flex-wrap gap-3 justify-content-end pauseallbutton'>
                            <BlockButton type='button' onClick={() => handleBlockAll()}>{
                                blockAllLoader ?
                                    <Oval
                                        height={15}
                                        width={15}
                                        color="black"
                                        wrapperStyle={{}}
                                        wrapperClass=""
                                        visible={true}
                                        ariaLabel="oval-loading"
                                        secondaryColor="#4fa94d"
                                        strokeWidth={2}
                                        strokeWidthSecondary={2}
                                    /> : <p className='mb-0 d-flex gap-1 align-items-center pauseallfortour'>Pause All Offers <MdOutlineBlock size={14} /></p>
                            }</BlockButton>
                            <BlockButton type='button' onClick={() => handleUnblockAll()}>{
                                unblockAllLoader ?
                                    <Oval
                                        height={15}
                                        width={15}
                                        color="black"
                                        wrapperStyle={{}}
                                        wrapperClass=""
                                        visible={true}
                                        ariaLabel="oval-loading"
                                        secondaryColor="#4fa94d"
                                        strokeWidth={2}
                                        strokeWidthSecondary={2}
                                    /> : <p className='mb-0 d-flex gap-1 align-items-center resumeallfortour'>Resume All Offers <CgUnblock size={14} /></p>
                            }</BlockButton>
                        </div>
                }
            </div>

            <div className='mt-3'>
                <ul className="buyselloffercatogoryfortour nav nav-tabs myoffer-tabs gap-3 border-0" id="myTab" role="tablist">
                    <div className="nav-item text-decoration-none mybuyofferbutton" >
                        <button
                            className={`nav-link gap-1 ${isTabSelected ? location.hash.includes('buyoffer') ? 'active' : '' : 'active'}`}
                            id="buyoffer-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#buyoffer"
                            type="button"
                            role="tab"
                            aria-controls="buyoffer"
                            onClick={() => navigate('#buyoffer')}
                            aria-selected="true">
                            <BsArrowDownLeft />
                            Buy offer <Span active={isTabSelected ? location.hash.includes('buyoffer') ? true : false : true}>{buyofferdata?.length}</Span>
                        </button>
                    </div>
                    <div className="nav-item text-decoration-none mysellofferbutton" >
                        <button
                            className={`nav-link gap-1 ${isTabSelected ? location.hash.includes('selloffer') ? 'active' : '' : ''}`} id="selloffer-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#selloffer"
                            type="button"
                            role="tab"
                            aria-controls="selloffer"
                            onClick={() => navigate('#selloffer')}
                            aria-selected="true">
                            <BsArrowUpRight />
                            Sell Offer <Span active={isTabSelected ? location.hash.includes('selloffer') ? true : false : false}>{sellofferdata?.length}</Span>
                        </button>
                    </div>
                </ul>
            </div>

            <Div className="tab-content" id="myTabContent">
                <div
                    className={`tab-pane fade ${isTabSelected ? location.hash.includes('buyoffer') ? 'show active' : '' : 'show active'}`}
                    id="buyoffer"
                    role="tabpanel"
                    aria-labelledby="buyoffer-tab">
                    <Buyoffer buyofferdata={buyofferdata} getAllOffers={getAllOffers} dataLoader={dataLoader} />
                </div>
                <div
                    className={`tab-pane fade ${isTabSelected ? location.hash.includes('selloffer') ? 'show active' : '' : ''}`}
                    id='selloffer'
                    role='tabpanel'
                    aria-labelledby='selloffer-tab'>
                    <Selloffer sellofferdata={sellofferdata} getAllOffers={getAllOffers} dataLoader={dataLoader} />
                </div>

                <div className='d-flex justify-content-end p-2 me-3'>
                    <Button as={Link} to='/dashboard/create-offer' className='px-3 py-1'>Create Offer</Button>
                </div>
            </Div>

            {showModal && (<CustomModal
                show={showModal}
                handleClose={handleCloseModal}
                navigateLink={navigateLink}
                data={modalData}/>
            )}
        </div>
    );
}