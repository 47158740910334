import styled from 'styled-components';

export const SubP = styled.p`
font-size: 18px;
z-index: 1;
color: #CED4DA;
`

export const Title = styled.label`
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 0.9rem;
`
export const Input = styled.input`
    border: none;
    outline: none;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    width: 70%;
    font-size: 14px;
    padding: 12px 16px;
    border-radius: 25px;
    color: ${({theme})=> theme.text};
`
export const SubmitButton = styled.button`
    background: #3545EE;
    border-radius: 32px;
    padding: 1rem 1.5rem;
    border: none;
    outline: none;
    color: white;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
`
export const TagButton = styled.button`
    border: 1px solid #DEE2E6;
    color: #404040;
    border-radius: 32px;
    font-size: 16px;
    padding: 6px 20px;
    font-weight: 500;
    margin: 2px;
    background: white;
    text-wrap: nowrap;
    
`
export const TagButtonCurr = styled.button`
    border: 1px solid #DEE2E6;
    color: #404040;
    border-radius: 32px;
    font-size: 16px;
    padding: 6px 11px;
    font-weight: 500;
    margin: 2px;
    background: white;
    
`
export const Card = styled.div`
position: relative;
background: ${({theme})=> theme.body=='#F7F9FC' ? 'white' : theme.body};
border-radius: 26px;
box-shadow: 0px 24px 48px #D2D2D266;
border: ${({theme})=> theme.border};
top: -300px;
@media (min-width: 5001px){
    top: -110px;
}
`

export const InputContainer = styled.div`
display: flex;
background: white;
padding: 5px;
justify-content: space-between;
align-items: center;
border-radius: 32px;
`
export const InputContainer1 = styled.div`
display: flex;
background: white;
border: 1px solid #DEE2E6;
width: 100%;
justify-content: space-between;
align-items: center;
border-radius: 32px;
`
export const Input1 = styled.input`
border: none;
width: 100%;
outline: none;
padding: 10px 16px;
border-radius: 32px;
`