import React from 'react'
import styled from 'styled-components';
import Img from '../../assets/Images/AboutMision.png'
const P = styled.p`
color: #202939;
margin-bottom: 0px;
font-size: 46px;
font-weight: 700;
@media (max-width: 992px){
    font-size: 30px;
    margin: 10px 0;
}
`
const Button = styled.button`
border-radius: 20px;
border: none;
font-size: 14px;
font-weight: 600;
`
const Para = styled.p`
font-size: 17px;
`
function Mission() {
  return (
    <div className='container bg-white'>
        <div className='row m-0 pt-5 pb-2 d-flex flex-wrap justify-content-center align-items-center'>
            <div className='col-md-6'>
                <Button>
                    Our Mission
                </Button>
                <P>
                    <span className='d-lg-block'>Safe and convenient </span> 
                    <span className='d-lg-block'> cryptocurrencies </span> 
                    <span className='d-lg-block'>trading</span>
                </P>
            </div>
            <div className='col-md-6'>
                <Para className='text-justify'>Behind Qvani Technologies is a group of early adopters who believe in a blockchain-based future. We’re updating the century-old financial system by providing a trusted platform that makes it easy for people and institutions to engage with crypto assets, including trading and safekeeping, and lightning-fast global transfers.</Para>
            </div>
        </div>
        <div className='d-none d-md-block mt-5 text-center'>
            <img src={Img} width={'100%'}/>
        </div>
    </div>
  )
}

export default Mission