import React from 'react'
import Trade from './Trade'
import BuySellForm from './BuySellForm';
import Card from './Card'
import Experience from './Experience'
export default function Home() {

    return (
        <div className='bg-white'>
            <Trade />
            <BuySellForm/>
            <Card />
            <Experience/>
        </div>
    );
}

