import styled from "styled-components";

export const NotificationHeading = styled.p`
font-weight: 700;
font-size: 24px;
margin-bottom: 0px;
`
export const Button = styled.button`
width: 100%;
border: none;
background: transparent;
color: white;
`
export const NotificationBody = styled.p`
border-radius: 20px;
background: ${({theme})=>theme.body === "#F7F9FC" ? 'white': theme.body};
border: ${({theme})=>theme.border};
box-shadow: ${({theme})=>theme.boxshadow};
`
export const SubNotificationHeading = styled.p`
font-weight: 700;
font-size: 17px;
text-transform: capitalize;
margin-bottom: 10px;
`
export const Unred = styled.p`
    background: red;
    color: white;
    font-size: 12px;
    border: none;
    border-radius: 93px;
    padding: 0px 5px;
`
export const Allbutton = styled.button`
border: none;
outline: none;
color: ${({theme})=>theme.text};
background: transparent;
font-weight: 600;
font-size: 13px;
transition: .9s ease-out;
:hover{
    color: red;
    transition: .9s ease-out;
}
`