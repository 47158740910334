import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import { useFormik } from 'formik';
import * as Yup from 'yup'
import { darkTheme, lightTheme } from "../../Theme/theme";
import { useThemeContext } from '../../Context/ThemeContext';
import styled from 'styled-components';
import { useAPIContextHook } from "../../Context/ApiContext";
import { API_URLs } from '../../Utils/ApiUrls';
import axios from 'axios';
import ButtonLoader from '../../Component/Loader/ButtonLoader';
import PropTypes from 'prop-types';
import CustomModal from '../../hooks/CustomModal';

export const InputLabel = styled.label`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    color: #303350;
    padding-left: 10px;
`
export const PrevButton = styled.button`
    border: 2px solid #1476FF;
    background-color: #1476FF;
    color: white;
    border-radius: 20px;
    padding: 3px 25px;
    width: 100%;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
`
export const Input = styled.input`
    all: unset;
    background: transparent;
    border: ${({theme})=>theme.border};
    box-shadow: ${({theme})=>theme.boxshadow};
    border-radius: 52px;
    outline: none;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: ${({theme})=>theme.text};  
    :disabled{
        opacity: 0.8;
        color: #6F7182;
    }
`
export default function Upi({ handleClose, handleSetRefresh, singleUpiDetails, isUpiEdit }) {
    const { fiatCurrencyOptions, customFilterForCrypto } = useAPIContextHook()
    const { isDarkTheme } = useThemeContext();
    const [loader, setLoader] = useState(false)

    // console.log(fiatCurrencyOptions);
    const [showModal, setShowModal] = useState(false);
    const [modalData, setModalData] = useState(null);
    const [navigateLink, setNavigateLink] = useState('');
    const handleShowModal = (data) => {
        setModalData(data);
        setShowModal(true);
    };
    const handleCloseModal = () => {
        setShowModal(false)
    };

    const handleSubmitUPI = (values) => {
        setLoader(true)
        axios.post(API_URLs.upi, {
            upiId: values.upiId,
            currency: values.currency.value
        }, {
            headers: {
                'accept': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('jwt')}`
            }
        })
            .then((res) => {
                handleShowModal(res.data.msg ? res.data.msg : 'Card Add Successfully!!');
                setNavigateLink('');
                handleClose();
                setLoader(false)
                handleSetRefresh()
            })
            .catch((error) => {
                setLoader(false);
                setNavigateLink('');  
                handleShowModal(error?.response?.data?.message ? error?.response?.data?.message : "Some Error Occured");
            })
    }
    const handleEditUPI = (values) => {
        setLoader(true);
        axios.patch(API_URLs.updateUpi(singleUpiDetails.id), {
            upiId: values.upiId,
            currency: values.currency.value
        }, {
            headers: {
                accept: 'application/json',
                Authorization: `Bearer ${localStorage.getItem('jwt')}`
            }
        })
            .then((res) => {
                handleClose();
                handleSetRefresh();
                setLoader(false)
                handleShowModal(res.data.msg ? res.data.msg : 'Card Add Successfully!!');
                setNavigateLink('');
            })
            .catch((error) => {
                setLoader(false)
                setNavigateLink('');  
                handleShowModal(error?.response?.data?.message ? error?.response?.data?.message : "Some Error Occured");
            })
    }
    const formik = useFormik({
        initialValues: { upiId: '', currency: '' },
        validationSchema: Yup.object().shape({
            upiId: Yup.string().required("UPI ID is Required"),
            currency: Yup.object().shape({
                value: Yup.string().required("Currency is Required")
            })
        }),
        onSubmit: values => {
            if (isUpiEdit) {
                handleEditUPI(values);
            } else {
                handleSubmitUPI(values);
            }
        }
    })

    const addValue=()=>{
        const currency = fiatCurrencyOptions.filter((data)=>{
            return data.symbol === singleUpiDetails.currency.symbol
        })
        formik.setValues({
            currency: currency[0],
            upiId: singleUpiDetails.upiId
        })
    }
    useEffect(()=>{
        if(isUpiEdit){
            addValue();
        }
    },[])
    const styles = {
        control: (styles) => ({
            ...styles,
            background: 'transparent',
            borderRadius: '52px',
            width: '100%',
            height: '50px',
            color: 'white',
            boxShadow: `${isDarkTheme ? darkTheme.boxshadow : lightTheme.boxshadow}`,
            border: `${isDarkTheme ? darkTheme.border : lightTheme.border}`, "&:hover": {
                border: `${isDarkTheme ? darkTheme.border : lightTheme.border}`
            },
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: isDarkTheme && state.isHovered ? '#302F30' : `${({theme})=> theme.body}`,
            ':hover': {
              backgroundColor:  `${isDarkTheme ? '#302F30' : '#D9EDE7'}` ,
            },
            color: isDarkTheme && state.isSelected ? '#1476FF' : `${({theme})=> theme.text}`,
        }),
        indicatorSeparator: () => ({
            all: 'unset'
        }),
        indicatorsContainer: (styles) => ({
            ...styles, color: isDarkTheme ? 'white' : 'black', 'div:nth-child(2)': {
                color: isDarkTheme ? 'white' : lightTheme.text
            }
        }),
        menu: (styles) => ({
            ...styles, background: isDarkTheme ? 'rgb(27, 27, 28)' : 'white',
            color: isDarkTheme ? 'white' : lightTheme.text,
        })
    }
    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className='d-flex flex-column'>
                    <InputLabel htmlFor='upiId'>UPI ID <span className='text-danger'>*</span></InputLabel>
                    <Input
                        name='upiId'
                        id='upiId'
                        placeholder='Enter your UPI ID'
                        className="px-3 py-2"
                        value={formik.values.upiId}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                    />
                </div>
                <div className='col-md-12'>
                    <InputLabel htmlFor='currency'>Currency<span className="text-danger"> *</span></InputLabel>
                    <Select options={fiatCurrencyOptions}
                        filterOption={customFilterForCrypto}
                        styles={styles}
                        name='currency'
                        id='currency'
                        onChange={(selectedValues) => {
                            let event = { target: { name: 'currency', value: selectedValues } }
                            formik.handleChange(event)
                        }}
                        onBlur={(selectedValues) => {
                            let event = { target: { name: 'currency', value: selectedValues } }
                            formik.handleBlur(event)
                        }}
                        value={formik.values.currency}
                    />
                    <small>
                        {formik.errors.currency && formik.touched.currency && (
                            <div className="input-feedback text-danger auth-error text-start">{String(formik.errors.currency?.value).replace('.value', '')}</div>
                        )}
                    </small>
                </div>
                <div className='mt-2 text-center'>
                    <PrevButton type='submit' disabled={loader} >{loader ? <span className='d-flex justify-content-center align-items-center'><ButtonLoader /> {isUpiEdit ? 'Update' : 'Add'}</span> : <>{isUpiEdit ? 'Update' : 'Add'}</>}</PrevButton>
                </div>
            </form>
            {showModal && (<CustomModal
                show={showModal}
                handleClose={handleCloseModal}
                navigateLink={navigateLink}
                data={modalData}
            />)}
        </>
    );
}
Upi.propTypes = {
    isUpiEdit: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleSetRefresh: PropTypes.func.isRequired,
    singleUpiDetails: PropTypes.object.isRequired,
};