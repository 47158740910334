import React, { Suspense } from 'react'
import { Outlet } from 'react-router-dom'
import { useAuthContextHook } from '../Context/AuthContext'
import Loader from '../Component/Loader/Loader'

export default function LoaderLayout() {
    const { loading, apiLoader } = useAuthContextHook()
    return (
        <Suspense>
            {
                loading || apiLoader ? <Loader /> :
                    <Outlet />
            }
        </Suspense>
    )
}
