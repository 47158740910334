import React from 'react'
import img1 from '../../assets/Images/Chart.png'
import img2 from '../../assets/Images/Table.png'
import img3 from '../../assets/Images/Detailed Statistics.png'
import img4 from '../../assets/Images/Chart-3.png'
import styled from 'styled-components'




const P = styled.p`
font-size: 13px;
font-weight: 600;
`;




const arr =[
    {
        img: img1,
        head: "Buy & Sell Cryptocurrencies in P2P Marketplace",
        subhead: "Trade Crypto globally on your own terms using over 400+ payment methods and any currency. Also, Buy or sell gift cards for Bitcoin.",
    },
    {
        img: img2,
        head: "The Lowest Fees",
        subhead: "We offer the lowest trading fees in the market. Our fees structure is transparent to our community.",
    },
    {
        img: img3,
        head: "Secure Trading",
        subhead: " Crypto is held in secure escrow until a trade is successfully completed. Also, we use the most secure and multifaceted risk management technologies designed to protect our customers' assets.",
    },
    {
        img: img4,
        head: "Your crypto is your crypto",
        subhead: "Your crypto is your crypto. It’s that simple. Qvani exchange doesn't use, or lend your assets without your permission.",
    
    },
]
export default function Experience() {
    return (
        <div className='container-fluid mt-5'>
        {
            arr.map((data, index)=>{
                return <div className={`row m-0 d-flex ${index%2!= 0 && 'flex-row-reverse'} gap-2 gap-md-5 mt-4 justify-content-center align-items-center`}>

                    <div className='col-md-4 col-sm-6 my-1'>
                        <p className='fs-3 fw-bold mb-2 text-center text-md-start'>{data.head}</p>
                        <P className='mb-4 text-secondary text-center text-md-start'>{data.subhead}</P>
                    </div>

                    <div className='col-md-4 my-1'>
                        <img src={data.img} width='100%' />
                    </div>
                </div>
            })
        }
        </div>
    );
}