import React, { useCallback, useEffect, useState } from 'react'
import DataTable, { createTheme } from "react-data-table-component";
import { ImgDiv } from './StyledComponent';
import { ProfileHeading } from '../Profile/StyledComponents';
import { useAuthContextHook } from "../../../src/Context/AuthContext";
import { BsEyeFill } from "react-icons/bs";
import styled from 'styled-components';
import { FaArrowLeft, FaRegCopy } from "react-icons/fa";
import CryptoTransactionSingle from './CryptoTransactionSingle';
import { CSVLink } from "react-csv";
import { Small } from './CryptoBalance'
import Btc from '../../images/Home/btc.png';
import Usdt from '../../images/Home/usdt.png'
import Matic from '../../images/Home/matic.png'
import Ether from '../../images/Home/ether.png'
import Usdc from '../../images/Home/USDC.png'
import { useThemeContext } from '../../Context/ThemeContext';
import { useWalletContextHook } from '../../Context/WalletContext';
import { TiTick } from 'react-icons/ti';

const Heading = styled.p`
font-weight: 700 !important;
font-size: 14px;
margin-bottom: 0px
`
const FilterInput = styled.input`
outline: none;
padding: 1px 10px;
margin: 4px 20px;
color: ${({ theme }) => theme.text};
border-radius: 20px;
background: ${({ theme }) => theme.body == '#F7F9FC' ? 'white' : theme.body};
width: 10rem;
border: ${({ theme }) => theme.border};
box-shadow: ${({ theme }) => theme.boxshadow};
`
const Div = styled.div`
margin-top: 1rem;
background: ${({ theme }) => theme.body == '#F7F9FC' ? 'white' : theme.body};
border-radius: 20px;
border: ${({ theme }) => theme.border};
box-shadow: ${({ theme }) => theme.boxshadow};
`
export default function CryptoTransaction({ shown }) {
    const { userDetails } = useAuthContextHook();
    const { isDarkTheme } = useThemeContext();
    const { cryptoTransactionList, getCryptoTransactionList } = useWalletContextHook()
    const [singleTransDetail, setSingleTransDetail] = useState({})
    const [searchText, setSearchText] = useState('')
    const [downloadData, setDownloadData] = useState([])
    const [copySuccess, setCopySuccess] = useState(false);
    const [copiedAdd, setCopiedAdd] = useState('')
    createTheme('solarized', {
        background: {
            default: `${isDarkTheme ? "#0c0c0c" : 'white'}`
        },
        action: {
            hover: 'rgba(0,0,0,.08)',
        },
        text: {
            secondary: `${isDarkTheme ? "white" : 'black'}`
        },
    });

    const CurrencyObj = {
        ETH: { name: 'Ethereum', shortName: 'Ether', img: Ether },
        BTC: { name: 'Bitcoin', shortName: 'Btc', img: Btc },
        USDC: { name: 'USD Coin', shortName: 'Usdc', img: Usdc },
        USDT: { name: 'Tether', shortName: 'Usdt', img: Usdt },
        MATIC: { name: 'Polygon', shortName: 'Matic', img: Matic }
    }

    const copyToClipBoard = useCallback(async copyMe => {
        try {
            await navigator.clipboard.writeText(copyMe);
            setCopiedAdd(copyMe);
            setCopySuccess(true);
        } catch (err) {
            setCopySuccess(false);
        }
    }, [])

    const columns = [
        {
            name: <div>
                <Heading>Transaction ID</Heading>
            </div>,
            selector: (row) => <div className='d-flex gap-1 '>
                { (copySuccess && copiedAdd === row?.transactionId) ? <TiTick onMouseLeave={()=>setCopySuccess(false)} size={17}/> : <FaRegCopy onClick={() => copyToClipBoard(row?.transactionId)} role='button' title='copy' size={17} /> }
                <p className='mb-0 '>{row?.transactionId}</p>
            </div>,
            wrap: true
        },
        {
            name: <div>
                <Heading>Currency</Heading>
            </div>,
            selector: (row) => <div className='d-flex justify-content-between align-items-center gap-1'>
                <ImgDiv>
                    <img src={CurrencyObj[row.currency].img} alt='' width='100%' />
                </ImgDiv>
                <p className='mb-0 fw-bold'>{CurrencyObj[row.currency].name}</p>
                {/* <Small className='d-none d-md-block text-secondary'>{CurrencyObj[row.currency].shortName}</Small> */}
            </div>
        },
        {
            name: <div>
                <Heading> Amount </Heading>
            </div>,
            wrap: true,
            selector: (row) => row?.toUserId?.id === userDetails?.user?._id ? <>{row?.receivedAmount}</> : <>{row?.transactionAmount}</>,
        },
        {
            name: <div>
                <Heading>Amount (USD)</Heading>
            </div>,
            // selector: (row) => row?.toUserId?._id === userDetails?.user?._id ? <>{row?.convertCurrencyUSD}</> : <>{ `$${row?.convertinUSD}`}</>
            selector: (row) =>  <>{`$${Number(row?.convertinUSD).toFixed(3)}`}</>
            
        },
        {
            name: <div>
                <Heading>Type </Heading>
            </div>,
            selector: (row) => row?.toUserId === '' ? '' :
                row?.toUserId?.id === userDetails?.user?._id ?
                    <div className="text-success fw-bold">Credited</div> : <div className='text-danger fw-bold'>Debited</div>
        },
        {
            name: <div>
                <Heading>Date</Heading>
            </div>
            ,
            selector: (row) => row?.createdAt === '' ? '' : new Date(row?.updatedAt).toDateString(),
            sortable: true,
        },
        {
            name: <Heading>Action</Heading>,
            cell: (row) => (
                <> {row?.createdAt !== '' &&
                    <div className="d-flex align-items-center gap-1 transactionlistviewfortour" role='button' onClick={() => {
                        setSingleTransDetail(row)
                    }}>
                        View <BsEyeFill />
                    </div>
                }
                </>
            ),
        },
    ];

    // console.log('rowwwwww', row)
    const columnsForDashboard = [
        {
            name: <div>
                <Heading>Transaction ID</Heading>
            </div>,
            selector: (row) => <div className='d-flex gap-1 '>
                { (copySuccess && copiedAdd === row?.id) ? <TiTick onMouseLeave={()=>setCopySuccess(false)} size={17}/> : <FaRegCopy onClick={() => copyToClipBoard(row?.id)} role='button' title='copy' size={17} /> }
                <p className='mb-0 '>{row?.id}</p>
            </div>,
            wrap: true
        },
        {
            name: <div>
                <Heading>Currency</Heading>
            </div>,
            selector: (row) => <div className='d-flex justify-content-between align-items-center gap-1'>
                <ImgDiv>
                    <img src={CurrencyObj[row.currency].img} alt='' width='100%' />
                </ImgDiv>
                <p className='mb-0 fw-bold'>{CurrencyObj[row.currency].name}</p>
                <Small className='d-none d-md-block text-secondary'>{CurrencyObj[row.currency].shortName}</Small>
            </div>
        },
        {
            name: <div>
                <Heading> Amount </Heading>
            </div>,
            selector: (row) => row?.toUserId?.id === userDetails?.user?._id ? <>{row?.receivedAmount}</> : <>{row?.transactionAmount}</>,
        },
        {
            name: <div>
                <Heading>Type </Heading>
            </div>,
            selector: (row) => row?.toUserId === '' ? '' :
                row?.toUserId?.id === userDetails?.user?._id ?
                    <div className="text-success fw-bold">Credited</div> : <div className='text-danger fw-bold'>Debited</div>
        },
        {
            name: <div>
                <Heading>Date</Heading>
            </div>,
            selector: (row) => row?.createdAt === '' ? '' : new Date(row?.updatedAt).toDateString(),
            sortable: true,
        },
    ];
    const customStyles = {
        header: {
            style: {
                fontSize: '20px',
                color: '#0B0E2C',
                fontFamily: 'Inter',
                fontStyle: 'normal',
                fontWeight: '600',
            },
        },
        headRow: {
            style: {
                fontFamily: 'Inter',
                fontStyle: 'normal',
                fontSize: '12px',
                ...(isDarkTheme ? { borderTop: '1px solid #302f30' } :
                    { border: '1px solid lightgray' }),
                color: `${isDarkTheme ? "#e9e9e9" : '#6F7182'}`,
                backgroundColor: `${isDarkTheme ? "#0c0d0d" : "#F7F9FC"}`,
                textTransform: 'uppercase'
            },
        },
        rows: {
            style: {
                color: `${isDarkTheme && 'white'}`,
                backgroundColor: `${isDarkTheme && 'rgba(8, 9, 14, 0.96)'}`,
                borderTop: '1px solid lightgray',
                '&:not(:last-of-type)': {
                    borderBottom: 'none',
                },
            }
        },
        headCells: {
            style: {
                display: 'flex',
                alignItems: 'center'
            },
        },
        cells: {
            style: {
                display: 'flex',
                alignItems: 'center'
            },
        }
    }

    useEffect(() => {
        let array = [];
        for (let key in cryptoTransactionList) {
            let rowData = cryptoTransactionList[key];
            array.push({
                transactionType: rowData?.transactionType,
                transactionId: rowData?.transactionId,
                fromUserId: rowData?.fromUserId ? rowData?.fromUserId?.name : '',
                toUserId: rowData?.toUserId ? rowData?.toUserId?.name : '',
                adminFee: rowData?.adminFee,
                gasfee: rowData?.gasfee,
                transactionAmount: rowData?.transactionAmount,
            })
        }
        setDownloadData(array)
    }, [cryptoTransactionList])

    useEffect(()=>{
        getCryptoTransactionList()
    },[])
    const handleSearch = (e) => {
        setSearchText(e.target.value)
    }

    const filterData = cryptoTransactionList.filter((data) => {
        return data?._id?.toLowerCase()?.includes(searchText?.toLowerCase()) || data?.receivedAmount?.toLowerCase()?.includes(searchText?.toLowerCase()) || data?.transactionAmountnt?.toLowerCase()?.includes(searchText?.toLowerCase()) || data?.currency?.toLowerCase()?.includes(searchText?.toLowerCase())
    })
    const handleClose = () => setSingleTransDetail({});

    return (
        <Div className='mt-3'>
            {
                !Object.keys(singleTransDetail).length ?
                    <>
                        <div className='d-flex justify-content-between align-items-center overflow-auto' >
                            <p style={{ margin: '13px', fontSize: '16px', fontWeight: '700' }}>Transactions</p>
                            {shown && <FilterInput
                                placeholder='Search Here'
                                value={searchText}
                                onChange={handleSearch}
                            />}
                        </div>
                        <div className='transactionlistfortour' style={{overflowX: 'hidden'}}>
                            <DataTable
                                columns={shown ? columns : columnsForDashboard}
                                data={filterData}
                                customStyles={customStyles}
                                pagination
                                showPageSizeOptions={true}
                                paginationRowsPerPageOptions={[5, 10, 20, 25, 50, 100]}
                                paginationResetDefaultPage={true}
                                paginationPerPage={5}
                                fixedHeader
                                highlightOnHover
                                responsive={true}
                                theme='solarized'
                            />
                            {
                                downloadData?.length > 0 && <div className=" my-3 mx-5  d-flex justify-content-end">
                                    <CSVLink
                                        className=" transactionlistdownloadfortour text-decoration-none text-white bg-primary p-1 rounded outline-none"
                                        data={downloadData}
                                    >Download CSV
                                    </CSVLink>
                                </div>
                            }
                        </div>
                    </> :
                    <>
                        <div className="d-flex justify-content-start px-3 pt-2 align-items-center" onClick={handleClose}>
                            <FaArrowLeft /><ProfileHeading>Transactions Details</ProfileHeading>
                        </div>
                        <hr className='mx-2 my-1' />
                        <CryptoTransactionSingle
                            singleTransDetail={singleTransDetail}
                            handleClose={handleClose}
                        />
                    </>
            }
        </Div>
    )
}
