import axios from 'axios';
import React, { useEffect, useLayoutEffect, useState } from 'react'
import styled from 'styled-components'
import { API_URLs } from '../../Utils/ApiUrls';
import { useThemeContext } from '../../Context/ThemeContext';
import { useAPIContextHook } from '../../Context/ApiContext';
import { useParams, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import { useAuthContextHook } from '../../Context/AuthContext';
import { InputLabel } from '../Profile/StyledComponents';
import Filters from './Filters';
import List from './List';

const Heading = styled.p`
font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size:20px;
    line-height: 34px;
    color: #0B0E2C;
    margin-bottom: 0px;
    text-transform: capitalize;
    word-break: break-all;
`
const ListCard = styled.div`
padding: 16px 0px 27px;
background: ${({ theme }) => theme.body == '#F7F9FC' ? 'white' : theme.body};
margin-top: 16px;
border-radius: 26px;
border: ${({ theme }) => theme.border};
box-shadow: ${({ theme }) => theme.boxshadow};
`

export default function Buy() {
  const { isDarkTheme, handleSetNavbarHeading } = useThemeContext();
  const { userDetails } = useAuthContextHook();
  const navigate = useNavigate();
  const { cryptoCurrenciesList, fiatCurrencyOptions, fetchFiatCurrency } = useAPIContextHook();

  const params = useParams();
  const { currency } = params
  const [offersList, setOffersList] = useState([]);
  const [loader, setLoader] = useState(true);
  const [currencyDetail, setCurrencyDetail] = useState({});

  const formik = useFormik({
    initialValues: {
      buyCurrency: '',
      payVia: '',
      spendAmount: '',
      preferredCurrency: '',
      offerLocation: "",
      offerOwnerLocation: '',
      tenMinActive: false
    },
    onSubmit: (values) => {
      newfetchOffers(values)
    }
  })

  const newfetchOffers = async (values) => {
    try {
      setLoader(true)
      let res = await axios.get(API_URLs.getOfferForCurrency(currency, values.payVia?.value, values.spendAmount, values.preferredCurrency?.value, values.offerLocation?.value, values.offerOwnerLocation?.value, formik.values.tenMinActive, 'sell'), {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('jwt')}`
        }
      })

      setOffersList(res?.data?.res_obj?.result);

      setLoader(false)
    } catch (error) {
      setLoader(false)
    }

  }

  useEffect(() => {
    newfetchOffers(formik.values)
  }, [currency, formik.values.tenMinActive])

  useLayoutEffect(() => {
    let cryptoDetail = cryptoCurrenciesList.filter((curr) => {

      return curr._id === currency
    })

    setCurrencyDetail(cryptoDetail[0])
  }, [cryptoCurrenciesList, currency])

  useEffect(() => {
    handleSetNavbarHeading("Buy Offer")
    if (fiatCurrencyOptions?.length === 0) {
      fetchFiatCurrency();
    }
  }, [])

  return (
    <div className='p-3 container othersbuyoffers '>
      <div className="lower" >
        <Filters
          currency={currency}
          formik={formik}
        />
        <ListCard>
          <div className='d-flex flex-wrap px-2 justify-content-between align-items-center'>
            <Heading className={isDarkTheme && 'text-light'} >Offers</Heading>
            <div className="d-flex align-items-center lasttenminutefortour justify-content-end gap-2">
              <input
                className='mx-1'
                name='tenMinActive'
                id='tenMinActive'
                value={!formik.values.tenMinActive}
                checked={formik.values.tenMinActive}
                onChange={formik.handleChange}
                type='checkbox' />
              <InputLabel htmlFor='tenMinActive' className='text-secondary'>Vendors active in last 10 min</InputLabel>
            </div>
          </div>
          <List offersList={offersList} loader={loader} currency={currencyDetail} />
        </ListCard>
      </div>
    </div>
  )
}
