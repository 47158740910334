import React, { useState } from 'react'
import Chart from "react-apexcharts";
import { Modal } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';
import CryptoBalance from '../Wallet/CryptoBalance'
import CryptoTransaction from '../Wallet/CryptoTransaction'
import { Heading, Button } from './StyledComponent'
import { useAuthContextHook } from '../../Context/AuthContext';
import { useThemeContext } from '../../Context/ThemeContext';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { API_URLs } from '../../Utils/ApiUrls';

const Div1 = styled.div`
margin: 10px;
border: ${({ theme }) => theme.border};
box-shadow: ${({ theme }) => theme.boxshadow};
color: ${({ theme }) => theme.text};
border-radius: 20px;
background: ${({ theme }) => theme.body == '#F7F9FC' ? 'white' : theme.body};
padding: 10px;
overflow: hidden;
`
export default function Index() {
    const { t } = useTranslation();
    const { userDetails } = useAuthContextHook();
    const { isDarkTheme } = useThemeContext();
    const [btcGraphData, setBtcGraphData] = useState([]);
    const [btcGraphDataAmount, setBtcGraphDataAmount] = useState([]);
    const [offerGraphData, setOfferGraphData] = useState([]);

    const btcGraph = (id) => {
        axios.get(API_URLs.btcGraph(id), {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${localStorage.getItem('jwt')}`
            }
        })
            .then((data) => {
                setBtcGraphData(data)
                let arr = [];

                for (var key in data.data.monthlyData.amount) {
                    arr.push(data.data.monthlyData.amount[key]?.toFixed(4))
                }
                setBtcGraphDataAmount(arr)
            })
            .catch((err) => {
            })
    }

    const offerGraph = (id) => {
        axios.get(API_URLs.offerGraph(id), {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${localStorage.getItem('jwt')}`
            }
        })
            .then((res) => {
                setOfferGraphData(res)
            })
            .catch((err) => {
           // console.log('offerGraph', err);
            })
    }
    React.useEffect(() => {
        btcGraph(userDetails?.user?._id);
        offerGraph(userDetails?.user?._id);
    }, [])


    return (
        <div className='container-fluid'>
            <Heading>{t('Dashboard')}</Heading>
            <div className='row m-0 d-flex justify-content-center align-items-center'>
                <div className='col-md-6 col-sm-12'>
                    <Div1 className='card offerscardfortour' >
                        <Chart
                            options={{
                                chart: {
                                    height: 350,
                                    type: 'line',
                                    background: 'transparent',
                                    zoom: {
                                        enabled: false
                                    },
                                    toolbar: {
                                        show: false
                                    }
                                },
                                stroke: {
                                    curve: 'straight'
                                },
                                title: {
                                    text: 'Your Offers',
                                    align: 'left',
                                    style: {
                                        color: `${isDarkTheme ? 'white' : 'black'}`
                                    }
                                },
                                grid: {
                                    row: {
                                        colors: [`${isDarkTheme ? '#0c0c0c' : '#f3f3f3'}`, `${isDarkTheme ? '#0c0d0d' : 'white'}`],
                                        opacity: 0.5
                                    },
                                },
                                xaxis: {
                                    labels: {
                                        style: {
                                            colors: `${isDarkTheme ? 'lightgrey' : '#0c0c0c'}`,
                                        },
                                    },
                                    axisBorder: {
                                        show: true
                                    },
                                    categories: offerGraphData?.data?.months,
                                },
                                yaxis: {
                                    labels: {
                                        style: {
                                            colors: `${isDarkTheme ? 'lightgrey' : '#0c0c0c'}`,
                                        },
                                    },
                                    axisBorder: {
                                        show: true
                                    },
                                },
                                tooltip: {
                                    theme: `${isDarkTheme ? 'dark' : 'light'}`,
                                }
                            }}
                            series={[{
                                name: "Your Offers",
                                data: offerGraphData?.data?.offers,
                                color: '#1476FF',
                            }]}
                            type='line'
                            width='450'
                            height='250'
                        />
                    </Div1>
                </div>
                <div className='col-md-6 col-sm-12'>
                    <Div1 className='card holdingscardfortour' >
                        <Chart
                            options={{
                                chart: {
                                    height: 350,
                                    type: 'line',
                                    background: 'transparent',
                                    zoom: {
                                        enabled: false
                                    },
                                    toolbar: {
                                        show: false
                                    }
                                },
                                dataLabels: {
                                    enabled: false
                                },
                                stroke: {
                                    curve: 'straight'
                                },
                                title: {
                                    text: 'Your Holdings(BTC)',
                                    align: 'left',
                                    style: {
                                        color: `${isDarkTheme ? 'white' : 'black'}`
                                    }
                                },
                                grid: {
                                    row: {
                                        colors: [`${isDarkTheme ? '#0c0c0c' : '#f3f3f3'}`, `${isDarkTheme ? '#0c0d0d' : 'white'}`],
                                        opacity: 0.5
                                    },
                                },
                                xaxis: {
                                    labels: {
                                        style: {
                                            colors: `${isDarkTheme ? 'lightgrey' : '#0c0c0c'}`,
                                        },
                                    },
                                    axisBorder: {
                                        show: true
                                    },
                                    categories: btcGraphData?.data?.monthlyData?.months,
                                },
                                yaxis: {
                                    labels: {
                                        style: {
                                            colors: `${isDarkTheme ? 'lightgrey' : '#0c0c0c'}`,
                                        },
                                    },
                                    axisBorder: {
                                        show: true
                                    },
                                },
                                tooltip: {
                                    theme: `${isDarkTheme ? 'dark' : 'light'}`,
                                }
                            }}
                            series={[{
                                name: "Holdings(BTC)",
                                data: btcGraphDataAmount,
                                color: '#1476FF',
                                labels: {
                                    style: {
                                        background: 'black'
                                    }
                                }
                            }]}
                            type='line'
                            width='450'
                            height='250'
                        />
                    </Div1>
                </div>
            </div>
            <div className='p-3'>
                <CryptoBalance shown={false} />
            </div>
            <div className='p-3'>
                <CryptoTransaction shown={false} />
            </div>
        </div>
    );
}