import React from 'react'
import { ThreeDots } from 'react-loader-spinner'

export default function Loader() {
    return (
        <div className='d-flex justify-content-center align-items-center'
            style={{ height: '100vh', width: '100vw', position: 'fixed', top: 0, left: 0, background: 'rgb(255, 255, 255, 0.1)', zIndex: '50' }}>
            <ThreeDots
                height={80}
                width={80}
                color="#3545EE"
                wrapperStyle={{}}
                wrapperclassName=""
                visible={true}
                ariaLabel='oval-loading'
                secondaryColor="#3545EE"
                strokeWidth={7}
                strokeWidthSecondary={7} />
        </div>
    )
}
