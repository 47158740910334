import React, { useState, useEffect } from 'react'
import * as Yup from 'yup'
import Select from 'react-select'
import styled from 'styled-components';
import { CardContainer } from '../Profile/StyledComponents';
import { ProfileHeading } from '../ProfileNew/StyledComponent';
import { useAPIContextHook } from '../../Context/ApiContext';
import { darkTheme, lightTheme } from '../../Theme/theme';
import { useThemeContext } from '../../Context/ThemeContext';
import { useFormik } from 'formik';
import ButtonLoader from '../../Component/Loader/ButtonLoader';
import { API_URLs } from '../../Utils/ApiUrls';
import axios from 'axios';
import { useAuthContextHook } from '../../Context/AuthContext';
import CustomModal from '../../hooks/CustomModal';

const BlueButton = styled.button`
color: white;
border: none;
background-color: #0052FF;
font-size: 15px;
width: 7rem;
font-weight: 700;
font-family: 'Inter';
align-items: center;
padding: 7px 26px;
text-decoration: none;
border-radius: 25px;
`
const WhiteButton = styled.button`
border: 1px solid #E9EAF3;
background: #EEF0F3;
margin: 1px 10px;
width: 7rem;
border-radius: 30px;
padding: 7px 26px;
font-size: 15px;
font-weight: 500;
font-family: 'Inter';
`
const P = styled.p`
font-size: 16px;
margin-bottom: 9px;
font-weight: 700;
`
export default function Preference() {
    const { isDarkTheme } = useThemeContext();
    const { userDetails, getUserDetails} = useAuthContextHook();
    const [isSubmit, setisSubmit] = useState(false);

    const { fiatCurrencyOptions, customFilterForCrypto, fetchFiatCurrency } = useAPIContextHook();

    const [showModal, setShowModal] = useState(false);
    const [modalData, setModalData] = useState(null);
    const [navigateLink, setNavigateLink] = useState('');
    const handleShowModal = (data) => {
        setModalData(data);
        setShowModal(true);
    };
    const handleCloseModal = () => {
        setShowModal(false)
    };

    const styles = {
        control: (styles) => ({
            ...styles,
            background: 'transparent',
            borderRadius: '52px',
            width: '100%',
            height: '50px',
            color: 'white',
            boxShadow: `${isDarkTheme ? darkTheme.boxshadow : lightTheme.boxshadow}`,
            border: `${isDarkTheme ? darkTheme.border : lightTheme.border}`, "&:hover": {
                border: `${isDarkTheme ? darkTheme.border : lightTheme.border}`
            },
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: isDarkTheme && state.isHovered ? '#302F30' : `${({theme})=> theme.body}`,
            ':hover': {
              backgroundColor:  `${isDarkTheme ? '#302F30' : '#D9EDE7'}` ,
            },
            color: isDarkTheme && state.isSelected ? '#1476FF' : `${({theme})=> theme.text}`,
        }),
        indicatorSeparator: () => ({
            all: 'unset'
        }),
        indicatorsContainer: (styles) => ({
            ...styles, color: isDarkTheme ? 'white' : 'black', 'div:nth-child(2)': {
                color: isDarkTheme ? 'white' : lightTheme.text
            }
        }),
        menu: (styles) => ({
            ...styles, background: isDarkTheme ? 'rgb(27, 27, 28)' : 'white',
            color: isDarkTheme ? 'white' : lightTheme.text,
        })
    }

    const formikStep1 = useFormik({
        initialValues: {
            prefferedCurrency: "",
        },
        validationSchema: Yup.object().shape({
            prefferedCurrency: Yup.object().shape({
                value: Yup.string().required('Currency is Required'),
            }),

        }),
        onSubmit: values => {
       // console.log(values);
            handlePreferenceCurrency(formikStep1.values.prefferedCurrency.value)
        }
    })
    const handlePreferenceCurrency = async (crypto) => {
   // console.log("crypto::",crypto);
        try {
            setisSubmit(true);
            let res = await axios.patch(API_URLs.updateCurrency, {
                currency: crypto,
            },{
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('jwt')}`
                }
            })
            setisSubmit(false);
            handleShowModal(res?.data?.msg ? res?.data?.msg : 'Changes have been saved successfully.');
            setNavigateLink('');
            getUserDetails();
        } catch (error) {
            setisSubmit(false);
            handleShowModal(error?.response?.data?.message ? error?.response?.data?.message : 'Some Error Occured!!');
            setNavigateLink('');
        }
       
    }
    useEffect(()=>{
        if(userDetails?.user?.currency){
            let curr = fiatCurrencyOptions.filter((data)=>{
                return data._id === userDetails.user.currency._id;
            })
            formikStep1.setValues({
                prefferedCurrency: curr[0]
            })
        }
        if(fiatCurrencyOptions?.length === 0){
            fetchFiatCurrency();
        }
    },[])
    return (
        <div className=''>
            <CardContainer className='p-4'>
                <ProfileHeading className='w-50'>Preferences</ProfileHeading>
                <hr className='w-75' />
                <div className='mb-4'>
                    <P>Preferred Currency</P>
                    <form className='pb-1' onSubmit={formikStep1.handleSubmit}>
                        <Select className="select"
                            name='prefferedCurrency'
                            id='prefferedCurrency'
                            width='100%'
                            options={fiatCurrencyOptions}
                            styles={styles}
                            filterOption={customFilterForCrypto}
                            onChange={(selectedValues) => {
                                let event = { target: { name: 'prefferedCurrency', value: selectedValues } }
                                formikStep1.handleChange(event)
                            }}
                            onBlur={(selectedValues) => {
                                let event = { target: { name: 'prefferedCurrency', value: selectedValues } }
                                formikStep1.handleBlur(event)
                            }}
                            value={formikStep1.values.prefferedCurrency}
                        />
                        <div className='mt-2 text-end'>
                            <WhiteButton type='button' onClick={()=>{ formikStep1.setValues({prefferedCurrency: ''})}}>Cancel </WhiteButton>
                            <BlueButton type='submit' disabled={isSubmit}>
                            {
                                isSubmit ?
                                    <><ButtonLoader/>Save</> : 'Save'
                            }
                            </BlueButton>
                            
                        </div>
                    </form>
                </div>
            </CardContainer>
            {showModal && (<CustomModal
                show={showModal}
                handleClose={handleCloseModal}
                navigateLink={navigateLink}
                data={modalData}
            />)}
        </div>
    );
}