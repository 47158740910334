// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.text-justify {
    text-align: justify;
}


/* @media screen and (min-width: 720px){
    .qvani-refral-rwrd{
        margin-top: 6rem!important;
    }
    
} */

@media screen and (min-width: 650px){
    .rewrds-para{
        text-align: justify!important;
    }
    
}
`, "",{"version":3,"sources":["webpack://./src/otherPages/Rewards/Rewards.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;AACvB;;;AAGA;;;;;GAKG;;AAEH;IACI;QACI,6BAA6B;IACjC;;AAEJ","sourcesContent":[".text-justify {\n    text-align: justify;\n}\n\n\n/* @media screen and (min-width: 720px){\n    .qvani-refral-rwrd{\n        margin-top: 6rem!important;\n    }\n    \n} */\n\n@media screen and (min-width: 650px){\n    .rewrds-para{\n        text-align: justify!important;\n    }\n    \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
