import React,{useEffect, useState} from 'react'
import { useFormik } from 'formik';
import * as Yup from 'yup'
import { darkTheme, lightTheme } from "../../Theme/theme";
import Select from 'react-select';
import styled from 'styled-components';
import { useThemeContext } from '../../Context/ThemeContext';
import axios from 'axios';
import { API_URLs } from '../../Utils/ApiUrls';
import PropTypes from 'prop-types';
import CustomModal from '../../hooks/CustomModal';
import ButtonLoader from '../../Component/Loader/ButtonLoader';

const Option = [
    {
        label: 'Username',
        value: 'username',
    },
    {
        label: 'Mobile Number',
        value: 'mobile',
    },
    {
        label: 'Email ID',
        value: 'email',
    }
]

export const InputLabel = styled.label`
font-family: 'Inter';
font-style: normal;
font-weight: 700;
font-size: 14px;
color: #303350;
`
export const PrevButton = styled.button`
border: 2px solid #1476FF;
background-color: #1476FF;
color: white;
width: 100%;
border-radius: 20px;
padding: 3px 25px;
font-family: 'Montserrat';
font-style: normal;
font-weight: 600;
font-size: 16px;
`
export const Input = styled.input`
all: unset;
background: transparent;
border: ${({theme})=>theme.border};
box-shadow: ${({theme})=>theme.boxshadow};
border-radius: 52px;
outline: none;
font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 14px;
color: ${({theme})=>theme.text};  

:disabled{
    opacity: 0.8;
    color: #6F7182;
}
`


export default function Paypal({ handleClose, handleSetRefresh, singlePaypalDetails, isPaypalEdit }) {
    const { isDarkTheme } = useThemeContext();
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [modalData, setModalData] = useState(null);
    const [navigateLink, setNavigateLink] = useState('');
    const handleShowModal = (data) => {
        setModalData(data);
        setShowModal(true);
    };
    const handleCloseModal = () => {
        setShowModal(false)
    };

    const handleAddPaypal = (data) => {
        setLoading(true)
        axios.post(API_URLs.paypal, data, {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${localStorage.getItem('jwt')}`
            }
        })
            .then((res) => {
                handleClose();
                setLoading(false)
                handleSetRefresh();
            })
            .catch((error) => {
                setLoading(false)
            })
    }
    const handleUpdatePaypal = (data) => {
        setLoading(true)
        axios.patch(API_URLs.paypal + '/' +singlePaypalDetails.id, data, {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${localStorage.getItem('jwt')}`
            }
        })
            .then((res) => {
                handleClose();
                setLoading(false)
                handleSetRefresh();
            })
            .catch((error) => {
                setLoading(false)
            })
    }

    const formik = useFormik({
        initialValues: { authType: "", username: "", mobile: "", email: "" },
        // validationSchema: Yup.object().shape({
            // authType: Yup.object().shape({
            //     value: Yup.string().required("Auth Type is Required")
            // })
        // }),
        onSubmit: (values) => {
            if(values.authType.value === 'username'){
                isPaypalEdit ? handleUpdatePaypal({ authType: values.authType.value, username: values.username }) : 
                handleAddPaypal({ authType: values.authType.value, username: values.username })
            } else if(values.authType.value === 'mobile'){
                isPaypalEdit ? handleUpdatePaypal({ authType: values.authType.value, mobile: values.mobile }) :
                handleAddPaypal({ authType: values.authType.value, mobile: values.mobile })
            } else{
                isPaypalEdit ? handleUpdatePaypal({ authType: values.authType.value, email: values.email }) :
                handleAddPaypal({ authType: values.authType.value, email: values.email })
            }
        }
    })

    const addValue = ()=>{
        const option = Option?.filter((data)=>{
            return data?.value === singlePaypalDetails?.authType
        })
        formik.setValues({
            authType: option[0],
            ...( singlePaypalDetails?.authType === 'username' ? { username: singlePaypalDetails?.username} : 
                singlePaypalDetails?.authType === 'mobile' ? { mobile: singlePaypalDetails?.mobile} : { email: singlePaypalDetails?.email })            
        })
    }
    useEffect(()=>{
        if(isPaypalEdit){
            addValue();
        }
    },[])

    const styles = {
        control: (styles) => ({
            ...styles,
            background: 'transparent',
            borderRadius: '52px',
            width: '100%',
            height: '50px',
            color: 'white',
            boxShadow: `${isDarkTheme ? darkTheme.boxshadow : lightTheme.boxshadow}`,
            border: `${isDarkTheme ? darkTheme.border : lightTheme.border}`, "&:hover": {
                border: `${isDarkTheme ? darkTheme.border : lightTheme.border}`
            },
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: isDarkTheme && state.isHovered ? '#302F30' : `${({theme})=> theme.body}`,
            ':hover': {
              backgroundColor:  `${isDarkTheme ? '#302F30' : '#D9EDE7'}` ,
            },
            color: isDarkTheme && state.isSelected ? '#1476FF' : `${({theme})=> theme.text}`,
        }),
        indicatorSeparator: () => ({
            all: 'unset'
        }),
        indicatorsContainer: (styles) => ({
            ...styles, color: isDarkTheme ? 'white' : 'black', 'div:nth-child(2)': {
                color: isDarkTheme ? 'white' : lightTheme.text
            }
        }),
        menu: (styles) => ({
            ...styles, background: isDarkTheme ? 'rgb(27, 27, 28)' : 'white',
            color: isDarkTheme ? 'white' : lightTheme.text,
        })
    }
    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <div className='d-flex flex-column mb-2'>
                    <InputLabel htmlFor='authType'>Authentication Type <span className='text-danger'> *</span></InputLabel>
                    <Select
                        options={Option}
                        styles={styles}
                        name='authType'
                        id='authType'
                        onChange={(selectedValues) => {
                            let event = { target: { name: 'authType', value: selectedValues } }
                            formik.handleChange(event)
                        }}
                        onBlur={(selectedValues) => {
                            let event = { target: { name: 'authType', value: selectedValues } }
                            formik.handleBlur(event)
                        }}
                        value={formik.values.authType}
                    />
                </div>
                {formik.values.authType.value === "username" &&
                    <div className='d-flex flex-column mb-2'>
                        <InputLabel htmlFor='username'>Enter Username <span className='text-danger'> *</span></InputLabel>
                        <Input
                            name='username'
                            id='username'
                            className="px-3 py-2"
                            placeholder='Enter Your Username'
                            value={formik.values.username}
                            onChange={formik.handleChange}
                        />
                    </div>}
                { formik.values.authType.value === "mobile" &&
                    <div className='d-flex flex-column mb-2'>
                        <InputLabel htmlFor='mobile'>Enter Mobile Number <span className='text-danger'> *</span></InputLabel>
                        <Input
                            name='mobile'
                            id='mobile'
                            className="px-3 py-2"
                            placeholder='Enter Mobile Number'
                            value={formik.values.mobile}
                            onChange={formik.handleChange}
                        />
                    </div>}
                { formik.values.authType.value === "email" &&
                    <div className='d-flex flex-column mb-2'>
                        <InputLabel htmlFor='email'>Enter Email ID <span className='text-danger'> *</span></InputLabel>
                        <Input
                            name='email'
                            id='email'
                            className="px-3 py-2"
                            placeholder='Enter Your E-Mail ID'
                            value={formik.values.email}
                            onChange={formik.handleChange}
                        />
                    </div>
                }
                <PrevButton  type='submit'>{loading ?  <ButtonLoader/> : isPaypalEdit ? 'Update':'Add'}</PrevButton>
            </form>
            {showModal && (<CustomModal
                show={showModal}
                handleClose={handleCloseModal}
                navigateLink={navigateLink}
                data={modalData}
            />)}
        </>
    );
}
Paypal.propTypes = {
    handleClose: PropTypes.func.isRequired,
    handleSetRefresh: PropTypes.func.isRequired,
};