import React from 'react'
import styled from 'styled-components'
import Image from '../assets/Images/error.png'
import ErrImg from '../assets/Images/error404.png'
import Footer from '../Component/Footer/Index'
import PublicNavbar from '../Component/PublicNavbar/Index'
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import { FaArrowLeft } from "react-icons/fa6";

const Container = styled.div`
    min-height: 80vh;
    background: #fff;
`

const Heading = styled.h1`
    font-family: 'Inter';
    font-size: 80px;
    line-height: 112px;
    font-weight: 700;
    text-align: center;
    color: #222222;
    @media screen and (max-width: 600px){
        font-size: 76px;
    }
`

const Text = styled.p`
    font-family: 'Inter';
    font-weight: 500;
    font-size: 22px;
    line-height: 34px;
    text-align: center;
    color: #0B0E2C;
    color: ${({theme})=> theme.text === "#121620" ? "#0B0E2C" : theme.text};
    @media screen and (max-width: 600px){
        font-size: 18px;
        text-align: center;
        padding: 0 5px;
    }
`

const Button = styled.button`
    width: 200px;
    height: 40px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    background: #3545EE;
    font-family: 'Inter';
    font-weight: 600;
    font-size: 20px;
    outline: none;
    border-radius: 12px;
    text-decoration: none;
    color: white;
    cursor: pointer;
    // :hover{
    //     color: white;
    //     opacity: 0.9;
    // }
`

export default function Error404() {
    const navigate = useNavigate();

    // useEffect(() => {
        const handleNavigate = () => {
            if (localStorage.getItem('jwt')) {
                navigate('/dashboard/user-dashboard');  
            } else {
                navigate('/home');
            }
        };

        handleNavigate();
    // }, []);

    return (
        <>
            <PublicNavbar />
            <Container className='d-flex flex-column align-items-center justify-content-end'>
                <div className='d-flex flex-column align-items-center justify-content-end mb-5'>
                    <img className='' src={ErrImg} alt="Error 404" />
                    <Heading>404 Error</Heading>
                    <Text>
                        <span className='d-lg-block'>Ooops! It seems like we’re having some difficulties;</span>
                        <span className='d-lg-block'>please don’t abandon ship, we’re sending for help.</span>
                    </Text>
                    <Button onClick={() => navigate('/home')} className='text-white'><FaArrowLeft /> Go back</Button>
                </div>
            </Container>
            <Footer />
        </>
    )
}
