import React from 'react'
import styled from 'styled-components'
import img1 from '../../assets/Images/Circle-1.png'
import img2 from '../../assets/Images/Circle-2.png'
import img3 from '../../assets/Images/Circle-3.png'
import './Rewards.css'

const Div = styled.div`
width: 150px;
height: 150px;
background-color: #3545EE;
`
const H1 = styled.h1`
font-size: 75px;
`
const Span = styled.span`
color: #3F3F3F;
`
const ReferFriends = () => {
  return (
    <>
      <div className='d-none d-md-block'>
        <h2 className='text-center fw-bold mt-5'>How To Refer Your Friends</h2>
        <div className='row justify-content-center gap-4 mt-5'>
          <div className='col-md-5 d-flex flex-column align-items-end gap-5 me-4'>
            <Div className='text-light rounded-circle d-flex justify-content-center align-items-center'><H1>01</H1></Div>
            <p className='rewrds-para fs-5 text-dark'>Educate your referrals about crypto and how to trade on Qvani. Every time they buy Bitcoin, you get 30% of their fee. You also make 10% of the escrow fee every time one of their referrals buys Bitcoin on Qvani. Your friends will receive 10% discount on trading fees.</p>
            <Div className='text-light rounded-circle d-flex justify-content-center align-items-center'><H1>03</H1></Div>
          </div>

          <div className='col-md-5 d-flex flex-column gap-5 justify-content-evenly border-start border-3' style={{paddingLeft: "4rem"}}>
            <p className='rewrds-para fs-5 text-dark'>Share your referral code or link on social media, crypto forums, or communities. You can find your unique code or link under your Referral Rewards Dashboard.</p>
            <Div className='text-light rounded-circle d-flex justify-content-center align-items-center'><H1>02</H1></Div>
            <p className='rewrds-para fs-5 text-dark'>Track your referrals’ activity from your Referral Rewards Dashboard and watch your earnings automatically build up. You can cash out your earnings to your Qvani wallet.</p>
          </div>
        </div>
      </div>

      {/* for mobile */}
      <div className='d-md-none d-lg-none border-2 border-start border-secondary ps-3 mt-5'>
        <div className='d-flex gap-3 align-items-center mb-3'>
          <img src={img1} alt="" className='h-100' width={'10%'} />
          <Span>
            You make money every time the people you introduce to Qvani, and the people they invite, trade on Qvani.com.
          </Span>
        </div>
        <div className='d-flex gap-3 align-items-center mb-3'>
          <img src={img2} alt="" className='h-100' width={'10%'} />
          <Span>
            Educate your referrals about crypto and how to trade on Qvani. Every time they buy Bitcoin, you get 30% of their fee. You also make 10% of the escrow fee every time one of their referrals buys Bitcoin on Qvani. Your friends will receive 10% discount on trading fees.
          </Span>
        </div>
        <div className='d-flex gap-3 align-items-center mb-3'>
          <img src={img3} alt="" className='h-100' width={'10%'} />
          <Span>
            Track your referrals’ activity from your Referral Rewards Dashboard and watch your earnings automatically build up. You can cash out your earnings to your Qvani wallet.
          </Span>
        </div>

      </div>

    </>
  )
}

export default ReferFriends