import React, { useState, useEffect } from "react";
import { useThemeContext } from "../../Context/ThemeContext";
import styled from "styled-components";
import Select from "react-select";
import DataTable, { createTheme } from "react-data-table-component";
import "./Wallet.css";
import axios from "axios";
import ConvertImg from "../../images/Group 42449.png";
import { API_URLs } from "../../Utils/ApiUrls";
import { darkTheme, lightTheme } from "../../Theme/theme";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CustomModal from "../../hooks/CustomModal";

const Input = styled.input`
  border: none;
  border-bottom: 1px solid grey;
  outline: none;
  font-size: 16px;
  color: grey;
  font-weight: 600;
  width: 65%;
  background: transparent;
`;
const Button = styled.button`
  background: #1476ff;
  border: none;
  color: white;
  border-radius: 20px;
  padding: 5px 20px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
`;
const ImgDiv = styled.div`
  background: #defffa;
  padding: 7px;
  border: none;
  border-radius: 10px;
`;
const Card = styled.div`
  border: ${({ theme }) => theme.border};
  box-shadow: ${({ theme }) => theme.boxshadow};
  padding: 12px;
  border-radius: 20px;
  background: ${({ theme }) =>
    theme.body == "#F7F9FC" ? "white" : theme.body};
`;
const conertTxnData = [
  {
    fromAmount: "0.002",
    fromCrypto: "btc",
    toAmount: "1.23",
    toCrypto: "eth",
    date: "12-04-2023",
  },
  {
    fromAmount: "0.002",
    fromCrypto: "btc",
    toAmount: "1.23",
    toCrypto: "eth",
    date: "12-04-2023",
  },
  {
    fromAmount: "0.002",
    fromCrypto: "btc",
    toAmount: "1.23",
    toCrypto: "eth",
    date: "12-04-2023",
  },
];
const column = [
  {
    name: "From Amount",
    selector: (row) => <div>{row.fromAmount}</div>,
  },
  {
    name: "From Crypto",
    selector: (row) => <div>{row.fromCrypto}</div>,
  },
  {
    name: "To Amount",
    selector: (row) => <div>{row.toAmount}</div>,
  },
  {
    name: "To Crypto",
    selector: (row) => <div>{row.toCrypto}</div>,
  },
];
export default function Convert() {
  const { isDarkTheme } = useThemeContext();
  const [isFill, setisFill] = useState(false);
  const [convertFrom, setconvertFrom] = useState();
  const [convertFromAmount, setconvertFromAmount] = useState("");
  const [convertTo, setconvertTo] = useState();
  const [convertToAmount, setconvertToAmount] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [exchangeRateValue, setExchangeRateValue] = useState('')

  const handleShowModal = (data) => {
    setModalData(data);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  // changly
  const [currencyList, setCurrencyList] = useState([]);
  const GetCurrency = () => {
    axios
      .get(API_URLs.getChangelyCurrency, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      })
      .then((res) => {
        let changlyCryptoList =
          res.data?.length > 0 &&
          res.data.map((currencyObject) => {
            return {
              ...currencyObject,
              value: currencyObject.ticker,
              label: `${
                currencyObject.name != "MATICPOLYGON"
                  ? currencyObject.name
                  : "MATIC"
              }`,
              name: currencyObject?.name,
              image: currencyObject?.image,
            };
          });
        setCurrencyList(changlyCryptoList || []);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    GetCurrency();
  }, []);

  const convertHandle = () => {
    setisFill(true);
    axios
      .post(
        API_URLs.getChangelyCreateTxn,
        {
          hash: localStorage.getItem("changely-hash"),
        },
        {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        }
      )
      .then((res) => {
        // toast.success('Conversion successful!');
        console.log("convertHandle", res);
        handleShowModal("The conversion has been completed successfully.");
        setShowModal(true);
        localStorage.removeItem("changely-hash");
        // setconvertToAmount(res.data[0]?.amountTo)
      })
      .catch((error) => {
        console.log("convertHandleError", error);
        // toast.error('Conversion failed!');
        handleShowModal(
          "Conversion failed because there was not enough balance available."
        );
        setconvertToAmount("");
      })
      .finally(() => {
        setisFill(false);
      });
  };

  const exchangeRate = async (currency, balance, crypto) => {
    try {
        if(convertFrom?.name && convertTo?.name){
            let res = await axios.get(API_URLs.cryptoToFiat + '?from=' + crypto + '&amount=' + balance + '&target=' + currency)
            setExchangeRateValue(res?.data)
            return res.data;
        }
    } catch (error) {

    }
}

  const convertCrypto = (from, to, amount) => {
    if (from && to && amount) {
      axios
        .get(API_URLs.getChangelyFixAmount(from, to, amount), {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${localStorage.getItem("jwt")}`,
          },
        })
        .then((res) => {
          console.log("convertCrypto", res);
          // handleShowModal(res.)
          setconvertToAmount(res?.data?.Amount);
          localStorage.setItem("changely-hash", res?.data?.hash);
        })
        .catch((error) => {
          console.log("convertCryptoError", error);
          handleShowModal(error?.response?.data?.message);
          // const errorMsg = error.response?.data?.message || "An error occurred";
          // const minimalAmount = parseFloat(errorMsg.match(/(\d+(\.\d+)?)/)[0]).toFixed(2);
          // const formattedErrorMsg = errorMsg.replace(/(\d+(\.\d+)?)/, minimalAmount);

          // toast.error(formattedErrorMsg);
          setconvertToAmount("");
        });
    }
  };

  const styles = {
    control: (styles) => ({
      ...styles,
      background: "transparent",
      borderRadius: "52px",
      width: "100%",
      height: "50px",
      color: "white",
      boxShadow: `${isDarkTheme ? darkTheme.boxshadow : lightTheme.boxshadow}`,
      border: `${isDarkTheme ? darkTheme.border : lightTheme.border}`,
      "&:hover": {
        border: `${isDarkTheme ? darkTheme.border : lightTheme.border}`,
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor:
        isDarkTheme && state.isHovered
          ? "#302F30"
          : `${({ theme }) => theme.body}`,
      ":hover": {
        backgroundColor: `${isDarkTheme ? "#302F30" : "#D9EDE7"}`,
      },
      color:
        isDarkTheme && state.isSelected
          ? "#1476FF"
          : `${({ theme }) => theme.text}`,
    }),
    indicatorSeparator: () => ({
      all: "unset",
    }),
    indicatorsContainer: (styles) => ({
      ...styles,
      color: isDarkTheme ? "white" : "black",
      "div:nth-child(2)": {
        color: isDarkTheme ? "white" : lightTheme.text,
      },
    }),
    menu: (styles) => ({
      ...styles,
      background: isDarkTheme ? "rgb(27, 27, 28)" : "white",
      color: isDarkTheme ? "white" : lightTheme.text,
    }),
  };
  createTheme("solarized", {
    background: {
      default: `${isDarkTheme ? "#0c0c0c" : "white"}`,
    },
    action: {
      hover: "rgba(0,0,0,.08)",
    },
    text: {
      secondary: `${isDarkTheme ? "white" : "black"}`,
    },
  });
  const customStyles = {
    header: {
      style: {
        fontSize: "20px",
        color: "#0B0E2C",
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: "600",
      },
    },
    headRow: {
      style: {
        fontFamily: "Inter",
        fontStyle: "normal",
        fontSize: "12px",
        ...(isDarkTheme
          ? { borderTop: "1px solid #302f30" }
          : { border: "1px solid lightgray" }),
        color: `${isDarkTheme ? "#e9e9e9" : "#6F7182"}`,
        backgroundColor: `${isDarkTheme ? "#0c0d0d" : "#F7F9FC"}`,
        textTransform: "uppercase",
      },
    },
    rows: {
      style: {
        color: `${isDarkTheme && "white"}`,
        backgroundColor: `${isDarkTheme && "rgba(8, 9, 14, 0.96)"}`,
        borderTop: "1px solid lightgray",
        "&:not(:last-of-type)": {
          borderBottom: "none",
        },
      },
    },
    headCells: {
      style: {
        display: "flex",
        alignItems: "center",
      },
    },
    cells: {
      style: {
        display: "flex",
        alignItems: "center",
      },
    },
  };

  const convertStateHandle = () => {
    let from = convertFrom;
    let to = convertTo;
    convertCrypto(from?.value, to?.value, convertFromAmount);
    setconvertFrom(to);
    setconvertTo(from);
  };

  console.log("convert from ", convertFrom, convertTo);

  return (
    <div>
      <ToastContainer />
      <div className="row m-0 d-flex justify-content-center align-items-center">
        <Card className="card col-md-7 col-sm-12 d-flex flex-column h-100 px-4 py-3">
          <h1 className="convertTitle text-center">
            Convert <span>{convertFrom?.name || 'BTC'}</span> to{" "}
            <span>{convertTo?.name || 'USDT'}</span>
          </h1>
          <p className="mb-0 convert-para">You are Converting</p>
          <div className="d-flex pt-3 pb-2 input-dev">
            <Input
              className="flex-grow-1 border-0"
              value={convertFromAmount}
              onChange={(e) => {
                setconvertFromAmount(e.target.value);
                convertCrypto(
                  convertFrom?.value,
                  convertTo?.value,
                  e.target.value
                );
                exchangeRate(convertFrom?.name, convertFromAmount, convertTo?.name )
              }}
              placeholder="Enter Amount"
            />
            <div className="select-token-option d-flex align-items-center px-2">
              {convertFrom?.name && (
                <div className="selectedTokenIconNname d-flex align-items-center">
                  <img src={convertFrom?.image} />
                  <span>{convertFrom?.name}</span>
                </div>
              )}
              <Select
                className="select selectCurrency"
                value={convertFrom}
                width="100%"
                options={currencyList?.filter((data) => {
                  return data.name !== convertTo?.name;
                })}
                styles={styles}
                onChange={(selectedOption) => {
                  setconvertFrom(selectedOption);
                  convertCrypto(
                    convertFrom?.value,
                    convertTo?.value,
                    convertFromAmount
                  );
                  exchangeRate(convertFrom?.name, convertFromAmount, convertTo?.name )
                }}
              />
            </div>
            {/* <Select className="select"
                            value={convertFrom}
                            width='100%'
                            options={currencyList?.filter((data) => {
                                return data.name !== convertTo?.name;
                            })}
                            styles={styles}
                            onChange={(selectedOption) => {
                                setconvertFrom(selectedOption)
                                convertCrypto(convertFrom?.value, convertTo?.value, convertFromAmount)
                            }
                            }
                        /> */}
          </div>
          <div className="group_button my-3 d-flex align-items-center gap-2 gap-md-4 flex-wrap">
            <button>Minimum Amount</button>
            <button>Maximum Amount</button>
          </div>
        </Card>
      </div>
      <div className="d-flex justify-content-center align-items-center">
        <ImgDiv onClick={convertStateHandle} className="text-center">
          <img src={ConvertImg} alt="Convert" width="60%" />
        </ImgDiv>
      </div>
      <div className="m-0 row d-flex justify-content-center align-items-center">
        <Card className="card col-md-7 col-sm-12 d-flex flex-column h-100 px-4 py-3">
          <p className="mb-0 convert-para">You will Receive</p>
          <div className="d-flex justify-content-between pt-3 pb-2 input-dev">
            <Input
              className="flex-grow-1 border-0"
              value={convertToAmount}
              placeholder="You'll Receive Amount"
              disabled
            />
            <div className="select-token-option d-flex align-items-center px-2">
              {convertTo?.name && (
                <div className="selectedTokenIconNname d-flex align-items-center">
                  <img src={convertTo?.image} />
                  <span>{convertTo?.name}</span>
                </div>
              )}
              <Select
                className="select selectCurrency"
                value={convertTo}
                width="100%"
                options={currencyList?.filter((data) => {
                  return data.name !== convertFrom?.name;
                })}
                styles={styles}
                onChange={(selectedOption) => {
                  setconvertTo(selectedOption);
                  convertCrypto(
                    convertFrom?.value,
                    convertTo?.value,
                    convertFromAmount
                  );
                  exchangeRate(convertFrom?.name, convertFromAmount, convertTo?.name )
                }}
              />
            </div>
          </div>
          <p className="mt-2">Exchange Rate = {exchangeRateValue?.convertedRate ? exchangeRateValue?.convertedRate?.toFixed(6) : 0} {exchangeRateValue?.target}</p>
        </Card>
      </div>
      <div className="text-center mt-3">
        <Button disabled={isFill} onClick={() => convertHandle()}>
          {isFill ? "converting..." : "Convert"}
        </Button>
      </div>
      {/* <div className='row m-0 mt-3 d-flex justify-content-center '>
                <Card className='col-md-11 px-0'>
                <p className='fw-bold fs-6 mb-0 px-3'>Transactions</p>
                    <DataTable 
                    data={conertTxnData}
                    columns={column}
                    theme='solarized'
                    customStyles={customStyles}
                    />
                </Card>
            </div> */}
      {showModal && (
        <CustomModal
          show={showModal}
          handleClose={handleCloseModal}
          data={modalData}
        />
      )}
    </div>
  );
}
