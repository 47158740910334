import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Edit from '../../assets/Images/ic-edit.png'
import Delete from '../../assets/Images/ic-delete.png'
import WalletIcon from '../../assets/Images/WalletIcon.png'
import axios from 'axios'
import { API_URLs } from '../../Utils/ApiUrls'
import Modal from 'react-bootstrap/Modal';
import ButtonLoader from '../../Component/Loader/ButtonLoader'
import DataLoader from '../../Component/Loader/DataLoader'
import { CardContainer, ChangeButton, ProfileHeading, SecurityCard, SubHeading } from './StyledComponents'
import { useAPIContextHook } from '../../Context/ApiContext'
import Card from '../PayMethod/Card'
import { v4 as uuidv4 } from 'uuid';
import { useThemeContext } from '../../Context/ThemeContext'

const Para = styled.p`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
`
const BoldPara = styled.p`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
`
const Span = styled.span`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #7A7A7A;
    text-transform: capitalize;
`
export default function PaymentMethod() {
    const {isDarkTheme} = useThemeContext();
    const { fetchCardDetail } = useAPIContextHook();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [cardDetails, setCardDetails] = useState([]);
    const [isCardEdit, setIsCardEdit] = useState(false)
    const [singleCardDetails, setSingleCardDetails] = useState({})
    const [refresh, setRefresh] = useState(false);

    const [onDelete, setOnDelete] = useState({
        id: '',
        'loading': false
    })

    const handleSetRefresh = () => setRefresh(!refresh)

    let handleFetchCardDetails = async () => {
        try {
            let res = await fetchCardDetail()
            let options = res?.length > 0 && res.map((card) => {
                return {
                    ...card,
                    value: card?._id,
                    label: <div className='d-flex'>
                        <Para className='mb-0'>{card?.cardNo}</Para> -
                        <Para className='mb-0'>{card?.cardHolderName}</Para>
                        <Para className='mb-0'>{card?.cardType}</Para>
                    </div>
                }
            })
            setCardDetails(options)


        }
        catch (error) {
        }
    }

    const deleteCard = async (id) => {
        setOnDelete({
            id,
            'loading': true
        })
        try {
            const res = await axios.delete(API_URLs.deleteCard(id), {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('jwt')}`
                }
            })


            setOnDelete({
                id: "",
                'loading': false
            })
        } catch (error) {
            setOnDelete({
                id: "",
                'loading': false
            })
        }
    }

    useEffect(() => {
        if (!onDelete.loading) {
            handleFetchCardDetails()
        }
    }, [onDelete, refresh])

    return (
        <div>
            <CardContainer className='container py-4 px-4'>
                <div className='d-flex flex-column flex-md-row justify-content-between'>
                    <div className='d-flex flex-column w-100 w-md-50'>
                        <ProfileHeading className=''>Credit/ Debit Card</ProfileHeading>
                    </div>

                    <div className='pt-2'>
                        <ChangeButton className='px-3 py-1' onClick={() => {
                            setSingleCardDetails({})
                            setIsCardEdit(false)
                            handleShow()
                        }}>Add Card</ChangeButton>
                    </div>
                </div>
                <SubHeading>
                    Add your Card below. You can share these details with your trade partner via trade chat, for Card type trades.
                </SubHeading>
                {
                    cardDetails?.length > 0 ? cardDetails.map((details) => {
                        return (
                            <div className='pt-4' key={uuidv4()}>
                                <SecurityCard className='p-3'>
                                    <div className='d-flex gap-3'>
                                        <BoldPara className='w-25'>Card No.</BoldPara>
                                        <Span>: {details?.cardNo}</Span>
                                
                                    </div>
                                    <div className='d-flex gap-3'>
                                        <BoldPara className='w-25'>Holder Name</BoldPara>
                                        <Span>: {details?.cardHolderName}</Span>
                                    </div>
                                    <div className='d-flex gap-3'>
                                        <BoldPara className='w-25'>Card Type</BoldPara>
                                        <Span>: {details?.cardType}</Span>
                                    </div>
                                    <div className='d-flex justify-content-end align-items-lg-center flex-column flex-lg-row gap-2 gap-lg-0'>

                                        <div className='d-flex gap-3'>

                                            <ChangeButton onClick={() => { deleteCard(details?.id) }}
                                                className='d-flex align-items-center gap-2 px-3 py-1'>

                                                {
                                                    details?.id === onDelete?.id && onDelete?.loading ?
                                                        <div className='px-4'>
                                                            <ButtonLoader /> <span>Delete</span>
                                                        </div>
                                                        : <>
                                                            <img src={Delete} alt="delete" />
                                                            Delete
                                                        </>
                                                }
                                            </ChangeButton>
                                            <ChangeButton onClick={() => {
                                                setIsCardEdit(true)
                                                setSingleCardDetails(details)
                                                handleShow()
                                            }} className='d-flex align-items-center gap-2 px-3 py-1'>
                                                <img src={Edit} alt="delete"/>
                                                Edit
                                            </ChangeButton>
                                        </div>
                                    </div>
                                </SecurityCard>
                            </div>
                        )
                    }) : <div className='pt-3'>
                        {
                            onDelete?.loading ?
                                <div className='px-4 d-flex justify-content-center align-items-center'>
                                    <DataLoader />
                                </div> :
                                <CardContainer className='p-3 py-4 d-flex justify-content-center'>
                                    <div className='d-flex flex-column justify-content-center align-items-center'>
                                        <img src={WalletIcon} alt="wallet"/>
                                        <Span>No Card are added</Span>
                                    </div>
                                </CardContainer>

                        }

                    </div>
                }

                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton style={{ background: `${isDarkTheme ? '#0c0c0c' : 'white'}`, wordBreak: 'break-word' }}> 
                        <Modal.Title>
                            <ProfileHeading className='m-0'>{isCardEdit ? 'Update Card' : 'Add Card'}</ProfileHeading>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ background: `${isDarkTheme ? '#0c0c0c' : 'white'}`, wordBreak: 'break-word' }}>
                        <Card handleClose={handleClose} singleCardDetails={singleCardDetails} isCardEdit={isCardEdit} handleSetRefresh={handleSetRefresh} />
                    </Modal.Body>
                </Modal>
            </CardContainer >
        </div >
    )
}
