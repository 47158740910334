// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.otp-input-box-trade > input{
    width: 38px !important;
    height: 38px !important;
    text-align: center;
    margin-right: 8px !important;
    border-radius: 9px;
    border: 1px solid black
}`, "",{"version":3,"sources":["webpack://./src/pages/TradeScreen/Index.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,uBAAuB;IACvB,kBAAkB;IAClB,4BAA4B;IAC5B,kBAAkB;IAClB;AACJ","sourcesContent":[".otp-input-box-trade > input{\n    width: 38px !important;\n    height: 38px !important;\n    text-align: center;\n    margin-right: 8px !important;\n    border-radius: 9px;\n    border: 1px solid black\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
